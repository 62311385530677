/** @jsxImportSource theme-ui */
import { Box, ControlledToggle, Flex, IconRefresh, LegacySelect, Text } from "@powerledger/ui-component-lib";
import { FC, useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";

export const DemoToggle: FC = () => {
	const [isDemoView, setIsDemoView] = useState(() => {
		const storedValue = localStorage.getItem("isDemoView");
		return storedValue ? JSON.parse(storedValue) : false;
	});

	const [client, setClient] = useState(() => {
		const storedValue = localStorage.getItem("demoClient");
		return storedValue || null;
	});

	useEffect(() => {
		localStorage.setItem("isDemoView", JSON.stringify(isDemoView));
	}, [isDemoView]);

	const clientOptions = [
		{ value: "default", label: "Default" },
		{ value: "endeavour", label: "Endeavour Energy" },
		{ value: "tata", label: "Tata Power" },
		{ value: "cesc", label: "CESC" },
	];

	return (
		<Box>
			<Flex sx={{ pt: 6, flexDirection: "column", alignItems: "center" }}>
				{/* eslint-disable-next-line i18next/no-literal-string */}
				<Text sx={{ pb: 3, fontSize: 5 }}>Toggle Stubbed Demo Data</Text>
				<ControlledToggle
					optionOne="On"
					optionTwo="Off"
					selected={!isDemoView}
					onClick={() => setIsDemoView(!isDemoView)}
				/>
				<Flex sx={{ alignItems: "center", mt: 4 }}>
					{/* eslint-disable-next-line i18next/no-literal-string */}
					<Text sx={{ px: 3, fontSize: 3 }}>Select client</Text>
					<LegacySelect
						sx={{ minWidth: 200, width: "100%" }}
						translation={{ allSelected: "All Selected", loading: "loading" }}
						value={clientOptions.find((option) => client === option.value) || null}
						options={clientOptions}
						onChange={(option) => {
							localStorage.setItem("demoClient", option?.value || "");
							setClient(option?.value || "");
						}}
					/>
				</Flex>
				<RouterLink to={"/dashboard"} sx={{ pt: 3 }}>
					<IconRefresh size={9} />
				</RouterLink>
			</Flex>
		</Box>
	);
};

export const isDemoView = () => {
	const storedValue = localStorage.getItem("isDemoView");
	return storedValue ? JSON.parse(storedValue) : false;
};
