import { Box, Grid, Text } from "@powerledger/ui-component-lib";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { commaSeparateNumberToThousandPlaces } from "@/app/util";

import { ContentBox } from "../../../../../components";
import { TradingGroupSummaryType } from "./trading-group-summary.types";

export const TradingGroupSummary: FC<TradingGroupSummaryType> = ({
	residentialConsumers,
	residentialProsumers,
	residentialBESSProsumers,
	cIConsumers,
	cIProsumers,
	cIBESSProsumers,
	communityBattery,
}) => {
	const { t } = useTranslation();
	const totalValue = useMemo(() => {
		return (
			(residentialConsumers || 0) +
			(residentialProsumers || 0) +
			(residentialBESSProsumers || 0) +
			(cIConsumers || 0) +
			(cIProsumers || 0) +
			(cIBESSProsumers || 0) +
			(communityBattery || 0)
		);
	}, [
		residentialConsumers,
		residentialProsumers,
		residentialBESSProsumers,
		cIConsumers,
		cIProsumers,
		cIBESSProsumers,
		communityBattery,
	]);
	return (
		<Box pb={4} mb={3}>
			<Text sx={{ fontSize: 3 }}>{t("LEM Trading Group")}</Text>
			<Grid columns={[1]} gap={3} mt={2} sx={{ height: "100%" }}>
				<Grid columns={4} gap={3} grid-auto-rows={"1fr"}>
					<Box>
						<ContentBox
							title={"Consumers"}
							value={commaSeparateNumberToThousandPlaces(residentialConsumers)}
							subtitle={"Residential"}
							testid="trading-group-summary--residential-consumers"
						/>
					</Box>
					<Box>
						<ContentBox
							title={"Prosumers"}
							value={commaSeparateNumberToThousandPlaces(residentialProsumers)}
							subtitle={"Residential"}
							testid="trading-group-summary--residential-prosumers"
						/>
					</Box>
					<Box>
						<ContentBox
							title={"Prosumers + BESS"}
							value={commaSeparateNumberToThousandPlaces(residentialBESSProsumers)}
							subtitle={"Residential"}
							testid="trading-group-summary--residential-prosumers-bess"
						/>
					</Box>
					<Box>
						<ContentBox
							title={"Total"}
							value={commaSeparateNumberToThousandPlaces(totalValue)}
							strong
							testid="trading-group-summary--total"
						/>
					</Box>
				</Grid>
				<Grid columns={4} gap={3}>
					<Box>
						<ContentBox
							title={"Consumers"}
							value={commaSeparateNumberToThousandPlaces(cIConsumers)}
							subtitle={"C&I"}
							testid="trading-group-summary--c&i-consumers"
						/>
					</Box>
					<Box>
						<ContentBox
							title={"Prosumers"}
							value={commaSeparateNumberToThousandPlaces(cIProsumers)}
							subtitle={"C&I"}
							testid="trading-group-summary--c&i-prosumers"
						/>
					</Box>
					<Box>
						<ContentBox
							title={"Prosumers + BESS"}
							value={commaSeparateNumberToThousandPlaces(cIBESSProsumers)}
							subtitle={"C&I"}
							testid="trading-group-summary--c&i-prosumers-bess"
						/>
					</Box>
					<Box>
						<ContentBox
							title={"Community Battery"}
							value={commaSeparateNumberToThousandPlaces(communityBattery)}
							testid="trading-group-summary--community-battery"
						/>
					</Box>
				</Grid>
			</Grid>
		</Box>
	);
};
