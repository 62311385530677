import { AuthnTransaction } from "@okta/okta-auth-js";
import { useOktaAuth } from "@okta/okta-react";
import { Box, Button, Flex, IconEye, IconLoading, IconNoEye } from "@powerledger/ui-component-lib";
import { Formik, FormikValues } from "formik";
import { FormikHelpers } from "formik/dist/types";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { object, string } from "yup";

import { ErrorMessage, Footer, Form, InputWithAction, LemLogo } from "../../../components";

interface LoginFormValues {
	username: string;
	password: string;
}

export const LoginBase: FC = () => {
	const { t } = useTranslation();

	const { oktaAuth } = useOktaAuth();
	const [showPassword, setShowPassword] = useState(false);
	const history = useHistory();

	useEffect(() => {
		const hasToken = oktaAuth.getAccessToken();
		if (hasToken) history.push("/dashboard");
	}, [history, oktaAuth]);

	const EyeComponent = useMemo(
		() => (
			<Flex onClick={() => setShowPassword(!showPassword)} sx={{ cursor: "pointer", alignItems: "center" }}>
				{showPassword ? <IconNoEye size={9} color="" /> : <IconEye size={9} color="" />}
			</Flex>
		),
		[showPassword],
	);

	const handleLogin = useCallback(
		async ({ username, password }: FormikValues, { setStatus }: FormikHelpers<LoginFormValues>) => {
			try {
				const loginTransaction: AuthnTransaction = await oktaAuth.signInWithCredentials({
					username,
					password,
				});
				if (loginTransaction.status === "SUCCESS") {
					await oktaAuth.token.getWithRedirect({
						sessionToken: loginTransaction.sessionToken,
					});
				} else {
					throw new Error(`Unknown transaction state hit: ${loginTransaction.status}`);
				}
			} catch (error: any) {
				setStatus("Sorry, we don't recognise this username & password combination.");
			}
		},
		[oktaAuth],
	);
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "center",
				height: "100vh",
				backgroundImage: "url(/background-illustration.svg)",
				backgroundPositionY: "bottom",
				backgroundPositionX: "right",
				backgroundRepeat: "no-repeat",
				backgroundSize: ["90%", "63%", "53%", "37%"],
			}}
		>
			<Formik
				initialValues={{
					username: "",
					password: "",
				}}
				validationSchema={object().shape({
					username: string().required(t("Username is required")),
					password: string().required(t("Password is required")),
				})}
				onSubmit={handleLogin}
			>
				{({ handleSubmit, isSubmitting, status, setFieldValue }) => (
					<Box
						sx={{
							width: "100%",
							maxWidth: 580,
							px: [4, 5],
							mb: 6,
						}}
					>
						<Form onSubmit={handleSubmit} sx={{ mt: 4, fontSize: 1, display: "flex", alignItems: "center" }}>
							<Box sx={{ pb: 4 }}>
								<LemLogo width={200} />
							</Box>
							<Form.Item sx={{ width: "100%" }}>
								<InputWithAction
									noButton
									color="grey"
									placeholder={t("Username")}
									variant="forms.inputWithAction.LEM"
									disabled={isSubmitting}
									autoCapitalize="none"
									onInput={(e) => {
										setFieldValue("username", (e.target as HTMLInputElement).value);
									}}
								/>
								<ErrorMessage name="username" sx={{ mt: 2 }} />
							</Form.Item>

							<Form.Item sx={{ width: "100%" }}>
								<InputWithAction
									noButton
									onInput={(e) => {
										setFieldValue("password", (e.target as HTMLInputElement).value);
									}}
									variant="forms.inputWithAction.LEM"
									type={showPassword ? "text" : "password"}
									placeholder={t("Password")}
									disabled={isSubmitting}
									suffix={EyeComponent}
								/>
								<ErrorMessage name="password" sx={{ mt: 2 }} />
							</Form.Item>
							<Form.Item sx={{ width: "100%" }}>
								<Button disabled={isSubmitting} onClick={() => handleSubmit} variant="LEM.action">
									<span data-testid="loginButton">{isSubmitting ? <IconLoading size="small" /> : t("Log in")}</span>
								</Button>
							</Form.Item>
							{!!status && <Box sx={{ color: "yellow", mt: 3 }}>{t(status)}</Box>}
							<Form.Item>
								<Flex sx={{ alignItems: "center", flexDirection: ["column", "row"] }}>
									<Button
										variant={"text"}
										onClick={() => history.push("/forgot-password")}
										sx={{
											textDecoration: "underline",
											textDecorationColor: "primary",
											fontWeight: "medium",
											"&:hover": {
												textDecoration: "underline",
												textDecorationColor: "primary",
												color: "primary",
											},
										}}
									>
										{t("Forgot your password")}
									</Button>
								</Flex>
							</Form.Item>
						</Form>
					</Box>
				)}
			</Formik>
			<Footer />
		</Box>
	);
};

export const Login = () => {
	return <LoginBase />;
};
