import { eachDayOfInterval, format, sub } from "date-fns";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { useOkta } from "../../hooks/use-okta";
import {
	useParticipantTradeSummaryQuery,
	UserRole,
	useTradingGroupTradeComparisonQuery,
	useTradingGroupTradeSummaryQuery,
	useUserProfileQuery,
	useUsersQuery,
	useUserTariffsQuery,
} from "../../types/generated/graphql";
import { UseDashboardType } from "./dashboard.types";

const adminRoles = [UserRole.AppHostAdmin, UserRole.PlatformAdmin, UserRole.TenantAdmin];

export const useDashboard: UseDashboardType = () => {
	const history = useHistory();
	const [currentTab, setCurrentTab] = useState("/dashboard");
	const [date, setDate] = useState<Date | string | [Date | null, Date | null] | null>(new Date());
	const [showUserAlert, setShowUserAlert] = useState(false);
	const [useUsersLoading, setUseUsersLoading] = useState(false);
	const [showBidAlert, setShowBidAlert] = useState(false);

	const { identityProviderRef } = useOkta();

	// JIRA-1160 -> To remove FE Demo Stubbed Data
	const start = sub(new Date(), { days: 4 });
	const end = new Date();
	const dateRange = eachDayOfInterval({ start, end }).map((day) => format(day, "yyyy-MM-dd"));
	const [demoDataIndex, setDemoDataIndex] = useState<number>(() =>
		dateRange.indexOf(format(Date.parse(date?.toString() || ""), "yyyy-MM-dd")),
	);

	const { data: profileData, loading } = useUserProfileQuery({
		variables: {
			identityProviderRef,
		},
		skip: !identityProviderRef,
	});

	useUsersQuery({
		variables: {
			where: {
				tenantId: profileData?.userProfile?.tenantId,
			},
		},
		skip:
			!profileData?.userProfile?.tenantId ||
			!(profileData?.userProfile?.roles && adminRoles.some((role) => profileData?.userProfile?.roles?.includes(role))),
		onCompleted: (usersData) => {
			if (usersData.users) {
				const userList =
					usersData.users.edges?.filter((user) => {
						return (
							profileData?.userProfile.username !== user?.node?.username &&
							!adminRoles.some((role) => user?.node?.roles && user?.node?.roles.includes(role))
						);
					}) || [];
				setShowUserAlert(!(userList.length > 0));
			}
			setUseUsersLoading(false);
		},
	});

	useUserTariffsQuery({
		variables: {
			where: {
				userId: profileData?.userProfile?.id,
			},
		},
		skip:
			!profileData?.userProfile?.id ||
			!(profileData?.userProfile?.roles && !adminRoles.some((role) => profileData?.userProfile?.roles?.includes(role))),
		onCompleted: (tarrifs) => {
			if (tarrifs.userTariffs?.length === 0) {
				setShowBidAlert(true);
			}
		},
	});

	const userIsAdmin = adminRoles.some((role) => profileData?.userProfile?.roles?.includes(role));

	// Make query `ParticipantTradeSummary` if user is participant, otherwise `TradingGroupTradeSummary`
	const isParticipantUser = profileData?.userProfile?.roles?.some((role) => role === UserRole.ParticipantUser);

	// TODO: After BE is hooked up, call `refetch` function from the query, on change in date
	// TODO: Use `settlementId` to filter trade summary
	const { data: tradingGroupTradeSummary } = useTradingGroupTradeSummaryQuery({
		variables: {
			filter: {
				tenantId: profileData?.userProfile?.tenantId || "",
				tradingGroupId: profileData?.userProfile?.tradingGroupId || "",
				applicationHostId: profileData?.userProfile?.applicationHostId || "",
				// endDateTime: date && format(Date.parse(date.toString()), "yyyy-MM-dd hh:mm:ssX").replace(" ", "T"),
			},
		},
		skip: !profileData || isParticipantUser,
	});

	const { data: participantTradeSummary } = useParticipantTradeSummaryQuery({
		skip: !profileData || !isParticipantUser,
	});

	// TODO: After BE is hooked up, call `refetch` function from the query, on change in date
	// TODO: Use `settlementId` to filter trade summary
	// Skip if the user is not admin
	const { data: tradeComparisonSummaryData } = useTradingGroupTradeComparisonQuery({
		variables: {
			filter: {
				tenantId: profileData?.userProfile?.tenantId || "",
				tradingGroupId: profileData?.userProfile?.tradingGroupId || "",
				applicationHostId: profileData?.userProfile?.applicationHostId || "",
				// endDateTime: date && format(Date.parse(date.toString()), "yyyy-MM-dd hh:mm:ssX").replace(" ", "T"),
			},
		},
		skip: !profileData || !userIsAdmin,
	});

	const switchTab = useCallback(
		(tab: string) => {
			history.push({ pathname: tab });
			setCurrentTab(tab);
		},
		[history],
	);

	useEffect(() => {
		setCurrentTab(window.location.pathname);
	}, []);

	const isLoading = loading || useUsersLoading;
	const tradeSummaryData =
		tradingGroupTradeSummary?.tradingGroupTradeSummary || participantTradeSummary?.participantTradeSummary;

	return {
		userIsAdmin,
		tradeSummaryData,
		tradeComparisonSummaryData,
		switchTab,
		currentTab,
		start,
		end,
		date,
		setDate,
		showUserAlert,
		showBidAlert,
		isLoading,
		demoDataIndex,
		setDemoDataIndex,
		dateRange,
	};
};
