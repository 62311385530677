import { Box, SimpleButton, Text } from "@powerledger/ui-component-lib";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { UserAlertTypes } from "./user-alert.types";

export const UserAlert: FC<UserAlertTypes> = ({ message, link, linkState = {} }) => {
	const { t } = useTranslation();
	const history = useHistory();
	return (
		<Box onClick={() => history.push({ pathname: link, state: linkState })}>
			<SimpleButton
				sx={{
					width: ["100%", null, "50vw"],
					borderRadius: 3,
					border: "1px solid",
					borderColor: "primary",
					p: 3,
					color: "warning",
					background: "transparent",
					justifyContent: "center",
					"&:hover": {
						textDecoration: "underline",
						background: "transparent",
						cursor: "pointer",
						borderColor: "highlight",
					},
				}}
			>
				<Text>{message}</Text>
			</SimpleButton>
		</Box>
	);
};
