import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { container } from "@/app/container";
import { notifications } from "@/app/container/notifications";
import { useUserProfile } from "@/app/hooks/use-user-profile";
import {
	ProfileTemplatesDocument,
	useCreateUpdateProfileTemplateMutation,
	useEnergyDataUploadUrlQuery,
} from "@/app/types/generated/graphql";

import { BaselineProfileCriteriaType } from "./baseline-profile-criteria/baseline-profile-criteria.types";
import { BASELINE_PROFILE_CREATE_EDIT_STEPS, UseCreateEditMeterProfileType } from "./create-edit-meter-profile.types";
import { convertFormDataToProfileInputData } from "./helpers";
import { UploadCsvType } from "./upload-csv";

/**
 * Generates a custom hook for creating or editing a meter profile.
 *
 * @param onSubmit - The submit callback function.
 * @param profileId - The ID of the profile being edited (optional).
 * @returns An object containing the following properties:
 *   - saveProfile: A function for saving profile information received from Step 1: Define Profile Criteria.
 *   - createEditProfileStep: The current step of the create/edit profile process.
 *   - uploadCsvFile: A function for saving profile information received from Step 2: Upload Profile Values.
 *   - skipToFileUpload: A function for skipping to the file upload step.
 */
export const useCreateEditMeterProfile: UseCreateEditMeterProfileType = ({ onSubmit, profileId }) => {
	const { t } = useTranslation();
	const { api } = container;

	// If the profile is being edited, we get the profile id from initial values
	// otherwise we set when the profile is created
	const [savedProfileId, setSavedProfileId] = useState<string | null>(profileId);
	const [createEditProfileStep, setCreateEditProfileStep] = useState<BASELINE_PROFILE_CREATE_EDIT_STEPS>(
		BASELINE_PROFILE_CREATE_EDIT_STEPS.DEFINE_PROFILE_CRITERIA,
	);

	const { userProfile, loading: loadingUserProfile } = useUserProfile();
	const { data: energyDataUploadUrl } = useEnergyDataUploadUrlQuery({
		variables: {
			input: {
				profileId: savedProfileId ?? "",
				tenancyInfo: {
					applicationHostId: userProfile?.applicationHostId ?? "",
					tenantId: userProfile?.tenantId ?? "",
					tradingGroupId: userProfile?.tradingGroupId ?? "",
				},
			},
		},
		skip: !savedProfileId || loadingUserProfile,
	});

	const [mutateCreateEditBaselineProfile] = useCreateUpdateProfileTemplateMutation({
		refetchQueries: [ProfileTemplatesDocument],
		onCompleted: (profile) => {
			if (profile?.createUpdateProfileTemplate?.id) {
				setSavedProfileId(profile.createUpdateProfileTemplate.id);
			}
		},
	});

	// Save profile information received from Step 1: Define Profile Criteria
	const saveProfile = useCallback(
		async (data: BaselineProfileCriteriaType) => {
			try {
				const baselineProfileInput = convertFormDataToProfileInputData({
					...data,
					tenantId: userProfile?.tenantId ?? "",
					tradingGroupId: userProfile?.tradingGroupId ?? "",
					applicationHostId: userProfile?.applicationHostId ?? "",
				});

				await mutateCreateEditBaselineProfile({
					variables: {
						template: baselineProfileInput,
					},
				});

				notifications.success({
					message: t("Baseline profile saved successfully"),
				});

				setCreateEditProfileStep(BASELINE_PROFILE_CREATE_EDIT_STEPS.UPLOAD_CSV);
			} catch (error) {
				notifications.error({
					message: t("Failed to save baseline profile"),
				});
			}
		},
		[mutateCreateEditBaselineProfile, t, userProfile],
	);

	// Save profile information received from Step 2: Upload Profile Values
	const uploadCsvFile = useCallback(
		async (data: UploadCsvType) => {
			if (!data.file) return;
			try {
				await api.put(energyDataUploadUrl?.getEnergyDataUploadURL?.url ?? "", data.file, {
					headers: {
						"Content-Type": data.file.type,
						"x-goog-meta-profile_id": savedProfileId ?? "",
						"x-goog-meta-tenant_id": userProfile?.tenantId ?? "",
						"x-goog-meta-trading_group_id": userProfile?.tradingGroupId ?? "",
						"x-goog-meta-application_host_id": userProfile?.applicationHostId ?? "",
					},
				});

				onSubmit();

				notifications.success({
					message: t("CSV for baseline profile uploaded successfully"),
				});
			} catch (error) {
				notifications.error({
					message: t("Failed to upload CSV file"),
				});
			}
		},
		[api, energyDataUploadUrl?.getEnergyDataUploadURL?.url, savedProfileId, userProfile, onSubmit, t],
	);

	const skipToFileUpload = useCallback(
		() => setCreateEditProfileStep(BASELINE_PROFILE_CREATE_EDIT_STEPS.UPLOAD_CSV),
		[setCreateEditProfileStep],
	);

	return {
		saveProfile,
		createEditProfileStep,
		uploadCsvFile,
		skipToFileUpload,
	};
};
