import * as React from "react";
import { SVGProps } from "react";

export const NetworkMapHorizontal = (props: SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="-5 0 500 420" fill="none" {...props}>
		<path stroke="#F4D34C" strokeWidth={2} d="M287.654 206.545h-19.616" />
		<path stroke="#F4D34C" strokeWidth={2.304} d="m375.22 122.758-50.218 63.627" />
		<path
			stroke="#F4D34C"
			d="M440.5 103h-27.783m11.437-39.23-18.856 20.404m-53.097-18.225 19.645 19.645m49.826 55.634-13.892-24.061m-18.498-66.475v27.783"
		/>
		<path stroke="#F4D34C" strokeWidth={2} d="M219 206.545h-19.615" />
		<g fill="#27D9E5" clipPath="url(#a)">
			<path d="M463.734 127.192c-13.086 0-23.734-10.991-23.734-24.5 0-13.508 10.648-24.5 23.734-24.5 13.087 0 23.735 10.992 23.735 24.5 0 13.509-10.648 24.5-23.735 24.5Zm0-47.948c-12.527 0-22.715 10.517-22.715 23.448 0 12.932 10.191 23.449 22.715 23.449 12.525 0 22.716-10.52 22.716-23.449 0-12.928-10.191-23.448-22.716-23.448Z" />
			<path d="m461.324 104.796 1.486-7.667h11.882l1.486 7.667h-14.854Zm.742-14.183v-1.15h2.227v1.15h-2.227Zm.632 13.03h5.495v-2.107h-5.087l-.408 2.107Zm.632-3.257h4.866v-2.107h-4.455l-.407 2.107h-.004Zm5.98 3.257h5.494l-.407-2.107h-5.087v2.107Zm0-3.257h4.865l-.407-2.107h-4.455v2.107h-.003Zm-5.126-5.56-.797-.804 1.577-1.629.797.805-1.577 1.629Zm4.567-1.914c-.917 0-1.69-.334-2.322-1.005-.632-.67-.973-1.486-1.022-2.444h1.113c.039.639.267 1.183.689 1.629.421.446.934.67 1.542.67a2.04 2.04 0 0 0 1.542-.67c.422-.447.65-.99.689-1.629h1.114c-.05.958-.39 1.774-1.023 2.445-.632.67-1.405 1.004-2.322 1.004Zm-.558 3.449v-2.3h1.113v2.3h-1.113Zm5.125-1.552-1.577-1.629.78-.805 1.595 1.61-.798.824Zm-7.557-9.095-.797.805-1.577-1.629.797-.805 1.577 1.629Zm5.309 1.294c.632.671.973 1.487 1.022 2.444h-1.114a2.516 2.516 0 0 0-.688-1.628 2.043 2.043 0 0 0-1.542-.67c-.608 0-1.121.224-1.543.67-.421.446-.65.99-.688 1.628h-1.114c.049-.957.39-1.773 1.022-2.444.633-.67 1.406-1.004 2.323-1.004s1.689.333 2.322 1.004Zm-1.764-4.457v2.3h-1.113v-2.3h1.113Zm4.81 2.376-1.595 1.61-.78-.805 1.577-1.629.798.824Zm-.91 5.686v-1.15h2.227v1.15h-2.227Z" />
			<path d="M470.251 120.803h-3.221c-.573 0-1.037-.479-1.037-1.07v-9.657h-9.545v9.657c0 .591-.463 1.07-1.036 1.07h-3.186c-.573 0-1.037-.479-1.037-1.07v-13.955c0-.337.158-.66.418-.859l9.082-6.977c.368-.283.878-.28 1.243.003l1.181.922-.689.939-1.121-.874-8.979 6.901v13.795h2.983v-9.657c0-.592.463-1.07 1.036-1.07h9.749c.572 0 1.036.478 1.036 1.07v9.657h3.018v-13.799l-1.535-1.196.688-.94 1.574 1.23c.256.199.411.518.411.852v13.958c0 .591-.464 1.07-1.036 1.07h.003Z" />
			<path d="m461.324 104.796 1.486-7.667h11.882l1.486 7.667h-14.854Zm.742-14.183v-1.15h2.227v1.15h-2.227Zm.632 13.03h5.495v-2.107h-5.087l-.408 2.107Zm.632-3.257h4.866v-2.107h-4.455l-.407 2.107h-.004Zm5.98 3.257h5.494l-.407-2.107h-5.087v2.107Zm0-3.257h4.865l-.407-2.107h-4.455v2.107h-.003Zm-5.126-5.56-.797-.804 1.577-1.629.797.805-1.577 1.629Zm4.567-1.914c-.917 0-1.69-.334-2.322-1.005-.632-.67-.973-1.486-1.022-2.444h1.113c.039.639.267 1.183.689 1.629.421.446.934.67 1.542.67a2.04 2.04 0 0 0 1.542-.67c.422-.447.65-.99.689-1.629h1.114c-.05.958-.39 1.774-1.023 2.445-.632.67-1.405 1.004-2.322 1.004Zm-.558 3.449v-2.3h1.113v2.3h-1.113Zm5.125-1.552-1.577-1.629.78-.805 1.595 1.61-.798.824Zm-7.557-9.095-.797.805-1.577-1.629.797-.805 1.577 1.629Zm5.309 1.294c.632.671.973 1.487 1.022 2.444h-1.114a2.516 2.516 0 0 0-.688-1.628 2.043 2.043 0 0 0-1.542-.67c-.608 0-1.121.224-1.543.67-.421.446-.65.99-.688 1.628h-1.114c.049-.957.39-1.773 1.022-2.444.633-.67 1.406-1.004 2.323-1.004s1.689.333 2.322 1.004Zm-1.764-4.457v2.3h-1.113v-2.3h1.113Zm4.81 2.376-1.595 1.61-.78-.805 1.577-1.629.798.824Zm-.91 5.686v-1.15h2.227v1.15h-2.227Z" />
			<path d="M470.251 120.803h-3.221c-.573 0-1.037-.479-1.037-1.07v-9.657h-9.545v9.657c0 .591-.463 1.07-1.036 1.07h-3.186c-.573 0-1.037-.479-1.037-1.07v-13.955c0-.337.158-.66.418-.859l9.082-6.977c.368-.283.878-.28 1.243.003l1.181.922-.689.939-1.121-.874-8.979 6.901v13.795h2.983v-9.657c0-.592.463-1.07 1.036-1.07h9.749c.572 0 1.036.478 1.036 1.07v9.657h3.018v-13.799l-1.535-1.196.688-.94 1.574 1.23c.256.199.411.518.411.852v13.958c0 .591-.464 1.07-1.036 1.07h.003Z" />
		</g>
		<g fill="#27D9E5" clipPath="url(#b)">
			<path d="M243.519 231.064c-13.519 0-24.519-11-24.519-24.519 0-13.519 11-24.519 24.519-24.519 13.519 0 24.519 11 24.519 24.519 0 13.519-11 24.519-24.519 24.519Zm0-47.986c-12.942 0-23.467 10.525-23.467 23.467 0 12.942 10.529 23.467 23.467 23.467 12.939 0 23.467-10.529 23.467-23.467 0-12.938-10.528-23.467-23.467-23.467Z" />
			<path d="M263.68 205.227c-.348 0-15.04-.141-20.709-14.691l1.096-.428c5.415 13.9 19.479 13.954 19.609 13.94l.011 1.176h-.003l-.004.003Z" />
			<path d="M223.359 205.227h-.004l.011-1.175c.276 0 14.223-.131 19.605-13.94l1.096.428c-5.669 14.546-20.356 14.687-20.708 14.687Z" />
			<path d="M246.942 189.636h-6.867v1.176h6.867v-1.176Zm0-1.716h-6.867v1.175h6.867v-1.175Z" />
			<path d="M244.107 189.992h-1.176v35.929h1.176v-35.929Z" />
		</g>
		<g fill="#27D9E5" clipPath="url(#c)">
			<path d="M388.737 126.974c-13.518 0-24.519-11-24.519-24.519 0-13.519 11.001-24.52 24.519-24.52 13.519 0 24.52 11.001 24.52 24.52s-11.001 24.519-24.52 24.519Zm0-47.986c-12.941 0-23.466 10.525-23.466 23.467 0 12.942 10.528 23.467 23.466 23.467 12.939 0 23.467-10.529 23.467-23.467 0-12.938-10.528-23.467-23.467-23.467Z" />
			<path d="M408.898 101.138c-.348 0-15.039-.142-20.708-14.692l1.096-.428c5.415 13.9 19.478 13.955 19.609 13.94l.01 1.176h-.003l-.004.004Z" />
			<path d="M368.577 101.138h-.004l.011-1.176c.276 0 14.223-.13 19.606-13.94l1.096.428c-5.669 14.546-20.357 14.688-20.709 14.688Z" />
			<path d="M392.16 85.546h-6.867v1.176h6.867v-1.176Zm0-1.716h-6.867v1.176h6.867V83.83Z" />
			<path d="M389.326 85.902h-1.176v35.93h1.176v-35.93Z" />
		</g>
		<path
			fill="#27D9E5"
			d="M435.29 186.192c-13.944 0-25.29-10.991-25.29-24.5 0-13.508 11.346-24.5 25.29-24.5 13.944 0 25.291 10.992 25.291 24.5 0 13.509-11.347 24.5-25.291 24.5Zm0-47.948c-13.349 0-24.204 10.517-24.204 23.448 0 12.932 10.859 23.449 24.204 23.449 13.346 0 24.205-10.52 24.205-23.449 0-12.928-10.859-23.448-24.205-23.448Z"
		/>
		<path
			fill="#27D9E5"
			d="m437.499 162.233 1.456-7.054h11.653l1.457 7.054h-14.566Zm.73-13.052v-1.059h2.186v1.059h-2.186Zm.618 11.993h5.39v-1.94h-4.99l-.4 1.94Zm.617-2.999h4.769v-1.94h-4.368l-.401 1.94Zm5.862 2.999h5.391l-.401-1.94h-4.99v1.94Zm0-2.999h4.77l-.401-1.94h-4.369v1.94Zm-5.023-5.113-.783-.74 1.546-1.498.783.74-1.546 1.498Zm4.477-1.763c-.898 0-1.658-.308-2.276-.925a3.315 3.315 0 0 1-1-2.248h1.094a2.25 2.25 0 0 0 .673 1.498c.412.409.917.616 1.513.616.595 0 1.096-.207 1.512-.616.412-.41.636-.911.674-1.498h1.093a3.333 3.333 0 0 1-1 2.248c-.617.617-1.377.925-2.276.925h-.007Zm-.547 3.173v-2.118h1.093v2.118h-1.093Zm5.024-1.429-1.546-1.497.764-.74 1.564 1.483-.782.758v-.004Zm-7.408-8.369-.783.739-1.546-1.497.783-.74 1.546 1.498Zm5.207 1.189c.618.617.954 1.367.999 2.248h-1.093a2.245 2.245 0 0 0-.674-1.497 2.063 2.063 0 0 0-1.512-.617 2.07 2.07 0 0 0-1.512.617c-.412.41-.637.91-.674 1.497h-1.093a3.338 3.338 0 0 1 .999-2.248c.618-.616 1.378-.925 2.276-.925.899 0 1.659.309 2.276.925h.008Zm-1.73-4.101v2.117h-1.093v-2.117h1.093Zm4.717 2.186-1.565 1.484-.763-.74 1.546-1.498.782.758v-.004Zm-.891 5.233v-1.059h2.186v1.059h-2.186Z"
		/>
		<path
			fill="#27D9E5"
			d="M446.251 177.01h-3.159c-.588 0-1.067-.464-1.067-1.03v-8.841h-9.265v8.841c0 .57-.479 1.03-1.063 1.03h-3.126c-.588 0-1.063-.464-1.063-1.03v-12.844c0-.323.161-.635.43-.827l8.91-6.422a1.1 1.1 0 0 1 1.276.003l1.161.849-.734.939-1.071-.783-8.759 6.313v12.627h2.826v-8.841c0-.569.479-1.033 1.067-1.033h9.56c.588 0 1.064.464 1.064 1.033v8.841h2.863v-12.627l-1.486-1.088.734-.939 1.546 1.131c.266.193.423.501.423.824v12.844c0 .57-.479 1.03-1.063 1.03h-.004Zm-20.237.015h-8.362l-.03-.472v-14.861l.486-.029 1.659-.011v-1.058l.486-.029h4.118l.037.46.015.638h2.048l.03.472v14.861l-.487.029Zm-7.483-.881h7.057v-13.599h-2.033l-.038-.461-.015-.638h-2.83v1.055l-.486.029-1.659.011v13.603h.004Z"
		/>
		<path fill="#27D9E5" d="M424.884 172.136h-5.649v3.148h5.649v-3.148Zm0-4.039h-5.649v3.147h5.649v-3.147Z" />
		<g fill="#27D9E5" clipPath="url(#d)">
			<path d="M443.692 75c-13.508 0-24.5-10.992-24.5-24.5s10.992-24.5 24.5-24.5c13.509 0 24.5 10.992 24.5 24.5S457.201 75 443.692 75Zm0-47.948c-12.931 0-23.448 10.516-23.448 23.448s10.52 23.448 23.448 23.448c12.929 0 23.449-10.52 23.449-23.448s-10.52-23.448-23.449-23.448Z" />
			<path d="M455.801 65.34h-4.33a1.215 1.215 0 0 1-1.215-1.216V51.418l-13.138-.04-.04 12.746c0 .671-.544 1.215-1.215 1.215h-4.279a1.215 1.215 0 0 1-1.215-1.215V45.96c0-.381.185-.747.489-.976l12.203-9.084a1.22 1.22 0 0 1 1.458.007l12.014 9.08c.301.23.483.592.483.97V64.12c0 .67-.544 1.215-1.215 1.215v.003Zm-18.683-15.137h13.098c.671 0 1.215.544 1.215 1.214v12.707l4.37.04.04-18.204-2.133-1.632-9.896-7.481-12.254 9.084-.018 18.193 4.319.04.04-12.747c0-.67.544-1.214 1.215-1.214h.004Z" />
		</g>
		<path
			fill="#27D9E5"
			d="M332.5 75.192c-13.508 0-24.5-10.991-24.5-24.5 0-13.508 10.992-24.5 24.5-24.5s24.5 10.992 24.5 24.5c0 13.509-10.992 24.5-24.5 24.5Zm0-47.948c-12.932 0-23.448 10.517-23.448 23.448 0 12.932 10.52 23.449 23.448 23.449s23.448-10.52 23.448-23.449c0-12.928-10.52-23.448-23.448-23.448Z"
		/>
		<path
			fill="#27D9E5"
			d="M344.609 65.532h-4.33a1.215 1.215 0 0 1-1.215-1.215V51.61l-13.139-.04-.04 12.747c0 .67-.544 1.215-1.214 1.215h-4.28a1.214 1.214 0 0 1-1.214-1.215V46.152c0-.38.184-.747.489-.975l12.203-9.084c.431-.32 1.03-.32 1.458.007l12.014 9.08c.301.229.482.591.482.968v18.165c0 .671-.544 1.215-1.214 1.215v.004Zm-18.684-15.137h13.099c.671 0 1.215.544 1.215 1.215v12.707l4.37.04.039-18.205-2.132-1.632-9.896-7.481-12.254 9.084-.018 18.194 4.319.04.04-12.747c0-.671.544-1.215 1.215-1.215h.003Z"
		/>
		<g fill="#27D9E5" clipPath="url(#e)">
			<path d="M389.5 51.192c-13.508 0-24.5-10.991-24.5-24.5 0-13.508 10.992-24.5 24.5-24.5s24.5 10.992 24.5 24.5c0 13.509-10.992 24.5-24.5 24.5Zm0-47.948c-12.932 0-23.448 10.517-23.448 23.448 0 12.932 10.52 23.449 23.448 23.449s23.448-10.52 23.448-23.449c0-12.928-10.52-23.448-23.448-23.448Z" />
			<path d="M401.609 41.532h-4.33a1.215 1.215 0 0 1-1.215-1.215V27.61l-13.139-.04-.04 12.747c0 .67-.544 1.215-1.214 1.215h-4.28a1.214 1.214 0 0 1-1.214-1.215V22.152c0-.38.184-.747.489-.975l12.203-9.085a1.218 1.218 0 0 1 1.458.008l12.014 9.08c.301.229.482.591.482.968v18.165c0 .671-.544 1.215-1.214 1.215v.004Zm-18.684-15.137h13.099c.671 0 1.215.544 1.215 1.215v12.707l4.37.04.039-18.205-2.132-1.632-9.896-7.481-12.254 9.084-.018 18.194 4.319.04.04-12.747c0-.671.544-1.215 1.215-1.215h.003Z" />
		</g>
		<path stroke="#F4D34C" strokeWidth={2.304} d="m113.164 121.758 50.219 63.627" />
		<path
			stroke="#F4D34C"
			d="M47.885 102h27.783M64.231 62.77l18.856 20.404m53.097-18.225-19.646 19.645m-49.825 55.634 13.892-24.061m18.498-66.475v27.783"
		/>
		<g fill="#27D9E5" clipPath="url(#f)">
			<path d="M24.734 78C11.648 78 1 88.992 1 102.5S11.648 127 24.734 127c13.087 0 23.735-10.992 23.735-24.5S37.82 78 24.734 78Zm0 47.948c-12.527 0-22.715-10.516-22.715-23.448S12.21 79.052 24.734 79.052 47.45 89.572 47.45 102.5s-10.192 23.448-22.716 23.448Z" />
			<path d="M37.608 117.339h-4.33c-.67 0-1.214-.544-1.214-1.215v-12.707l-13.139-.039-.04 12.746c0 .671-.544 1.215-1.215 1.215h-4.279c-.67 0-1.214-.544-1.214-1.215V97.96c0-.381.184-.747.49-.976L24.868 87.9a1.22 1.22 0 0 1 1.458.007l12.014 9.08c.301.23.482.592.482.97v18.164c0 .671-.544 1.215-1.215 1.215v.003Zm-18.683-15.136h13.099c.67 0 1.215.544 1.215 1.214v12.707l4.37.04.04-18.204-2.133-1.632-9.896-7.481-12.254 9.084-.018 18.193 4.319.04.04-12.747c0-.67.544-1.214 1.215-1.214h.003Z" />
		</g>
		<g fill="#27D9E5" clipPath="url(#g)">
			<path d="M99.648 125.974c13.519 0 24.519-11 24.519-24.519 0-13.52-11-24.52-24.52-24.52-13.518 0-24.519 11-24.519 24.52 0 13.519 11 24.519 24.52 24.519Zm0-47.986c12.942 0 23.467 10.525 23.467 23.467 0 12.942-10.529 23.466-23.467 23.466-12.938 0-23.467-10.528-23.467-23.466 0-12.939 10.529-23.467 23.467-23.467Z" />
			<path d="M79.487 100.137c.348 0 15.04-.141 20.709-14.691l-1.096-.428c-5.415 13.9-19.479 13.954-19.61 13.94l-.01 1.176h.003l.004.003Z" />
			<path d="M119.808 100.137h.004l-.011-1.176c-.276 0-14.223-.13-19.605-13.94l-1.096.429c5.669 14.546 20.356 14.687 20.708 14.687Z" />
			<path d="M96.225 84.546h6.867v1.176h-6.867v-1.176Zm0-1.716h6.867v1.175h-6.867V82.83Z" />
			<path d="M99.06 84.902h1.176v35.929H99.06v-35.93Z" />
		</g>
		<path
			fill="#27D9E5"
			d="M55.29 137.192c-13.944 0-25.29 10.992-25.29 24.5 0 13.509 11.346 24.5 25.29 24.5 13.944 0 25.29-10.991 25.29-24.5 0-13.508-11.346-24.5-25.29-24.5Zm0 47.949c-13.349 0-24.204-10.517-24.204-23.449 0-12.931 10.86-23.448 24.204-23.448 13.345 0 24.205 10.52 24.205 23.448 0 12.929-10.86 23.449-24.205 23.449Z"
		/>
		<path
			fill="#27D9E5"
			d="M68.165 176.87h-4.33c-.67 0-1.214-.544-1.214-1.215v-12.707l-13.139-.04-.04 12.747c0 .671-.544 1.215-1.215 1.215h-4.279c-.67 0-1.215-.544-1.215-1.215V157.49c0-.38.185-.747.49-.975l12.203-9.084a1.22 1.22 0 0 1 1.458.007l12.014 9.081c.3.228.482.591.482.968v18.165c0 .67-.544 1.214-1.215 1.214v.004Zm-18.683-15.137h13.099c.67 0 1.215.544 1.215 1.215v12.707l4.37.04.04-18.205-2.133-1.631-9.896-7.482-12.254 9.084-.018 18.194 4.319.04.04-12.747c0-.671.544-1.215 1.214-1.215h.004Z"
		/>
		<g fill="#27D9E5" clipPath="url(#h)">
			<path d="M44.692 25c-13.508 0-24.5 10.992-24.5 24.5S31.184 74 44.692 74c13.509 0 24.5-10.992 24.5-24.5S58.201 25 44.692 25Zm0 47.948c-12.931 0-23.448-10.516-23.448-23.448s10.52-23.448 23.448-23.448S68.141 36.572 68.141 49.5 57.62 72.948 44.692 72.948Z" />
			<path d="m41.517 51.603 1.486-7.666h11.881l1.486 7.666H41.517Zm.741-14.183v-1.15h2.227v1.15h-2.227Zm.633 13.03h5.494v-2.107h-5.087l-.407 2.107Zm.632-3.256h4.865v-2.107h-4.454l-.408 2.107h-.003Zm5.98 3.256h5.493l-.407-2.107h-5.087v2.107Zm0-3.256h4.865l-.408-2.107h-4.454v2.107h-.004Zm-5.127-5.56-.797-.805 1.577-1.628.798.805-1.578 1.628Zm4.568-1.914c-.917 0-1.69-.334-2.323-1.005-.632-.67-.973-1.487-1.022-2.444h1.114c.038.638.267 1.182.688 1.628.422.446.935.671 1.542.671.608 0 1.121-.225 1.543-.67.421-.447.65-.99.688-1.63h1.114c-.05.958-.39 1.774-1.022 2.445-.633.671-1.406 1.005-2.322 1.005Zm-.56 3.448V40.87H49.5v2.3h-1.114Zm5.127-1.552-1.578-1.628.78-.805 1.595 1.61-.797.823Zm-7.557-9.095-.798.805-1.577-1.628.797-.805 1.578 1.628Zm5.308 1.295c.633.67.973 1.487 1.022 2.444h-1.113c-.039-.638-.267-1.182-.689-1.628a2.041 2.041 0 0 0-1.542-.671c-.608 0-1.12.225-1.542.67-.422.447-.65.99-.689 1.629h-1.114c.05-.957.39-1.773 1.023-2.444.632-.671 1.405-1.005 2.322-1.005s1.69.334 2.322 1.005ZM49.5 29.359v2.3h-1.114v-2.3H49.5Zm4.809 2.375-1.595 1.61-.78-.805 1.578-1.628.797.823Zm-.91 5.686v-1.15h2.227v1.15h-2.227Z" />
			<path d="M50.444 67.61h-3.222c-.573 0-1.036-.478-1.036-1.07v-9.657H36.64v9.657c0 .592-.464 1.07-1.037 1.07h-3.186c-.573 0-1.036-.478-1.036-1.07V52.586c0-.337.158-.66.418-.86l9.081-6.977a1.019 1.019 0 0 1 1.244.004l1.18.921-.689.94-1.12-.875-8.98 6.902v13.794H35.5v-9.657c0-.59.464-1.07 1.036-1.07h9.75c.572 0 1.035.48 1.035 1.07v9.657h3.018V52.637l-1.535-1.197.689-.94 1.573 1.23c.257.2.411.519.411.853V66.54c0 .592-.463 1.07-1.036 1.07h.004Z" />
		</g>
		<path
			fill="#27D9E5"
			d="M154.5 25.192c-13.508 0-24.5 10.992-24.5 24.5 0 13.509 10.992 24.5 24.5 24.5s24.5-10.991 24.5-24.5c0-13.508-10.992-24.5-24.5-24.5Zm0 47.949c-12.932 0-23.448-10.517-23.448-23.449 0-12.931 10.52-23.448 23.448-23.448s23.448 10.52 23.448 23.448-10.52 23.449-23.448 23.449Z"
		/>
		<path
			fill="#27D9E5"
			d="M166.342 64.532h-4.33a1.215 1.215 0 0 1-1.215-1.215V50.61l-13.138-.04-.04 12.747c0 .67-.544 1.215-1.215 1.215h-4.279a1.215 1.215 0 0 1-1.215-1.215V45.152c0-.38.185-.747.489-.975l12.203-9.084c.432-.32 1.03-.32 1.458.007l12.014 9.08c.301.229.483.591.483.968v18.165c0 .671-.544 1.215-1.215 1.215v.004Zm-18.683-15.137h13.098c.671 0 1.215.544 1.215 1.215v12.707l4.37.04.04-18.205-2.133-1.632-9.896-7.481-12.254 9.084-.018 18.194 4.319.04.04-12.747c0-.671.544-1.215 1.215-1.215h.004Z"
		/>
		<g fill="#27D9E5" clipPath="url(#i)">
			<path d="M99.692 0c-13.508 0-24.5 10.992-24.5 24.5S86.184 49 99.692 49c13.509 0 24.5-10.992 24.5-24.5S113.201 0 99.692 0Zm0 47.948c-12.931 0-23.448-10.516-23.448-23.448S86.764 1.052 99.692 1.052c12.929 0 23.449 10.52 23.449 23.448s-10.52 23.448-23.449 23.448Z" />
			<path d="M111.801 39.34h-4.33a1.215 1.215 0 0 1-1.215-1.216V25.417l-13.138-.04-.04 12.747c0 .671-.544 1.215-1.215 1.215h-4.28c-.67 0-1.214-.544-1.214-1.215V19.96c0-.381.185-.747.49-.976L99.06 9.9a1.22 1.22 0 0 1 1.458.007l12.014 9.08c.301.23.483.592.483.97V38.12c0 .67-.544 1.215-1.215 1.215v.003ZM93.118 24.202h13.098c.671 0 1.215.544 1.215 1.215v12.706l4.37.04.04-18.204-2.133-1.632-9.896-7.481-12.254 9.084-.018 18.193 4.32.04.04-12.747c0-.67.543-1.214 1.214-1.214h.004Z" />
		</g>
		<path stroke="#F4D34C" strokeWidth={2.304} d="M113.164 292.627 163.383 229" />
		<path
			stroke="#F4D34C"
			d="M47.885 312.385h27.783m-11.437 39.23 18.856-20.404m53.097 18.225-19.646-19.646m-49.825-55.633 13.892 24.061m18.498 66.474v-27.783"
		/>
		<g fill="#27D9E5" clipPath="url(#j)">
			<path d="M23.734 288.192c13.087 0 23.735 10.992 23.735 24.5 0 13.509-10.648 24.5-23.735 24.5-13.086 0-23.734-10.991-23.734-24.5 0-13.508 10.648-24.5 23.734-24.5Zm0 47.949c12.528 0 22.716-10.517 22.716-23.449 0-12.931-10.192-23.448-22.716-23.448-12.524 0-22.715 10.52-22.715 23.448 0 12.929 10.191 23.449 22.715 23.449Z" />
			<path d="m27.85 313.233 1.456-7.054h11.653l1.456 7.054H27.85Zm.73-13.052v-1.059h2.186v1.059H28.58Zm.617 11.993h5.39v-1.94h-4.99l-.4 1.94Zm.618-2.999h4.769v-1.94h-4.369l-.4 1.94Zm5.862 2.999h5.39l-.4-1.94h-4.99v1.94Zm0-2.999h4.77l-.401-1.94h-4.369v1.94Zm-5.024-5.113-.782-.74 1.546-1.498.782.74-1.546 1.498Zm4.477-1.763c-.898 0-1.658-.308-2.276-.925a3.316 3.316 0 0 1-.999-2.248h1.093a2.25 2.25 0 0 0 .674 1.498c.412.409.917.616 1.512.616s1.097-.207 1.513-.616a2.25 2.25 0 0 0 .673-1.498h1.093a3.332 3.332 0 0 1-.999 2.248c-.618.617-1.378.925-2.276.925h-.008Zm-.546 3.173v-2.118h1.093v2.118h-1.093Zm5.024-1.429-1.546-1.497.763-.74 1.565 1.483-.782.758v-.004Zm-7.409-8.369-.782.739-1.546-1.497.782-.74 1.546 1.498Zm5.208 1.189a3.318 3.318 0 0 1 .999 2.248h-1.093a2.247 2.247 0 0 0-.674-1.497 2.065 2.065 0 0 0-1.512-.617c-.595 0-1.097.207-1.513.617-.411.41-.636.91-.673 1.497h-1.094a3.335 3.335 0 0 1 1-2.248c.618-.616 1.378-.925 2.276-.925.898 0 1.658.309 2.276.925h.008Zm-1.73-4.101v2.117h-1.093v-2.117h1.093Zm4.717 2.186-1.565 1.484-.764-.74 1.546-1.498.783.758v-.004Zm-.891 5.233v-1.059h2.186v1.059h-2.186Z" />
			<path d="M36.602 328.01h-3.16c-.588 0-1.067-.464-1.067-1.03v-8.841H23.11v8.841c0 .57-.479 1.03-1.063 1.03h-3.125c-.588 0-1.064-.464-1.064-1.03v-12.844c0-.323.161-.635.43-.827l8.91-6.422a1.1 1.1 0 0 1 1.277.003l1.16.849-.734.939-1.07-.783-8.76 6.313v12.627h2.826v-8.841c0-.569.48-1.033 1.067-1.033h9.561c.588 0 1.063.464 1.063 1.033v8.841h2.864v-12.627l-1.486-1.088.733-.939 1.546 1.132c.266.192.423.5.423.823v12.844c0 .57-.479 1.03-1.063 1.03h-.003Zm-20.237.015H8.002l-.03-.472v-14.861l.487-.029 1.658-.011v-1.058l.487-.029h4.118l.037.46.015.638h2.048l.03.472v14.861l-.487.029Zm-7.483-.881h7.056v-13.599h-2.033l-.037-.461-.015-.638h-2.83v1.055l-.487.029-1.658.011v13.603h.004Z" />
			<path d="M15.234 323.136H9.585v3.148h5.65v-3.148Zm0-4.039H9.585v3.147h5.65v-3.147Z" />
		</g>
		<g fill="#27D9E5" clipPath="url(#k)">
			<path d="M99.648 337.449c-13.52 0-24.52-11-24.52-24.519 0-13.519 11-24.519 24.52-24.519 13.519 0 24.519 11 24.519 24.519 0 13.519-11 24.519-24.52 24.519Zm0-47.986c-12.942 0-23.467 10.525-23.467 23.467 0 12.942 10.528 23.467 23.467 23.467 12.938 0 23.466-10.529 23.466-23.467 0-12.938-10.528-23.467-23.466-23.467Z" />
			<path d="M119.808 311.613c-.348 0-15.039-.142-20.708-14.692l1.096-.428c5.415 13.9 19.478 13.955 19.609 13.94l.011 1.176h-.004l-.004.004Z" />
			<path d="M79.487 311.613h-.003l.01-1.176c.276 0 14.224-.131 19.606-13.94l1.096.428c-5.67 14.546-20.357 14.688-20.709 14.688Z" />
			<path d="M103.07 296.021h-6.866v1.176h6.866v-1.176Zm0-1.716h-6.866v1.176h6.866v-1.176Z" />
			<path d="M100.236 296.377H99.06v35.93h1.176v-35.93Z" />
		</g>
		<path
			fill="#27D9E5"
			d="M55.692 228.328c13.944 0 25.29 10.991 25.29 24.5 0 13.508-11.346 24.5-25.29 24.5-13.944 0-25.29-10.992-25.29-24.5 0-13.509 11.346-24.5 25.29-24.5Zm0 47.948c13.35 0 24.205-10.516 24.205-23.448s-10.86-23.449-24.205-23.449c-13.345 0-24.204 10.521-24.204 23.449s10.86 23.448 24.204 23.448Z"
		/>
		<path
			fill="#27D9E5"
			d="M42.817 268.005h4.33c.671 0 1.215-.544 1.215-1.214v-12.707l13.139-.04.04 12.747c0 .67.543 1.214 1.214 1.214h4.28c.67 0 1.214-.544 1.214-1.214v-18.165a1.23 1.23 0 0 0-.49-.976l-12.202-9.084a1.22 1.22 0 0 0-1.458.007l-12.014 9.081a1.224 1.224 0 0 0-.483.968v18.165c0 .671.544 1.215 1.215 1.215v.003Zm18.684-15.136H48.4c-.67 0-1.214.544-1.214 1.215v12.707l-4.37.039-.04-18.204 2.133-1.632 9.896-7.481 12.254 9.084.018 18.194-4.32.039-.039-12.746c0-.671-.544-1.215-1.215-1.215H61.5Z"
		/>
		<g fill="#27D9E5" clipPath="url(#l)">
			<path d="M44.692 340.385c13.509 0 24.5 10.991 24.5 24.5 0 13.508-10.991 24.5-24.5 24.5-13.508 0-24.5-10.992-24.5-24.5 0-13.509 10.992-24.5 24.5-24.5Zm0 47.948c12.932 0 23.449-10.516 23.449-23.448s-10.52-23.449-23.449-23.449c-12.928 0-23.448 10.521-23.448 23.449s10.52 23.448 23.448 23.448Z" />
			<path d="m47.573 365.425 1.456-7.053h11.654l1.456 7.053H47.573Zm.73-13.051v-1.059h2.186v1.059h-2.186Zm.618 11.992h5.39v-1.94h-4.99l-.4 1.94Zm.617-2.999h4.77v-1.94h-4.369l-.4 1.94Zm5.863 2.999h5.39l-.4-1.94H55.4v1.94Zm0-2.999h4.769l-.4-1.94H55.4v1.94Zm-5.024-5.113-.782-.74 1.546-1.498.782.74-1.546 1.498Zm4.477-1.763c-.898 0-1.658-.308-2.276-.924a3.318 3.318 0 0 1-1-2.249h1.094c.037.588.262 1.088.674 1.498.411.41.917.617 1.512.617s1.097-.207 1.512-.617c.412-.41.637-.91.674-1.498h1.093a3.335 3.335 0 0 1-1 2.249c-.617.616-1.377.924-2.275.924h-.008Zm-.546 3.174v-2.118H55.4v2.118h-1.093Zm5.023-1.429-1.546-1.498.764-.74 1.565 1.483-.783.758v-.003Zm-7.408-8.37-.782.74-1.546-1.498.782-.74 1.546 1.498Zm5.207 1.19a3.315 3.315 0 0 1 1 2.248h-1.094a2.248 2.248 0 0 0-.673-1.498 2.064 2.064 0 0 0-1.513-.616 2.07 2.07 0 0 0-1.512.616 2.25 2.25 0 0 0-.674 1.498h-1.093a3.332 3.332 0 0 1 1-2.248c.617-.617 1.377-.925 2.276-.925.898 0 1.658.308 2.276.925h.007Zm-1.73-4.102v2.118h-1.092v-2.118H55.4Zm4.717 2.187-1.564 1.483-.764-.74 1.546-1.498.782.758v-.003Zm-.89 5.233v-1.059h2.186v1.059h-2.187Z" />
			<path d="M56.325 380.203h-3.16c-.587 0-1.066-.464-1.066-1.03v-8.841h-9.265v8.841c0 .569-.48 1.03-1.063 1.03h-3.126c-.588 0-1.063-.464-1.063-1.03v-12.845c0-.323.161-.635.43-.827l8.91-6.422a1.1 1.1 0 0 1 1.276.004l1.16.848-.733.939-1.07-.783-8.76 6.314v12.627h2.826v-8.841c0-.57.48-1.034 1.067-1.034h9.56c.588 0 1.064.464 1.064 1.034v8.841h2.863v-12.627l-1.486-1.088.734-.94 1.546 1.132c.266.192.423.5.423.823v12.845c0 .569-.48 1.03-1.063 1.03h-.004Zm-20.236.014h-8.363l-.03-.471v-14.861l.486-.029 1.659-.011v-1.059l.486-.029h4.118l.038.461.015.638h2.047l.03.471v14.861l-.486.029Zm-7.484-.881h7.057v-13.599h-2.033l-.037-.461-.015-.638h-2.83v1.055l-.487.029-1.658.011v13.603h.003Z" />
			<path d="M34.958 375.329h-5.649v3.148h5.649v-3.148Zm0-4.04h-5.649v3.148h5.649v-3.148Z" />
		</g>
		<path
			fill="#27D9E5"
			d="M157.692 337.118c13.509 0 24.5 10.992 24.5 24.5s-10.991 24.5-24.5 24.5c-13.508 0-24.5-10.992-24.5-24.5s10.992-24.5 24.5-24.5Zm0 47.948c12.932 0 23.449-10.516 23.449-23.448s-10.52-23.448-23.449-23.448c-12.928 0-23.448 10.52-23.448 23.448s10.52 23.448 23.448 23.448Z"
		/>
		<path
			fill="#27D9E5"
			d="M145.85 376.457h4.33c.671 0 1.215-.544 1.215-1.214v-12.707l13.139-.04.04 12.747c0 .67.544 1.214 1.214 1.214h4.28c.671 0 1.214-.544 1.214-1.214v-18.165a1.23 1.23 0 0 0-.489-.976l-12.203-9.084a1.22 1.22 0 0 0-1.458.007l-12.014 9.081a1.224 1.224 0 0 0-.482.968v18.165c0 .671.544 1.215 1.214 1.215v.003Zm18.684-15.136h-13.099c-.671 0-1.215.544-1.215 1.215v12.707l-4.37.039-.039-18.204 2.132-1.632 9.896-7.481 12.254 9.084.018 18.194-4.319.039-.04-12.746c0-.671-.544-1.215-1.215-1.215h-.003Z"
		/>
		<g fill="#27D9E5" clipPath="url(#m)">
			<path d="M99.692 365.385c13.509 0 24.5 10.991 24.5 24.5 0 13.508-10.991 24.5-24.5 24.5-13.508 0-24.5-10.992-24.5-24.5 0-13.509 10.992-24.5 24.5-24.5Zm0 47.948c12.932 0 23.449-10.516 23.449-23.448s-10.52-23.449-23.449-23.449c-12.928 0-23.448 10.521-23.448 23.449s10.52 23.448 23.448 23.448Z" />
			<path d="M87.584 404.724h4.33c.67 0 1.215-.544 1.215-1.215v-12.707l13.138-.04.04 12.747c0 .671.544 1.215 1.215 1.215h4.279c.671 0 1.215-.544 1.215-1.215v-18.164a1.23 1.23 0 0 0-.49-.976l-12.203-9.084a1.219 1.219 0 0 0-1.457.007l-12.015 9.081c-.3.228-.482.591-.482.968v18.165c0 .67.544 1.214 1.215 1.214v.004Zm18.683-15.137H93.168c-.67 0-1.214.544-1.214 1.215v12.707l-4.37.04-.04-18.204 2.132-1.632 9.897-7.482 12.253 9.085.019 18.193-4.32.04-.039-12.747c0-.671-.544-1.215-1.215-1.215h-.004Z" />
		</g>
		<path stroke="#F4D34C" strokeWidth={2.304} d="m375.22 122.758-50.218 63.627" />
		<path
			stroke="#F4D34C"
			d="M440.5 103h-27.783m11.437-39.23-18.856 20.404m-53.097-18.225 19.645 19.645m49.826 55.634-13.892-24.061m-18.498-66.475v27.783"
		/>
		<path stroke="#F4D34C" strokeWidth={2.304} d="M375.22 290.627 325.002 227" />
		<path
			stroke="#F4D34C"
			d="M440.5 310.385h-27.783m11.437 39.23-18.856-20.404m-53.097 18.225 19.645-19.646m49.826-55.633-13.892 24.061m-18.498 66.474v-27.783"
		/>
		<g fill="#27D9E5" clipPath="url(#n)">
			<path d="M463.734 335.192c13.087 0 23.735-10.991 23.735-24.5 0-13.508-10.648-24.5-23.735-24.5-13.086 0-23.734 10.992-23.734 24.5 0 13.509 10.648 24.5 23.734 24.5Zm0-47.948c12.528 0 22.716 10.517 22.716 23.448 0 12.932-10.191 23.449-22.716 23.449-12.524 0-22.715-10.52-22.715-23.449 0-12.928 10.191-23.448 22.715-23.448Z" />
			<path d="m461.324 312.796 1.486-7.667h11.882l1.486 7.667h-14.854Zm.742-14.183v-1.15h2.227v1.15h-2.227Zm.632 13.03h5.495v-2.107h-5.087l-.408 2.107Zm.632-3.257h4.866v-2.107h-4.455l-.407 2.107h-.004Zm5.98 3.257h5.494l-.407-2.107h-5.087v2.107Zm0-3.257h4.865l-.407-2.107h-4.455v2.107h-.003Zm-5.126-5.559-.797-.805 1.577-1.629.797.805-1.577 1.629Zm4.567-1.915c-.917 0-1.69-.334-2.322-1.005-.632-.67-.973-1.486-1.022-2.444h1.113c.039.639.267 1.182.689 1.629.421.446.934.67 1.542.67a2.04 2.04 0 0 0 1.542-.67c.422-.447.65-.99.689-1.629h1.114c-.05.958-.39 1.774-1.023 2.444-.632.671-1.405 1.005-2.322 1.005Zm-.558 3.449v-2.299h1.113v2.299h-1.113Zm5.125-1.552-1.577-1.629.78-.805 1.595 1.61-.798.824Zm-7.557-9.095-.797.805-1.577-1.629.797-.805 1.577 1.629Zm5.309 1.294c.632.671.973 1.487 1.022 2.444h-1.114a2.515 2.515 0 0 0-.688-1.628 2.043 2.043 0 0 0-1.542-.671c-.608 0-1.121.225-1.543.671-.421.446-.65.99-.688 1.628h-1.114c.049-.957.39-1.773 1.022-2.444.633-.671 1.406-1.004 2.323-1.004s1.689.333 2.322 1.004Zm-1.764-4.457v2.299h-1.113v-2.299h1.113Zm4.81 2.376-1.595 1.61-.78-.805 1.577-1.629.798.824Zm-.91 5.686v-1.15h2.227v1.15h-2.227Z" />
			<path d="M470.251 328.803h-3.221c-.573 0-1.037-.479-1.037-1.07v-9.657h-9.545v9.657c0 .591-.463 1.07-1.036 1.07h-3.186c-.573 0-1.037-.479-1.037-1.07v-13.955c0-.337.158-.66.418-.859l9.082-6.977a1.017 1.017 0 0 1 1.243.003l1.181.922-.689.939-1.121-.874-8.979 6.901v13.795h2.983v-9.657c0-.592.463-1.07 1.036-1.07h9.749c.572 0 1.036.478 1.036 1.07v9.657h3.018v-13.799l-1.535-1.196.688-.94 1.574 1.23c.256.199.411.518.411.852v13.958c0 .591-.464 1.07-1.036 1.07h.003Z" />
		</g>
		<g fill="#27D9E5" clipPath="url(#o)">
			<path d="M388.737 335.449c13.519 0 24.519-11 24.519-24.519 0-13.519-11-24.519-24.519-24.519-13.519 0-24.519 11-24.519 24.519 0 13.519 11 24.519 24.519 24.519Zm0-47.986c12.942 0 23.467 10.525 23.467 23.467 0 12.942-10.529 23.467-23.467 23.467-12.938 0-23.467-10.529-23.467-23.467 0-12.939 10.529-23.467 23.467-23.467Z" />
			<path d="M368.576 309.612c.349 0 15.04-.141 20.709-14.691l-1.096-.428c-5.415 13.9-19.478 13.954-19.609 13.94l-.011 1.176h.004l.003.003Z" />
			<path d="M408.897 309.612h.004l-.011-1.176c-.276 0-14.223-.13-19.605-13.94l-1.096.429c5.669 14.546 20.356 14.687 20.708 14.687Z" />
			<path d="M385.315 294.021h6.866v1.176h-6.866v-1.176Zm0-1.717h6.866v1.176h-6.866v-1.176Z" />
			<path d="M388.149 294.377h1.176v35.929h-1.176v-35.929Z" />
		</g>
		<path
			fill="#27D9E5"
			d="M432.692 275.328c13.944 0 25.291-10.992 25.291-24.5 0-13.509-11.347-24.5-25.291-24.5s-25.29 10.991-25.29 24.5c0 13.508 11.346 24.5 25.29 24.5Zm0-47.949c13.349 0 24.205 10.517 24.205 23.449 0 12.932-10.859 23.448-24.205 23.448-13.345 0-24.204-10.52-24.204-23.448s10.859-23.449 24.204-23.449Z"
		/>
		<path
			fill="#27D9E5"
			d="m429.189 252.931 1.486-7.666h11.881l1.486 7.666h-14.853Zm.741-14.183v-1.149h2.228v1.149h-2.228Zm.633 13.03h5.494v-2.107h-5.087l-.407 2.107Zm.632-3.257h4.866v-2.107h-4.455l-.408 2.107h-.003Zm5.979 3.257h5.495l-.408-2.107h-5.087v2.107Zm0-3.257h4.866l-.408-2.107h-4.454v2.107h-.004Zm-5.125-5.559-.798-.805 1.578-1.628.797.805-1.577 1.628Zm4.567-1.915c-.917 0-1.69-.333-2.323-1.004-.632-.671-.973-1.487-1.022-2.444h1.114c.038.638.267 1.182.688 1.628.422.446.935.671 1.543.671.607 0 1.12-.225 1.542-.671.421-.446.65-.99.688-1.628h1.114c-.049.957-.39 1.773-1.022 2.444-.633.671-1.405 1.004-2.322 1.004Zm-.559 3.449v-2.299h1.114v2.299h-1.114Zm5.126-1.552-1.578-1.628.78-.805 1.595 1.61-.797.823Zm-7.557-9.095-.797.805-1.578-1.628.798-.805 1.577 1.628Zm5.308 1.295c.633.67.973 1.486 1.023 2.444h-1.114c-.039-.638-.267-1.182-.689-1.628a2.037 2.037 0 0 0-1.542-.671c-.608 0-1.121.224-1.542.671-.422.446-.65.99-.689 1.628h-1.113c.049-.958.39-1.774 1.022-2.444.632-.671 1.405-1.005 2.322-1.005s1.69.334 2.322 1.005Zm-1.763-4.457v2.299h-1.114v-2.299h1.114Zm4.809 2.375-1.595 1.61-.78-.805 1.578-1.628.797.823Zm-.91 5.686v-1.149h2.228v1.149h-2.228Z"
		/>
		<path
			fill="#27D9E5"
			d="M438.116 268.938h-3.222c-.572 0-1.036-.479-1.036-1.07v-9.657h-9.545v9.657c0 .591-.464 1.07-1.037 1.07h-3.186c-.573 0-1.036-.479-1.036-1.07v-13.954c0-.337.158-.66.418-.86l9.081-6.977a1.018 1.018 0 0 1 1.244.004l1.18.921-.688.939-1.121-.874-8.98 6.901v13.795h2.983v-9.657c0-.591.464-1.07 1.036-1.07h9.749c.573 0 1.037.479 1.037 1.07v9.657h3.017v-13.798l-1.535-1.197.689-.939 1.574 1.229c.256.199.411.519.411.852v13.958c0 .591-.464 1.07-1.037 1.07h.004Z"
		/>
		<g fill="#27D9E5" clipPath="url(#p)">
			<path d="M443.692 387.385c13.509 0 24.5-10.992 24.5-24.5 0-13.509-10.991-24.5-24.5-24.5-13.508 0-24.5 10.991-24.5 24.5 0 13.508 10.992 24.5 24.5 24.5Zm0-47.949c12.932 0 23.449 10.517 23.449 23.449 0 12.932-10.52 23.448-23.449 23.448-12.928 0-23.448-10.52-23.448-23.448s10.52-23.449 23.448-23.449Z" />
			<path d="m446.573 363.425 1.456-7.053h11.654l1.456 7.053h-14.566Zm.73-13.051v-1.059h2.186v1.059h-2.186Zm.618 11.992h5.39v-1.94h-4.99l-.4 1.94Zm.618-2.999h4.769v-1.94h-4.369l-.4 1.94Zm5.862 2.999h5.39l-.4-1.94h-4.99v1.94Zm0-2.999h4.769l-.401-1.94h-4.368v1.94Zm-5.024-5.113-.782-.74 1.546-1.498.782.74-1.546 1.498Zm4.477-1.763c-.898 0-1.658-.308-2.276-.924a3.316 3.316 0 0 1-.999-2.249h1.093c.037.588.262 1.088.674 1.498.411.41.917.617 1.512.617a2.07 2.07 0 0 0 1.512-.617c.412-.41.637-.91.674-1.498h1.093a3.339 3.339 0 0 1-.999 2.249c-.618.616-1.378.924-2.276.924h-.008Zm-.546 3.174v-2.118h1.093v2.118h-1.093Zm5.023-1.429-1.546-1.498.764-.74 1.565 1.483-.783.758v-.003Zm-7.408-8.37-.782.74-1.546-1.498.782-.74 1.546 1.498Zm5.207 1.19a3.315 3.315 0 0 1 1 2.248h-1.093a2.254 2.254 0 0 0-.674-1.498 2.065 2.065 0 0 0-1.513-.616 2.07 2.07 0 0 0-1.512.616 2.25 2.25 0 0 0-.674 1.498h-1.093a3.333 3.333 0 0 1 1-2.248c.617-.617 1.377-.925 2.276-.925.898 0 1.658.308 2.276.925h.007Zm-1.729-4.102v2.118h-1.093v-2.118h1.093Zm4.716 2.187-1.564 1.483-.764-.74 1.546-1.498.782.758v-.003Zm-.891 5.233v-1.059h2.187v1.059h-2.187Z" />
			<path d="M455.325 378.203h-3.159c-.588 0-1.067-.464-1.067-1.03v-8.841h-9.265v8.841c0 .569-.479 1.03-1.063 1.03h-3.126c-.588 0-1.063-.464-1.063-1.03v-12.845c0-.323.161-.635.431-.827l8.909-6.422a1.1 1.1 0 0 1 1.276.004l1.161.848-.734.939-1.071-.783-8.759 6.314v12.627h2.826v-8.841c0-.57.479-1.034 1.067-1.034h9.561c.587 0 1.063.464 1.063 1.034v8.841h2.863v-12.627l-1.486-1.088.734-.94 1.546 1.132c.266.192.423.5.423.823v12.845c0 .569-.479 1.03-1.063 1.03h-.004Zm-20.237.014h-8.362l-.03-.471v-14.861l.486-.029 1.659-.011v-1.059l.486-.029h4.118l.038.461.015.638h2.047l.03.471v14.861l-.487.029Zm-7.483-.881h7.057v-13.599h-2.033l-.037-.461-.015-.638h-2.83v1.055l-.487.029-1.658.011v13.603h.003Z" />
			<path d="M433.958 373.329h-5.649v3.148h5.649v-3.148Zm0-4.04h-5.649v3.148h5.649v-3.148Z" />
		</g>
		<path
			fill="#27D9E5"
			d="M330.692 384.118c13.509 0 24.5-10.992 24.5-24.5s-10.991-24.5-24.5-24.5c-13.508 0-24.5 10.992-24.5 24.5s10.992 24.5 24.5 24.5Zm0-47.948c12.932 0 23.449 10.516 23.449 23.448s-10.52 23.448-23.449 23.448c-12.928 0-23.448-10.52-23.448-23.448s10.52-23.448 23.448-23.448Z"
		/>
		<path
			fill="#27D9E5"
			d="M318.584 374.457h4.33c.671 0 1.215-.544 1.215-1.214v-12.707l13.138-.04.04 12.747c0 .67.544 1.214 1.215 1.214h4.279c.671 0 1.215-.544 1.215-1.214v-18.165a1.23 1.23 0 0 0-.49-.976l-12.203-9.084a1.218 1.218 0 0 0-1.457.007l-12.015 9.081a1.224 1.224 0 0 0-.482.968v18.165c0 .671.544 1.215 1.215 1.215v.003Zm18.683-15.136h-13.098c-.671 0-1.215.544-1.215 1.215v12.707l-4.37.039-.04-18.204 2.132-1.632 9.897-7.481 12.253 9.084.019 18.194-4.32.039-.039-12.746c0-.671-.544-1.215-1.215-1.215h-.004Z"
		/>
		<g fill="#27D9E5" clipPath="url(#q)">
			<path d="M388.692 412.385c13.509 0 24.5-10.992 24.5-24.5 0-13.509-10.991-24.5-24.5-24.5-13.508 0-24.5 10.991-24.5 24.5 0 13.508 10.992 24.5 24.5 24.5Zm0-47.949c12.932 0 23.449 10.517 23.449 23.449 0 12.932-10.52 23.448-23.449 23.448-12.928 0-23.448-10.52-23.448-23.448s10.52-23.449 23.448-23.449Z" />
			<path d="M376.584 402.724h4.33c.671 0 1.215-.544 1.215-1.215v-12.707l13.138-.04.04 12.747c0 .671.544 1.215 1.215 1.215h4.279c.671 0 1.215-.544 1.215-1.215v-18.164a1.23 1.23 0 0 0-.49-.976l-12.203-9.084a1.218 1.218 0 0 0-1.457.007l-12.015 9.081a1.224 1.224 0 0 0-.482.968v18.165c0 .67.544 1.214 1.215 1.214v.004Zm18.683-15.137h-13.098c-.671 0-1.215.544-1.215 1.215v12.707l-4.37.04-.04-18.204 2.132-1.632 9.897-7.482 12.253 9.085.019 18.193-4.32.04-.039-12.747c0-.671-.544-1.215-1.215-1.215h-.004Z" />
		</g>
		<g fill="#27D9E5" clipPath="url(#r)">
			<path d="M324.436 202.941v15.276h-24.53v-15.276h24.53Zm.487-.929H299.42c-.247 0-.443.2-.443.443v16.248c0 .247.2.443.443.443h25.503c.246 0 .442-.2.442-.443v-16.248a.442.442 0 0 0-.442-.443Z" />
			<path d="M326.222 219.146v1.451h-28.098v-1.451h28.098Zm.722-.929h-29.542a.207.207 0 0 0-.207.206v2.893c0 .116.094.207.207.207h29.542a.207.207 0 0 0 .207-.207v-2.893a.206.206 0 0 0-.207-.206Zm-22.781-25.848a.83.83 0 0 1 .828.827v8.729h-1.652v-8.729a.83.83 0 0 1 .828-.827m0-.82c-.911 0-1.648.737-1.648 1.647v9.553h3.296v-9.553c0-.91-.737-1.647-1.648-1.647Z" />
			<path d="M304.163 200.978c-3.357 0-3.357-.262-3.357-.628 0-.439.349-.541 2.072-.603l.015.403c.367.015.791.022 1.27.022.657 0 1.212-.015 1.648-.04l.018-.37c1.227.061 1.688.13 1.688.588 0 .363 0 .628-3.357.628h.003Zm0-2.011c-3.357 0-3.357-.261-3.357-.628 0-.439.349-.541 2.072-.606l.015.403a33.6 33.6 0 0 0 1.27.022c.657 0 1.212-.015 1.648-.04l.018-.37c1.227.061 1.688.13 1.688.587 0 .363 0 .628-3.357.628l.003.004Zm0-2.011c-3.357 0-3.357-.261-3.357-.627 0-.44.349-.541 2.072-.607l.015.403c.367.015.791.022 1.27.022.657 0 1.212-.014 1.648-.04l.018-.37c1.227.062 1.688.131 1.688.588 0 .363 0 .628-3.357.628l.003.003Zm0-2.014c-3.357 0-3.357-.261-3.357-.628 0-.439.349-.54 2.072-.606l.015.403c.367.015.791.022 1.27.022.657 0 1.212-.015 1.648-.04l.018-.37c1.227.061 1.688.13 1.688.588 0 .363 0 .628-3.357.628l.003.003Zm8.065-2.573a.83.83 0 0 1 .827.827v8.729h-1.651v-8.729a.83.83 0 0 1 .827-.827m0-.82c-.911 0-1.648.737-1.648 1.647v9.553h3.296v-9.553c0-.91-.737-1.647-1.648-1.647Z" />
			<path d="M312.227 200.978c-3.357 0-3.357-.262-3.357-.628 0-.439.349-.541 2.073-.603l.014.403c.367.015.791.022 1.27.022.657 0 1.213-.015 1.648-.04l.018-.37c1.227.061 1.688.13 1.688.588 0 .363 0 .628-3.357.628h.003Zm0-2.011c-3.357 0-3.357-.261-3.357-.628 0-.439.349-.541 2.073-.606l.014.403a33.6 33.6 0 0 0 1.27.022c.657 0 1.213-.015 1.648-.04l.018-.37c1.227.061 1.688.13 1.688.587 0 .363 0 .628-3.357.628l.003.004Zm0-2.011c-3.357 0-3.357-.261-3.357-.627 0-.44.349-.541 2.073-.607l.014.403c.367.015.791.022 1.27.022.657 0 1.213-.014 1.648-.04l.018-.37c1.227.062 1.688.131 1.688.588 0 .363 0 .628-3.357.628l.003.003Zm0-2.014c-3.357 0-3.357-.261-3.357-.628 0-.439.349-.54 2.073-.606l.014.403c.367.015.791.022 1.27.022.657 0 1.213-.015 1.648-.04l.018-.37c1.227.061 1.688.13 1.688.588 0 .363 0 .628-3.357.628l.003.003Zm7.956-2.573c.454 0 .827.37.827.827v8.729h-1.651v-8.729a.83.83 0 0 1 .827-.827m0-.82c-.91 0-1.647.737-1.647 1.647v9.553h3.295v-9.553c0-.91-.737-1.647-1.648-1.647Z" />
			<path d="M320.183 200.978c-3.357 0-3.357-.262-3.357-.628 0-.439.348-.541 2.072-.603l.015.403c.366.015.791.022 1.27.022.657 0 1.212-.015 1.647-.04l.019-.37c1.226.061 1.687.13 1.687.588 0 .363 0 .628-3.357.628h.004Zm0-2.011c-3.357 0-3.357-.261-3.357-.628 0-.439.348-.541 2.072-.606l.015.403c.366.014.791.022 1.27.022.657 0 1.212-.015 1.647-.04l.019-.37c1.226.061 1.687.13 1.687.587 0 .363 0 .628-3.357.628l.004.004Zm0-2.011c-3.357 0-3.357-.261-3.357-.627 0-.44.348-.541 2.072-.607l.015.403c.366.015.791.022 1.27.022.657 0 1.212-.014 1.647-.04l.019-.37c1.226.062 1.687.131 1.687.588 0 .363 0 .628-3.357.628l.004.003Zm0-2.014c-3.357 0-3.357-.261-3.357-.628 0-.439.348-.54 2.072-.606l.015.403c.366.015.791.022 1.27.022.657 0 1.212-.015 1.647-.04l.019-.37c1.226.061 1.687.13 1.687.588 0 .363 0 .628-3.357.628l.004.003Z" />
			<path d="M312.173 231.064c-13.519 0-24.519-11-24.519-24.519 0-13.519 11-24.519 24.519-24.519 13.519 0 24.519 11 24.519 24.519 0 13.519-11 24.519-24.519 24.519Zm0-47.986c-12.942 0-23.467 10.525-23.467 23.467 0 12.942 10.529 23.467 23.467 23.467 12.938 0 23.467-10.529 23.467-23.467 0-12.938-10.529-23.467-23.467-23.467Z" />
		</g>
		<g fill="#27D9E5" clipPath="url(#s)">
			<path d="M187.129 202.941v15.276h-24.53v-15.276h24.53Zm.486-.929h-25.503c-.247 0-.443.2-.443.443v16.248c0 .247.2.443.443.443h25.503c.247 0 .443-.2.443-.443v-16.248c0-.247-.2-.443-.443-.443Z" />
			<path d="M188.914 219.146v1.451h-28.097v-1.451h28.097Zm.722-.929h-29.542a.207.207 0 0 0-.207.206v2.893c0 .116.095.207.207.207h29.542a.207.207 0 0 0 .207-.207v-2.893a.206.206 0 0 0-.207-.206Zm-22.78-25.848a.83.83 0 0 1 .827.827v8.729h-1.651v-8.729a.83.83 0 0 1 .827-.827m0-.82c-.911 0-1.647.737-1.647 1.647v9.553h3.295v-9.553c0-.91-.737-1.647-1.648-1.647Z" />
			<path d="M166.856 200.978c-3.357 0-3.357-.262-3.357-.628 0-.439.348-.541 2.072-.603l.014.403c.367.015.792.022 1.271.022.656 0 1.212-.015 1.647-.04l.018-.37c1.227.061 1.688.13 1.688.588 0 .363 0 .628-3.357.628h.004Zm0-2.011c-3.357 0-3.357-.261-3.357-.628 0-.439.348-.541 2.072-.606l.014.403c.367.014.792.022 1.271.022.656 0 1.212-.015 1.647-.04l.018-.37c1.227.061 1.688.13 1.688.587 0 .363 0 .628-3.357.628l.004.004Zm0-2.011c-3.357 0-3.357-.261-3.357-.627 0-.44.348-.541 2.072-.607l.014.403c.367.015.792.022 1.271.022.656 0 1.212-.014 1.647-.04l.018-.37c1.227.062 1.688.131 1.688.588 0 .363 0 .628-3.357.628l.004.003Zm0-2.014c-3.357 0-3.357-.261-3.357-.628 0-.439.348-.54 2.072-.606l.014.403c.367.015.792.022 1.271.022.656 0 1.212-.015 1.647-.04l.018-.37c1.227.061 1.688.13 1.688.588 0 .363 0 .628-3.357.628l.004.003Zm8.064-2.573c.454 0 .827.37.827.827v8.729h-1.651v-8.729a.83.83 0 0 1 .828-.827m0-.82c-.911 0-1.648.737-1.648 1.647v9.553h3.295v-9.553c0-.91-.736-1.647-1.647-1.647Z" />
			<path d="M174.92 200.978c-3.357 0-3.357-.262-3.357-.628 0-.439.348-.541 2.072-.603l.015.403c.366.015.791.022 1.27.022.657 0 1.212-.015 1.647-.04l.019-.37c1.226.061 1.687.13 1.687.588 0 .363 0 .628-3.357.628h.004Zm0-2.011c-3.357 0-3.357-.261-3.357-.628 0-.439.348-.541 2.072-.606l.015.403c.366.014.791.022 1.27.022.657 0 1.212-.015 1.647-.04l.019-.37c1.226.061 1.687.13 1.687.587 0 .363 0 .628-3.357.628l.004.004Zm0-2.011c-3.357 0-3.357-.261-3.357-.627 0-.44.348-.541 2.072-.607l.015.403c.366.015.791.022 1.27.022.657 0 1.212-.014 1.647-.04l.019-.37c1.226.062 1.687.131 1.687.588 0 .363 0 .628-3.357.628l.004.003Zm0-2.014c-3.357 0-3.357-.261-3.357-.628 0-.439.348-.54 2.072-.606l.015.403c.366.015.791.022 1.27.022.657 0 1.212-.015 1.647-.04l.019-.37c1.226.061 1.687.13 1.687.588 0 .363 0 .628-3.357.628l.004.003Zm7.955-2.573a.83.83 0 0 1 .828.827v8.729h-1.652v-8.729a.83.83 0 0 1 .828-.827m0-.82c-.911 0-1.648.737-1.648 1.647v9.553h3.296v-9.553c0-.91-.737-1.647-1.648-1.647Z" />
			<path d="M182.875 200.978c-3.357 0-3.357-.262-3.357-.628 0-.439.348-.541 2.072-.603l.015.403c.366.015.791.022 1.27.022.657 0 1.212-.015 1.648-.04l.018-.37c1.227.061 1.688.13 1.688.588 0 .363 0 .628-3.358.628h.004Zm0-2.011c-3.357 0-3.357-.261-3.357-.628 0-.439.348-.541 2.072-.606l.015.403c.366.014.791.022 1.27.022.657 0 1.212-.015 1.648-.04l.018-.37c1.227.061 1.688.13 1.688.587 0 .363 0 .628-3.358.628l.004.004Zm0-2.011c-3.357 0-3.357-.261-3.357-.627 0-.44.348-.541 2.072-.607l.015.403c.366.015.791.022 1.27.022.657 0 1.212-.014 1.648-.04l.018-.37c1.227.062 1.688.131 1.688.588 0 .363 0 .628-3.358.628l.004.003Zm0-2.014c-3.357 0-3.357-.261-3.357-.628 0-.439.348-.54 2.072-.606l.015.403c.366.015.791.022 1.27.022.657 0 1.212-.015 1.648-.04l.018-.37c1.227.061 1.688.13 1.688.588 0 .363 0 .628-3.358.628l.004.003Z" />
			<path d="M174.865 231.064c-13.519 0-24.519-11-24.519-24.519 0-13.519 11-24.519 24.519-24.519 13.519 0 24.52 11 24.52 24.519 0 13.519-11.001 24.519-24.52 24.519Zm0-47.986c-12.941 0-23.466 10.525-23.466 23.467 0 12.942 10.528 23.467 23.466 23.467 12.939 0 23.467-10.529 23.467-23.467 0-12.938-10.528-23.467-23.467-23.467Z" />
		</g>
		<defs>
			<clipPath id="a">
				<path fill="#fff" d="M440 78.192h47.469v49H440z" />
			</clipPath>
			<clipPath id="b">
				<path fill="#fff" d="M268.039 182.026v49.038h-49.038v-49.038z" />
			</clipPath>
			<clipPath id="c">
				<path fill="#fff" d="M364.218 77.936h49.038v49.038h-49.038z" />
			</clipPath>
			<clipPath id="d">
				<path fill="#fff" d="M419.192 26h49v49h-49z" />
			</clipPath>
			<clipPath id="e">
				<path fill="#fff" d="M365 2.192h49v49h-49z" />
			</clipPath>
			<clipPath id="f">
				<path fill="#fff" d="M1 127h47.469V78H1z" />
			</clipPath>
			<clipPath id="g">
				<path fill="#fff" d="M124.167 76.935H75.129v49.038h49.038z" />
			</clipPath>
			<clipPath id="h">
				<path fill="#fff" d="M20.192 74h49V25h-49z" />
			</clipPath>
			<clipPath id="i">
				<path fill="#fff" d="M75.192 49h49V0h-49z" />
			</clipPath>
			<clipPath id="j">
				<path fill="#fff" d="M47.469 337.192H-.001v-49h47.47z" />
			</clipPath>
			<clipPath id="k">
				<path fill="#fff" d="M75.128 288.411h49.038v49.038H75.128z" />
			</clipPath>
			<clipPath id="l">
				<path fill="#fff" d="M69.192 389.385h-49v-49h49z" />
			</clipPath>
			<clipPath id="m">
				<path fill="#fff" d="M124.192 414.385h-49v-49h49z" />
			</clipPath>
			<clipPath id="n">
				<path fill="#fff" d="M487.469 286.192H440v49h47.469z" />
			</clipPath>
			<clipPath id="o">
				<path fill="#fff" d="M413.256 286.411h-49.038v49.038h49.038z" />
			</clipPath>
			<clipPath id="p">
				<path fill="#fff" d="M468.192 338.385h-49v49h49z" />
			</clipPath>
			<clipPath id="q">
				<path fill="#fff" d="M413.192 412.385v-49h-49v49z" />
			</clipPath>
			<clipPath id="r">
				<path fill="#fff" d="M336.692 182.026v49.038h-49.038v-49.038z" />
			</clipPath>
			<clipPath id="s">
				<path fill="#fff" d="M150.346 182.026h49.038v49.038h-49.038z" />
			</clipPath>
		</defs>
	</svg>
);
