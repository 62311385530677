import { Option } from "@powerledger/ui-component-lib/dist-types";

import { MeterAssetType, ProfileClass } from "@/app/types/generated/graphql";

import { ProfileClassLabel } from "../baseline-forecast-profile/profile-overview-table";

/**
 * Returns the list of supported ProfileClass for a given participant type.
 *
 * @param participantType the type of participant
 * @return the list of supported ProfileClass for the given participant type
 */
export const getParticipantSupportedProfileClassList = (participantType: MeterAssetType): ProfileClass[] => {
	// TODO: Currently profile class is only associciated to participants of type Consumser, Prosumer and Prosumer with BESS
	// When it is defined for other types of participants, add more statements here

	if (participantType === MeterAssetType.Consumer) {
		return [ProfileClass.Load];
	} else if (participantType === MeterAssetType.Prosumer) {
		return [ProfileClass.Load, ProfileClass.Generation];
	} else if (participantType === MeterAssetType.ProsumerBess) {
		return [ProfileClass.Load, ProfileClass.Generation, ProfileClass.Bess];
	}

	return [];
};

/**
 * Returns the options for editing a meter profile class for a given participant type.
 *
 * @param participantType The type of participant.
 * @return An array of options containing the label and value for each supported profile class.
 */
export const getEditMeterProfileClassOptions = (participantType: MeterAssetType): Option[] => {
	return getParticipantSupportedProfileClassList(participantType).map((profileClass) => ({
		label: `${ProfileClassLabel[profileClass]} Profile`,
		value: profileClass,
	}));
};
