import { addMinutes, format, parse } from "date-fns";
import { parse as csvParse } from "papaparse";

import { END_TIMESTAMP, ENERGY_DATA_INTERVAL_IN_MINUTES, START_TIMESTAMP } from "./constants";

/**
 * Validates the format of a CSV file containing time series data. The file should have 24 hours of data in ascending order of timestamp,
 * with each timestamp certain minutes apart, defaults to 30 minutes. The file should have a header with the columns "TIME" and "VALUE".
 *
 * @param  file - the CSV file to be validated
 * @return - a promise that resolves if the file is in the correct format and rejects with an error message otherwise
 */
export const validateMeterProfileCsv = (
	file: File,
	options: { startTimestamp: string; endTimestamp: string; interval: number } = {
		startTimestamp: START_TIMESTAMP,
		endTimestamp: END_TIMESTAMP,
		interval: ENERGY_DATA_INTERVAL_IN_MINUTES,
	},
): Promise<void> => {
	return new Promise((resolve, reject) => {
		csvParse(file, {
			complete: (results: { data: Array<Array<string>>; errors: Array<Papa.ParseError>; meta: Papa.ParseMeta }) => {
				const { data } = results;
				const headers = data.shift();

				if (!headers || headers[0] !== "TIME" || headers[1] !== "VALUE") {
					reject("Invalid CSV file format");
					return;
				}

				let prevTimestamp: string | null = null;

				for (let index = 0; index < data.length; index++) {
					/**
					 * Remove whitespace from each cell i.e. timestamp or value and filter cell if there is no value
					 * - ["12:30", " 0.5"] -> ["12:30", "0.5"]
					 * - [" 12:30", " 0.5 "] -> ["12:30", "0.5"]
					 * - [""] -> row without any value will be filtered out
					 * - [" ", "0.5"] -> ["0.5"] or ["12:30", ""] -> ["12:30"] -> would fail with "Invalid CSV file format" in the next step
					 */
					const row = data[index].map((cell: string) => cell.trim()).filter((cell) => cell.length > 0);

					if (row.length > 0) {
						const timestamp = row[0];
						const value = parseFloat(row[1]);

						// timestamp in CSV file should be in the format of "H:mm" or "HH:mm"
						if (isNaN(value) || !/^\d{1,2}:\d{2}$/.test(timestamp)) {
							reject("Invalid CSV file format");
							return;
						}

						// Data in CSV file should start from timestamp as specified
						if (index === 0 && timestamp !== options.startTimestamp) {
							reject(
								`CSV file should cover 24 hours of data in ascending order of timestamp starting from ${options.startTimestamp} to ${options.endTimestamp}`,
							);
							return;
						}

						const expectedTimeStamp: string | null = prevTimestamp
							? format(addMinutes(parse(prevTimestamp, "HH:mm", new Date()), options.interval), "H:mm")
							: null;

						if (prevTimestamp && timestamp !== expectedTimeStamp) {
							reject(
								`Timestamps should be ${options.interval} minutes apart. Found invalid data between timestamps ${prevTimestamp} and ${timestamp}`,
							);
							return;
						}
						prevTimestamp = timestamp;
					}
				}

				// Data in CSV file should end at timestamp as specified
				if (prevTimestamp !== options.endTimestamp) {
					reject(
						`CSV file should cover 24 hours of data in ascending order of timestamp starting from ${options.startTimestamp} to ${options.endTimestamp}`,
					);
					return;
				}

				resolve();
			},
			error: () => {
				reject("Invalid CSV file format");
			},
		});
	});
};
