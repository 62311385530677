import { StyleSheet, View } from "@react-pdf/renderer";
import React, { FC } from "react";

import { FormattedTableDataType, SourcedEnergyQuantityType } from "../../tables";
import { TableHeader } from "./table-header";
import { TableRow } from "./table-row";

const styles = StyleSheet.create({
	tableContainer: {
		flexDirection: "row",
		flexWrap: "wrap",
	},
});

type DocumentType = {
	data: FormattedTableDataType[] | SourcedEnergyQuantityType[];
	key?: string;
	fees?: boolean;
};
export const ItemsTable: FC<DocumentType> = ({ data, key = "", fees = false }) => (
	<View style={styles.tableContainer}>
		<TableHeader key={`${key}-header`} fees={fees} />
		{data.map((bill, index) => (
			<TableRow item={bill} key={`${key}-row-${index}`} fees={fees} />
		))}
	</View>
);
