import { StyleSheet, Text, View } from "@react-pdf/renderer";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";

const styles = StyleSheet.create({
	row: {
		flexDirection: "row",
		alignItems: "center",
	},
	cellCommon: {
		fontWeight: "bold",
		fontSize: "34px",
	},
	cell: {
		fontWeight: "bold",
		width: "20%",
	},
	cellSmall: {
		fontWeight: "bold",
		width: "10%",
	},
	cellWide: {
		fontWeight: "bold",
		width: "30%",
	},
});
type RowType = {
	key: string;
	fees?: boolean;
};
export const TableHeader: FC<RowType> = ({ key, fees = false }) => {
	const { t } = useTranslation();
	return (
		<View style={styles.row} key={key}>
			<Text style={fees ? styles.cell : styles.cellSmall}>{t("Source")}</Text>
			<Text style={fees ? styles.cellWide : styles.cell}>{!fees && t("Time")}</Text>
			{!fees && <Text style={styles.cellWide}>{""}</Text>}
			<Text style={styles.cell}>{t("Units\n(kWh)")}</Text>
			<Text style={styles.cell}>{t("Unit Price\n($)")}</Text>
			<Text style={styles.cell}>{t("Amount\n($)")}</Text>
		</View>
	);
};
