/** @jsxImportSource theme-ui */
import {
	BuildingsSVG,
	ButtonGroup,
	Flex,
	IconBattery,
	IconSolarPanel,
	InfoSVG,
	LegacyRadio,
	Text,
	Tooltip,
} from "@powerledger/ui-component-lib";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import { MeterAssetType } from "@/app/types/generated/graphql";

import { MeterAssetsProps } from "./meter-assets.types";

/**
 * Renders a component for displaying and selecting meter assets.
 *
 * @param value - The selected meter asset type.
 * @param disabled - Indicates whether the radio buttons should be disabled.
 * @param onChange - The callback function called when the meter asset value changes.
 * @param hideTooltip - Indicates whether the tooltip should be hidden.
 * @return The rendered component with the meter assets types as options.
 */
export const MeterAssets: FC<MeterAssetsProps> = ({ value, onChange, disabled, hideTooltip }) => {
	const { t } = useTranslation();
	return (
		<ButtonGroup
			sx={{
				width: "100%",
				alignItems: "center",
				justifyContent: "flex-start",
				fill: "inactiveDark",
			}}
		>
			<LegacyRadio
				id={MeterAssetType.Prosumer}
				label={
					<Flex sx={{ flexDirection: "column", gap: 2, ...(disabled ? { cursor: "not-allowed" } : {}) }}>
						<IconSolarPanel
							width={40}
							sx={{
								ml: 2,
								...(!disabled ? { fill: "green" } : {}),
							}}
						/>
						<Flex sx={{ flexDirection: "row" }}>
							<Text sx={{ ml: 2, ...(disabled ? { opacity: 0.5 } : {}) }}>{t("Solar")}</Text>
							{!hideTooltip && (
								<Tooltip
									id="prosumer-info"
									content={
										<Text>
											{t(
												"Choose this option if you have solar panels installed on your property but do not have a battery storage system.",
											)}
										</Text>
									}
								>
									<InfoSVG />
								</Tooltip>
							)}
						</Flex>
					</Flex>
				}
				value={MeterAssetType.Prosumer}
				checked={value === MeterAssetType.Prosumer}
				onChange={() => onChange?.(MeterAssetType.Prosumer)}
				labelSx={{ width: "65%" }}
				disabled={disabled}
			/>
			<LegacyRadio
				id={MeterAssetType.ProsumerBess}
				label={
					<Flex sx={{ flexDirection: "column", gap: 2, ...(disabled ? { cursor: "not-allowed" } : {}) }}>
						<Flex>
							<IconSolarPanel
								width={40}
								sx={{
									ml: 2,
									...(!disabled ? { fill: "green" } : {}),
								}}
							/>
							<IconBattery
								sx={{
									transform: "rotate(-90deg)",
									ml: 2,
									...(!disabled ? { fill: "green" } : {}),
								}}
								width={40}
							/>
						</Flex>
						<Flex sx={{ flexDirection: "row" }}>
							<Text sx={{ ml: 2, ...(disabled ? { opacity: 0.5 } : {}) }}>{t("Solar & Battery")}</Text>
							{!hideTooltip && (
								<Tooltip
									id="prosumer-bess-info"
									content={
										<Text>
											{t(
												"Select this option if you have both solar panels and a battery storage system installed on your property.",
											)}
										</Text>
									}
								>
									<InfoSVG />
								</Tooltip>
							)}
						</Flex>
					</Flex>
				}
				value={MeterAssetType.ProsumerBess}
				checked={value === MeterAssetType.ProsumerBess}
				onChange={() => onChange?.(MeterAssetType.ProsumerBess)}
				disabled={disabled}
			/>
			<LegacyRadio
				id={MeterAssetType.Consumer}
				label={
					<Flex sx={{ flexDirection: "column", gap: 2, ...(disabled ? { cursor: "not-allowed" } : {}) }}>
						<BuildingsSVG
							width={40}
							sx={{
								ml: 2,
								"> path": {
									stroke: disabled ? "inactiveDark" : "green",
									strokeWidth: 2,
								},
							}}
						/>

						<Flex sx={{ flexDirection: "row" }}>
							<Text sx={{ ml: 2, ...(disabled ? { opacity: 0.5 } : {}) }}>{t("None (No Solar or Battery)")}</Text>
							{!hideTooltip && (
								<Tooltip
									id="consumer-info"
									content={
										<Text>
											{t(
												"Choose this option if you do not have any solar panels or battery storage devices installed on your property.",
											)}
										</Text>
									}
								>
									<InfoSVG />
								</Tooltip>
							)}
						</Flex>
					</Flex>
				}
				value={MeterAssetType.Consumer}
				checked={value === MeterAssetType.Consumer}
				onChange={() => onChange?.(MeterAssetType.Consumer)}
				disabled={disabled}
			/>
		</ButtonGroup>
	);
};
