import { User } from "@/app/types/generated/graphql";

import { UserDataRow } from "./user-table.types";

/**
 * Returns a UserDataRow object based on the given User object and index.
 *
 * @param user - User object to extract data from.
 * @param index - Index of the UserDataRow.
 * @return The resulting UserDataRow object.
 */
export const getUserDataRowFromUserQueryNode = (user: User | undefined | null, index: number | string): UserDataRow => {
	return {
		id: user?.id || index.toString(),
		participant: user?.firstName ? `${user?.firstName} ${user?.lastName}` : "-",
		email: user?.username || "",
		status: user?.status || undefined,
		role: user?.roles?.at(0) || "-",
	};
};
