import { ActionButton, Box, Heading } from "@powerledger/ui-component-lib";
import { FC, ReactNode } from "react";

export type PublicLayoutProps = {
	children?: any;
	heading: string;
	subheading?: string;
	action?: {
		to?: string;
		text?: string;
		onClick?: () => void;
		icon?: ReactNode;
	};
};

export const PublicLayout: FC<PublicLayoutProps> = ({ children, heading, subheading, action }) => (
	<Box
		sx={{
			display: ["block", null, null, null, "flex"],
			mt: [4, 5],
			mb: [5],
			pl: [4, 5],
			pr: [4, 6],
			justifyContent: "space-between",
		}}
	>
		<Box sx={{ width: ["100%", null, null, null, "50%"] }}>
			{subheading && (
				<Heading sx={{ fontSize: 4, mb: 4, color: "textDarker", fontWeight: "light" }}>{subheading}</Heading>
			)}
			<Heading
				as="h1"
				sx={{
					fontSize: [5, null, null, null, 6],
					fontWeight: "bold",
					lineHeight: 1,
					fontFamily: "MintGroteskV08",
				}}
			>
				{heading}
			</Heading>
			{action && (
				<ActionButton
					// to={action.to}
					onClick={action.onClick}
					icon={action.icon}
					sx={{ mt: [3, null, null, null, 5] }}
				>
					{action.text}
				</ActionButton>
			)}
		</Box>
		<Box
			sx={{
				width: ["100%", null, null, null, "50%"],
				mt: [4, null, null, null, 0],
				ml: [0, null, null, null, 4],
			}}
		>
			{children}
		</Box>
	</Box>
);

export default PublicLayout;
