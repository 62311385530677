import { Flex, Text } from "@powerledger/ui-component-lib";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";

import { Layout } from "@/app/components";

import { UserTable } from "./user-table";

export const Admin: FC = () => {
	const { t } = useTranslation();
	const location = useLocation<{ inviteMessage: string }>();
	const history = useHistory();
	const [inviteStatusMessage, setInviteStatusMessage] = useState("");

	useEffect(() => {
		if (location.state) {
			setInviteStatusMessage(location.state.inviteMessage);
			history.replace({ state: undefined });
		}
	}, [history, location.state]);

	return (
		<Layout pageTitle="Admin">
			<Flex sx={{ flexDirection: "column", height: "100%", p: 4 }}>
				{inviteStatusMessage && <Text sx={{ color: "positive" }}>{t(inviteStatusMessage)}</Text>}
				<UserTable />
			</Flex>
		</Layout>
	);
};
