import { BuyersData } from "./bids.types";

export const mockData: BuyersData[] = [
	{
		date: "11/09/22",
		kWh: "2.25",
	},
];

export const largeMockData: BuyersData[] = [
	{
		date: "10/09/22",
		kWh: "0.25",
	},
	{
		date: "09/09/22",
		kWh: "0.20",
	},
	{
		date: "08/09/22",
		kWh: "2.20",
	},
];
