import { Flex, IconLoading } from "@powerledger/ui-component-lib";

/**
 * FullContainerLoader
 * Simple loading element that takes full width and height based on the parent container with loader in the center
 */
export const FullContainerLoader = () => {
	return (
		<Flex
			sx={{
				width: "100%",
				alignItems: "center",
				justifyContent: "center",
			}}
		>
			<IconLoading />
		</Flex>
	);
};
