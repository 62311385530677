import { Box, Flex, Text } from "@powerledger/ui-component-lib";
import { format } from "date-fns";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { SummaryTypes } from "../settlements.types";
import { formatCurrency } from "../tables/common-functions";
import { StructuredData } from "./account-summary.types";
import { BoughtSoldGraph } from "./bought-sold-graph";
import { structureData } from "./structure-data";

export const AccountSummary = (props: SummaryTypes) => {
	const { t } = useTranslation();
	const { bills, startDate } = props;

	const structuredData: StructuredData = useMemo(() => {
		return structureData(bills);
	}, [bills]);

	const Period = useMemo(() => {
		if (bills.length > 0) {
			return format(new Date(startDate), "dd/MM/yyyy");
		} else {
			return "-";
		}
	}, [bills, startDate]);
	return (
		<Flex sx={{ flexDirection: "column" }}>
			<Flex sx={{ width: "100%" }}>
				<Flex sx={{ justifyContent: "flex-start" }}>
					<Flex sx={{ flexDirection: "column", minWidth: 200 }}>
						<Text sx={{ fontWeight: "bold" }}>{t("Trade Date")}</Text>
						<Text>{Period}</Text>
						<Text sx={{ fontWeight: "bold" }}>{t("Generated on:")}</Text>
						<Text>{format(new Date(startDate), "dd/MM/yyyy")}</Text>
					</Flex>
					<Flex
						sx={{
							mx: 3,
							backgroundColor: "secondaryDarker",
							borderRadius: 3,
							alignItems: "center",
							justifyContent: "center",
							minWidth: "200px",
							minHeight: "160px",
							flexDirection: "column",
						}}
					>
						<Text sx={{ textAlign: "center", fontSize: 24, fontWeight: "bold" }}>
							{formatCurrency(structuredData.boughtCost + structuredData.additionalCharges - structuredData.soldCost)}
						</Text>
						<Text sx={{ textAlign: "center", fontSize: 18 }}>{t("Net Amount")}</Text>
					</Flex>
				</Flex>

				<Box sx={{ flexGrow: 0.6 }}>
					<BoughtSoldGraph
						boughtCost={structuredData.boughtCost}
						boughtkWh={structuredData.boughtkWh}
						soldCost={structuredData.soldCost}
						soldkWh={structuredData.soldkWh}
						additionalCharges={structuredData.additionalCharges}
					/>
				</Box>
			</Flex>
		</Flex>
	);
};
