/** @jsxImportSource theme-ui */
import { ApolloError } from "@apollo/client";
import { Box, Button, Flex, Paragraph, Text } from "@powerledger/ui-component-lib";
import { Location } from "history";
import { Fragment, ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { useGeneralResendUserActivationEmailMutation } from "../../../types/generated/graphql";

/**
 * Invalid defines expired, non-usable or malformed.
 * Okta Tokens are burnt on use (e.g. sending one over to Okta's API).
 */
export const InvalidTokenError = ({ location }: { location: Location }) => {
	const { t } = useTranslation();

	const userIdFromParams = new URLSearchParams(location.search).get("u") || "";

	const [resendUserActivationEmailMutation, { data, error, loading, reset }] =
		useGeneralResendUserActivationEmailMutation();

	const [errorMessage, setErrorMessage] = useState<ReactNode>(
		<Fragment>{t("Unfortunately we encountered an error in resending the activation link.")}</Fragment>,
	);

	async function handleResendOnClick() {
		if (!userIdFromParams) return;
		reset();
		try {
			await resendUserActivationEmailMutation({
				variables: {
					userId: userIdFromParams,
				},
			});
		} catch (e) {
			// TODO: Handle error logging
			// If we have Okta E0000016, we can assume the user is already active
			if (e instanceof ApolloError) {
				if (e.message.includes("E0000016")) {
					setErrorMessage(
						<>
							<Text
								sx={{
									color: "yellow",
								}}
							>
								{t("Your account is already active!")}
								<br />
							</Text>
							<Link to="/login">
								<Button sx={{ mt: 2 }} variant="text">
									{t("Click here to log in")}
								</Button>
							</Link>
						</>,
					);
				}
			}
		}
	}

	return (
		<Flex
			sx={{
				width: "100%",
				height: "100%",
				alignItems: "center",
			}}
		>
			<Box>
				<Text variant={"error"} as={"h1"}>
					{t("Something went wrong.")}
				</Text>
				<Paragraph
					sx={{
						mt: [1, 2],
						maxWidth: "680px",
						color: "warning",
					}}
				>
					{t("The activation link has expired or is invalid. If you have already set your password, please ")}
					<Link
						sx={{
							textDecoration: "none",
							color: "primary",
							"&:hover": {
								color: "textDarker",
							},
						}}
						to={"/login"}
					>
						{t("login")}
					</Link>
				</Paragraph>
				{userIdFromParams && (
					<>
						<Paragraph
							sx={{
								mt: [3, 4],
								mb: 2,
							}}
						>
							<Button
								sx={{
									fontSize: `1rem`,
								}}
								variant={"secondary"}
								onClick={handleResendOnClick}
								disabled={loading}
							>
								{t("Please click here to resend a new activation link.")}
							</Button>
						</Paragraph>
						{error && (
							<Text
								sx={{
									color: "warning",
								}}
							>
								{errorMessage}
							</Text>
						)}
						{loading && <Text>{t("Resending activation link...")}</Text>}
						{data && <Text sx={{ color: "positive" }}>{t("Activation link sent!")}</Text>}
					</>
				)}
			</Box>
		</Flex>
	);
};
