import { Flex, Modal } from "@powerledger/ui-component-lib";
import { FC } from "react";

import { AssignMeterToProfiles } from "./assign-meter-to-profiles/assign-meter-to-profiles";
import { AssignMeterToProfilesModalProps } from "./assign-meter-to-profiles-modal.types";

/**
 * Renders a modal component with the AssignMeterToProfiles component inside.
 *
 * @param visible - A boolean indicating whether the modal is visible.
 * @param onClose - A function to be called when the modal is closed.
 * @param meterData - An object containing meter data.
 * @return A React component representing the modal.
 */
export const AssignMeterToProfilesModal: FC<AssignMeterToProfilesModalProps> = ({ visible, onClose, meterData }) => {
	return (
		<Modal
			visible={visible}
			onCancel={onClose}
			sx={{
				display: "flex",
				justifyContent: "center",
				backgroundColor: "transparent",
			}}
			hideCloseButton
		>
			<Flex
				sx={{
					height: 650,
					width: 817,
					padding: 26,
					backgroundColor: "secondaryDark",
				}}
			>
				{!!meterData && <AssignMeterToProfiles meterData={meterData} onClose={onClose} />}
			</Flex>
		</Modal>
	);
};
