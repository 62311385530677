import { isAfter } from "date-fns";

import { Profile, ProfileType } from "@/app/types/generated/graphql";

import { DATE_RANGE_SELECTOR_PROFILE_TYPES } from "./create-edit-meter-profile-modal";
import { BaselineProfileCriteriaType } from "./create-edit-meter-profile-modal/create-edit-meter-profile/baseline-profile-criteria";

/**
 * Converts the data from the profile type to the format required by the form.
 *
 * @param profile - The profile object containing the data from the table.
 * @returns The converted data in the format required by the form.
 */
export const convertProfileDataToFormData = (profile?: Profile): BaselineProfileCriteriaType | undefined => {
	if (!profile) {
		return undefined;
	}

	const initialValues: BaselineProfileCriteriaType = {
		id: profile.id,
		name: profile.name || "",
		profileClass: profile.profileClass || null,
		startDate: null,
		endDate: null,
		month: null,
		type: profile.type || null,
		isDefault: profile.isDefault ?? false,
	};

	if (profile.type === ProfileType.Day && profile.day) {
		return { ...initialValues, startDate: new Date(profile.day) };
	} else if (profile.type === ProfileType.Month && profile.month) {
		return { ...initialValues, month: profile.month };
	} else if (DATE_RANGE_SELECTOR_PROFILE_TYPES.includes(profile.type)) {
		const { seasonStartDate, seasonEndDate } = convertSeasonStartEndMonthDayValueToDate(
			profile.seasonStart,
			profile.seasonEnd,
		);
		return { ...initialValues, startDate: seasonStartDate, endDate: seasonEndDate };
	}

	return initialValues;
};

/**
 * Converts season start and end month/day values to JavaScript Date objects.
 *
 * @param {string} seasonStart - The month/day value of the season start.
 * @param {string} seasonEnd - The month/day value of the season end.
 * @returns {Object} An object containing the converted season start and end dates.
 */
export const convertSeasonStartEndMonthDayValueToDate = (seasonStart: string, seasonEnd: string) => {
	const seasonStartDate = new Date(seasonStart);
	const seasonEndDate = new Date(seasonEnd);

	const currentYear = new Date().getFullYear();

	// Set current year as the year for season start & end date, as default years
	seasonStartDate.setFullYear(currentYear);
	seasonEndDate.setFullYear(currentYear);

	/**
	 * The season end date must be after the season start date
	 * If season is ending before the start of the season,
	 * set season end year to be the following year as of season start year
	 */
	if (!isAfter(seasonEndDate, seasonStartDate)) {
		seasonEndDate.setFullYear(seasonStartDate.getFullYear() + 1);
	}

	return { seasonStartDate, seasonEndDate };
};
