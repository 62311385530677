import { Box, Flex, Heading } from "@powerledger/ui-component-lib";
import { ReactElement } from "react";

export const StepLayout = ({
	heading,
	description,
	disclaimer,
	label,
	actions,
	fullWidthContent,
	children,
	id,
}: {
	heading: ReactElement | string;
	description?: ReactElement | string;
	disclaimer?: ReactElement | string;
	label?: ReactElement | string;
	actions?: ReactElement;
	fullWidthContent?: boolean;
	children: ReactElement;
	id?: string;
}) => {
	return (
		<Flex
			sx={{
				flexDirection: "column",
				height: "100%",
			}}
		>
			<Box>
				<Box sx={{ width: ["100%", null, null, null, "80%", "66%"] }}>
					{label && (
						<Box
							sx={{
								color: "textDarker",
								fontSize: 0,
								pb: 5,
								textTransform: "uppercase",
								letterSpacing: 2,
							}}
						>
							{label}
						</Box>
					)}
					<Flex sx={{ flexDirection: "column", pb: 3 }}>
						<Heading
							as="h1"
							sx={{
								fontWeight: "900",
								fontSize: 6,
								fontFamily: "MintGroteskV08",
								width: "100%",
							}}
							id={id}
						>
							{heading}
						</Heading>
						{description && (
							<Box sx={{ color: "textDarker", fontSize: 1, pt: 3, pb: disclaimer ? 0 : 3 }}>{description}</Box>
						)}
						{disclaimer && <Box sx={{ color: "textDarker", fontSize: 1, py: 3 }}>{disclaimer}</Box>}
					</Flex>
				</Box>
				<Box
					sx={{
						width: fullWidthContent ? "100%" : ["100%", null, "66%"],
						pr: fullWidthContent ? 5 : 0,
					}}
				>
					{children}
				</Box>
			</Box>

			{actions && (
				<Box
					sx={{
						pr: [0, null, 4],
						py: 3,
					}}
				>
					{actions}
				</Box>
			)}
		</Flex>
	);
};
