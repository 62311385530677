import { Box, Flex, Tabs } from "@powerledger/ui-component-lib";
import { FC, lazy, Suspense, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Redirect, Route, Switch } from "react-router";

import { FullContainerLoader } from "@/app/components/lem-loading";

import { SettingsViewProps } from "./settings.types";

const MyProfileTab = lazy(() => import("./my-profile-tab/my-profile-tab"));
const UpdatePasswordTab = lazy(() => import("./update-password-tab/update-password-tab"));

export const SettingsView: FC<SettingsViewProps> = ({
	userProfile,
	currentTab,
	logout,
	logoutLoading,
	switchTab,
	isParticipant,
	meterData,
	onUpdateUserDetails,
	updateUserError,
}) => {
	const { t } = useTranslation();
	const settingsTabs = useMemo(
		() => [
			{
				title: t("My Profile"),
				key: "/settings",
				component: (
					<MyProfileTab
						firstName={userProfile?.firstName || ""}
						lastName={userProfile?.lastName || ""}
						emailAddress={userProfile?.username || ""}
						nmi={meterData?.meterExternalRef || ""}
						meterAssetType={meterData?.assetType}
						isParticipant={isParticipant}
						onUpdateDetails={onUpdateUserDetails}
						updateUserError={updateUserError}
					/>
				),
			},
			{
				title: t("Update Password"),
				key: "/settings/update-password",
				component: <UpdatePasswordTab />,
			},
		],
		[t, userProfile, meterData, isParticipant, onUpdateUserDetails, updateUserError],
	);
	return (
		<Box
			sx={{
				p: 4,
			}}
		>
			<Flex sx={{ flexDirection: "column", height: "100%" }}>
				<Tabs
					activeKey={currentTab}
					onChange={switchTab}
					activeStyles={{ backgroundColor: "primary" }}
					sx={{ maxHeight: "inherit", position: "relative" }}
				>
					{settingsTabs.map(({ title, key }) => (
						<Tabs.TabPane
							tab={<Flex sx={{ color: key === currentTab ? "background" : "text" }}>{title}</Flex>}
							key={key}
						/>
					))}
				</Tabs>
				<Box sx={{ pt: "5", height: "100%" }}>
					<Switch>
						<Suspense fallback={<FullContainerLoader />}>
							{settingsTabs.map(({ key, component }) => (
								<Route exact key={key} path={key}>
									{component}
								</Route>
							))}
						</Suspense>
						<Route path={"*"}>
							<Redirect to={"/not-found"} />
						</Route>
					</Switch>
				</Box>
			</Flex>
		</Box>
	);
};
