import { useMemo, useState } from "react";

import { useUserProfile } from "@/app/hooks/use-user-profile";
import { useListOfMetersQuery } from "@/app/types/generated/graphql";

import { SelectedMeterDataType } from "./assign-meter-to-profiles-modal";
import { MeterProfileType, UseMeterProfilesFn } from "./meter-profile.types";

export const useMeterProfile: UseMeterProfilesFn = () => {
	const [selectedMeterData, setSelectedMeterData] = useState<SelectedMeterDataType | null>(null);

	const { userProfile, loading: userProfileLoading } = useUserProfile();
	const { data: listOfMeters, loading: listOfMeterLoading } = useListOfMetersQuery({
		variables: {
			applicationHostName: userProfile?.applicationHostName,
			tenantName: userProfile?.tenantName,
			tradingGroupName: userProfile?.tradingGroupName,
		},
		skip: userProfileLoading,
	});

	const dataSource = useMemo(
		() =>
			listOfMeters?.getMeters?.map(
				(meter): MeterProfileType => ({
					id: meter.meterId,
					meterNumber: meter.meterExternalRef,
					participantType: meter.assetType,
				}),
			) || [],
		[listOfMeters?.getMeters],
	);

	return {
		loading: userProfileLoading || listOfMeterLoading,
		dataSource,
		selectedMeterData,
		setSelectedMeterData,
	};
};
