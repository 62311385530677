import { Flex, Label, Text, ThemeUIStyleObject, Tooltip } from "@powerledger/ui-component-lib";
import { FC, ReactNode } from "react";

import { ErrorMessage } from "../error-message";

export type FormFieldLabelProps = {
	name?: string;
	hasErrorMessage?: boolean;
	label?: string;
	wrapLabel?: boolean;
	small?: boolean;
	sx?: ThemeUIStyleObject;
	/** Pass in string wrapped in i18next useTranslation t('information') */
	info?: string;
	children: ReactNode;
};

export const FormFieldLabel: FC<FormFieldLabelProps> = ({
	name = "",
	hasErrorMessage = true,
	label,
	wrapLabel = false,
	info,
	sx = {},
	children,
}) => {
	return (
		<Label id={name} sx={sx}>
			<Flex sx={{ alignItems: "center", flexShrink: wrapLabel ? 1 : 0, mb: 3 }}>
				{label}
				{info && (
					<Tooltip
						id={`form-field-label-${label}-tooltip`}
						content={<Text>{info}</Text>}
						sx={{
							ml: 1,
						}}
					/>
				)}
			</Flex>
			{children}
			{name && hasErrorMessage && <ErrorMessage name={name} sx={{ textAlign: "left", mt: 3 }} />}
		</Label>
	);
};

export default FormFieldLabel;
