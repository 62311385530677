/** @jsxImportSource theme-ui */
import { AuthnTransaction } from "@okta/okta-auth-js";
import { useOktaAuth } from "@okta/okta-react";
import { Box, Button, Flex, IconLoading, IconLongArrow, Link, Paragraph, Text } from "@powerledger/ui-component-lib";
import { Formik, FormikProps } from "formik";
import { TFunction } from "i18next";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { object, ref, string } from "yup";

import { Form, FormFieldLabel, PasswordInput } from "../../../../components";
import { resetPassword } from "../../../../services/OktaApi";
import { useUpdateOnboardingStepMutation } from "../../../../types/generated/graphql";
import { OktaTokenType } from "..";
import { StepLayout } from "../step-layout";

export const passwordValidationSchemaMessages = {
	required: "Password is required",
	minLength: "Password must contain at least 12 characters",
	oneUpperCase: "Password must contain at least one uppercase letter",
	oneLowerCase: "Password must contain at least one lowercase letter",
	oneNumber: "Password must contain at least one number",
	oneSpecialCharacter: "Password must contain at least one special character",
};

export const confirmPasswordValidationSchemaMessages = {
	required: "Password confirmation is required",
	notMatch: "Passwords do not match",
};

const setPasswordValidationSchema = (t: TFunction) =>
	object().shape({
		password: string()
			.required(t(passwordValidationSchemaMessages.required))
			.min(12, t(passwordValidationSchemaMessages.minLength))
			.matches(/^.*[A-Z]+.*$/, t(passwordValidationSchemaMessages.oneUpperCase))
			.matches(/^.*[a-z]+.*$/, t(passwordValidationSchemaMessages.oneLowerCase))
			.matches(/^.*[0-9]+.*$/, t(passwordValidationSchemaMessages.oneNumber))
			.matches(/^.*[?=.*?[\]#?!@$%^&*-]+.*$/, t(passwordValidationSchemaMessages.oneSpecialCharacter)),
		confirmPassword: string()
			.required(t(confirmPasswordValidationSchemaMessages.required))
			.oneOf([ref("password")], t(confirmPasswordValidationSchemaMessages.notMatch)),
	});

export const SetPassword = ({ oktaToken, goToNextStep }: { oktaToken: OktaTokenType; goToNextStep: () => void }) => {
	const { t } = useTranslation();
	const { oktaAuth } = useOktaAuth();
	const [error, setError] = useState(false);

	const [updateOnboardingToStepOne] = useUpdateOnboardingStepMutation({
		variables: {
			step: 1,
		},
	});

	const setInitialPassword = useCallback(
		async (password: string) => {
			await resetPassword({
				newPassword: password,
				stateToken: oktaToken.stateToken,
			});
		},
		[oktaToken.stateToken],
	);

	const signInToOkta = useCallback(
		async (password: string) => {
			const loginTransaction: AuthnTransaction = await oktaAuth.signInWithCredentials({
				username: oktaToken.email,
				password,
			});
			if (loginTransaction.status === "SUCCESS") {
				const { tokens } = await oktaAuth.token.getWithoutPrompt({
					responseType: "id_token",
					sessionToken: loginTransaction.sessionToken,
				});
				oktaAuth.tokenManager.setTokens(tokens);
			}
		},
		[oktaAuth, oktaToken.email],
	);

	const handleSubmit = useCallback(
		async ({ password }: { password: string }, { setSubmitting }) => {
			setError(false);
			try {
				// Set our user's password
				await setInitialPassword(password);
				// Log them in
				await signInToOkta(password);
				// Update their onboarding step from NEW to UNVERIFIED
				await updateOnboardingToStepOne();
				// Then go to the next screen
				goToNextStep();
			} catch (error: any) {
				// Handle Axios response errors.
				if (error.response) {
					console.error(error.response);
				}
				setError(true);
			} finally {
				setSubmitting(false);
			}
		},
		[goToNextStep, setInitialPassword, signInToOkta, updateOnboardingToStepOne],
	);

	interface FormValues {
		password: string;
		confirmPassword: string;
	}

	return (
		<Formik
			initialValues={{
				password: "",
				confirmPassword: "",
			}}
			validationSchema={() => setPasswordValidationSchema(t)}
			onSubmit={handleSubmit}
			validateOnMount
		>
			{({ handleSubmit, isSubmitting, isValid }: FormikProps<FormValues>) => (
				<Form onSubmit={handleSubmit} sx={{ height: "100%" }}>
					<StepLayout
						label={t("")}
						heading={t("Set your password")}
						id="account-details"
						actions={
							<>
								<Flex sx={{ alignItems: "center", justifyContent: "flex-start", gap: [2, 3] }}>
									<Button type="submit" variant="pill" disabled={isSubmitting || !isValid}>
										<Text>{t("Next")}</Text>
										<IconLongArrow size={4} noSvgPositioning sx={{ ml: 2 }} />
									</Button>
									{isSubmitting && <IconLoading />}
								</Flex>
								{error && (
									<Box
										sx={{
											mt: [2, 4],
										}}
									>
										<Paragraph sx={{ color: "warning", fontSize: 1 }}>{t("Error! Something went wrong.")}</Paragraph>
										<Link href={"#"} variant={"simple"} sx={{ fontSize: 1 }}>
											{t("Please contact support.")}
										</Link>
									</Box>
								)}
							</>
						}
					>
						<Box>
							<Paragraph
								sx={{
									mt: 1,
									maxWidth: "320px",
									color: "yellow",
								}}
							>
								{t("Your password must contain at least ")}
							</Paragraph>
							<ul
								sx={{
									marginTop: 1,
									marginBottom: 1,
									color: "yellow",
								}}
							>
								<li>{t("12 characters")}</li>
								<li>{t("one uppercase letter")}</li>
								<li>{t("one lowercase letter")}</li>
								<li>{t("one number")}</li>
								<li>{t("and one special character.")}</li>
							</ul>
							<Flex sx={{ mt: 4 }}>
								<Form.Item sx={{ flex: 1, mr: 2 }}>
									<FormFieldLabel name="password" label={t("Password*")}>
										<PasswordInput
											sx={{
												"&::placeholder": {
													opacity: 0.75,
												},
											}}
											name="password"
											disabled={isSubmitting}
											showStrength
											placeholder={t("Password")}
										/>
									</FormFieldLabel>
								</Form.Item>
								<Form.Item sx={{ flex: 1, ml: 2 }}>
									<FormFieldLabel name="confirmPassword" label={t("Confirm password*")}>
										<PasswordInput
											sx={{
												"&::placeholder": {
													opacity: 0.75,
												},
											}}
											name="confirmPassword"
											disabled={isSubmitting}
											placeholder={t("Your password")}
										/>
									</FormFieldLabel>
								</Form.Item>
							</Flex>
						</Box>
					</StepLayout>
				</Form>
			)}
		</Formik>
	);
};
