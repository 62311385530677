import { Box, getColor, Text, useTheme } from "@powerledger/ui-component-lib";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";

import { StatusBoxProps } from "./status-box.types";

/**
 * Renders a colored box of a given size.
 *
 * @param id The id for the tooltip.
 * @param size The size of the box in `rem`. Defaults to `0.625rem` i.e. `10px`.
 * @param color The color of the box in any valid CSS color format.
 * @param allowTooltip Whether to allow displaying tooltip.
 * @param tooltipContent The content to be displayed in the tooltip.
 * @return A `Box` component with the specified dimensions and background color having a tooltip.
 */
export const StatusBox = ({ id, size = 0.625, color, allowTooltip, tooltipContent }: StatusBoxProps) => {
	const { theme } = useTheme();
	const { t } = useTranslation();
	return (
		<Fragment>
			{allowTooltip && tooltipContent && (
				<ReactTooltip
					id={`tooltip-${id}`}
					backgroundColor={getColor(theme, "secondaryDark")}
					getContent={() => (
						<Box sx={{ maxWidth: 300, whiteSpace: "pre-line" }}>
							<Text>{t(tooltipContent)}</Text>
						</Box>
					)}
				/>
			)}
			<Box
				data-tip
				data-for={`tooltip-${id}`}
				sx={{ width: `${size}rem`, height: `${size}rem`, backgroundColor: color }}
			/>
		</Fragment>
	);
};
