import { Theme, ThemeUIStyleObject } from "@powerledger/ui-component-lib";

export const CustomThemeProperty = <T extends string>(
	key: T & (T extends keyof Theme ? "Value is reserved by Theme!" : string),
	value: ThemeUIStyleObject,
) => value;

const layouts = CustomThemeProperty("layouts", {
	layoutHeader: {
		height: 120,
	},
	headers: {
		pt: 3,
		height: 120,
		width: "100%",
		display: "flex",
		bg: "background",
		alignItems: "flex-start",
		justifyContent: "space-between",
	},
});

// Type brought from @powerledger/ui-component-lib `defaultTheme.ts`
export const lemTheme: Theme & { [key: string]: any } = {
	layouts,
};
