import axios from "axios";

export type ApiGetOptionsType = {
	timeout: number;
};

export type ApiType = {
	get: <T>(path: string, options?: ApiGetOptionsType) => T;
	post: (path: string, args?: Record<any, any>) => any;
};

export const api = () => {
	const $axios = axios.create();

	return {
		get: (path: string, options: ApiGetOptionsType): any => $axios.get(path, options).then(({ data }) => data),
		post: (path: string, args: Record<any, any>, options?: any): any =>
			$axios.post(path, args, options).then(({ data }) => data),
		put: (path: string, args: Record<any, any>, options?: any): any =>
			$axios.put(path, args, options).then(({ data }) => data),
	};
};

export default api;
