import { add, format, startOfDay } from "date-fns";

import { ENERGY_DATA_INTERVAL_IN_MINUTES } from "../../pages/forecast-profiles/baseline-forecast-profile/create-edit-meter-profile-modal/create-edit-meter-profile/upload-csv/import-csv/constants";

export const convertEnergyDataToEnergyDataGraphDataSet = (energyData?: number[] | null) => {
	let xAxisTimeStamp = startOfDay(new Date());

	return (
		energyData?.map((data) => {
			const dataPoint = {
				x: format(xAxisTimeStamp, "yyyy-MM-dd HH:mm"),
				y: data,
			};

			xAxisTimeStamp = add(xAxisTimeStamp, { minutes: ENERGY_DATA_INTERVAL_IN_MINUTES });

			return dataPoint;
		}) ?? []
	);
};
