import { commaSeparateNumberToThousandPlaces } from "@/app/util";

import { EnergyQuantity, SettlementType } from "../settlements.types";
import { FormattedTableDataType, LookupTable, mappedType } from "./tables.types";

export const parseSettlements = (settlement: Array<EnergyQuantity>, source: string) => {
	const formattedSingle: Array<FormattedTableDataType> = settlement.map((bill: EnergyQuantity, index: number) => {
		const desc = bill.description.split(";")[0];
		const descArr = bill.description.split(";");
		descArr.splice(0, 1);
		return {
			source: index === 0 ? source : "",
			description: desc,
			timeGroup: descArr.join("\n"),
			units: bill.units,
			amount: bill.amount,
			rate: bill.rate,
		};
	});
	return formattedSingle;
};

/**
 * @param dataSet - Flattened array of all returned settlements
 * @returns - Array of merged data
 *
 * Foramts data from multiple settlements for task: https://pltasks.atlassian.net/browse/LEM-316
 * designed with the following assumptions:
 * - The settlements query will return the raw entries without any initial grouping or parsing of the data
 * - There can be several settlement dates selected, which means there will be duplicate time slots and time ranges
 * - We want to minimise bloat of the table
 * - We want to show distinct entries for different time ranges
 */
export const unflattenSettlementData = (dataSet: Array<FormattedTableDataType>) => {
	const mergedData: Array<FormattedTableDataType> = [];
	const lookupTable: LookupTable = {};
	// Creates lookup table of time groups
	dataSet.forEach((datapoint, index) => {
		if (datapoint.description in lookupTable) {
			lookupTable[datapoint.description] = [...lookupTable[datapoint.description], index];
		} else {
			lookupTable[datapoint.description] = [index];
		}
	});
	// for each entry of the first lookup table we create a second lookup table grouping entries by time ranges
	for (const entry in lookupTable) {
		const indexes = lookupTable[entry];
		const innerLookupTable: LookupTable = {};
		if (indexes.length > 1) {
			for (let i = 0; i < indexes.length; i++) {
				const index = indexes[i];
				const timeGroup = dataSet[index].timeGroup;
				if (timeGroup in innerLookupTable) {
					innerLookupTable[timeGroup] = [...innerLookupTable[timeGroup], index];
				} else {
					innerLookupTable[timeGroup] = [index];
				}
			}
			// going through the second lookup table, we merge entries and average values that need to be
			for (const timeGroup in innerLookupTable) {
				if (innerLookupTable[timeGroup].length > 1) {
					const mergedObj = {
						source: "",
						amount: 0,
						description: "",
						timeGroup: "",
						units: 0,
						rate: 0,
					};
					for (let i = 0; i < innerLookupTable[timeGroup].length; i++) {
						const mergingData = dataSet[innerLookupTable[timeGroup][i]];
						mergedObj.source =
							mergingData.source !== "" ? mergingData.source : mergedObj.source === "" ? "" : mergedObj.source;
						mergedObj.amount = mergedObj.amount + (mergingData.amount > -1 ? mergingData.amount : 0);
						mergedObj.units = mergedObj.units + (mergingData.units > -1 ? mergingData.units : 0);
						mergedObj.rate = mergedObj.rate + (mergingData.rate > -1 ? mergingData.rate : 0);
						mergedObj.description = mergingData.description;
						mergedObj.timeGroup = mergingData.timeGroup;
					}
					mergedObj.rate = mergedObj.rate / innerLookupTable[timeGroup].length;

					mergedData.push(mergedObj);
				} else {
					mergedData.push(dataSet[innerLookupTable[timeGroup][0]]);
				}
			}
		} else {
			mergedData.push(dataSet[lookupTable[entry][0]]);
		}
		// if only one entry exists, we skip any additional looping
	}
	return mergedData;
};

export const formatFinalData = (settlements: SettlementType[], dataType: "Purchased" | "Sold") => {
	const parsedGridGroup: Array<FormattedTableDataType> = [];
	const parsedP2PGroup: Array<FormattedTableDataType> = [];

	settlements
		.map((settlement: SettlementType): mappedType => {
			return {
				grid: parseSettlements(settlement[`gridQuantity${dataType}`], "Grid"),
				p2p: parseSettlements(settlement[`p2pQuantity${dataType}`], "P2P"),
			};
		})
		.forEach((settlement) => {
			settlement.grid.forEach((group) => {
				parsedGridGroup.push(group);
			});
			settlement.p2p.forEach((group) => {
				parsedP2PGroup.push(group);
			});
		});

	const formattedGridGroup =
		parsedGridGroup.length > 0
			? unflattenSettlementData(parsedGridGroup)
			: [
					{
						source: "Grid",
						amount: 0,
						description: "",
						timeGroup: "",
						units: -1,
						rate: -1,
					},
			  ];

	const formattedP2PGroup =
		parsedP2PGroup.length > 0
			? unflattenSettlementData(parsedP2PGroup)
			: [
					{
						source: "P2P",
						amount: 0,
						description: "",
						timeGroup: "",
						units: -1,
						rate: -1,
					},
			  ];

	let subtotalCost = 0;
	[...formattedGridGroup, ...formattedP2PGroup].forEach((item) => {
		subtotalCost = subtotalCost + item.amount;
	});
	const finalRow: FormattedTableDataType = {
		source: "Subtotal",
		amount: subtotalCost,
		description: "",
		timeGroup: "",
		units: -1,
		rate: -1,
		fee: 0,
	};
	return [...formattedGridGroup, ...formattedP2PGroup, finalRow];
};

// specifically for the tables to hide cell values for number values
// handles the special case for value = -1 (as it is in the stubbed data)
export const formatNumberValue = (number: string | number, trailingZeros = 2, commaSeparated = true) => {
	let pNumber = typeof number === "string" ? parseFloat(number) : number;
	pNumber = +pNumber.toFixed(trailingZeros);
	return pNumber === -1
		? ""
		: pNumber.toString() === "NaN"
		? ""
		: commaSeparated
		? commaSeparateNumberToThousandPlaces(pNumber, {
				minimumFractionDigits: trailingZeros,
				maximumFractionDigits: trailingZeros,
		  })
		: pNumber.toString();
};

export const formatCurrency = (number: string | number, trailingZeros = 2, commaSeparated = true) => {
	const pNumber = formatNumberValue(number, trailingZeros, commaSeparated);
	return pNumber === "" ? "" : "$" + pNumber;
};
