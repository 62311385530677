import { Box, Flex, Table, Text, Tooltip } from "@powerledger/ui-component-lib";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Column, UseSortByColumnOptions } from "react-table";

import { FormattedTableDataType, TableType } from "../";
import { formatFinalData, formatNumberValue } from "../common-functions";

export const BoughtTable: FC<TableType> = ({ settlements }) => {
	const { t } = useTranslation();

	const formattedData = useMemo(() => formatFinalData(settlements, "Purchased"), [settlements]);
	const tableColumns: (Column<FormattedTableDataType> & UseSortByColumnOptions<FormattedTableDataType>)[] = [
		{
			Header: t("Source") as string,
			accessor: "source",
			Cell: ({ value, row }) => {
				const lastRow = row.index === formattedData.length - 1;
				return <Box sx={{ color: "white" }}>{t(lastRow ? `${value} ($)` : value || "")}</Box>;
			},
			disableSortBy: true,
		},
		{
			Header: t("Time") as string,
			accessor: "description",
			Cell: ({ value }: { value: string }) => {
				return <Box sx={{ color: "white" }}>{t(value || "")}</Box>;
			},
			disableSortBy: true,
		},
		{
			Header: t("") as string,
			accessor: "timeGroup",
			Cell: ({ value }: { value: string }) => {
				return <Box sx={{ color: "white" }}>{t(value || "")}</Box>;
			},
			disableSortBy: true,
		},
		{
			Header: t("Units\n(kWh)") as string,
			accessor: "units",
			Cell: ({ value }: { value: string }) => {
				return <Box sx={{ color: "white" }}>{t(formatNumberValue(value) || "")}</Box>;
			},
			disableSortBy: true,
		},
		{
			Header: () => (
				<Flex sx={{ alignItems: "center" }}>
					{t("Unit Price\n($)")}
					<Tooltip
						content={<Text>{t("The unit price is averaged over the selected time period")}</Text>}
						id={"sou-bought-table-unit-price"}
					/>
				</Flex>
			),
			accessor: "rate",
			Cell: ({ value }: { value: string }) => {
				return <Box sx={{ color: "white" }}>{t(formatNumberValue(value) || "")}</Box>;
			},
			disableSortBy: true,
		},
		{
			Header: t("Amount\n($)") as string,
			accessor: "amount",
			Cell: ({ value }) => {
				return <Box sx={{ color: "white", textAlign: "right" }}>{t(formatNumberValue(value) || "0")}</Box>;
			},
			disableSortBy: true,
		},
	];
	return (
		<Box>
			<Table
				translation={{
					item: t("item"),
					items: t("items"),
					noDataText: t("No data"),
					pagination: {
						firstPage: t("first page"),
						previousPage: t("previous page"),
						nextPage: t("next page"),
						lastPage: t("last page"),
					},
				}}
				columns={tableColumns}
				dataSource={formattedData}
				pagination={false}
				noHover
			/>
		</Box>
	);
};
