import { Box, Flex, IconArrow, IconButton } from "@powerledger/ui-component-lib";
import { useTranslation } from "react-i18next";

import { PaginationInfo, UsersQuery, UsersQueryVariables } from "../../../types/generated/graphql";

// Update the query with the new results
const updateQuery = (previousResult: UsersQuery, { fetchMoreResult }: { fetchMoreResult: UsersQuery }) => {
	return fetchMoreResult?.users?.edges?.length ? fetchMoreResult : previousResult;
};

type ApolloCursorPaginationProps<TVariables, TData> = {
	paginationInfo: PaginationInfo;
	// TODO: fix fetchmore type..
	// fetchMore: (options: FetchMoreQueryOptions<TVariables, TData>) => void;
	fetchMore: any;
	pageSize?: number;
	loading?: boolean;
};

/**
 * Cursor based pagination
 * Supports backwards & forwards
 *
 * Not to be confused with Relay style pagination..
 */
export const ApolloCursorPagination = ({
	paginationInfo,
	pageSize = 5,
	fetchMore,
	loading,
}: ApolloCursorPaginationProps<UsersQueryVariables, UsersQuery>) => {
	const { t } = useTranslation();

	function handleOnPrevious() {
		fetchMore({
			variables: {
				first: null,
				last: pageSize,
				after: null,
				before: paginationInfo.startCursor ?? null,
			},
			updateQuery,
		});
	}

	function handleOnNext() {
		fetchMore({
			variables: {
				first: pageSize,
				last: null,
				after: paginationInfo.endCursor ?? null,
				before: null,
			},
			updateQuery,
		});
	}

	return (
		<Flex
			sx={{
				my: 3,
				paddingRight: 3,
				alignItems: "center",
			}}
		>
			<Box
				sx={{
					ml: "auto",
				}}
			>
				<IconButton
					aria-label={t("Previous Page")}
					data-testid="previous-page"
					sx={{ variant: "tables.normal.pagination.button" }}
					disabled={!paginationInfo.hasPreviousPage || loading}
					onClick={handleOnPrevious}
				>
					<IconArrow />
				</IconButton>
				<IconButton
					aria-label={t("Next Page")}
					data-testid="next-page"
					sx={{ variant: "tables.normal.pagination.button" }}
					disabled={!paginationInfo.hasNextPage || loading}
					onClick={handleOnNext}
				>
					<IconArrow sx={{ transform: "rotate(180deg)" }} />
				</IconButton>
			</Box>
		</Flex>
	);
};
