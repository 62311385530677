import { Text, View } from "@react-pdf/renderer";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import { structureData } from "../../account-summary/structure-data";
import { SettlementType } from "../../settlements.types";
import { formatCurrency, formatNumberValue } from "../../tables/common-functions";

type AccountSummaryType = {
	bills: SettlementType[];
};
export const PdfAccountSummary: FC<AccountSummaryType> = ({ bills }) => {
	const { t } = useTranslation();
	const structuredData = structureData(bills);
	const { boughtkWh, soldkWh, boughtCost, soldCost, additionalCharges } = structuredData;
	const boughtWidth = (boughtCost / (boughtCost + soldCost + additionalCharges)) * 100 || 0;
	const soldWidth = (soldCost / (boughtCost + soldCost + additionalCharges)) * 100 || 0;
	const chargesWidth = (additionalCharges / (boughtCost + soldCost + additionalCharges)) * 100 || 0;
	return (
		<View style={{ display: "flex", flexDirection: "row" }}>
			<View style={{ display: "flex", width: "15%" }}>
				<Text>{formatCurrency(boughtCost + additionalCharges - soldCost, 2)}</Text>
			</View>
			<View style={{ display: "flex", width: "85%" }}>
				<View style={{ display: "flex", flexDirection: "row", height: "80px" }}>
					<View
						style={{
							backgroundColor: "blue",
							height: "100%",
							width: `${boughtWidth}%`,
							margin: "auto",
						}}
					/>
					<View
						style={{
							backgroundColor: "red",
							height: "100%",
							width: `${soldWidth}%`,
							margin: "auto",
						}}
					/>
					<View
						style={{
							backgroundColor: "blue",
							height: "100%",
							width: `${chargesWidth}%`,
							margin: "auto",
						}}
					/>
				</View>

				<View style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
					<View style={{ margin: "auto", width: `${boughtWidth}%`, textAlign: "left" }}>
						<Text>{t("Bought")}</Text>
						<Text>{formatCurrency(boughtCost, 2)}</Text>
						<Text>{formatNumberValue(boughtkWh, 2) + t("kWh")}</Text>
					</View>
					<View style={{ margin: "auto", width: `${soldWidth}%`, textAlign: "right" }}>
						<Text style={{ textAlign: "right" }}>{t("Sold")}</Text>
						<Text style={{ textAlign: "right" }}>{formatCurrency(soldCost, 2)}</Text>
						<Text style={{ textAlign: "right" }}>{formatNumberValue(soldkWh, 2) + t("kWh")}</Text>
					</View>
					<View style={{ margin: "auto", width: `${chargesWidth}%`, minWidth: "75px", textAlign: "right" }}>
						<Text style={{ textAlign: "right" }}>{t("Additional Charges")}</Text>
						<Text style={{ textAlign: "right" }}>{formatNumberValue(additionalCharges, 2)}</Text>
					</View>
				</View>
			</View>
		</View>
	);
};
