import "react-dates/initialize";

import { CreateEditMeterProfileTypeProps } from "./create-edit-meter-profile.types";
import { CreateEditMeterProfileView } from "./create-edit-meter-profile-view";
import { useCreateEditMeterProfile } from "./use-create-edit-meter-profile";

/**
 * Renders a form for creating a meter profile.
 *
 * @param editMode - Indicates whether the form is in edit mode.
 * @param onSubmit - The function to call when form is submitted.
 * @param onCancel - The function to be called when the form is canceled.
 * @param initialValues - The initial values for the form fields.
 * @return The component markup.
 */
export const CreateEditMeterProfile: React.FC<CreateEditMeterProfileTypeProps> = ({
	editMode,
	onSubmit,
	onCancel,
	initialValues,
}) => {
	const logic = useCreateEditMeterProfile({
		onSubmit,
		profileId: initialValues?.id ?? null,
	});

	return (
		<CreateEditMeterProfileView {...logic} initialValues={initialValues} editMode={editMode} onCancel={onCancel} />
	);
};
