import { DatePicker, DatePickerProps } from "@powerledger/ui-component-lib";
import * as Locales from "date-fns/locale";
import { FC } from "react";
import { useTranslation } from "react-i18next";

export type Languages = keyof typeof Locales;

/**
 * Re-exports the react-datepicker component from the @powerledger/ui-component-lib package with some customizations.
 * @param disabledKeyboardNavigation
 * set to true by default to disable the highlight of unselected date in other months
 * which is same as the selected date in one of the month,
 * if you want to enable highlighting set it to false, refer to stories of `LemDatePicker`
 *
 * @example
 * ```tsx
 * <LemDatePicker disabledKeyboardNavigation={false}/>
 * ```
 * @ref Find more info on `disabledKeyboardNavigation` at https://reactdatepicker.com/#:~:text=This%20is%20disabled-,Disable%20keyboard%20navigation,-()%20%3D%3E
 *
 * @param props accepts all the parameters of the react-datepicker component
 */
export const LemDatePicker: FC<DatePickerProps> = ({ disabledKeyboardNavigation = true, ...props }) => {
	const { i18n } = useTranslation();
	const localeString = i18n?.language?.replace("-", "") as Languages;
	return (
		<DatePicker {...props} locale={Locales[localeString]} disabledKeyboardNavigation={disabledKeyboardNavigation} />
	);
};
