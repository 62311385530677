import { Box, Flex, IconLoading } from "@powerledger/ui-component-lib";

import { LemLogo } from "../logos/lem-logo";

export const LemLoading = () => {
	return (
		<Flex
			sx={{
				alignItems: "center",
				justifyContent: "center",
				height: "100vh",
			}}
		>
			<Box>
				<LemLogo width={150} />
				<IconLoading
					sx={{
						mt: 3,
						justifyContent: "center",
					}}
				/>
			</Box>
		</Flex>
	);
};
