import { ResponsivePie } from "@nivo/pie";
import { Box, Flex, Text, Tooltip } from "@powerledger/ui-component-lib";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import { DonutChartContentType, DonutChartType, ResponsivePieType } from "./donut-chart.types";

const DonutChartContent: FC<DonutChartContentType> = ({ title, tooltipContent, tooltipIconPosition }) => {
	const { t } = useTranslation();
	return (
		<Flex
			sx={{
				justifyContent: "center",
				flexDirection: tooltipIconPosition === "right" ? "row" : "row-reverse",
				gap: 1,
				mr: 1,
			}}
		>
			<Text sx={{ color: "textDarker" }}>{t(title)}</Text>
			{tooltipContent && <Tooltip id={`${title}-tooltip`} content={<Text>{t(tooltipContent)}</Text>} />}
		</Flex>
	);
};

export const DonutChart: FC<DonutChartType> = ({
	title,
	tooltipContent,
	tooltipIconPosition = "right",
	percentageDecimal,
	sx,
	testid,
}) => {
	const { t } = useTranslation();

	const data: ResponsivePieType[] = percentageDecimal
		? [
				{
					id: "percentage",
					label: "percentage",
					value: percentageDecimal,
					color: "",
				},
				{
					id: "remainder",
					label: "remainder",
					value: 1 - percentageDecimal,
					color: "",
				},
		  ]
		: [
				{
					id: "percentage",
					label: "percentage",
					value: 1,
					color: "",
				},
		  ];

	return (
		<Flex
			sx={{
				gap: 2,
				p: 2,
				position: "relative",
				width: "auto",
				...sx,
			}}
			data-testid={testid}
		>
			<Box sx={{ position: "absolute", width: "100%", height: "100%" }}>
				<Flex
					sx={{
						position: "absolute",
						top: "50%",
						left: "50%",
						width: "100%",
						transform: "translate(-50%, -50%)",
						textAlign: "center",
						lineHeight: "normal",
						justifyContent: "center",
						flexDirection: "column",
						zIndex: 1,
					}}
				>
					{percentageDecimal ? (
						<>
							<Text sx={{ fontWeight: "bold", fontSize: 4, mb: 1 }}>{`${(percentageDecimal * 100).toFixed(2)}%`}</Text>
						</>
					) : (
						<>
							<Text sx={{ fontSize: 4, mb: 1, color: percentageDecimal !== 0 && "textDarker" }}>
								{percentageDecimal !== 0 ? t("No data") : "0%"}
							</Text>
						</>
					)}
					<DonutChartContent
						title={t(title)}
						tooltipContent={tooltipContent}
						tooltipIconPosition={tooltipIconPosition}
					/>
				</Flex>
				<ResponsivePie
					data={data}
					innerRadius={0.8}
					enableArcLabels={false}
					enableArcLinkLabels={false}
					isInteractive={false}
					padAngle={4}
					cornerRadius={4}
					colors={percentageDecimal ? ["#21DEAB", "#ffffff"] : ["#ffffff"]}
				/>
			</Box>
		</Flex>
	);
};
