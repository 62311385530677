import { Grid, Text } from "@powerledger/ui-component-lib";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import { commaSeparateNumberToThousandPlaces } from "@/app/util";

import { ContentBox, DonutChart } from "../../../../../components";
import { HighlightType } from "./highlights.types";

export const Highlights: FC<HighlightType> = ({
	p2pSellPrice,
	p2pBuyPrice,
	p2pVol,
	selfSufficiency,
	selfConsumption,
	userIsAdmin,
}) => {
	const { t } = useTranslation();
	const paddingVal = 1;

	return (
		<>
			<Grid columns={5} gap={3} grid-auto-rows={"1fr"}>
				<Text sx={{ textAlign: "center", fontSize: "small" }} mb={1}>
					{t("Average P2P Sell Price")}
				</Text>
				<Text sx={{ textAlign: "center", fontSize: "small" }} mb={1}>
					{t("Average P2P Buy Price")}
				</Text>
				<Text sx={{ textAlign: "center", fontSize: "small" }} mb={1}>
					{t("P2P Traded Volume")}
				</Text>
			</Grid>
			<Grid columns={5} gap={3} grid-auto-rows={"1fr"}>
				<ContentBox
					title={"$/kWh"}
					value={commaSeparateNumberToThousandPlaces(p2pSellPrice, {
						minimumFractionDigits: 3,
						maximumFractionDigits: 3,
					})}
					subtitle={"Sell price"}
					sx={{ padding: paddingVal }}
					testid="highlights--average-p2p-sell-price"
				/>
				<ContentBox
					title={"$/kWh"}
					value={commaSeparateNumberToThousandPlaces(p2pBuyPrice, {
						minimumFractionDigits: 3,
						maximumFractionDigits: 3,
					})}
					subtitle={"Buy price"}
					sx={{ padding: paddingVal }}
					testid="highlights--average-p2p-buy-price"
				/>
				<ContentBox
					title={"kWh"}
					value={commaSeparateNumberToThousandPlaces(p2pVol, {
						minimumFractionDigits: 2,
						maximumFractionDigits: 3,
					})}
					strong
					sx={{ padding: paddingVal }}
					testid="highlights--total"
				/>

				<DonutChart
					percentageDecimal={selfSufficiency}
					title="Self-sufficiency"
					tooltipContent={
						userIsAdmin
							? t(
									"Self-sufficiency refers to the level the trading group can meet its energy needs without relying heavily on external or imported sources of energy. It is the ability to generate, produce, and consume energy from renewable and locally available resources, minimizing dependence on non-renewable fossil fuels or grid energy imports. Advantages of high energy self-sufficiency include increased energy security, reduced vulnerability to price fluctuations in energy markets, and a lower carbon footprint. It promotes environmental sustainability by minimizing greenhouse gas emissions and dependence on finite resources. In a LEM it is a combination of local renewable energy directly consumed within the premise and energy imported from solar PV or battery systems of peers.",
							  )
							: t(
									"Self-sufficiency refers to the level you met your energy needs without relying heavily on external or imported sources of energy. It is your ability to generate, produce, and consume energy from renewable and locally available resources, minimizing dependence on non-renewable fossil fuels or grid energy imports. Advantages of high energy self-sufficiency include increased energy security, reduced vulnerability to price fluctuations in energy markets, and a lower carbon footprint. It promotes environmental sustainability by minimizing greenhouse gas emissions and dependence on finite resources. In your LEM it is a combination of local renewable energy directly consumed within the premise and energy imported from solar PV or battery systems from your peers.",
							  )
					}
					tooltipIconPosition="left"
					sx={{
						height: 220,
					}}
				/>
				<DonutChart
					percentageDecimal={selfConsumption}
					title="Self-consumption"
					tooltipContent={
						userIsAdmin
							? t(
									"Self-consumption refers to the practice of consuming the energy that is generated on-site or within the trading group, rather than exporting it back to the grid. It involves utilizing the energy produced locally for immediate use within the premises where it is generated, such as a residential home, or shared with the peers in the trading group. The concept of energy self-consumption aligns with the idea of maximizing the utilization of locally generated energy and minimizing export to the public grid. It promotes energy efficiency and reduces dependency on fossil fuels, thereby contributing to environmental sustainability and potentially lowering energy costs for the consumer. In a LEM it is a combination of local renewable energy directly consumed within the premise and exported energy from solar PV or battery systems to peers thereby reducing feed-in to the grid.",
							  )
							: t(
									"Self-consumption refers to the practice of consuming the energy that is generated on your site, rather than exporting it back to the grid. It involves utilizing the energy produced locally for immediate use within the premise where it is generated. The concept of energy self-consumption aligns with the idea of maximizing the utilization of locally generated energy and minimizing export to the public grid. It promotes energy efficiency and reduces dependency on fossil fuels, thereby contributing to environmental sustainability and potentially lowering energy costs for the consumer. In your LEM it is a combination of local renewable energy directly consumed within your premise and exported energy from your solar PV or battery systems to peers thereby reducing feed-in to the grid.",
							  )
					}
					tooltipIconPosition="left"
					sx={{
						height: 220,
					}}
				/>
			</Grid>
		</>
	);
};
