/**
 * Not sure why it was done this way, but my guess is importing some of these variables is now made easy
 * No need to import the whole AppConfig just to extract may be a single key
 */
import { AppConfig } from "../services/config";

export const AUTH_ISSUER = AppConfig.AUTH_ISSUER;
export const AUTH_BASE_URL = AppConfig.AUTH_BASE_URL;
export const AUTH_CLIENT_ID = AppConfig.AUTH_CLIENT_ID;
export const FEDERATED_GATEWAY_URL = AppConfig.FEDERATED_GATEWAY_URL;
export const USE_GRAPHQL_DEMO_DATA = AppConfig.USE_GRAPHQL_DEMO_DATA;
export const ENABLE_WDYR = AppConfig.ENABLE_WDYR;
export const USER_GUIDE_URL = AppConfig.USER_GUIDE_URL;
export const PRIVACY_POLICY_URL = AppConfig.PRIVACY_POLICY_URL;
export const TERMS_OF_ACCESS_URL = AppConfig.TERMS_OF_ACCESS_URL;
export const LEM_RULES_URL = AppConfig.LEM_RULES_URL;
export const SENTRY_DSN = AppConfig.SENTRY_DSN;
export const SENTRY_ENVIRONMENT = AppConfig.SENTRY_ENVIRONMENT;
export const SENTRY_RELEASE_ID = AppConfig.SENTRY_ENVIRONMENT;

export const sentryConfig = {
	dsn: AppConfig.SENTRY_DSN,
	env: AppConfig.SENTRY_ENVIRONMENT,
	release: SENTRY_RELEASE_ID,
};
