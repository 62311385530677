import { Button, Flex, Label, Text } from "@powerledger/ui-component-lib";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { AnyObject, mixed, object } from "yup";

import { ErrorMessage, Form } from "@/app/components/form";

import { FILE_SIZE, SUPPORTED_FORMATS } from "./import-csv/constants";
import { ImportCsv } from "./import-csv/import-csv";
import { UploadCsvPropsType } from "./upload-csv.types";
import { useUploadCSV } from "./use-upload-csv";

/**
 * Renders the UploadCsv component.
 *
 * @param fileAlreadyUploaded - Indicates whether a file has already been uploaded.
 * @param onSave - The function to call when the form is submitted.
 * @param onClose - The function to call when the form is closed.
 * @return The rendered UploadCsv component.
 */
export const UploadCsv: React.FC<UploadCsvPropsType> = ({ fileAlreadyUploaded: fileAlreadyExist, onSave, onClose }) => {
	const { t } = useTranslation();
	const { fileInputRef, formikRef, setLocalFilePickerOpened, validateCsvFile, onFormFocus } = useUploadCSV();
	return (
		<Flex
			sx={{
				flexDirection: "column",
				width: "100%",
			}}
		>
			<Text
				sx={{
					fontSize: "23px",
					fontWeight: 700,
				}}
			>
				{t("Step 2: Upload Profile Values")}
			</Text>
			<Formik
				validateOnMount
				initialValues={{
					file: null,
				}}
				validationSchema={object().shape({
					file: mixed()
						.required(t("A CSV file with meter profile data is required"))
						.test(
							"fileSize",
							t(`File size is too large. File should not exceed ${(FILE_SIZE / 1024).toFixed(1)} KB.`),
							(value: File | AnyObject) => (value?.size ? value?.size <= FILE_SIZE : true),
						)
						.test("fileFormat", t("Unsupported file format"), (value: File | AnyObject) =>
							value?.type ? SUPPORTED_FORMATS.includes(value?.type) : true,
						)
						.test("validateFile", async (file: File | AnyObject, { createError }) => {
							if (file) {
								try {
									await validateCsvFile(file as File);
								} catch (error: unknown) {
									if (typeof error === "string") {
										return createError({
											message: error,
											path: "file",
										});
									}
								}
							}
							return true;
						}),
				})}
				onSubmit={onSave}
				innerRef={formikRef}
			>
				{({ handleSubmit, isSubmitting, isValid, dirty }) => {
					return (
						<Form onSubmit={handleSubmit} sx={{ display: "flex", rowGap: 2, pt: 3 }} onFocus={onFormFocus}>
							<Form.Item
								sx={{
									display: "flex",
									flexDirection: "row",
									alignItems: "center",
									width: "100%",
									justifyContent: "center",
									mt: 20,
									...(fileAlreadyExist ? { height: 130 } : { height: 80 }),
								}}
							>
								<Label htmlFor="file" style={{ display: "none", width: "35%", paddingBottom: 21 }}>
									{t("Import Meter Profile CSV File")}
								</Label>
								<Flex sx={{ flexDirection: "column", height: "100%", alignItems: "flex-start", width: "90%" }}>
									{fileAlreadyExist && (
										<Flex
											sx={{
												flexDirection: "column",
												fontSize: 1,
												color: "warningLighter",
												mb: 2,
											}}
										>
											<Text>{t("A CSV with baseline values has been added upon profile creation.")}</Text>
											<Text>{t("Please upload a new file only if you need to update the profile values.")}</Text>
										</Flex>
									)}
									<ImportCsv
										onImport={(event) => {
											event.preventDefault();
											if (fileInputRef) {
												fileInputRef.current?.click();
												setLocalFilePickerOpened(true);
											}
										}}
										ref={fileInputRef}
									/>
									<ErrorMessage name="file" />
								</Flex>
							</Form.Item>

							<Form.Item sx={{ justifySelf: "flex-end", mb: 0 }}>
								<Flex sx={{ flexDirection: "column", alignItems: "center", justifyContent: "center", rowGap: 3 }}>
									<Button
										type="submit"
										disabled={!isValid || isSubmitting || !dirty}
										sx={{
											width: 145,
										}}
									>
										{isSubmitting ? t("Uploading CSV...") : t("Save & Close")}
									</Button>

									<Button
										type="button"
										disabled={isSubmitting}
										onClick={onClose}
										sx={{
											width: 145,
											color: "primary",
											border: "2px solid",
											borderColor: "primary",
											backgroundColor: "transparent",
											":disabled": {
												borderColor: "transparent",
											},
										}}
									>
										{t("Cancel")}
									</Button>
								</Flex>
							</Form.Item>
						</Form>
					);
				}}
			</Formik>
		</Flex>
	);
};
