import * as React from "react";
import { SVGProps } from "react";

export const ProsumerBESS = (props: SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} fill="none" {...props}>
		<path
			fill="#27D9E5"
			d="M15 30C6.73 30 0 23.27 0 15S6.73 0 15 0s15 6.73 15 15-6.73 15-15 15ZM15 .644C7.083.644.644 7.083.644 15S7.084 29.356 15 29.356c7.915 0 14.356-6.44 14.356-14.356C29.356 7.085 22.916.644 15 .644Z"
		/>
		<path
			fill="#27D9E5"
			d="m16.31 15.33.864-4.318h6.911l.864 4.319h-8.64Zm.433-7.99v-.648h1.297v.648h-1.297Zm.366 7.342h3.197v-1.187h-2.96l-.237 1.188Zm.367-1.836h2.828v-1.188h-2.59l-.238 1.188Zm3.477 1.836h3.197l-.238-1.187h-2.96v1.188Zm0-1.836h2.828l-.237-1.188h-2.591v1.188Zm-2.98-3.13-.464-.453.917-.917.464.453-.917.917Zm2.655-1.08a1.81 1.81 0 0 1-1.35-.565 2.062 2.062 0 0 1-.592-1.377h.648c.022.36.155.666.4.917.244.25.543.377.896.377.354 0 .651-.126.898-.377a1.4 1.4 0 0 0 .4-.917h.648c-.03.54-.227 1-.593 1.377a1.81 1.81 0 0 1-1.35.566h-.005Zm-.324 1.944V9.282h.649v1.296h-.649Zm2.98-.875-.917-.917.453-.453.928.908-.464.464v-.002ZM18.89 4.58l-.464.453-.917-.917.464-.453.917.917Zm3.088.729c.367.377.566.837.593 1.376h-.648a1.397 1.397 0 0 0-.4-.917 1.206 1.206 0 0 0-.897-.377c-.353 0-.65.126-.897.377a1.401 1.401 0 0 0-.4.917h-.648a2.07 2.07 0 0 1 .593-1.376 1.809 1.809 0 0 1 1.35-.567c.533 0 .984.19 1.35.567h.004Zm-1.026-2.512v1.297h-.648V2.797h.649Zm2.798 1.34-.928.907-.453-.453.917-.917.464.465v-.003Zm-.528 3.203v-.648h1.296v.648h-1.296Z"
		/>
		<path
			fill="#27D9E5"
			d="M21.5 24.378h-1.873a.633.633 0 0 1-.633-.63v-5.413H13.5v5.413c0 .348-.284.63-.63.63h-1.854a.632.632 0 0 1-.63-.63v-7.864c0-.198.095-.389.254-.507l5.285-3.932a.636.636 0 0 1 .757.003l.688.52-.435.574-.635-.48-5.195 3.866v7.731h1.676v-5.413c0-.349.284-.633.633-.633h5.67c.349 0 .63.284.63.633v5.413h1.7v-7.73l-.882-.667.435-.575.917.693a.63.63 0 0 1 .25.504v7.864c0 .348-.284.63-.63.63h-.002Zm-12.002.009h-4.96L4.52 24.1V15l.29-.017.983-.006v-.649l.288-.018h2.443l.022.282.009.391h1.214l.018.289v9.098l-.289.018Zm-4.438-.54h4.185v-8.325H8.04l-.023-.282-.009-.391H6.33v.646l-.289.018-.983.007v8.328h.002Z"
		/>
		<path fill="#27D9E5" d="M8.828 21.394h-3.35v1.928h3.35v-1.928Zm0-2.474h-3.35v1.928h3.35v-1.927Z" />
	</svg>
);
