// Restrict the file size to be 2 KB
// TODO: Change the file size when feel the need or according to the restriction in the backend (not specified yet)
export const FILE_SIZE = 2 * 1024; // 2 KB
export const SUPPORTED_FORMATS = ["text/csv"];

/**
 * The timestamp from which the data in meter profile CSV file should start
 */
export const START_TIMESTAMP = "0:00";

/**
 * The timestamp at which the data in meter profile CSV file should end
 */
export const END_TIMESTAMP = "23:30";

/**
 * Interval between two timestamps in minutes
 */
export const ENERGY_DATA_INTERVAL_IN_MINUTES = 30; // 30 minutes
