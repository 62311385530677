import "../wdyr";
import "./services/sentry";
import "./lib/i18n";
import "./lib/validators/custom-yup-methods";
import "../styles/fonts.css";

import { StrictMode, Suspense } from "react";
import * as ReactDOM from "react-dom";

import App from "./app";
import { GlobalErrorBoundary } from "./components/error-boundary/error-boundary";
import { LemLoading } from "./components/lem-loading";

ReactDOM.render(
	<StrictMode>
		<GlobalErrorBoundary>
			<Suspense fallback={<LemLoading />}>
				<App />
			</Suspense>
		</GlobalErrorBoundary>
	</StrictMode>,
	document.getElementById("root"),
);
