import { FC } from "react";

import { Layout } from "../../components";
import { SettlementsView } from "./settlements-view";
import { useSettlements } from "./use-settlements";

export const Settlements: FC = () => {
	const logic = useSettlements();

	return (
		<Layout pageTitle="Forecasted Trade">
			<SettlementsView {...logic} />
		</Layout>
	);
};
