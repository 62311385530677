import numeral from "numeral";

export const init = async (locale: string): Promise<void> => {
	const baseLocale = locale.split("-")[0];
	if (numeral.locale() === baseLocale) return;

	try {
		if (!numeral.locales[baseLocale]) {
			await import(`numeral/locales/${baseLocale}`);
		}
		numeral.locale(baseLocale);
	} catch (e) {
		throw new Error(`Failed to load numeral locale info for ${baseLocale}`);
	}
};

export const format = (value: Date) => numeral(value).format("0 a");

export const i18nNumbers = {
	init,
	format,
};

export default i18nNumbers;
