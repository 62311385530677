import { Layout } from "@/app/components";

import { BaselineForecastProfileView } from "./baseline-forecast-profile-view";
import { useBaselineForecastProfile } from "./use-baseline-forecast-profile";

export const BaselineForecastProfile = () => {
	const baselineProfileLogic = useBaselineForecastProfile();

	return (
		<Layout pageTitle="Forecast Profiles">
			<BaselineForecastProfileView {...baselineProfileLogic} />
		</Layout>
	);
};
