import { Button, Flex, Heading } from "@powerledger/ui-component-lib";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { Footer } from "../../../components";

export const ResendEmail = () => {
	const { t } = useTranslation();
	const history = useHistory();

	return (
		<Flex
			sx={{
				flexDirection: "column",
				alignItems: "center",
				justifyContent: ["flex-start", "center"],
				pt: [4, 0],
				height: "100vh",
				mb: [4, 0],
				width: "100%",
				maxWidth: 580,
			}}
		>
			<Flex sx={{ width: "100%", alignItems: "center", mb: 4, flexDirection: "column" }}>
				<Heading as="h1" sx={{ fontWeight: "normal", fontSize: [5, 6], mb: 3 }}>
					{t("Success!")}
				</Heading>
				<Heading as="h2" sx={{ fontWeight: "normal", fontSize: [2], color: "textDarker" }}>
					{t("You will receive a link in your email inbox if an account exists")}
				</Heading>
			</Flex>
			<Button onClick={() => history.push("/")} sx={{ width: "70%" }} variant="LEM.action">
				<span data-testid="backToLoginButton">{t("Back to login")}</span>
			</Button>
			<Footer />
		</Flex>
	);
};

export default ResendEmail;
