import { useApolloClient } from "@apollo/client";
import { useOktaAuth } from "@okta/okta-react";
import { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";

import { useOkta } from "@/app/hooks/use-okta";
import { useUserRoles } from "@/app/hooks/use-user-roles";
import {
	useGetUserMeterQuery,
	UserProfileDocument,
	useUpdateOwnAccountMutation,
	useUserProfileQuery,
} from "@/app/types/generated/graphql";
import { oktaLogout } from "@/app/util";

import { MyProfileDataType } from "./my-profile-tab/my-profile-tab.types";
import { UseSettingsType } from "./settings.types";

/**
 * Generates a custom hook that provides settings-related functionality.
 * This hook uses various other hooks and queries to fetch and update user settings.
 *
 * @returns {object} An object containing the user, meter data, currently active tab related information,
 * functions to logout, update user or update meter data and their loading and error states
 */
export const useSettings: UseSettingsType = () => {
	const history = useHistory();
	const location = useLocation();
	const client = useApolloClient();
	const { oktaAuth } = useOktaAuth();
	const { identityProviderRef } = useOkta();

	const [logoutLoading, setLogoutLoading] = useState(false);
	const [currentTab, setCurrentTab] = useState("/settings");

	const isParticipant = useUserRoles(identityProviderRef).isParticipant;

	const { data: userData, loading: userProfileLoading } = useUserProfileQuery({
		variables: {
			identityProviderRef,
		},
		skip: !identityProviderRef,
	});

	const { data: meterData } = useGetUserMeterQuery({
		variables: {
			userId: userData?.userProfile?.id,
		},
		skip: !userData?.userProfile?.id || userProfileLoading,
	});

	const [updateUser, { error: updateUserError }] = useUpdateOwnAccountMutation();

	const onUpdateUserDetails = useCallback(
		async (values: MyProfileDataType) => {
			try {
				await updateUser({
					variables: {
						userId: userData?.userProfile?.id || "",
						user: {
							firstName: values.firstName,
							lastName: values.lastName,
						},
					},
					refetchQueries: [UserProfileDocument],
				});
			} catch (e) {
				// TODO: Sentry implementation
				console.error(e);
			}
		},
		[updateUser, userData],
	);

	const switchTab = useCallback(
		(tab: string) => {
			history.push({ pathname: tab });
		},
		[history],
	);

	useEffect(() => {
		const tab = location.pathname;
		setCurrentTab(tab || "/settings");
	}, [location]);

	const logout = useCallback(async () => {
		setLogoutLoading(true);
		try {
			oktaLogout({ oktaAuth, apolloClient: client });
		} catch (error: any) {
			if (error?.xhr?.status && error.xhr.status !== 401) console.error(error);
		} finally {
			setLogoutLoading(false);
		}
	}, [client, oktaAuth]);

	return {
		userProfile: userData?.userProfile,
		logout,
		logoutLoading,
		currentTab,
		switchTab,
		isParticipant,
		meterData: meterData?.getUserMeter,
		onUpdateUserDetails,
		updateUserError,
	};
};
