import { FormikHelpers } from "formik";

import { ProfileClass, ProfileType } from "@/app/types/generated/graphql";

export type BaselineProfileCriteriaType = {
	id: string | null;
	name: string;
	profileClass: ProfileClass | null;
	startDate: Date | null;
	endDate: Date | null;
	month: number | null;
	type: ProfileType | null;
	isDefault: boolean;
};

export type BaselineProfileCriteriaProps = {
	initialValues: BaselineProfileCriteriaType;
	editMode: boolean;
	onSave: (
		data: BaselineProfileCriteriaType,
		formikHelpers: FormikHelpers<BaselineProfileCriteriaType>,
	) => Promise<void>;
	onClose: () => void;
	onSkip: () => void;
};

export enum SaveActions {
	SAVE_AND_PROCEED,
	SAVE_AND_CLOSE,
}
