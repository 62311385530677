import { Box, Flex, Heading, LegacySelect } from "@powerledger/ui-component-lib";
import { useTranslation } from "react-i18next";

import { EnergyDataGraph } from "@/app/components/nivo-graphs";
import { ProfileClass } from "@/app/types/generated/graphql";
import { getDefaultSelectTranslation } from "@/app/util";

import { ProfileClassLabel } from "../baseline-forecast-profile/profile-overview-table";
import { MeterProfileVisualizationViewProps } from "./meter-profile-visualization.types";

export const MeterProfileVisualizationView: React.FC<MeterProfileVisualizationViewProps> = ({
	listOfMeterLoading,
	meterOptions,
	selectedMeter,
	forecastedMeterProfileEnergyDataGraphDataSet,
	forecastedMeterProfileEnergyDataLoading,
	setSelectedMeter,
}) => {
	const { t } = useTranslation();
	return (
		<Flex sx={{ flexDirection: "column", gap: 3 }}>
			<Heading as={"h2"}>{t("Forecast Profile Visualization")}</Heading>

			<LegacySelect
				isLoading={listOfMeterLoading}
				options={meterOptions}
				value={selectedMeter}
				hideSelectedOptions={false}
				onChange={setSelectedMeter}
				placeholder={t("Select a meter")}
				translation={getDefaultSelectTranslation()}
				getOptionLabel={(option) => t(option.label)}
				sx={{
					width: 250,
				}}
			/>

			<Box>
				<EnergyDataGraph
					loading={forecastedMeterProfileEnergyDataLoading}
					dataSet={Object.entries(forecastedMeterProfileEnergyDataGraphDataSet ?? {}).map(
						([profileClass, energyDataGraphDataSet]) => {
							return {
								id: ProfileClassLabel[profileClass as ProfileClass],
								data: energyDataGraphDataSet,
							};
						},
					)}
					allowLegendToggle
					labels={{
						xAxis: t("Time (hours)"),
						yAxis: t("kWh"),
					}}
				/>
			</Box>
		</Flex>
	);
};
