import { useUserProfileQuery } from "../types/generated/graphql";
import { useOkta } from "./use-okta";

/**
 * `useUserProfile` hook to retrieve the user profile from the server using the Okta identity provider reference.
 *
 * @return An object containing the user profile, loading status, error, and refetch function.
 */
export const useUserProfile = () => {
	const { identityProviderRef } = useOkta();
	const {
		data: userProfile,
		loading,
		refetch,
		error,
	} = useUserProfileQuery({
		variables: {
			identityProviderRef,
		},
	});

	return {
		error,
		loading,
		refetch,
		identityProviderRef,
		userProfile: userProfile?.userProfile,
	};
};
