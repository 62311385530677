import { useApolloClient } from "@apollo/client";
import { Fragment, ReactNode } from "react";
import { Redirect } from "react-router-dom";

import { useOkta } from "../../hooks/use-okta";
import { UserStatus, useUserProfileQuery } from "../../types/generated/graphql";
import { oktaLogout } from "../../util";
import { LemLoading } from "../lem-loading";

/**
 * @description
 * The Status property can be used to track the onboarding of the user when the user logs in to the LEM platform the very first time. For example:
 *
 * NEW (step 0) - the user is just created and the user has not logged into the platform before
 *
 * UNVERIFIED (step 1) - the user has logged onto the platform and has completed resetting their password but has not verified their details
 *
 * VERIFIED (step 2) - the user has verified their details but has not progressed past the “Welcome” page.
 *
 * ACTIVE (completed onboarding)  - the user has verified their details and has pressed next on the “Welcome” page.
 *
 * INACTIVE - disabled account
 *
 * @returns {Redirect}
 */
const RedirectFromOnboarding = (userStatus: UserStatus) => {
	const redirection = {
		pathname: "/signup",
		state: {
			from: window.location.pathname,
			step: 0,
		},
	};

	if (userStatus === UserStatus.New) {
		redirection.state.step = 0;
	}
	if (userStatus === UserStatus.Unverified) {
		redirection.state.step = 1;
	}
	if (userStatus === UserStatus.Verified) {
		redirection.state.step = 2;
	}

	return <Redirect to={redirection} />;
};

export const OnboardingRedirect = ({ children }: { children: ReactNode }) => {
	const { oktaAuth, authState, identityProviderRef } = useOkta();

	const apolloClient = useApolloClient();

	const isAuthenticated = authState?.isAuthenticated;

	const { data, loading, error, previousData } = useUserProfileQuery({
		variables: {
			identityProviderRef,
		},
		skip: !identityProviderRef && !isAuthenticated,
	});

	if (!previousData && loading) {
		return <LemLoading />;
	}

	if (error) {
		throw new Error(error.message);
	}

	// Prevent redirection loop on expired UID.
	// Todo: Keep close eye on this, may be a better method here.
	if (!isAuthenticated && identityProviderRef) {
		oktaLogout({ oktaAuth, apolloClient });
		return null;
	}

	// If our user isn't "Active", check their onboarding status and redirect them to the correct step
	if (typeof data?.userProfile?.status !== "undefined" && data?.userProfile?.status !== UserStatus.Active) {
		return RedirectFromOnboarding(data?.userProfile?.status);
	}

	// eslint-disable-next-line react/jsx-no-useless-fragment
	return <Fragment>{children}</Fragment>;
};
