import { ApolloError, useQuery } from "@apollo/client";
import { GraphQLError } from "graphql";
import { Dispatch, SetStateAction } from "react";

import {
	PaginationInfo,
	UserRole,
	UsersQuery,
	UsersQueryVariables,
	UserStatus,
} from "../../../types/generated/graphql";

export type UserDataRow = {
	id: string;
	participant: string;
	email: string;
	role: UserRole | "-";
	status?: UserStatus;
};
export type UserTableType = {
	TableBuyersData: UserDataRow[];
};
export type UserRoleList = {
	label: string;
	value: UserRole;
};

export type IndexedGraphQLError = {
	index: number;
	errors: GraphQLError[];
};

export const FullyOnboardedUserStatuses = [UserStatus.Active, UserStatus.Unverified, UserStatus.Verified];

export type UseUserTable = () => {
	interactionDisabled: boolean;
	selectedUserIDs: string[];
	disableResend: boolean;
	showConfirmResendInvite: boolean;
	setShowConfirmResendInvite: Dispatch<SetStateAction<boolean>>;
	participantsTable: UserDataRow[];
	csvExport: () => void;
	loadingProfile: boolean;
	profileError: ApolloError | undefined;
	loadingUsers: boolean;
	usersError: ApolloError | undefined;
	fetchMoreUsers: ReturnType<typeof useQuery<UsersQuery, UsersQueryVariables>>["fetchMore"];
	paginationInfo: PaginationInfo;
	getSelectedRowIDs: (rowIDs: readonly string[]) => void;
	showConfirmInvitationModal: () => void;
	hideConfirmInvitationModal: () => void;
};
