import {
	Box,
	Button,
	Checkbox,
	Flex,
	IconLoading,
	IconLongArrow,
	Label,
	Link,
	Paragraph,
	Text,
} from "@powerledger/ui-component-lib";
import * as Sentry from "@sentry/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useOkta } from "../../../../hooks";
import { TERMS_OF_ACCESS_URL } from "../../../../lib/endpoints";
import {
	UserRole,
	useUpdateOnboardingStepMutation,
	useUpdateOwnAccountMutation,
	useUserProfileQuery,
} from "../../../../types/generated/graphql";
import { StepLayout } from "../step-layout";

/**
 * This is Last stage in the onboarding process for users with the role of
 * "corporate", "operator" or "participant".
 */
export const Welcome = () => {
	const { t } = useTranslation();
	const [checked, setChecked] = useState(false);

	const [updateOnboardingToStepTwo, { error: updateOnboardingError, loading: updateOnboardingLoading }] =
		useUpdateOnboardingStepMutation({
			variables: {
				step: 3,
			},
			/* By re-fetching the query, we trigger the new status for OnboardingRedirect */
			refetchQueries: ["UserProfile"],
		});

	async function handleOnContinue() {
		if (checked) {
			try {
				if (!userProfileData?.userProfile?.id) {
					Sentry.captureMessage("No user id found", "error");
					return;
				}
				await updateOwnAccount({
					variables: {
						userId: userProfileData.userProfile.id,
						user: {
							acceptedTermsAndConditions: checked,
						},
					},
					refetchQueries: ["UserProfile"],
				});
				await updateOnboardingToStepTwo();
			} catch (e: any) {
				Sentry.captureException(new Error(e));
			}
		}
	}

	const { identityProviderRef } = useOkta();

	const {
		data: userProfileData,
		loading: userProfileLoading,
		error: userProfileError,
	} = useUserProfileQuery({
		variables: {
			identityProviderRef,
		},
	});

	const [updateOwnAccount, { error: errorSubmittingTermsAndConditions }] = useUpdateOwnAccountMutation();

	const userIsAdmin = userProfileData?.userProfile?.roles?.some(
		(role) => role === UserRole.AppHostAdmin || role === UserRole.TenantAdmin,
	);
	const tradingGroup = userProfileData?.userProfile?.tradingGroupName;

	return (
		<StepLayout
			heading={
				<>
					{t("Welcome to")}
					<br />
					{t("MODE LEM")}
				</>
			}
			actions={
				<Flex
					sx={{
						flexDirection: "column",
						alignItems: "flex-start",
					}}
				>
					{(updateOnboardingError || userProfileError) && (
						<Paragraph sx={{ color: "negative", mb: 2 }}>{t("Something went wrong")}</Paragraph>
					)}
					<Flex>
						<Button
							onClick={handleOnContinue}
							disabled={updateOnboardingLoading || userProfileLoading || !checked}
							type="button"
							variant="pill"
							sx={{ mr: 2 }}
						>
							{userIsAdmin ? t("Onboard Participants") : t("Continue")}
							<IconLongArrow size={4} noSvgPositioning sx={{ ml: 2 }} />
						</Button>
						{(updateOnboardingLoading || userProfileLoading) && <IconLoading />}
					</Flex>
				</Flex>
			}
		>
			<Flex
				as={"main"}
				sx={{
					flexDirection: `column`,
					rowGap: `1rem`,
					my: 3,
					maxWidth: `600px`,
				}}
			>
				<Paragraph>
					{t("MODE LEM is the marketplace for trading locally generated energy from sustainable sources.")}
				</Paragraph>
				{userIsAdmin && (
					<>
						<Paragraph>
							{t(`As `)}
							{t(
								userProfileData?.userProfile?.roles?.includes(UserRole.AppHostAdmin)
									? "an application host"
									: "a tenant",
							)}
							{t(` admin, get started by onboarding new participants for your LEM. New participants can be
            added at any stage of the project. Steps to follow for the onboarding process are detailed after you
            select 'Continue'.`)}
						</Paragraph>
						<Paragraph>
							{t(`After the onboarding is complete, you will be able to view details about the participants and the
            completed and forecasted trading. Added end users will receive invite links to join the platform and
            view their profile and change account details.`)}
						</Paragraph>
					</>
				)}
				<Paragraph sx={{ borderBottom: "1px solid", borderColor: "text", pb: 4 }}>
					{t("Additional product information is available at ")}
					<Link
						sx={{
							color: "primary",
							transition: "base",
							"&:hover": {
								color: "secondary",
							},
						}}
						href="https://powerledger.io"
						target="_blank"
					>
						{t("powerledger.io")}
					</Link>
				</Paragraph>
				<Box sx={{ mt: 3 }}>
					<Paragraph sx={{ whiteSpace: "pre-line", mb: 3 }}>
						{t(`By using this platform, you agree to the terms of usage agreement${
							userIsAdmin ? "." : ` and any terms set by your trading group: ${tradingGroup}.`
						} 

You must accept these conditions to continue using this platform.
`)}
					</Paragraph>
					<Flex>
						<Label sx={{ width: "auto" }}>
							<Checkbox
								onChange={() => setChecked(!checked)}
								checked={checked}
								sx={{
									color: "primary",
								}}
							/>
						</Label>
						<Paragraph>
							{t("I accept the ")}
							<Link
								sx={{
									color: "primary",
									transition: "base",
									"&:hover": {
										color: "secondary",
									},
								}}
								href={TERMS_OF_ACCESS_URL}
								target="_blank"
							>
								{t("terms and conditions.")}
							</Link>
						</Paragraph>
					</Flex>
					{errorSubmittingTermsAndConditions && (
						<Box sx={{ mb: 4 }}>
							<Text sx={{ color: "warning" }}>{t("There was an issue submitting. Please try again...")}</Text>
						</Box>
					)}
				</Box>
			</Flex>
		</StepLayout>
	);
};
