import { ProfileType } from "@/app/types/generated/graphql";

import { BaselineProfileCriteriaType } from "./baseline-profile-criteria/baseline-profile-criteria.types";
import { UploadCsvType } from "./upload-csv";

export enum BASELINE_PROFILE_CREATE_EDIT_STEPS {
	DEFINE_PROFILE_CRITERIA,
	UPLOAD_CSV,
}

export type TenancyInfoType = {
	applicationHostId: string;
	tenantId: string;
	tradingGroupId: string;
};

export type CreateEditMeterProfileTypeProps = {
	editMode: boolean;
	initialValues?: BaselineProfileCriteriaType;
	onSubmit: () => void;
	onCancel: () => void;
};

export type UseCreateEditMeterProfileType = (props: { onSubmit: () => void; profileId: string | null }) => {
	saveProfile: (data: BaselineProfileCriteriaType) => Promise<void>;
	createEditProfileStep: BASELINE_PROFILE_CREATE_EDIT_STEPS;
	uploadCsvFile: (data: UploadCsvType) => Promise<void>;
	skipToFileUpload: () => void;
};

export type CreateEditMeterProfileViewProps = Omit<CreateEditMeterProfileTypeProps, "onSubmit"> &
	ReturnType<UseCreateEditMeterProfileType>;

export const DATE_RANGE_SELECTOR_PROFILE_TYPES = [ProfileType.Season, ProfileType.Weekday, ProfileType.Weekend];
