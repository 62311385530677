import * as IconComponents from "@powerledger/ui-component-lib/src/assets/icons/components";
import { FC, lazy } from "react";
import { RouteProps } from "react-router-dom";

import { Admin } from "./pages/admin";
import { Bids } from "./pages/bids";
import { Dashboard } from "./pages/dashboard";
import { ForecastProfiles } from "./pages/forecast-profiles";
import { BaselineForecastProfile } from "./pages/forecast-profiles/baseline-forecast-profile";
import { Settings } from "./pages/settings";
import { Settlements } from "./pages/settlements";
import { UserRole } from "./types/generated/graphql";

const Invite = lazy(() => import("./pages/admin").then((module) => ({ default: module.Invite })));

export type NavigationRouteType = {
	/** The title for the URL */
	navTitle: string;
	/** The title for the header */
	headerTitle?: string;
	/** The icon for the sidebar */
	icon: keyof typeof IconComponents | null;
	/** The component to render */
	component?: FC<any>;
	/** The roles that are permitted to access this route */
	permittedRoles: UserRole[];
	/** The path for the route */
	path?: string;
	/** The child routes */
	childRoutes?: NavigationRouteProps[];
};

export type NavigationRouteProps = Omit<RouteProps, "path"> & NavigationRouteType;

export const routes: NavigationRouteProps[] = [
	{
		path: "/dashboard",
		navTitle: "Dashboard",
		headerTitle: "Dashboard",
		icon: "IconDashboard",
		component: Dashboard,
		permittedRoles: [],
		exact: true,
		childRoutes: [
			{
				path: "/dashboard/network",
				navTitle: "Network",
				icon: "IconDashboard",
				component: Dashboard,
				permittedRoles: [UserRole.PlatformAdmin, UserRole.TenantAdmin, UserRole.AppHostAdmin],
			},
		],
	},
	{
		path: "/settlements",
		navTitle: "Forecasted Trade",
		headerTitle: "Forecasted Trade",
		icon: "IconDocument",
		component: Settlements,
		exact: true,
		permittedRoles: [UserRole.PlatformAdmin, UserRole.TenantAdmin, UserRole.AppHostAdmin],
	},
	{
		path: "/bids",
		navTitle: "Bids",
		headerTitle: "Bids",
		icon: "IconDocument",
		component: Bids,
		exact: true,
		permittedRoles: [UserRole.ParticipantUser],
	},
	{
		path: "/settings",
		navTitle: "Settings",
		headerTitle: "Settings",
		icon: "IconCogWheel",
		component: Settings,
		permittedRoles: [],
		exact: true,
		childRoutes: [
			{
				path: "/settings/update-password",
				navTitle: "Update Password",
				icon: "IconCogWheel",
				component: Settings,
				permittedRoles: [],
			},
		],
	},
	{
		path: "/admin",
		navTitle: "Admin",
		headerTitle: "Admin",
		icon: "IconAdminUser",
		exact: true,
		component: Admin,
		permittedRoles: [UserRole.PlatformAdmin, UserRole.TenantAdmin, UserRole.AppHostAdmin],
		childRoutes: [
			{
				path: "/admin/invite",
				navTitle: "Invite User",
				icon: null,
				component: Invite,
				permittedRoles: [UserRole.PlatformAdmin, UserRole.TenantAdmin, UserRole.AppHostAdmin],
			},
		],
	},
	{
		path: "/forecast-profiles",
		navTitle: "Forecast Profiles",
		headerTitle: "Forecast Profiles",
		icon: "IconChartBar",
		component: ForecastProfiles,
		exact: true,
		permittedRoles: [UserRole.PlatformAdmin, UserRole.TenantAdmin, UserRole.AppHostAdmin],
		childRoutes: [
			{
				path: "/forecast-profiles/baseline-forecast-profiles",
				navTitle: "Baseline Forecast Profiles",
				icon: null,
				component: BaselineForecastProfile,
				permittedRoles: [UserRole.PlatformAdmin, UserRole.TenantAdmin, UserRole.AppHostAdmin],
			},
		],
	},
];
