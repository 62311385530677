import * as React from "react";
import { SVGProps } from "react";

export const Prosumer = (props: SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} fill="none" {...props}>
		<path
			fill="#27D9E5"
			d="M15 30C6.73 30 0 23.27 0 15S6.73 0 15 0s15 6.73 15 15-6.73 15-15 15ZM15 .644C7.083.644.644 7.083.644 15S7.084 29.356 15 29.356c7.915 0 14.356-6.44 14.356-14.356C29.356 7.085 22.916.644 15 .644Z"
		/>
		<path
			fill="#27D9E5"
			d="m13.477 16.288.94-4.694h7.508l.94 4.694h-9.388Zm.468-8.684V6.9h1.408v.704h-1.408Zm.4 7.978h3.472v-1.29h-3.214l-.258 1.29Zm.4-1.994h3.075v-1.29h-2.816l-.257 1.29h-.002Zm3.778 1.994h3.473l-.258-1.29h-3.215v1.29Zm0-1.994H21.6l-.258-1.29h-2.815v1.29h-.003Zm-3.239-3.404-.504-.493.997-.997.504.493-.997.997Zm2.886-1.172a1.966 1.966 0 0 1-1.467-.615c-.4-.41-.615-.91-.646-1.497h.704c.024.391.168.724.435.997.266.273.59.411.974.411.385 0 .709-.138.975-.41.267-.274.411-.607.435-.997h.704a2.248 2.248 0 0 1-.646 1.496c-.4.41-.888.615-1.468.615Zm-.353 2.111V9.716h.704v1.407h-.704Zm3.24-.95-.997-.997.493-.493 1.008.986-.504.504ZM16.28 4.605l-.504.493-.997-.997.504-.493.997.997Zm3.355.792c.4.411.615.91.646 1.497h-.704a1.518 1.518 0 0 0-.435-.997 1.31 1.31 0 0 0-.975-.41 1.31 1.31 0 0 0-.974.41 1.518 1.518 0 0 0-.436.997h-.703a2.248 2.248 0 0 1 .646-1.497c.4-.41.888-.615 1.467-.615.58 0 1.068.205 1.468.615ZM18.52 2.67v1.407h-.704V2.67h.704Zm3.04 1.454-1.008.986-.493-.493.997-.997.504.504Zm-.575 3.481V6.9h1.407v.704h-1.407Z"
		/>
		<path
			fill="#27D9E5"
			d="M19.119 26.088h-2.036a.655.655 0 0 1-.655-.655V19.52h-6.033v5.913a.655.655 0 0 1-.655.655H7.726a.655.655 0 0 1-.655-.655v-8.544c0-.206.1-.404.265-.526l5.739-4.272a.66.66 0 0 1 .786.003l.746.564-.435.575-.708-.535-5.675 4.225v8.446h1.885v-5.913c0-.362.293-.655.655-.655h6.16c.363 0 .656.293.656.655v5.913h1.907V16.92l-.97-.733.435-.575.995.752a.66.66 0 0 1 .26.522v8.546a.655.655 0 0 1-.656.655h.003Z"
		/>
	</svg>
);
