import { Page, StyleSheet, Text } from "@react-pdf/renderer";
import { FC } from "react";
import { useTranslation } from "react-i18next";

const styles = StyleSheet.create({
	page: {
		fontSize: 11,
		flexDirection: "column",
		padding: "2%",
	},
});
export const FormattedPage: FC = ({ children }) => {
	const { t } = useTranslation();
	return (
		<Page size="A4" style={styles.page}>
			<>
				<Text style={{ width: "100%", textAlign: "right", fontSize: "16px", paddingBottom: "1%" }}>
					{t("[Invoice Recipient Name & Address]")}
				</Text>
				{children}
			</>
		</Page>
	);
};
