import { MeterProfileView } from "./meter-profile-view";
import { useMeterProfile } from "./use-meter-profile";

/**
 * Component to render the table with each user account linked to some meter number
 * Allows assigning some meter profile to each account by uploading some CSV file or use the default BaselineProfile
 */
export const MeterProfile = () => {
	const logic = useMeterProfile();
	return <MeterProfileView {...logic} />;
};
