import { format } from "date-fns";

import { ProfileClass, ProfileInput, ProfileType } from "@/app/types/generated/graphql";

import { BaselineProfileCriteriaType } from "./baseline-profile-criteria/baseline-profile-criteria.types";
import { TenancyInfoType } from "./create-edit-meter-profile.types";
import { ENERGY_DATA_INTERVAL_IN_MINUTES } from "./upload-csv/import-csv/constants";

/**
 * Returns a label for a given ProfileType that describes the time frame to be selected.
 *
 * @param profileType - the type of profile for which to retrieve the label
 * @return the label for the given ProfileType
 */
export const getTimeFrameLabelByProfileType = (profileType: ProfileType | null) => {
	if (profileType === ProfileType.Month) {
		return "Select Month";
	} else if (profileType === ProfileType.Season) {
		return "Select Season Date Range";
	} else if (profileType === ProfileType.Weekday) {
		return "Select Weekday Date Range";
	} else if (profileType === ProfileType.Weekend) {
		return "Select Weekend Date Range";
	}

	return "Select Day";
};

/**
 * Converts form data to profile input data.
 *
 * @param data - The form data to convert.
 * @return The converted profile input data to integrate with `createUpdateProfileTemplate` mutation.
 */
export const convertFormDataToProfileInputData = (
	data: BaselineProfileCriteriaType & TenancyInfoType,
): ProfileInput => {
	const baseValue: ProfileInput = {
		id: data.id,
		name: data.name,
		profileClass: data.profileClass || ProfileClass.Load,
		type: data.type || ProfileType.Day,
		interval: ENERGY_DATA_INTERVAL_IN_MINUTES,
		applicationHostId: data.applicationHostId,
		tenantId: data.tenantId,
		tradingGroupId: data.tradingGroupId,
		isDefault: data.isDefault,
	};

	if (data.type === ProfileType.Day) {
		return {
			...baseValue,
			day: data.startDate?.toISOString(),
		};
	} else if (data.type === ProfileType.Month) {
		return {
			...baseValue,
			month: data.month,
		};
	}

	return {
		...baseValue,
		seasonStart: data.startDate && format(data.startDate, "--MM-dd"),
		seasonEnd: data.endDate && format(data.endDate, "--MM-dd"),
	};
};
