import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { LoginCallback, SecureRoute, Security } from "@okta/okta-react";
import { FC } from "react";
import { Route, Switch, useHistory, withRouter } from "react-router-dom";

import { OnboardingRedirect } from "./components";
import { useOkta, usePrivateRoutes, useUserRoles } from "./hooks";
import { ForgotPassword, Login, PageNotFound, ResetPassword, SignupPage } from "./pages";
// JIRA-1160 -> To remove FE Demo Stubbed Data
import { DemoToggle } from "./pages/dashboard/demo-stubbed-data";
import { oktaAuthClient } from "./services/okta";

/**
 * Secure Routes that require authentication via Okta.
 * These routes are also role restricted.
 */
const SecureRoutes = () => {
	const { identityProviderRef } = useOkta();
	const userRoles = useUserRoles(identityProviderRef);
	const routes = usePrivateRoutes(userRoles.roles, true);
	return (
		<Switch>
			{routes.map((route) => {
				// In order to avoid TS2604
				// We need to cast the component to a FunctionalComponent
				// Preempting migration to React Router 5.1 -> 6+
				const RouteComponent = route.component as FC;
				return (
					<SecureRoute exact={route.exact} key={route.path} path={route.path}>
						<RouteComponent />
					</SecureRoute>
				);
			})}
			<Route path="*" component={PageNotFound} />
		</Switch>
	);
};

export const AppRouter = () => {
	const history = useHistory();
	const restoreOriginalUri = async (_oktaClient: OktaAuth, originalUri: string | null) => {
		history.push(toRelativeUrl(originalUri || "/dashboard", window.location.origin));
	};

	const redirectToLogin = async () => {
		history.push("/login");
	};

	return (
		<Security oktaAuth={oktaAuthClient} restoreOriginalUri={restoreOriginalUri} onAuthRequired={redirectToLogin}>
			<Switch>
				<Route exact path="/">
					<Login />
				</Route>
				<Route exact path="/login">
					<Login />
				</Route>
				<Route exact path="/login/callback">
					<LoginCallback />
				</Route>
				<Route exact path="/forgot-password">
					<ForgotPassword />
				</Route>
				<Route exact path="/reset-password">
					<ResetPassword />
				</Route>
				<Route exact path="/signup">
					<SignupPage />
				</Route>
				{/* To be removed for FE Demo Stubbed Data */}
				<Route exact path="/demo">
					<DemoToggle />
				</Route>
				<OnboardingRedirect>
					<SecureRoutes />
				</OnboardingRedirect>
				<Route path="*" component={PageNotFound} />
			</Switch>
		</Security>
	);
};

export default withRouter(AppRouter);
