import { EnergyQuantity, SettlementType } from "../../settlements.types";
import { formatNumberValue } from "../common-functions";
import { SourcedEnergyQuantityType } from "../tables.types";

export const formatFeesData = (settlements: SettlementType[]) => {
	const unflattenFeeDuplicates = (dataSet: Array<SourcedEnergyQuantityType>) => {
		const mergedObj = {
			source: "",
			amount: 0,
			description: "",
			units: 0,
			formattedUnits: "",
			rate: 0,
		};
		dataSet.forEach((entry) => {
			mergedObj.source = entry.source !== "" ? entry.source : mergedObj.source === "" ? "" : mergedObj.source;
			mergedObj.units = mergedObj.units + (entry.units > -1 ? entry.units : 0);
			mergedObj.rate = mergedObj.rate + (entry.rate > -1 ? entry.rate : 0);
			mergedObj.description = entry.description;
		});
		mergedObj.rate = mergedObj.rate / dataSet.length;
		mergedObj.amount = mergedObj.units * mergedObj.rate;
		mergedObj.formattedUnits = `${formatNumberValue(mergedObj.units.toString(), 4)}kWh`;
		return mergedObj;
	};
	const dataMap = (data: Array<EnergyQuantity>, desc: string, source = "") =>
		data.map(
			(item): SourcedEnergyQuantityType => ({
				source: source,
				units: item.units,
				description: desc,
				amount: item.amount,
				formattedUnits: item.units,
				rate: item.fee || 0,
			}),
		);
	let totalDays = 0;
	let totalDailyCost = 0;
	const gridPurchasedArr: Array<SourcedEnergyQuantityType[]> = [];
	const p2pPurchasedArr: Array<SourcedEnergyQuantityType[]> = [];
	const gridSoldArr: Array<SourcedEnergyQuantityType[]> = [];
	const p2pSoldArr: Array<SourcedEnergyQuantityType[]> = [];
	const gridPurchasedParsed: Array<SourcedEnergyQuantityType> = [];
	const p2pPurchasedParsed: Array<SourcedEnergyQuantityType> = [];
	const gridSoldParsed: Array<SourcedEnergyQuantityType> = [];
	const p2pSoldParsed: Array<SourcedEnergyQuantityType> = [];
	settlements.forEach((array) => {
		totalDays = totalDays + (array.dailyFees?.timePeriod ? array.dailyFees?.timePeriod : 0);
		totalDailyCost = totalDailyCost + (array.dailyFees?.cost ? array.dailyFees?.cost : 0);
		gridPurchasedArr.push(dataMap(array.gridQuantityPurchased, "Grid Purchased", "Transaction Fees"));
		p2pPurchasedArr.push(dataMap(array.p2pQuantityPurchased, "P2P Purchased"));
		gridSoldArr.push(dataMap(array.gridQuantitySold, "Grid Sold"));
		p2pSoldArr.push(dataMap(array.p2pQuantitySold, "P2P Sold"));
	});
	totalDailyCost = totalDailyCost / settlements.length;
	gridPurchasedArr.map((group) => group.forEach((group) => gridPurchasedParsed.push(group)));
	p2pPurchasedArr.map((group) => group.forEach((group) => p2pPurchasedParsed.push(group)));
	gridSoldArr.map((group) => group.forEach((group) => gridSoldParsed.push(group)));
	p2pSoldArr.map((group) => group.forEach((group) => p2pSoldParsed.push(group)));
	const gridPurchased = unflattenFeeDuplicates(gridPurchasedParsed);
	const p2pPurchased = unflattenFeeDuplicates(p2pPurchasedParsed);
	const gridSold = unflattenFeeDuplicates(gridSoldParsed);
	const p2pSold = unflattenFeeDuplicates(p2pSoldParsed);
	const dailyFees: SourcedEnergyQuantityType = {
		source: "Daily Fees",
		amount: totalDailyCost * totalDays,
		description: "",

		formattedUnits: totalDays + " days",
		units: -1,
		rate: totalDailyCost,
	};
	const subtotalCalc = () => {
		return (
			(gridPurchased?.amount || 0) + (p2pPurchased?.amount || 0) - (gridSold?.amount || 0) - (p2pSold?.amount || 0)
		);
	};
	const subtotal: SourcedEnergyQuantityType = {
		source: "Subtotal",
		amount: subtotalCalc(),
		description: "",
		formattedUnits: "",
		units: -1,
		rate: -1,
	};
	const total: SourcedEnergyQuantityType = {
		source: "Total Owed",
		amount: totalDailyCost * totalDays + subtotalCalc(),
		description: "",
		formattedUnits: "",
		units: -1,
		rate: -1,
	};
	const returnArr = [dailyFees, gridPurchased, p2pPurchased, gridSold, p2pSold, subtotal, total];
	return returnArr.filter((el) => el.description !== "" || el.source !== "");
};
