import { Box, Button, Flex, Heading, IconLoading, Paragraph } from "@powerledger/ui-component-lib";
import { Formik } from "formik";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { object, string } from "yup";

import { ErrorMessage, Footer, Form, InputWithAction } from "../../../components";
import { EmailFormType } from "./email-form.types";

export const ForgotPasswordEmailForm: FC<EmailFormType> = ({ onSuccess, onError }) => {
	const { t } = useTranslation();
	const history = useHistory();
	return (
		<Flex
			sx={{
				flexDirection: "column",
				alignItems: "center",
				justifyContent: ["flex-start", "center"],
				pt: [4, 0],
				height: "100vh",
				mb: [4, 0],
				width: "100%",
				maxWidth: 580,
			}}
		>
			<Flex sx={{ width: "100%", alignItems: "center", mb: 4, flexDirection: "column" }}>
				<Heading as="h1" sx={{ fontWeight: "normal", fontSize: [5, 6], mb: 3 }}>
					{t("Forgot your password?")}
				</Heading>
				<Heading as="h2" sx={{ fontWeight: "normal", fontSize: [2], color: "textDarker" }}>
					{t(`Tell us your email address and we'll get you back on track`)}
				</Heading>
			</Flex>
			<Formik
				initialValues={{ email: "" }}
				validationSchema={object().shape({
					email: string().required(t("Email is required")).email(t("Please provide a valid email address")),
				})}
				onSubmit={async ({ email }) => await onSuccess(email)}
				validateOnMount
			>
				{({ handleSubmit, isSubmitting, setFieldValue, errors, submitCount }) => (
					<Box
						sx={{
							width: "100%",
							height: "270px",
							px: [4, 5],
							mb: 6,
						}}
					>
						<Form onSubmit={handleSubmit} sx={{ width: "100%" }}>
							<Form.Item sx={{ width: "100%" }}>
								<InputWithAction
									noButton
									placeholder={t("Your email address")}
									variant="forms.inputWithAction.LEM"
									disabled={isSubmitting}
									autoCapitalize="none"
									onInput={(e) => {
										setFieldValue("email", (e.target as HTMLInputElement).value);
									}}
								/>
							</Form.Item>
							<Form.Item
								sx={{
									width: "100%",
								}}
							>
								<Button
									disabled={isSubmitting || Object.keys(errors).length > 0}
									variant="LEM.action"
									type={"submit"}
									data-testid="sendResetPasswordButton"
								>
									{isSubmitting ? <IconLoading size="small" /> : t("Reset")}
								</Button>
							</Form.Item>
							{onError && (
								<Box sx={{ textAlign: "center", mb: 3 }} variant={"forms.errorMessage"}>
									<Paragraph>
										{t("Unfortunately we were unable to process this request. Please try again later.")}
									</Paragraph>
								</Box>
							)}
							<Form.Item sx={{ width: "100%" }}>
								<Button
									variant={"text"}
									onClick={() => history.push("/")}
									sx={{
										textDecoration: "underline",
										textDecorationColor: "primary",
										fontSize: 1,
										fontWeight: "medium",
										"&:hover": {
											textDecoration: "underline",
											textDecorationColor: "primary",
											color: "primary",
										},
									}}
								>
									{t("Already have an account? Log in here")}
								</Button>
							</Form.Item>
						</Form>
						{errors.email && submitCount > 0 && (
							<Box
								sx={{
									position: ["static", "static", "relative"],
									top: "-200px",
									left: "480px",
									maxWidth: 350,
								}}
							>
								<Paragraph
									sx={{
										mt: 1,
										maxWidth: "320px",
										color: "warning",
									}}
								>
									{t("Please fix the following issues: ")}
								</Paragraph>
								<Box
									as="ul"
									sx={{
										marginTop: 1,
										marginBottom: 1,
										variant: "forms.errorMessage",
									}}
								>
									{errors.email && (
										<Box as="li" sx={{ mt: 2 }}>
											<ErrorMessage name="email" />
										</Box>
									)}
								</Box>
							</Box>
						)}
					</Box>
				)}
			</Formik>
			<Footer />
		</Flex>
	);
};
