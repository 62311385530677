import { ResponsiveLine } from "@nivo/line";
import { Box, getColor, LoadingOverlay, PL_DEFAULT_THEME as defaultTheme, Text } from "@powerledger/ui-component-lib";
import { format } from "date-fns";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { ProfileClassLabel } from "@/app/pages/forecast-profiles/baseline-forecast-profile/profile-overview-table";
import { ProfileClass } from "@/app/types/generated/graphql";

import { CustomLegend } from "../../custom-legend";
import { generateYAxisRange, textDarkerColour, theme } from "../helpers";
import { StylesObject } from "../nivo-graphs.types";
import { EnergyDataGraphPropType } from "./energy-data-graph.types";

// Colors brought from figma based on the profile class
const styleByProfileClass: StylesObject = {
	[ProfileClassLabel[ProfileClass.Load]]: {
		strokeWidth: 2,
		stroke: "#648FFF",
	},
	[ProfileClassLabel[ProfileClass.Generation]]: {
		strokeWidth: 2,
		stroke: "#FFE14F",
	},
	[ProfileClassLabel[ProfileClass.Bess]]: {
		strokeWidth: 2,
		stroke: "#00B888",
	},
};

export const EnergyDataGraph: React.FC<EnergyDataGraphPropType> = ({
	dataSet,
	loading,
	labels,
	minMaxYRange,
	allowLegendToggle,
	customStylesByProfileClass,
}) => {
	const { t } = useTranslation();

	/**
	 * To maintain all the legends from original dataset
	 * although rendered graph don't contain all the data in original data set
	 */
	const [localDataSet, setLocalDataSet] = useState(dataSet);

	// To sync color for legend with corresponding line graph
	const [localCustomStylesByProfileClass, setLocalCustomStylesByProfileClass] = useState(
		customStylesByProfileClass ?? styleByProfileClass,
	);
	const { range, canYAxisStartFromZero } = generateYAxisRange(dataSet);

	const onChangeHiddenData = useCallback(
		(hiddenDataIds: Array<EnergyDataGraphPropType["dataSet"][0]["id"]>) => {
			const styleBasedOnProfileClass = {
				...(customStylesByProfileClass ?? styleByProfileClass),
			};

			hiddenDataIds.forEach((hiddenDataId) => {
				delete styleBasedOnProfileClass[hiddenDataId];
			});

			setLocalCustomStylesByProfileClass(styleBasedOnProfileClass);
			setLocalDataSet(dataSet.filter((data) => !hiddenDataIds.includes(data.id)));
		},
		[customStylesByProfileClass, dataSet],
	);

	return (
		<Box
			sx={{
				height: 300,
				backgroundColor: "graphBackground",
				overflow: "hidden",
				borderRadius: 4,
				pb: 3,
				position: "relative",
			}}
		>
			<CustomLegend
				data={dataSet.sort((a, b) => (a.id < b.id ? 1 : -1))}
				colors={customStylesByProfileClass ?? styleByProfileClass}
				sx={{
					left: 60,
					position: "relative",
					top: 20,
					height: "20px",
					overflow: "visible",
					width: "calc(100% - 60px)",
					zIndex: 1,
				}}
				allowLegendToggle={allowLegendToggle}
				onChangeHiddenData={onChangeHiddenData}
			/>
			<ResponsiveLine
				animate
				lineWidth={3}
				theme={theme}
				data={localDataSet}
				curve="stepBefore"
				enableArea
				enableGridX={false}
				enablePoints={false}
				colors={Object.values(localCustomStylesByProfileClass).map((style) => style.stroke)}
				layers={["markers", "axes", "areas", "crosshair", "points", "slices", "mesh", "lines"]}
				xFormat="time:%H:%M"
				xScale={{
					type: "time",
					format: "%Y-%m-%d %H:%M",
					useUTC: false,
					precision: "minute",
				}}
				yScale={{
					type: "linear",
					max: minMaxYRange?.max ?? range.at(-1),
					min: canYAxisStartFromZero ? 0 : minMaxYRange?.min ?? range[0],
				}}
				gridYValues={range}
				axisTop={null}
				axisRight={null}
				axisBottom={{
					format: "%H",
					legendOffset: 40,
					legend: labels?.xAxis ?? t("Time"),
					legendPosition: "middle",
					tickValues: "every 1 hour",
				}}
				axisLeft={{
					legendOffset: -40,
					legend: labels?.yAxis ?? t("kWh"),
					legendPosition: "middle",
				}}
				margin={{ top: 50, right: 60, bottom: 60, left: 70 }}
				enableSlices="x"
				sliceTooltip={({ slice }) => {
					const date = new Date(slice.points[0].data.x);
					return (
						<Box
							sx={{
								background: getColor(defaultTheme, "background"),
								padding: "9px 12px",
								border: "1px solid",
								borderColor: getColor(defaultTheme, "secondary"),
							}}
						>
							<Text sx={{ fontWeight: "bold", color: textDarkerColour }}>{`${t("Time")}: ${format(
								date,
								"hh:mm a",
							)}`}</Text>
							{slice.points.map((point) => (
								<Box
									key={point.id}
									sx={{
										color: point.serieColor,
										padding: "3px 0",
									}}
								>
									<Text sx={{ fontWeight: "bold" }}>{t(point.serieId.toString() ?? "Energy Data")}</Text>
									<Text sx={{ fontWeight: "bold" }}>{`: ${point.data.yFormatted}${t("kW")}`}</Text>
								</Box>
							))}
						</Box>
					);
				}}
			/>

			<LoadingOverlay loading={loading} />
		</Box>
	);
};
