import { Box, Flex, Grid, Text } from "@powerledger/ui-component-lib";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import { NetworkMap } from "../../../../components/network-map/network-map";
import {
	isDemoView as checkDemo,
	stubbedOverviewSummaryAdmin,
	stubbedOverviewSummaryParticipant,
} from "../../demo-stubbed-data";
import { ParticipantTradeSummary, TradingGroupTradeSummary } from "./../../../../types/generated/graphql";
import { AverageBillReduction } from "./average-bill-reduction";
import { CompetitiveStats } from "./competitive-stats";
import { Highlights } from "./highlights";
import { TradingGroupSummary } from "./trading-group-summary";
// JIRA-1160 -> To remove FE Demo Stubbed Data

type OverviewType = {
	userIsAdmin?: boolean;
	tradeSummaryData?: TradingGroupTradeSummary | ParticipantTradeSummary | null;
	demoDataSelection?: number;
};

export const Overview: FC<OverviewType> = ({ userIsAdmin = false, tradeSummaryData, demoDataSelection = 4 }) => {
	const { t } = useTranslation();
	const isDemoView = checkDemo();

	return userIsAdmin ? (
		<Grid columns={[1]} gap={2} sx={{ mb: 2 }}>
			<Grid columns={[1, 2, "1.5fr 3.5fr"]} gap={2}>
				<Box sx={{ height: "100%", display: "flex", flexDirection: "column", mr: 2 }}>
					<Text sx={{ fontSize: 3 }} mb={2}>
						{t("Grid View")}
					</Text>
					<NetworkMap />
				</Box>
				<Flex sx={{ flexDirection: "column" }}>
					<TradingGroupSummary
						residentialConsumers={
							isDemoView
								? stubbedOverviewSummaryAdmin[demoDataSelection]?.numberOfRetailConsumers
								: tradeSummaryData?.numRetailConsumers
						}
						residentialProsumers={
							isDemoView
								? stubbedOverviewSummaryAdmin[demoDataSelection]?.numberOfRetailProsumers
								: tradeSummaryData?.numRetailProsumers
						}
						residentialBESSProsumers={
							isDemoView
								? stubbedOverviewSummaryAdmin[demoDataSelection]?.numberOfRetailBessProsumers
								: tradeSummaryData?.numRetailBessProsumers
						}
						cIConsumers={
							isDemoView
								? stubbedOverviewSummaryAdmin[demoDataSelection]?.numberOfCorporateIndustryConsumers
								: tradeSummaryData?.numCorporateIndustryConsumers
						}
						cIProsumers={
							isDemoView
								? stubbedOverviewSummaryAdmin[demoDataSelection]?.numberOfCorporateIndustryProsumers
								: tradeSummaryData?.numCorporateIndustryProsumers
						}
						cIBESSProsumers={
							isDemoView
								? stubbedOverviewSummaryAdmin[demoDataSelection]?.numberOfCorporateIndustryBessProsumers
								: tradeSummaryData?.numCorporateIndustryBessProsumers
						}
						communityBattery={
							isDemoView
								? stubbedOverviewSummaryAdmin[demoDataSelection]?.numberOfCommunityBatteries
								: tradeSummaryData?.numCommunityBatteries
						}
					/>
					<CompetitiveStats
						topBuyers={
							isDemoView ? stubbedOverviewSummaryAdmin[demoDataSelection]?.topBuyers : tradeSummaryData?.topBuyers || []
						}
						topSellers={
							isDemoView
								? stubbedOverviewSummaryAdmin[demoDataSelection]?.topSellers
								: tradeSummaryData?.topSellers || []
						}
					/>
				</Flex>
			</Grid>
			<Box>
				<Text sx={{ fontSize: 3 }}>{t("Highlights")}</Text>
				<Highlights
					p2pSellPrice={
						isDemoView ? stubbedOverviewSummaryAdmin[demoDataSelection]?.p2pSalePrice : tradeSummaryData?.p2pSellPrice
					}
					p2pBuyPrice={
						isDemoView
							? stubbedOverviewSummaryAdmin[demoDataSelection]?.p2pPurchasePrice
							: tradeSummaryData?.p2pBuyPrice
					}
					p2pVol={
						isDemoView ? stubbedOverviewSummaryAdmin[demoDataSelection]?.p2pVolumeSold : tradeSummaryData?.p2pEnergySold
					}
					selfSufficiency={
						isDemoView
							? stubbedOverviewSummaryAdmin[demoDataSelection]?.selfSufficiencyPercentage
							: tradeSummaryData?.selfSufficiencyPercentage
					}
					selfConsumption={
						isDemoView
							? stubbedOverviewSummaryAdmin[demoDataSelection]?.selfConsumptionPercentage
							: tradeSummaryData?.selfConsumptionPercentage
					}
					userIsAdmin={userIsAdmin}
				/>
			</Box>
		</Grid>
	) : (
		<Grid columns={[1]} sx={{ mb: 2, width: "100%" }}>
			<AverageBillReduction
				averageSavingsBAU={
					isDemoView
						? stubbedOverviewSummaryParticipant[demoDataSelection]?.averageSavingsBAU
						: tradeSummaryData?.averageSavings
				}
				averageSavingsLEM={
					isDemoView
						? stubbedOverviewSummaryParticipant[demoDataSelection]?.averageSavingsLEM
						: tradeSummaryData?.totalSavings
				}
				demoDataSelection={demoDataSelection}
			/>
			<Box>
				<Text>{t("Highlights")}</Text>
				<Highlights
					p2pSellPrice={
						isDemoView
							? stubbedOverviewSummaryParticipant[demoDataSelection]?.p2pSalePrice
							: tradeSummaryData?.p2pSellPrice
					}
					p2pBuyPrice={
						isDemoView
							? stubbedOverviewSummaryParticipant[demoDataSelection]?.p2pPurchasePrice
							: tradeSummaryData?.p2pBuyPrice
					}
					p2pVol={
						isDemoView
							? stubbedOverviewSummaryParticipant[demoDataSelection]?.p2pVolumeSold
							: tradeSummaryData?.p2pEnergySold
					}
					selfSufficiency={
						isDemoView
							? stubbedOverviewSummaryParticipant[demoDataSelection]?.selfSufficiencyPercentage
							: tradeSummaryData?.selfSufficiencyPercentage
					}
					selfConsumption={
						isDemoView
							? stubbedOverviewSummaryParticipant[demoDataSelection]?.selfConsumptionPercentage
							: tradeSummaryData?.selfConsumptionPercentage
					}
					userIsAdmin={userIsAdmin}
				/>
			</Box>
		</Grid>
	);
};
