import { ProfileClass, ProfileType } from "@/app/types/generated/graphql";

import { ProfileClassLabel } from "./baseline-forecast-profile/profile-overview-table/profile-overview-table.types";

export const profileClassOptions = Object.values(ProfileClass).map((profileClass) => ({
	label: ProfileClassLabel[profileClass],
	value: profileClass,
}));

export const profileTypeOptions = Object.values(ProfileType).map((profileType) => ({
	label: profileType,
	value: profileType,
}));
