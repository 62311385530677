/** @jsxImportSource theme-ui */
import { Flex, Text, useBreakpointIndex } from "@powerledger/ui-component-lib";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import { Consumer, NetworkMapHorizontal, NetworkMapVertical, Prosumer, ProsumerBESS } from "./svg";

export const NetworkMap: FC = () => {
	const { t } = useTranslation();
	const bpIndex = useBreakpointIndex();
	return (
		<Flex
			sx={{
				bg: "secondaryDarker",
				borderRadius: 3,
				maxHeight: "100%",
				flexDirection: "column",
				alignItems: "center",
				pb: 3,
			}}
		>
			<Flex
				sx={{
					alignItems: "center",
					flexWrap: "wrap",
					bg: "secondaryDark",
					px: 2,
					mb: 2,
					borderTopLeftRadius: 3,
					borderTopRightRadius: 3,
					width: "100%",
				}}
			>
				<Flex sx={{ alignItems: "center", py: 1, fontSize: 1 }}>
					<Consumer sx={{ m: 1 }} />
					<Text px={1}>{t("Consumer")}</Text>
				</Flex>
				<Flex sx={{ alignItems: "center", py: 1, fontSize: 1 }}>
					<Prosumer sx={{ m: 1 }} />
					<Text px={1}>{t("Prosumer")}</Text>
				</Flex>
				<Flex sx={{ alignItems: "center", py: 1, fontSize: 1 }}>
					<ProsumerBESS sx={{ m: 1 }} />
					<Text px={1}>{t("Prosumer with BESS")}</Text>
				</Flex>
			</Flex>
			{bpIndex < 5 ? <NetworkMapVertical sx={{ px: 2, pt: 2 }} /> : <NetworkMapHorizontal sx={{ px: 2 }} />}
		</Flex>
	);
};
