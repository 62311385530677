import { Button, Flex, IconRightArrow, Input } from "@powerledger/ui-component-lib";
import { useFormikContext } from "formik";
import React, { forwardRef } from "react";
import { useTranslation } from "react-i18next";

import { UploadCsvType } from "../upload-csv.types";
import { SUPPORTED_FORMATS } from "./constants";
import { ImportCsvProps } from "./import-csv.types";

/**
 * Renders a form item component to import a csv file
 * * ⚠️ Should be wrapped within a Formik component
 */
export const ImportCsv = forwardRef<HTMLInputElement, ImportCsvProps>(function ImportCsv({ onImport }, fileInputRef) {
	const { t } = useTranslation();
	const { setFieldValue, values, isSubmitting } = useFormikContext<UploadCsvType>();
	return (
		<Flex sx={{ width: "100%", justifyContent: "center" }}>
			<Input
				type="text"
				sx={{ mr: 12 }}
				readOnly
				placeholder={t("Filename...")}
				value={values.file?.name || ""}
				disabled={isSubmitting}
			/>
			<Input
				ref={fileInputRef}
				type="file"
				id="file"
				name="file"
				accept={SUPPORTED_FORMATS.join(",")}
				sx={{ display: "none" }}
				onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
					const file = event.currentTarget.files?.[0];
					if (file) {
						setFieldValue("file", file);
					}
				}}
			/>

			<Button sx={{ minWidth: 147 }} onClick={onImport} disabled={isSubmitting}>
				<Flex sx={{ textAlign: "center" }}>
					{t("Import CSV")}
					<IconRightArrow style={{ marginLeft: 5 }} width={18} height={18} />
				</Flex>
			</Button>
		</Flex>
	);
});
