import { StyleSheet, Text, View } from "@react-pdf/renderer";
import React, { FC } from "react";

import { FormattedTableDataType, SourcedEnergyQuantityType } from "../../tables";
import { formatCurrency, formatNumberValue } from "../../tables/common-functions";

const styles = StyleSheet.create({
	row: {
		flexDirection: "row",
		alignItems: "center",
		borderTop: "1px solid black",
	},
	cellCommon: {
		marginVertical: "3px",
	},
	cell: {
		marginVertical: "3px",
		width: "20%",
	},
	cellSmall: {
		marginVertical: "3px",
		width: "10%",
	},
	cellWide: {
		marginVertical: "3px",
		width: "30%",
	},
});
type RowType = {
	item: FormattedTableDataType | SourcedEnergyQuantityType;
	key: string;
	fees?: boolean;
};
export const TableRow: FC<RowType> = ({ item, key, fees = false }) => {
	return (
		<View style={styles.row} key={key}>
			<Text style={(styles.cellCommon, fees ? styles.cell : styles.cellSmall)}>{item.source}</Text>
			<Text style={(styles.cellCommon, fees ? styles.cellWide : styles.cell)}>{item.description}</Text>
			{"timeGroup" in item && !fees && <Text style={(styles.cellCommon, styles.cellWide)}>{item.timeGroup}</Text>}
			<Text style={(styles.cellCommon, styles.cell)}>
				{fees && "formattedUnits" in item ? item.formattedUnits : formatNumberValue(item.units, 4)}
			</Text>
			<Text style={(styles.cellCommon, styles.cell)}>{formatNumberValue(item.rate, 4)}</Text>
			<Text style={(styles.cellCommon, styles.cell)}>{formatCurrency(item.amount, 4)}</Text>
		</View>
	);
};
