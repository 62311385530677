import * as React from "react";
import { SVGProps } from "react";

export const NetworkMapVertical = (props: SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="-25 0 460 500" fill="none" {...props}>
		<path stroke="#F4D34C" strokeWidth={2} d="M206.545 202.538v19.616" />
		<path stroke="#F4D34C" strokeWidth={2.304} d="m122.758 114.972 63.627 50.219" />
		<path
			stroke="#F4D34C"
			d="M103 49.692v27.783M63.77 66.038l20.404 18.856m-18.225 53.098 19.645-19.646m55.634-49.826-24.061 13.892M50.692 100.91h27.783"
		/>
		<path stroke="#F4D34C" strokeWidth={2} d="M206.545 271.192v19.616" />
		<g fill="#27D9E5" clipPath="url(#a)">
			<path d="M102.734 49C89.648 49 79 38.008 79 24.5S89.648 0 102.734 0c13.087 0 23.735 10.992 23.735 24.5S115.821 49 102.734 49Zm0-47.948c-12.527 0-22.715 10.516-22.715 23.448s10.191 23.448 22.715 23.448c12.525 0 22.716-10.52 22.716-23.448S115.259 1.052 102.734 1.052Z" />
			<path d="m100.324 26.603 1.486-7.666h11.882l1.486 7.666h-14.854Zm.742-14.183v-1.15h2.227v1.15h-2.227Zm.632 13.03h5.495v-2.107h-5.087l-.408 2.107Zm.632-3.256h4.866v-2.107h-4.455l-.407 2.107h-.004Zm5.98 3.256h5.494l-.407-2.107h-5.087v2.107Zm0-3.256h4.865l-.407-2.107h-4.455v2.107h-.003Zm-5.126-5.56-.797-.805 1.577-1.628.797.805-1.577 1.628Zm4.567-1.914c-.917 0-1.69-.334-2.322-1.005-.632-.67-.973-1.487-1.022-2.444h1.113c.039.638.267 1.182.689 1.628a2.04 2.04 0 0 0 1.542.671 2.04 2.04 0 0 0 1.542-.67c.422-.447.65-.99.689-1.63h1.114c-.05.958-.39 1.774-1.023 2.445-.632.671-1.405 1.005-2.322 1.005Zm-.558 3.448V15.87h1.113v2.3h-1.113Zm5.125-1.552-1.577-1.628.78-.805 1.595 1.61-.798.823Zm-7.557-9.095-.797.805-1.577-1.628.797-.805 1.577 1.628Zm5.309 1.295c.632.67.973 1.487 1.022 2.444h-1.114a2.516 2.516 0 0 0-.688-1.628 2.043 2.043 0 0 0-1.542-.671c-.608 0-1.121.225-1.543.67-.421.447-.65.99-.688 1.629h-1.114c.049-.957.39-1.773 1.022-2.444.633-.671 1.406-1.005 2.323-1.005s1.689.334 2.322 1.005Zm-1.764-4.457v2.3h-1.113v-2.3h1.113Zm4.81 2.375-1.595 1.61-.78-.805 1.577-1.628.798.823Zm-.91 5.686v-1.15h2.227v1.15h-2.227Z" />
			<path d="M109.251 42.61h-3.221c-.573 0-1.037-.478-1.037-1.07v-9.657h-9.545v9.658c0 .59-.463 1.07-1.036 1.07h-3.186c-.573 0-1.037-.48-1.037-1.07V27.586c0-.337.158-.66.418-.86l9.082-6.977a1.017 1.017 0 0 1 1.243.004l1.181.921-.689.94-1.121-.875-8.98 6.901v13.795h2.984v-9.657c0-.59.463-1.07 1.036-1.07h9.749c.572 0 1.036.48 1.036 1.07v9.657h3.018V27.637l-1.535-1.197.688-.94 1.574 1.23c.256.2.411.519.411.852v13.959c0 .59-.464 1.07-1.036 1.07h.003Z" />
			<path d="m100.324 26.603 1.486-7.666h11.882l1.486 7.666h-14.854Zm.742-14.183v-1.15h2.227v1.15h-2.227Zm.632 13.03h5.495v-2.107h-5.087l-.408 2.107Zm.632-3.256h4.866v-2.107h-4.455l-.407 2.107h-.004Zm5.98 3.256h5.494l-.407-2.107h-5.087v2.107Zm0-3.256h4.865l-.407-2.107h-4.455v2.107h-.003Zm-5.126-5.56-.797-.805 1.577-1.628.797.805-1.577 1.628Zm4.567-1.914c-.917 0-1.69-.334-2.322-1.005-.632-.67-.973-1.487-1.022-2.444h1.113c.039.638.267 1.182.689 1.628a2.04 2.04 0 0 0 1.542.671 2.04 2.04 0 0 0 1.542-.67c.422-.447.65-.99.689-1.63h1.114c-.05.958-.39 1.774-1.023 2.445-.632.671-1.405 1.005-2.322 1.005Zm-.558 3.448V15.87h1.113v2.3h-1.113Zm5.125-1.552-1.577-1.628.78-.805 1.595 1.61-.798.823Zm-7.557-9.095-.797.805-1.577-1.628.797-.805 1.577 1.628Zm5.309 1.295c.632.67.973 1.487 1.022 2.444h-1.114a2.516 2.516 0 0 0-.688-1.628 2.043 2.043 0 0 0-1.542-.671c-.608 0-1.121.225-1.543.67-.421.447-.65.99-.688 1.629h-1.114c.049-.957.39-1.773 1.022-2.444.633-.671 1.406-1.005 2.323-1.005s1.689.334 2.322 1.005Zm-1.764-4.457v2.3h-1.113v-2.3h1.113Zm4.81 2.375-1.595 1.61-.78-.805 1.577-1.628.798.823Zm-.91 5.686v-1.15h2.227v1.15h-2.227Z" />
			<path d="M109.251 42.61h-3.221c-.573 0-1.037-.478-1.037-1.07v-9.657h-9.545v9.658c0 .59-.463 1.07-1.036 1.07h-3.186c-.573 0-1.037-.48-1.037-1.07V27.586c0-.337.158-.66.418-.86l9.082-6.977a1.017 1.017 0 0 1 1.243.004l1.181.921-.689.94-1.121-.875-8.98 6.901v13.795h2.984v-9.657c0-.59.463-1.07 1.036-1.07h9.749c.572 0 1.036.48 1.036 1.07v9.657h3.018V27.637l-1.535-1.197.688-.94 1.574 1.23c.256.2.411.519.411.852v13.959c0 .59-.464 1.07-1.036 1.07h.003Z" />
		</g>
		<g fill="#27D9E5" clipPath="url(#b)">
			<path d="M206.545 271.192c-13.519 0-24.519-11-24.519-24.519 0-13.519 11-24.519 24.519-24.519 13.519 0 24.519 11 24.519 24.519 0 13.519-11 24.519-24.519 24.519Zm0-47.986c-12.942 0-23.467 10.525-23.467 23.467 0 12.942 10.529 23.467 23.467 23.467 12.938 0 23.467-10.529 23.467-23.467 0-12.938-10.529-23.467-23.467-23.467Z" />
			<path d="M226.706 245.356c-.349 0-15.04-.142-20.709-14.692l1.096-.428c5.415 13.9 19.478 13.955 19.609 13.94l.011 1.176h-.004l-.003.004Z" />
			<path d="M186.384 245.356h-.003l.011-1.176c.276 0 14.223-.131 19.605-13.94l1.096.428c-5.669 14.546-20.356 14.688-20.709 14.688Z" />
			<path d="M209.967 229.764h-6.866v1.176h6.866v-1.176Zm0-1.716h-6.866v1.176h6.866v-1.176Z" />
			<path d="M207.133 230.12h-1.176v35.93h1.176v-35.93Z" />
		</g>
		<g fill="#27D9E5" clipPath="url(#c)">
			<path d="M102.455 125.974c-13.519 0-24.52-11-24.52-24.519 0-13.519 11.001-24.52 24.52-24.52s24.519 11.001 24.519 24.52-11 24.519-24.519 24.519Zm0-47.986c-12.942 0-23.467 10.525-23.467 23.467 0 12.942 10.529 23.467 23.467 23.467 12.938 0 23.467-10.529 23.467-23.467 0-12.938-10.529-23.467-23.467-23.467Z" />
			<path d="M122.616 100.138c-.349 0-15.04-.142-20.709-14.692l1.096-.428c5.415 13.9 19.478 13.955 19.609 13.94l.011 1.176h-.004l-.003.004Z" />
			<path d="M82.295 100.138h-.004l.01-1.176c.277 0 14.224-.13 19.606-13.94l1.096.428c-5.669 14.546-20.356 14.688-20.708 14.688Z" />
			<path d="M105.878 84.546h-6.867v1.176h6.867v-1.176Zm0-1.716h-6.867v1.176h6.867V82.83Z" />
			<path d="M103.043 84.902h-1.176v35.93h1.176v-35.93Z" />
		</g>
		<path
			fill="#27D9E5"
			d="M162.557 82c-13.944 0-25.29-10.992-25.29-24.5S148.613 33 162.557 33c13.944 0 25.29 10.992 25.29 24.5S176.501 82 162.557 82Zm0-47.948c-13.349 0-24.205 10.516-24.205 23.448s10.86 23.448 24.205 23.448c13.345 0 24.205-10.52 24.205-23.448s-10.86-23.448-24.205-23.448Z"
		/>
		<path
			fill="#27D9E5"
			d="m164.766 58.04 1.456-7.053h11.653l1.456 7.053h-14.565Zm.73-13.051V43.93h2.186v1.059h-2.186Zm.617 11.992h5.391v-1.94h-4.99l-.401 1.94Zm.618-2.999h4.769v-1.94h-4.369l-.4 1.94Zm5.862 3h5.391l-.401-1.94h-4.99v1.94Zm0-3h4.769l-.4-1.94h-4.369v1.94Zm-5.024-5.113-.782-.74 1.546-1.497.782.74-1.546 1.497Zm4.478-1.762c-.899 0-1.659-.309-2.276-.925a3.316 3.316 0 0 1-1-2.248h1.093a2.25 2.25 0 0 0 .674 1.497c.412.41.917.617 1.512.617s1.097-.207 1.513-.617c.411-.41.636-.91.673-1.497h1.093a3.329 3.329 0 0 1-.999 2.248c-.618.616-1.378.925-2.276.925h-.007Zm-.547 3.173v-2.118h1.093v2.118H171.5Zm5.024-1.429-1.546-1.498.763-.74 1.565 1.484-.782.758v-.004Zm-7.409-8.37-.782.74-1.546-1.498.782-.74 1.546 1.498Zm5.207 1.19a3.316 3.316 0 0 1 1 2.248h-1.093a2.25 2.25 0 0 0-.674-1.498 2.065 2.065 0 0 0-1.512-.616c-.595 0-1.097.207-1.513.616-.411.41-.636.91-.673 1.498h-1.093a3.329 3.329 0 0 1 .999-2.248c.618-.617 1.378-.925 2.276-.925.898 0 1.658.308 2.276.925h.007Zm-1.729-4.102v2.118H171.5V37.57h1.093Zm4.717 2.187-1.565 1.483-.764-.74 1.546-1.497.783.758v-.004Zm-.891 5.233V43.93h2.186v1.059h-2.186Z"
		/>
		<path
			fill="#27D9E5"
			d="M173.518 72.818h-3.16c-.588 0-1.067-.464-1.067-1.03v-8.841h-9.265v8.841c0 .57-.479 1.03-1.063 1.03h-3.125c-.588 0-1.064-.464-1.064-1.03V58.943c0-.322.161-.634.431-.826l8.909-6.423a1.101 1.101 0 0 1 1.277.004l1.16.848-.734.94-1.07-.784-8.76 6.314v12.627h2.827v-8.841c0-.57.479-1.034 1.066-1.034h9.561c.588 0 1.063.465 1.063 1.034v8.841h2.864V59.016l-1.486-1.088.733-.94 1.546 1.132c.266.192.423.5.423.823v12.845c0 .57-.479 1.03-1.063 1.03h-.003Zm-20.237.014h-8.363l-.03-.471V57.5l.487-.029 1.658-.01V56.4l.487-.029h4.118l.037.46.015.639h2.048l.029.471v14.861l-.486.03Zm-7.483-.88h7.056v-13.6h-2.033l-.037-.46-.015-.639h-2.83v1.056l-.487.029-1.658.01v13.603h.004Z"
		/>
		<path fill="#27D9E5" d="M152.15 67.944h-5.649v3.148h5.649v-3.148Zm0-4.04h-5.649v3.148h5.649v-3.148Z" />
		<g fill="#27D9E5" clipPath="url(#d)">
			<path d="M50.5 71C36.992 71 26 60.008 26 46.5S36.992 22 50.5 22 75 32.992 75 46.5 64.008 71 50.5 71Zm0-47.948c-12.932 0-23.448 10.516-23.448 23.448S37.572 69.948 50.5 69.948 73.948 59.428 73.948 46.5 63.428 23.052 50.5 23.052Z" />
			<path d="M62.608 61.34h-4.33c-.67 0-1.214-.545-1.214-1.216V47.417l-13.139-.04-.04 12.747c0 .671-.544 1.215-1.215 1.215h-4.279c-.67 0-1.215-.544-1.215-1.215V41.96c0-.381.185-.747.49-.976L49.87 31.9a1.22 1.22 0 0 1 1.458.007l12.014 9.08c.301.23.482.592.482.97V60.12c0 .67-.544 1.215-1.215 1.215v.003ZM43.925 46.202h13.099c.67 0 1.215.544 1.215 1.214v12.707l4.37.04.04-18.204-2.133-1.632-9.896-7.481-12.254 9.084-.018 18.193 4.319.04.04-12.747c0-.67.544-1.214 1.215-1.214h.003Z" />
		</g>
		<path
			fill="#27D9E5"
			d="M53.767 184c-13.509 0-24.5-10.992-24.5-24.5s10.991-24.5 24.5-24.5c13.508 0 24.5 10.992 24.5 24.5S67.275 184 53.767 184Zm0-47.948c-12.932 0-23.449 10.516-23.449 23.448s10.52 23.448 23.449 23.448c12.928 0 23.448-10.52 23.448-23.448s-10.52-23.448-23.448-23.448Z"
		/>
		<path
			fill="#27D9E5"
			d="M65.875 174.339h-4.33c-.67 0-1.215-.544-1.215-1.215v-12.707l-13.138-.039-.04 12.746c0 .671-.544 1.215-1.215 1.215h-4.279c-.67 0-1.215-.544-1.215-1.215V154.96c0-.381.185-.747.49-.976l12.203-9.084a1.22 1.22 0 0 1 1.457.007l12.015 9.081c.3.228.482.591.482.968v18.165c0 .671-.544 1.215-1.215 1.215v.003Zm-18.683-15.136H60.29c.671 0 1.215.544 1.215 1.214v12.707l4.37.04.04-18.204-2.132-1.632-9.897-7.481-12.253 9.084-.019 18.193 4.32.04.04-12.747c0-.67.543-1.214 1.214-1.214h.004Z"
		/>
		<g fill="#27D9E5" clipPath="url(#e)">
			<path d="M25.5 126C11.992 126 1 115.008 1 101.5S11.992 77 25.5 77 50 87.992 50 101.5 39.008 126 25.5 126Zm0-47.948c-12.932 0-23.448 10.516-23.448 23.448s10.52 23.448 23.448 23.448 23.448-10.52 23.448-23.448S38.428 78.052 25.5 78.052Z" />
			<path d="M37.608 116.339h-4.33c-.67 0-1.214-.544-1.214-1.215v-12.707l-13.139-.039-.04 12.746c0 .671-.544 1.215-1.215 1.215h-4.279c-.67 0-1.214-.544-1.214-1.215V96.96c0-.381.184-.747.49-.976L24.868 86.9a1.22 1.22 0 0 1 1.458.007l12.014 9.08c.301.23.482.592.482.97v18.164c0 .671-.544 1.215-1.215 1.215v.003Zm-18.683-15.136h13.099c.67 0 1.215.544 1.215 1.214v12.707l4.37.04.04-18.204-2.133-1.632-9.896-7.481-12.254 9.084-.018 18.193 4.319.04.04-12.747c0-.67.544-1.214 1.215-1.214h.003Z" />
		</g>
		<path stroke="#F4D34C" strokeWidth={2.304} d="m121.758 377.028 63.627-50.219" />
		<path
			stroke="#F4D34C"
			d="M102 442.308v-27.783m-39.23 11.437 20.404-18.857m-18.225-53.097 19.645 19.646m55.634 49.825-24.061-13.891M49.692 391.09h27.783"
		/>
		<g fill="#27D9E5" clipPath="url(#f)">
			<path d="M101.734 443C88.648 443 78 453.992 78 467.5S88.648 492 101.734 492c13.087 0 23.735-10.992 23.735-24.5S114.821 443 101.734 443Zm0 47.948c-12.527 0-22.715-10.516-22.715-23.448s10.191-23.448 22.715-23.448c12.525 0 22.716 10.52 22.716 23.448s-10.191 23.448-22.716 23.448Z" />
			<path d="M114.609 482.339h-4.33a1.215 1.215 0 0 1-1.215-1.215v-12.707l-13.139-.039-.04 12.746c0 .671-.544 1.215-1.215 1.215h-4.279c-.67 0-1.215-.544-1.215-1.215V462.96c0-.381.186-.747.49-.976l12.203-9.084a1.22 1.22 0 0 1 1.458.007l12.014 9.081c.301.228.482.591.482.968v18.165c0 .671-.544 1.215-1.214 1.215v.003Zm-18.684-15.136h13.099c.671 0 1.215.544 1.215 1.214v12.707l4.37.04.039-18.204-2.132-1.632-9.896-7.481-12.254 9.084-.018 18.193 4.319.04.04-12.747c0-.67.544-1.214 1.215-1.214h.003Z" />
		</g>
		<g fill="#27D9E5" clipPath="url(#g)">
			<path d="M101.455 415.064c13.519 0 24.519-11 24.519-24.519 0-13.519-11-24.519-24.519-24.519-13.52 0-24.52 11-24.52 24.519 0 13.519 11 24.519 24.52 24.519Zm0-47.986c12.942 0 23.466 10.525 23.466 23.467 0 12.942-10.528 23.467-23.466 23.467-12.939 0-23.467-10.529-23.467-23.467 0-12.938 10.528-23.467 23.467-23.467Z" />
			<path d="M81.294 389.227c.348 0 15.04-.141 20.708-14.691l-1.096-.428c-5.414 13.9-19.478 13.954-19.608 13.94l-.011 1.176h.003l.004.003Z" />
			<path d="M121.615 389.227h.004l-.011-1.175c-.276 0-14.223-.131-19.605-13.94l-1.096.428c5.668 14.546 20.356 14.687 20.708 14.687Z" />
			<path d="M98.032 373.636h6.867v1.176h-6.867v-1.176Zm0-1.716h6.867v1.175h-6.867v-1.175Z" />
			<path d="M100.867 373.992h1.175v35.929h-1.175v-35.929Z" />
		</g>
		<path
			fill="#27D9E5"
			d="M161.557 410c-13.944 0-25.29 10.992-25.29 24.5s11.346 24.5 25.29 24.5c13.944 0 25.29-10.992 25.29-24.5s-11.346-24.5-25.29-24.5Zm0 47.948c-13.349 0-24.205-10.516-24.205-23.448s10.86-23.448 24.205-23.448c13.345 0 24.205 10.52 24.205 23.448s-10.86 23.448-24.205 23.448Z"
		/>
		<path
			fill="#27D9E5"
			d="M174.432 449.678h-4.33a1.215 1.215 0 0 1-1.215-1.215v-12.707l-13.138-.04-.04 12.747c0 .671-.544 1.215-1.215 1.215h-4.279a1.215 1.215 0 0 1-1.215-1.215v-18.165c0-.381.185-.747.49-.975l12.202-9.085a1.218 1.218 0 0 1 1.458.008l12.015 9.08c.301.229.482.591.482.968v18.165c0 .671-.544 1.215-1.215 1.215v.004Zm-18.683-15.137h13.098c.671 0 1.215.544 1.215 1.215v12.707l4.37.04.04-18.205-2.132-1.632-9.897-7.481-12.254 9.084-.018 18.194 4.319.04.04-12.747c0-.671.544-1.215 1.215-1.215h.004Z"
		/>
		<g fill="#27D9E5" clipPath="url(#h)">
			<path d="M49.5 421C35.992 421 25 431.992 25 445.5S35.992 470 49.5 470 74 459.008 74 445.5 63.008 421 49.5 421Zm0 47.948c-12.932 0-23.448-10.516-23.448-23.448s10.52-23.448 23.448-23.448 23.448 10.52 23.448 23.448-10.52 23.448-23.448 23.448Z" />
			<path d="m46.325 447.603 1.485-7.666h11.882l1.486 7.666H46.325Zm.74-14.183v-1.149h2.228v1.149h-2.227Zm.633 13.03h5.495v-2.107h-5.087l-.408 2.107Zm.632-3.256h4.866v-2.107h-4.454l-.408 2.107h-.004Zm5.98 3.256h5.494l-.407-2.107H54.31v2.107Zm0-3.256h4.865l-.407-2.107h-4.455v2.107h-.003Zm-5.126-5.56-.797-.805 1.577-1.628.797.805-1.577 1.628Zm4.567-1.914c-.917 0-1.69-.334-2.322-1.005-.632-.671-.973-1.487-1.022-2.444h1.113c.039.638.267 1.182.689 1.628.421.446.934.671 1.542.671s1.12-.225 1.542-.671c.422-.446.65-.99.689-1.628h1.114c-.05.957-.39 1.773-1.023 2.444-.632.671-1.405 1.005-2.322 1.005Zm-.558 3.448v-2.299h1.113v2.299h-1.113Zm5.125-1.552-1.577-1.628.78-.805 1.595 1.61-.798.823Zm-7.556-9.095-.798.805-1.577-1.628.797-.805 1.578 1.628Zm5.308 1.295c.632.671.973 1.487 1.022 2.444h-1.114a2.515 2.515 0 0 0-.688-1.628 2.042 2.042 0 0 0-1.542-.671c-.608 0-1.121.225-1.543.671-.421.446-.65.99-.688 1.628h-1.114c.05-.957.39-1.773 1.023-2.444.632-.671 1.405-1.005 2.322-1.005.916 0 1.69.334 2.322 1.005Zm-1.764-4.457v2.299h-1.113v-2.299h1.113Zm4.81 2.375-1.595 1.61-.78-.805 1.577-1.628.798.823Zm-.91 5.686v-1.149h2.227v1.149h-2.227Z" />
			<path d="M55.251 463.61H52.03c-.573 0-1.037-.478-1.037-1.07v-9.657h-9.545v9.657c0 .592-.463 1.07-1.036 1.07h-3.186c-.573 0-1.037-.478-1.037-1.07v-13.954c0-.337.158-.66.418-.859l9.082-6.978a1.018 1.018 0 0 1 1.243.004l1.18.921-.688.939-1.12-.874-8.98 6.901v13.795h2.983v-9.657c0-.591.463-1.07 1.036-1.07h9.749c.572 0 1.036.479 1.036 1.07v9.657h3.018v-13.798l-1.535-1.197.688-.939 1.574 1.229c.257.2.411.519.411.852v13.958c0 .592-.464 1.07-1.036 1.07h.003Z" />
		</g>
		<path
			fill="#27D9E5"
			d="M52.767 308c-13.509 0-24.5 10.992-24.5 24.5s10.991 24.5 24.5 24.5c13.508 0 24.5-10.992 24.5-24.5S66.275 308 52.767 308Zm0 47.948c-12.932 0-23.449-10.516-23.449-23.448s10.52-23.448 23.449-23.448c12.928 0 23.448 10.52 23.448 23.448s-10.52 23.448-23.448 23.448Z"
		/>
		<path
			fill="#27D9E5"
			d="M64.609 347.339h-4.33a1.215 1.215 0 0 1-1.215-1.215v-12.707l-13.139-.039-.04 12.746c0 .671-.544 1.215-1.215 1.215h-4.279c-.67 0-1.215-.544-1.215-1.215V327.96c0-.381.185-.747.49-.976L51.87 317.9a1.22 1.22 0 0 1 1.458.007l12.014 9.081c.301.228.482.591.482.968v18.165c0 .671-.544 1.215-1.214 1.215v.003Zm-18.684-15.136h13.099c.67 0 1.215.544 1.215 1.214v12.707l4.37.04.04-18.204-2.133-1.632-9.896-7.481-12.254 9.084-.018 18.193 4.319.04.04-12.747c0-.67.544-1.214 1.215-1.214h.003Z"
		/>
		<g fill="#27D9E5" clipPath="url(#i)">
			<path d="M24.5 366C10.992 366 0 376.992 0 390.5S10.992 415 24.5 415 49 404.008 49 390.5 38.008 366 24.5 366Zm0 47.948c-12.932 0-23.448-10.516-23.448-23.448s10.52-23.448 23.448-23.448 23.448 10.52 23.448 23.448-10.52 23.448-23.448 23.448Z" />
			<path d="M36.608 405.339h-4.33c-.67 0-1.214-.544-1.214-1.215v-12.707l-13.139-.039-.04 12.746c0 .671-.544 1.215-1.215 1.215h-4.279c-.67 0-1.214-.544-1.214-1.215V385.96c0-.381.184-.747.49-.976l12.202-9.084a1.22 1.22 0 0 1 1.458.007l12.014 9.081c.301.228.482.591.482.968v18.165c0 .671-.544 1.215-1.215 1.215v.003Zm-18.683-15.136h13.099c.67 0 1.215.544 1.215 1.214v12.707l4.37.04.04-18.204-2.133-1.632-9.896-7.481-12.254 9.084-.018 18.193 4.319.04.04-12.747c0-.67.544-1.214 1.215-1.214h.003Z" />
		</g>
		<path stroke="#F4D34C" strokeWidth={2.304} d="M292.627 377.028 229 326.809" />
		<path
			stroke="#F4D34C"
			d="M312.385 442.308v-27.783m39.23 11.437-20.404-18.857m18.225-53.097-19.646 19.646m-55.633 49.825 24.061-13.891m66.474-18.498h-27.783"
		/>
		<g fill="#27D9E5" clipPath="url(#j)">
			<path d="M312.65 443c13.087 0 23.735 10.992 23.735 24.5S325.737 492 312.65 492c-13.086 0-23.734-10.992-23.734-24.5S299.564 443 312.65 443Zm0 47.948c12.528 0 22.716-10.516 22.716-23.448s-10.191-23.448-22.716-23.448c-12.524 0-22.715 10.52-22.715 23.448s10.191 23.448 22.715 23.448Z" />
			<path d="m316.766 468.04 1.456-7.053h11.653l1.456 7.053h-14.565Zm.73-13.051v-1.059h2.186v1.059h-2.186Zm.617 11.992h5.391v-1.94h-4.99l-.401 1.94Zm.618-2.999h4.769v-1.94h-4.369l-.4 1.94Zm5.862 2.999h5.391l-.401-1.94h-4.99v1.94Zm0-2.999h4.769l-.4-1.94h-4.369v1.94Zm-5.024-5.113-.782-.74 1.546-1.497.782.739-1.546 1.498Zm4.478-1.762c-.899 0-1.659-.309-2.276-.925a3.315 3.315 0 0 1-1-2.248h1.093c.038.587.262 1.088.674 1.497.412.41.917.617 1.512.617s1.097-.207 1.513-.617c.411-.409.636-.91.673-1.497h1.093a3.327 3.327 0 0 1-.999 2.248c-.618.616-1.378.925-2.276.925h-.007Zm-.547 3.173v-2.118h1.093v2.118H323.5Zm5.024-1.429-1.546-1.498.763-.739 1.565 1.483-.782.758v-.004Zm-7.409-8.37-.782.74-1.546-1.498.782-.739 1.546 1.497Zm5.207 1.19a3.315 3.315 0 0 1 1 2.248h-1.093a2.252 2.252 0 0 0-.674-1.498 2.068 2.068 0 0 0-1.512-.616c-.595 0-1.097.207-1.513.616a2.25 2.25 0 0 0-.673 1.498h-1.093a3.327 3.327 0 0 1 .999-2.248c.618-.617 1.378-.925 2.276-.925.898 0 1.658.308 2.276.925h.007Zm-1.729-4.102v2.118H323.5v-2.118h1.093Zm4.717 2.187-1.565 1.483-.764-.74 1.546-1.497.783.758v-.004Zm-.891 5.233v-1.059h2.186v1.059h-2.186Z" />
			<path d="M325.518 482.818h-3.16c-.588 0-1.067-.464-1.067-1.03v-8.841h-9.265v8.841c0 .569-.479 1.03-1.063 1.03h-3.125c-.588 0-1.064-.464-1.064-1.03v-12.845c0-.322.161-.634.431-.827l8.909-6.422a1.101 1.101 0 0 1 1.277.004l1.16.848-.734.94-1.07-.784-8.76 6.314v12.627h2.827v-8.841c0-.57.479-1.034 1.066-1.034h9.561c.588 0 1.063.464 1.063 1.034v8.841h2.864v-12.627l-1.486-1.088.733-.939 1.546 1.131c.266.192.423.501.423.823v12.845c0 .569-.479 1.03-1.063 1.03h-.003Zm-20.237.014h-8.363l-.03-.471V467.5l.487-.029 1.658-.011v-1.059l.487-.029h4.118l.037.461.015.638h2.048l.029.471v14.861l-.486.029Zm-7.483-.881h7.056v-13.599h-2.033l-.037-.46-.015-.639h-2.83v1.056l-.487.029-1.658.011v13.602h.004Z" />
			<path d="M304.15 477.944h-5.649v3.148h5.649v-3.148Zm0-4.04h-5.649v3.148h5.649v-3.148Z" />
		</g>
		<g fill="#27D9E5" clipPath="url(#k)">
			<path d="M312.93 415.064c-13.519 0-24.519-11-24.519-24.519 0-13.519 11-24.519 24.519-24.519 13.519 0 24.519 11 24.519 24.519 0 13.519-11 24.519-24.519 24.519Zm0-47.986c-12.942 0-23.467 10.525-23.467 23.467 0 12.942 10.529 23.467 23.467 23.467 12.938 0 23.467-10.529 23.467-23.467 0-12.938-10.529-23.467-23.467-23.467Z" />
			<path d="M333.091 389.227c-.349 0-15.04-.141-20.709-14.691l1.096-.428c5.415 13.9 19.479 13.954 19.609 13.94l.011 1.176h-.004l-.003.003Z" />
			<path d="M292.77 389.227h-.004l.011-1.175c.276 0 14.223-.131 19.605-13.94l1.096.428c-5.669 14.546-20.356 14.687-20.708 14.687Z" />
			<path d="M316.353 373.636h-6.867v1.176h6.867v-1.176Zm0-1.716h-6.867v1.175h6.867v-1.175Z" />
			<path d="M313.518 373.992h-1.176v35.929h1.176v-35.929Z" />
		</g>
		<path
			fill="#27D9E5"
			d="M252.828 410c13.944 0 25.29 10.992 25.29 24.5s-11.346 24.5-25.29 24.5c-13.944 0-25.29-10.992-25.29-24.5s11.346-24.5 25.29-24.5Zm0 47.948c13.349 0 24.205-10.516 24.205-23.448s-10.86-23.448-24.205-23.448c-13.345 0-24.205 10.52-24.205 23.448s10.86 23.448 24.205 23.448Z"
		/>
		<path
			fill="#27D9E5"
			d="M239.953 449.678h4.33c.671 0 1.215-.544 1.215-1.215v-12.707l13.138-.04.04 12.747c0 .671.544 1.215 1.215 1.215h4.279c.671 0 1.215-.544 1.215-1.215v-18.165c0-.381-.185-.747-.49-.975l-12.203-9.085a1.217 1.217 0 0 0-1.457.008l-12.015 9.08a1.225 1.225 0 0 0-.482.968v18.165c0 .671.544 1.215 1.215 1.215v.004Zm18.683-15.137h-13.099c-.67 0-1.214.544-1.214 1.215v12.707l-4.37.04-.04-18.205 2.132-1.632 9.897-7.481 12.253 9.084.018 18.194-4.319.04-.04-12.747c0-.671-.543-1.215-1.214-1.215h-.004Z"
		/>
		<g fill="#27D9E5" clipPath="url(#l)">
			<path d="M364.885 421c13.508 0 24.5 10.992 24.5 24.5s-10.992 24.5-24.5 24.5c-13.509 0-24.5-10.992-24.5-24.5s10.991-24.5 24.5-24.5Zm0 47.948c12.932 0 23.448-10.516 23.448-23.448s-10.52-23.448-23.448-23.448-23.449 10.52-23.449 23.448 10.521 23.448 23.449 23.448Z" />
			<path d="m367.766 446.04 1.456-7.053h11.653l1.456 7.053h-14.565Zm.73-13.051v-1.059h2.186v1.059h-2.186Zm.617 11.992h5.391v-1.94h-4.99l-.401 1.94Zm.618-2.999h4.769v-1.94h-4.369l-.4 1.94Zm5.862 2.999h5.391l-.401-1.94h-4.99v1.94Zm0-2.999h4.769l-.4-1.94h-4.369v1.94Zm-5.024-5.113-.782-.74 1.546-1.497.782.739-1.546 1.498Zm4.478-1.762c-.899 0-1.659-.309-2.276-.925a3.315 3.315 0 0 1-1-2.248h1.093c.038.587.262 1.088.674 1.497.412.41.917.617 1.512.617s1.097-.207 1.513-.617c.411-.409.636-.91.673-1.497h1.093a3.327 3.327 0 0 1-.999 2.248c-.618.616-1.378.925-2.276.925h-.007Zm-.547 3.173v-2.118h1.093v2.118H374.5Zm5.024-1.429-1.546-1.498.763-.739 1.565 1.483-.782.758v-.004Zm-7.409-8.37-.782.74-1.546-1.498.782-.739 1.546 1.497Zm5.207 1.19a3.315 3.315 0 0 1 1 2.248h-1.093a2.252 2.252 0 0 0-.674-1.498 2.068 2.068 0 0 0-1.512-.616c-.595 0-1.097.207-1.513.616a2.25 2.25 0 0 0-.673 1.498h-1.093a3.327 3.327 0 0 1 .999-2.248c.618-.617 1.378-.925 2.276-.925.898 0 1.658.308 2.276.925h.007Zm-1.729-4.102v2.118H374.5v-2.118h1.093Zm4.717 2.187-1.565 1.483-.764-.74 1.546-1.497.783.758v-.004Zm-.891 5.233v-1.059h2.186v1.059h-2.186Z" />
			<path d="M376.518 460.818h-3.16c-.588 0-1.067-.464-1.067-1.03v-8.841h-9.265v8.841c0 .569-.479 1.03-1.063 1.03h-3.125c-.588 0-1.064-.464-1.064-1.03v-12.845c0-.322.161-.634.431-.827l8.909-6.422a1.101 1.101 0 0 1 1.277.004l1.16.848-.734.94-1.07-.784-8.76 6.314v12.627h2.827v-8.841c0-.57.479-1.034 1.066-1.034h9.561c.588 0 1.063.464 1.063 1.034v8.841h2.864v-12.627l-1.486-1.088.733-.939 1.546 1.131c.266.192.423.501.423.823v12.845c0 .569-.479 1.03-1.063 1.03h-.003Zm-20.237.014h-8.363l-.03-.471V445.5l.487-.029 1.658-.011v-1.059l.487-.029h4.118l.037.461.015.638h2.048l.029.471v14.861l-.486.029Zm-7.483-.881h7.056v-13.599h-2.033l-.037-.46-.015-.639h-2.83v1.056l-.487.029-1.658.011v13.602h.004Z" />
			<path d="M355.15 455.944h-5.649v3.148h5.649v-3.148Zm0-4.04h-5.649v3.148h5.649v-3.148Z" />
		</g>
		<path
			fill="#27D9E5"
			d="M361.618 308c13.509 0 24.5 10.992 24.5 24.5s-10.991 24.5-24.5 24.5c-13.508 0-24.5-10.992-24.5-24.5s10.992-24.5 24.5-24.5Zm0 47.948c12.932 0 23.449-10.516 23.449-23.448s-10.521-23.448-23.449-23.448-23.448 10.52-23.448 23.448 10.52 23.448 23.448 23.448Z"
		/>
		<path
			fill="#27D9E5"
			d="M349.776 347.339h4.33c.671 0 1.215-.544 1.215-1.215v-12.707l13.139-.039.039 12.746c0 .671.544 1.215 1.215 1.215h4.279c.671 0 1.215-.544 1.215-1.215V327.96c0-.381-.185-.747-.489-.976l-12.203-9.084a1.22 1.22 0 0 0-1.458.007l-12.014 9.081a1.224 1.224 0 0 0-.483.968v18.165c0 .671.544 1.215 1.215 1.215v.003Zm18.684-15.136h-13.099c-.671 0-1.215.544-1.215 1.214v12.707l-4.37.04-.04-18.204 2.133-1.632 9.896-7.481 12.254 9.084.018 18.193-4.319.04-.04-12.747c0-.67-.544-1.214-1.215-1.214h-.003Z"
		/>
		<g fill="#27D9E5" clipPath="url(#m)">
			<path d="M389.885 366c13.508 0 24.5 10.992 24.5 24.5s-10.992 24.5-24.5 24.5c-13.509 0-24.5-10.992-24.5-24.5s10.991-24.5 24.5-24.5Zm0 47.948c12.932 0 23.448-10.516 23.448-23.448s-10.52-23.448-23.448-23.448-23.449 10.52-23.449 23.448 10.521 23.448 23.449 23.448Z" />
			<path d="M377.776 405.339h4.33c.671 0 1.215-.544 1.215-1.215v-12.707l13.139-.039.039 12.746c0 .671.544 1.215 1.215 1.215h4.279c.671 0 1.215-.544 1.215-1.215V385.96c0-.381-.185-.747-.489-.976l-12.203-9.084a1.22 1.22 0 0 0-1.458.007l-12.014 9.081a1.224 1.224 0 0 0-.483.968v18.165c0 .671.544 1.215 1.215 1.215v.003Zm18.684-15.136h-13.099c-.671 0-1.215.544-1.215 1.214v12.707l-4.37.04-.04-18.204 2.133-1.632 9.896-7.481 12.254 9.084.018 18.193-4.319.04-.04-12.747c0-.67-.544-1.214-1.215-1.214h-.003Z" />
		</g>
		<path stroke="#F4D34C" strokeWidth={2.304} d="m122.758 114.972 63.627 50.219" />
		<path
			stroke="#F4D34C"
			d="M103 49.692v27.783M63.77 66.038l20.404 18.856m-18.225 53.098 19.645-19.646m55.634-49.826-24.061 13.892M50.692 100.91h27.783"
		/>
		<g fill="#27D9E5" clipPath="url(#n)">
			<path d="M102.734 49C89.648 49 79 38.008 79 24.5S89.648 0 102.734 0c13.087 0 23.735 10.992 23.735 24.5S115.821 49 102.734 49Zm0-47.948c-12.527 0-22.715 10.516-22.715 23.448s10.191 23.448 22.715 23.448c12.525 0 22.716-10.52 22.716-23.448S115.259 1.052 102.734 1.052Z" />
			<path d="m100.324 26.603 1.486-7.666h11.882l1.486 7.666h-14.854Zm.742-14.183v-1.15h2.227v1.15h-2.227Zm.632 13.03h5.495v-2.107h-5.087l-.408 2.107Zm.632-3.256h4.866v-2.107h-4.455l-.407 2.107h-.004Zm5.98 3.256h5.494l-.407-2.107h-5.087v2.107Zm0-3.256h4.865l-.407-2.107h-4.455v2.107h-.003Zm-5.126-5.56-.797-.805 1.577-1.628.797.805-1.577 1.628Zm4.567-1.914c-.917 0-1.69-.334-2.322-1.005-.632-.67-.973-1.487-1.022-2.444h1.113c.039.638.267 1.182.689 1.628a2.04 2.04 0 0 0 1.542.671 2.04 2.04 0 0 0 1.542-.67c.422-.447.65-.99.689-1.63h1.114c-.05.958-.39 1.774-1.023 2.445-.632.671-1.405 1.005-2.322 1.005Zm-.558 3.448V15.87h1.113v2.3h-1.113Zm5.125-1.552-1.577-1.628.78-.805 1.595 1.61-.798.823Zm-7.557-9.095-.797.805-1.577-1.628.797-.805 1.577 1.628Zm5.309 1.295c.632.67.973 1.487 1.022 2.444h-1.114a2.516 2.516 0 0 0-.688-1.628 2.043 2.043 0 0 0-1.542-.671c-.608 0-1.121.225-1.543.67-.421.447-.65.99-.688 1.629h-1.114c.049-.957.39-1.773 1.022-2.444.633-.671 1.406-1.005 2.323-1.005s1.689.334 2.322 1.005Zm-1.764-4.457v2.3h-1.113v-2.3h1.113Zm4.81 2.375-1.595 1.61-.78-.805 1.577-1.628.798.823Zm-.91 5.686v-1.15h2.227v1.15h-2.227Z" />
			<path d="M109.251 42.61h-3.221c-.573 0-1.037-.478-1.037-1.07v-9.657h-9.545v9.658c0 .59-.463 1.07-1.036 1.07h-3.186c-.573 0-1.037-.48-1.037-1.07V27.586c0-.337.158-.66.418-.86l9.082-6.977a1.017 1.017 0 0 1 1.243.004l1.181.921-.689.94-1.121-.875-8.98 6.901v13.795h2.984v-9.657c0-.59.463-1.07 1.036-1.07h9.749c.572 0 1.036.48 1.036 1.07v9.657h3.018V27.637l-1.535-1.197.688-.94 1.574 1.23c.256.2.411.519.411.852v13.959c0 .59-.464 1.07-1.036 1.07h.003Z" />
			<path d="m100.324 26.603 1.486-7.666h11.882l1.486 7.666h-14.854Zm.742-14.183v-1.15h2.227v1.15h-2.227Zm.632 13.03h5.495v-2.107h-5.087l-.408 2.107Zm.632-3.256h4.866v-2.107h-4.455l-.407 2.107h-.004Zm5.98 3.256h5.494l-.407-2.107h-5.087v2.107Zm0-3.256h4.865l-.407-2.107h-4.455v2.107h-.003Zm-5.126-5.56-.797-.805 1.577-1.628.797.805-1.577 1.628Zm4.567-1.914c-.917 0-1.69-.334-2.322-1.005-.632-.67-.973-1.487-1.022-2.444h1.113c.039.638.267 1.182.689 1.628a2.04 2.04 0 0 0 1.542.671 2.04 2.04 0 0 0 1.542-.67c.422-.447.65-.99.689-1.63h1.114c-.05.958-.39 1.774-1.023 2.445-.632.671-1.405 1.005-2.322 1.005Zm-.558 3.448V15.87h1.113v2.3h-1.113Zm5.125-1.552-1.577-1.628.78-.805 1.595 1.61-.798.823Zm-7.557-9.095-.797.805-1.577-1.628.797-.805 1.577 1.628Zm5.309 1.295c.632.67.973 1.487 1.022 2.444h-1.114a2.516 2.516 0 0 0-.688-1.628 2.043 2.043 0 0 0-1.542-.671c-.608 0-1.121.225-1.543.67-.421.447-.65.99-.688 1.629h-1.114c.049-.957.39-1.773 1.022-2.444.633-.671 1.406-1.005 2.323-1.005s1.689.334 2.322 1.005Zm-1.764-4.457v2.3h-1.113v-2.3h1.113Zm4.81 2.375-1.595 1.61-.78-.805 1.577-1.628.798.823Zm-.91 5.686v-1.15h2.227v1.15h-2.227Z" />
			<path d="M109.251 42.61h-3.221c-.573 0-1.037-.478-1.037-1.07v-9.657h-9.545v9.658c0 .59-.463 1.07-1.036 1.07h-3.186c-.573 0-1.037-.48-1.037-1.07V27.586c0-.337.158-.66.418-.86l9.082-6.977a1.017 1.017 0 0 1 1.243.004l1.181.921-.689.94-1.121-.875-8.98 6.901v13.795h2.984v-9.657c0-.59.463-1.07 1.036-1.07h9.749c.572 0 1.036.48 1.036 1.07v9.657h3.018V27.637l-1.535-1.197.688-.94 1.574 1.23c.256.2.411.519.411.852v13.959c0 .59-.464 1.07-1.036 1.07h.003Z" />
		</g>
		<g fill="#27D9E5" clipPath="url(#o)">
			<path d="M102.455 125.974c-13.519 0-24.52-11-24.52-24.519 0-13.519 11.001-24.52 24.52-24.52s24.519 11.001 24.519 24.52-11 24.519-24.519 24.519Zm0-47.986c-12.942 0-23.467 10.525-23.467 23.467 0 12.942 10.529 23.467 23.467 23.467 12.938 0 23.467-10.529 23.467-23.467 0-12.938-10.529-23.467-23.467-23.467Z" />
			<path d="M122.616 100.138c-.349 0-15.04-.142-20.709-14.692l1.096-.428c5.415 13.9 19.478 13.955 19.609 13.94l.011 1.176h-.004l-.003.004Z" />
			<path d="M82.295 100.138h-.004l.01-1.176c.277 0 14.224-.13 19.606-13.94l1.096.428c-5.669 14.546-20.356 14.688-20.708 14.688Z" />
			<path d="M105.878 84.546h-6.867v1.176h6.867v-1.176Zm0-1.716h-6.867v1.176h6.867V82.83Z" />
			<path d="M103.043 84.902h-1.176v35.93h1.176v-35.93Z" />
		</g>
		<path
			fill="#27D9E5"
			d="M162.557 82c-13.944 0-25.29-10.992-25.29-24.5S148.613 33 162.557 33c13.944 0 25.29 10.992 25.29 24.5S176.501 82 162.557 82Zm0-47.948c-13.349 0-24.205 10.516-24.205 23.448s10.86 23.448 24.205 23.448c13.345 0 24.205-10.52 24.205-23.448s-10.86-23.448-24.205-23.448Z"
		/>
		<path
			fill="#27D9E5"
			d="m164.766 58.04 1.456-7.053h11.653l1.456 7.053h-14.565Zm.73-13.051V43.93h2.186v1.059h-2.186Zm.617 11.992h5.391v-1.94h-4.99l-.401 1.94Zm.618-2.999h4.769v-1.94h-4.369l-.4 1.94Zm5.862 3h5.391l-.401-1.94h-4.99v1.94Zm0-3h4.769l-.4-1.94h-4.369v1.94Zm-5.024-5.113-.782-.74 1.546-1.497.782.74-1.546 1.497Zm4.478-1.762c-.899 0-1.659-.309-2.276-.925a3.316 3.316 0 0 1-1-2.248h1.093a2.25 2.25 0 0 0 .674 1.497c.412.41.917.617 1.512.617s1.097-.207 1.513-.617c.411-.41.636-.91.673-1.497h1.093a3.329 3.329 0 0 1-.999 2.248c-.618.616-1.378.925-2.276.925h-.007Zm-.547 3.173v-2.118h1.093v2.118H171.5Zm5.024-1.429-1.546-1.498.763-.74 1.565 1.484-.782.758v-.004Zm-7.409-8.37-.782.74-1.546-1.498.782-.74 1.546 1.498Zm5.207 1.19a3.316 3.316 0 0 1 1 2.248h-1.093a2.25 2.25 0 0 0-.674-1.498 2.065 2.065 0 0 0-1.512-.616c-.595 0-1.097.207-1.513.616-.411.41-.636.91-.673 1.498h-1.093a3.329 3.329 0 0 1 .999-2.248c.618-.617 1.378-.925 2.276-.925.898 0 1.658.308 2.276.925h.007Zm-1.729-4.102v2.118H171.5V37.57h1.093Zm4.717 2.187-1.565 1.483-.764-.74 1.546-1.497.783.758v-.004Zm-.891 5.233V43.93h2.186v1.059h-2.186Z"
		/>
		<path
			fill="#27D9E5"
			d="M173.518 72.818h-3.16c-.588 0-1.067-.464-1.067-1.03v-8.841h-9.265v8.841c0 .57-.479 1.03-1.063 1.03h-3.125c-.588 0-1.064-.464-1.064-1.03V58.943c0-.322.161-.634.431-.826l8.909-6.423a1.101 1.101 0 0 1 1.277.004l1.16.848-.734.94-1.07-.784-8.76 6.314v12.627h2.827v-8.841c0-.57.479-1.034 1.066-1.034h9.561c.588 0 1.063.465 1.063 1.034v8.841h2.864V59.016l-1.486-1.088.733-.94 1.546 1.132c.266.192.423.5.423.823v12.845c0 .57-.479 1.03-1.063 1.03h-.003Zm-20.237.014h-8.363l-.03-.471V57.5l.487-.029 1.658-.01V56.4l.487-.029h4.118l.037.46.015.639h2.048l.029.471v14.861l-.486.03Zm-7.483-.88h7.056v-13.6h-2.033l-.037-.46-.015-.639h-2.83v1.056l-.487.029-1.658.01v13.603h.004Z"
		/>
		<path fill="#27D9E5" d="M152.15 67.944h-5.649v3.148h5.649v-3.148Zm0-4.04h-5.649v3.148h5.649v-3.148Z" />
		<g fill="#27D9E5" clipPath="url(#p)">
			<path d="M50.5 71C36.992 71 26 60.008 26 46.5S36.992 22 50.5 22 75 32.992 75 46.5 64.008 71 50.5 71Zm0-47.948c-12.932 0-23.448 10.516-23.448 23.448S37.572 69.948 50.5 69.948 73.948 59.428 73.948 46.5 63.428 23.052 50.5 23.052Z" />
			<path d="M62.608 61.34h-4.33c-.67 0-1.214-.545-1.214-1.216V47.417l-13.139-.04-.04 12.747c0 .671-.544 1.215-1.215 1.215h-4.279c-.67 0-1.215-.544-1.215-1.215V41.96c0-.381.185-.747.49-.976L49.87 31.9a1.22 1.22 0 0 1 1.458.007l12.014 9.08c.301.23.482.592.482.97V60.12c0 .67-.544 1.215-1.215 1.215v.003ZM43.925 46.202h13.099c.67 0 1.215.544 1.215 1.214v12.707l4.37.04.04-18.204-2.133-1.632-9.896-7.481-12.254 9.084-.018 18.193 4.319.04.04-12.747c0-.67.544-1.214 1.215-1.214h.003Z" />
		</g>
		<path
			fill="#27D9E5"
			d="M53.767 184c-13.509 0-24.5-10.992-24.5-24.5s10.991-24.5 24.5-24.5c13.508 0 24.5 10.992 24.5 24.5S67.275 184 53.767 184Zm0-47.948c-12.932 0-23.449 10.516-23.449 23.448s10.52 23.448 23.449 23.448c12.928 0 23.448-10.52 23.448-23.448s-10.52-23.448-23.448-23.448Z"
		/>
		<path
			fill="#27D9E5"
			d="M65.875 174.339h-4.33c-.67 0-1.215-.544-1.215-1.215v-12.707l-13.138-.039-.04 12.746c0 .671-.544 1.215-1.215 1.215h-4.279c-.67 0-1.215-.544-1.215-1.215V154.96c0-.381.185-.747.49-.976l12.203-9.084a1.22 1.22 0 0 1 1.457.007l12.015 9.081c.3.228.482.591.482.968v18.165c0 .671-.544 1.215-1.215 1.215v.003Zm-18.683-15.136H60.29c.671 0 1.215.544 1.215 1.214v12.707l4.37.04.04-18.204-2.132-1.632-9.897-7.481-12.253 9.084-.019 18.193 4.32.04.04-12.747c0-.67.543-1.214 1.214-1.214h.004Z"
		/>
		<g fill="#27D9E5" clipPath="url(#q)">
			<path d="M25.5 126C11.992 126 1 115.008 1 101.5S11.992 77 25.5 77 50 87.992 50 101.5 39.008 126 25.5 126Zm0-47.948c-12.932 0-23.448 10.516-23.448 23.448s10.52 23.448 23.448 23.448 23.448-10.52 23.448-23.448S38.428 78.052 25.5 78.052Z" />
			<path d="M37.608 116.339h-4.33c-.67 0-1.214-.544-1.214-1.215v-12.707l-13.139-.039-.04 12.746c0 .671-.544 1.215-1.215 1.215h-4.279c-.67 0-1.214-.544-1.214-1.215V96.96c0-.381.184-.747.49-.976L24.868 86.9a1.22 1.22 0 0 1 1.458.007l12.014 9.08c.301.23.482.592.482.97v18.164c0 .671-.544 1.215-1.215 1.215v.003Zm-18.683-15.136h13.099c.67 0 1.215.544 1.215 1.214v12.707l4.37.04.04-18.204-2.133-1.632-9.896-7.481-12.254 9.084-.018 18.193 4.319.04.04-12.747c0-.67.544-1.214 1.215-1.214h.003Z" />
		</g>
		<path stroke="#F4D34C" strokeWidth={2.304} d="M290.627 114.972 227 165.191" />
		<path
			stroke="#F4D34C"
			d="M310.385 49.692v27.783m39.23-11.437-20.404 18.856m18.225 53.098-19.646-19.646M272.157 68.52l24.061 13.892m66.474 18.498h-27.783"
		/>
		<g fill="#27D9E5" clipPath="url(#r)">
			<path d="M310.734 49c13.087 0 23.735-10.992 23.735-24.5S323.821 0 310.734 0C297.648 0 287 10.992 287 24.5S297.648 49 310.734 49Zm0-47.948c12.528 0 22.716 10.516 22.716 23.448s-10.191 23.448-22.716 23.448c-12.524 0-22.715-10.52-22.715-23.448S298.21 1.052 310.734 1.052Z" />
			<path d="m308.324 26.603 1.486-7.666h11.882l1.486 7.666h-14.854Zm.742-14.183v-1.15h2.227v1.15h-2.227Zm.632 13.03h5.495v-2.107h-5.087l-.408 2.107Zm.632-3.256h4.866v-2.107h-4.455l-.407 2.107h-.004Zm5.98 3.256h5.494l-.407-2.107h-5.087v2.107Zm0-3.256h4.865l-.407-2.107h-4.455v2.107h-.003Zm-5.126-5.56-.797-.805 1.577-1.628.797.805-1.577 1.628Zm4.567-1.914c-.917 0-1.69-.334-2.322-1.005-.632-.67-.973-1.487-1.022-2.444h1.113c.039.638.267 1.182.689 1.628a2.04 2.04 0 0 0 1.542.671 2.04 2.04 0 0 0 1.542-.67c.422-.447.65-.99.689-1.63h1.114c-.05.958-.39 1.774-1.023 2.445-.632.671-1.405 1.005-2.322 1.005Zm-.558 3.448V15.87h1.113v2.3h-1.113Zm5.125-1.552-1.577-1.628.78-.805 1.595 1.61-.798.823Zm-7.557-9.095-.797.805-1.577-1.628.797-.805 1.577 1.628Zm5.309 1.295c.632.67.973 1.487 1.022 2.444h-1.114a2.516 2.516 0 0 0-.688-1.628 2.043 2.043 0 0 0-1.542-.671c-.608 0-1.121.225-1.543.67-.421.447-.65.99-.688 1.629h-1.114c.049-.957.39-1.773 1.022-2.444.633-.671 1.406-1.005 2.323-1.005s1.689.334 2.322 1.005Zm-1.764-4.457v2.3h-1.113v-2.3h1.113Zm4.81 2.375-1.595 1.61-.78-.805 1.577-1.628.798.823Zm-.91 5.686v-1.15h2.227v1.15h-2.227Z" />
			<path d="M317.251 42.61h-3.221c-.573 0-1.037-.478-1.037-1.07v-9.657h-9.545v9.658c0 .59-.463 1.07-1.036 1.07h-3.186c-.573 0-1.037-.48-1.037-1.07V27.586c0-.337.158-.66.418-.86l9.082-6.977a1.017 1.017 0 0 1 1.243.004l1.181.921-.689.94-1.121-.875-8.979 6.901v13.795h2.983v-9.657c0-.59.463-1.07 1.036-1.07h9.749c.572 0 1.036.48 1.036 1.07v9.657h3.018V27.637l-1.535-1.197.688-.94 1.574 1.23c.256.2.411.519.411.852v13.959c0 .59-.464 1.07-1.036 1.07h.003Z" />
		</g>
		<g fill="#27D9E5" clipPath="url(#s)">
			<path d="M310.93 125.974c13.519 0 24.519-11 24.519-24.519 0-13.519-11-24.52-24.519-24.52-13.519 0-24.519 11.001-24.519 24.52s11 24.519 24.519 24.519Zm0-47.986c12.942 0 23.466 10.525 23.466 23.467 0 12.942-10.528 23.467-23.466 23.467-12.939 0-23.467-10.529-23.467-23.467 0-12.938 10.528-23.467 23.467-23.467Z" />
			<path d="M290.769 100.138c.348 0 15.04-.142 20.709-14.692l-1.096-.428c-5.415 13.9-19.479 13.955-19.609 13.94l-.011 1.176h.003l.004.004Z" />
			<path d="M331.09 100.138h.004l-.011-1.176c-.276 0-14.223-.13-19.605-13.94l-1.096.428c5.668 14.546 20.356 14.688 20.708 14.688Z" />
			<path d="M307.507 84.546h6.867v1.176h-6.867v-1.176Zm0-1.716h6.867v1.176h-6.867V82.83Z" />
			<path d="M310.342 84.902h1.176v35.93h-1.176v-35.93Z" />
		</g>
		<path
			fill="#27D9E5"
			d="M250.828 82c13.944 0 25.29-10.992 25.29-24.5S264.772 33 250.828 33c-13.944 0-25.29 10.992-25.29 24.5S236.884 82 250.828 82Zm0-47.948c13.349 0 24.205 10.516 24.205 23.448s-10.86 23.448-24.205 23.448c-13.345 0-24.205-10.52-24.205-23.448s10.86-23.448 24.205-23.448Z"
		/>
		<path
			fill="#27D9E5"
			d="m247.324 59.603 1.486-7.666h11.882l1.486 7.666h-14.854Zm.742-14.183v-1.15h2.227v1.15h-2.227Zm.632 13.03h5.495v-2.107h-5.087l-.408 2.107Zm.632-3.256h4.866v-2.107h-4.455l-.407 2.107h-.004Zm5.98 3.256h5.494l-.407-2.107h-5.087v2.107Zm0-3.256h4.865l-.407-2.107h-4.455v2.107h-.003Zm-5.126-5.56-.797-.805 1.577-1.628.797.805-1.577 1.628Zm4.567-1.914c-.917 0-1.69-.334-2.322-1.005-.632-.67-.973-1.487-1.022-2.444h1.113c.039.638.267 1.182.689 1.628a2.04 2.04 0 0 0 1.542.671 2.04 2.04 0 0 0 1.542-.67c.422-.447.65-.99.689-1.63h1.114c-.05.958-.39 1.774-1.023 2.445-.632.671-1.405 1.005-2.322 1.005Zm-.558 3.448V48.87h1.113v2.3h-1.113Zm5.125-1.552-1.577-1.628.78-.805 1.595 1.61-.798.823Zm-7.557-9.095-.797.805-1.577-1.628.797-.805 1.577 1.628Zm5.309 1.295c.632.67.973 1.487 1.022 2.444h-1.114a2.516 2.516 0 0 0-.688-1.628 2.043 2.043 0 0 0-1.542-.671c-.608 0-1.121.225-1.543.67-.421.447-.65.99-.688 1.629h-1.114c.049-.957.39-1.773 1.022-2.444.633-.671 1.406-1.005 2.323-1.005s1.689.334 2.322 1.005Zm-1.764-4.457v2.3h-1.113v-2.3h1.113Zm4.81 2.375-1.595 1.61-.78-.805 1.577-1.628.798.823Zm-.91 5.686v-1.15h2.227v1.15h-2.227Z"
		/>
		<path
			fill="#27D9E5"
			d="M256.251 75.61h-3.221c-.573 0-1.037-.478-1.037-1.07v-9.657h-9.545v9.657c0 .592-.463 1.07-1.036 1.07h-3.186c-.573 0-1.037-.478-1.037-1.07V60.586c0-.337.158-.66.418-.86l9.082-6.977a1.017 1.017 0 0 1 1.243.004l1.181.921-.689.94-1.121-.875-8.979 6.902v13.794h2.983v-9.657c0-.59.463-1.07 1.036-1.07h9.749c.572 0 1.036.48 1.036 1.07v9.657h3.018V60.637l-1.535-1.197.688-.94 1.574 1.23c.256.2.411.519.411.852V74.54c0 .592-.464 1.07-1.036 1.07h.003Z"
		/>
		<g fill="#27D9E5" clipPath="url(#t)">
			<path d="M362.885 71c13.508 0 24.5-10.992 24.5-24.5S376.393 22 362.885 22c-13.509 0-24.5 10.992-24.5 24.5s10.991 24.5 24.5 24.5Zm0-47.948c12.932 0 23.448 10.516 23.448 23.448s-10.52 23.448-23.448 23.448-23.449-10.52-23.449-23.448 10.521-23.448 23.449-23.448Z" />
			<path d="m365.766 47.04 1.456-7.053h11.653l1.456 7.053h-14.565Zm.73-13.051V32.93h2.186v1.059h-2.186Zm.617 11.992h5.391v-1.94h-4.99l-.401 1.94Zm.618-2.999h4.769v-1.94h-4.369l-.4 1.94Zm5.862 3h5.391l-.401-1.94h-4.99v1.94Zm0-3h4.769l-.4-1.94h-4.369v1.94Zm-5.024-5.113-.782-.74 1.546-1.497.782.74-1.546 1.497Zm4.478-1.762c-.899 0-1.659-.309-2.276-.925a3.316 3.316 0 0 1-1-2.248h1.093a2.25 2.25 0 0 0 .674 1.497c.412.41.917.617 1.512.617s1.097-.207 1.513-.617c.411-.41.636-.91.673-1.497h1.093a3.329 3.329 0 0 1-.999 2.248c-.618.616-1.378.925-2.276.925h-.007Zm-.547 3.173v-2.118h1.093v2.118H372.5Zm5.024-1.429-1.546-1.498.763-.74 1.565 1.484-.782.758v-.004Zm-7.409-8.37-.782.74-1.546-1.498.782-.74 1.546 1.498Zm5.207 1.19a3.316 3.316 0 0 1 1 2.248h-1.093a2.25 2.25 0 0 0-.674-1.498 2.065 2.065 0 0 0-1.512-.616c-.595 0-1.097.207-1.513.616-.411.41-.636.91-.673 1.498h-1.093a3.329 3.329 0 0 1 .999-2.248c.618-.617 1.378-.925 2.276-.925.898 0 1.658.308 2.276.925h.007Zm-1.729-4.102v2.118H372.5V26.57h1.093Zm4.717 2.187-1.565 1.483-.764-.74 1.546-1.497.783.758v-.004Zm-.891 5.233V32.93h2.186v1.059h-2.186Z" />
			<path d="M374.518 61.818h-3.16c-.588 0-1.067-.464-1.067-1.03v-8.841h-9.265v8.841c0 .57-.479 1.03-1.063 1.03h-3.125c-.588 0-1.064-.464-1.064-1.03V47.943c0-.322.161-.634.431-.826l8.909-6.423a1.101 1.101 0 0 1 1.277.004l1.16.848-.734.94-1.07-.784-8.76 6.314v12.627h2.827v-8.841c0-.57.479-1.034 1.066-1.034h9.561c.588 0 1.063.465 1.063 1.034v8.841h2.864V48.016l-1.486-1.088.733-.94 1.546 1.132c.266.192.423.5.423.823v12.845c0 .57-.479 1.03-1.063 1.03h-.003Zm-20.237.015h-8.363l-.03-.472V46.5l.487-.029 1.658-.01V45.4l.487-.029h4.118l.037.46.015.639h2.048l.029.471v14.861l-.486.03Zm-7.483-.882h7.056V47.352h-2.033l-.037-.46-.015-.639h-2.83v1.056l-.487.029-1.658.01v13.603h.004Z" />
			<path d="M353.15 56.944h-5.649v3.148h5.649v-3.148Zm0-4.04h-5.649v3.148h5.649v-3.148Z" />
		</g>
		<path
			fill="#27D9E5"
			d="M359.618 184c13.509 0 24.5-10.992 24.5-24.5s-10.991-24.5-24.5-24.5c-13.508 0-24.5 10.992-24.5 24.5s10.992 24.5 24.5 24.5Zm0-47.948c12.932 0 23.449 10.516 23.449 23.448s-10.521 23.448-23.449 23.448-23.448-10.52-23.448-23.448 10.52-23.448 23.448-23.448Z"
		/>
		<path
			fill="#27D9E5"
			d="M347.51 174.339h4.33c.67 0 1.214-.544 1.214-1.215v-12.707l13.139-.039.04 12.746c0 .671.544 1.215 1.215 1.215h4.279c.671 0 1.215-.544 1.215-1.215V154.96a1.23 1.23 0 0 0-.49-.976l-12.203-9.084a1.22 1.22 0 0 0-1.458.007l-12.014 9.081a1.224 1.224 0 0 0-.482.968v18.165c0 .671.544 1.215 1.215 1.215v.003Zm18.683-15.136h-13.099c-.671 0-1.215.544-1.215 1.214v12.707l-4.369.04-.04-18.204 2.132-1.632 9.897-7.481 12.253 9.084.018 18.193-4.319.04-.04-12.747c0-.67-.544-1.214-1.214-1.214h-.004Z"
		/>
		<g fill="#27D9E5" clipPath="url(#u)">
			<path d="M387.885 126c13.508 0 24.5-10.992 24.5-24.5S401.393 77 387.885 77c-13.509 0-24.5 10.992-24.5 24.5s10.991 24.5 24.5 24.5Zm0-47.948c12.932 0 23.448 10.516 23.448 23.448s-10.52 23.448-23.448 23.448-23.449-10.52-23.449-23.448 10.521-23.448 23.449-23.448Z" />
			<path d="M375.776 116.339h4.33c.671 0 1.215-.544 1.215-1.215v-12.707l13.139-.039.039 12.746c0 .671.544 1.215 1.215 1.215h4.279c.671 0 1.215-.544 1.215-1.215V96.96c0-.381-.185-.747-.489-.976L388.516 86.9a1.219 1.219 0 0 0-1.458.007l-12.014 9.08a1.225 1.225 0 0 0-.483.97v18.164c0 .671.544 1.215 1.215 1.215v.003Zm18.684-15.136h-13.099c-.671 0-1.215.544-1.215 1.214v12.707l-4.37.04-.04-18.204 2.133-1.632 9.896-7.481 12.254 9.084.018 18.193-4.319.04-.04-12.747c0-.67-.544-1.214-1.215-1.214h-.003Z" />
		</g>
		<g fill="#27D9E5" clipPath="url(#v)">
			<path d="M218.808 174.415v15.276h-24.53v-15.276h24.53Zm.486-.929h-25.502c-.247 0-.443.2-.443.443v16.248c0 .247.199.443.443.443h25.502c.247 0 .443-.2.443-.443v-16.248a.442.442 0 0 0-.443-.443Z" />
			<path d="M220.594 190.62v1.452h-28.098v-1.452h28.098Zm.722-.929h-29.542a.207.207 0 0 0-.207.207v2.892c0 .116.094.207.207.207h29.542a.207.207 0 0 0 .207-.207v-2.892a.207.207 0 0 0-.207-.207Zm-22.781-25.848a.83.83 0 0 1 .828.828v8.728h-1.652v-8.728a.83.83 0 0 1 .828-.828m0-.82c-.911 0-1.648.737-1.648 1.648v9.552h3.295v-9.552c0-.911-.736-1.648-1.647-1.648Z" />
			<path d="M198.535 172.452c-3.357 0-3.357-.261-3.357-.628 0-.439.348-.541 2.072-.602l.015.402c.366.015.791.022 1.27.022.657 0 1.212-.014 1.648-.04l.018-.37c1.227.062 1.687.131 1.687.588 0 .363 0 .628-3.357.628h.004Zm0-2.011c-3.357 0-3.357-.261-3.357-.628 0-.439.348-.54 2.072-.606l.015.403c.366.015.791.022 1.27.022.657 0 1.212-.014 1.648-.04l.018-.37c1.227.062 1.687.131 1.687.588 0 .363 0 .628-3.357.628l.004.003Zm0-2.01c-3.357 0-3.357-.262-3.357-.628 0-.439.348-.541 2.072-.606l.015.403c.366.014.791.021 1.27.021.657 0 1.212-.014 1.648-.039l.018-.371c1.227.062 1.687.131 1.687.588 0 .363 0 .628-3.357.628l.004.004Zm0-2.014c-3.357 0-3.357-.262-3.357-.628 0-.439.348-.541 2.072-.606l.015.402c.366.015.791.022 1.27.022.657 0 1.212-.014 1.648-.04l.018-.37c1.227.062 1.687.131 1.687.588 0 .363 0 .628-3.357.628l.004.004Zm8.064-2.574a.83.83 0 0 1 .828.828v8.728h-1.651v-8.728a.83.83 0 0 1 .827-.828m0-.82c-.911 0-1.648.737-1.648 1.648v9.552h3.296v-9.552c0-.911-.737-1.648-1.648-1.648Z" />
			<path d="M206.599 172.452c-3.357 0-3.357-.261-3.357-.628 0-.439.349-.541 2.072-.602l.015.402c.367.015.791.022 1.27.022.657 0 1.212-.014 1.648-.04l.018-.37c1.227.062 1.688.131 1.688.588 0 .363 0 .628-3.357.628h.003Zm0-2.011c-3.357 0-3.357-.261-3.357-.628 0-.439.349-.54 2.072-.606l.015.403c.367.015.791.022 1.27.022.657 0 1.212-.014 1.648-.04l.018-.37c1.227.062 1.688.131 1.688.588 0 .363 0 .628-3.357.628l.003.003Zm0-2.01c-3.357 0-3.357-.262-3.357-.628 0-.439.349-.541 2.072-.606l.015.403c.367.014.791.021 1.27.021.657 0 1.212-.014 1.648-.039l.018-.371c1.227.062 1.688.131 1.688.588 0 .363 0 .628-3.357.628l.003.004Zm0-2.014c-3.357 0-3.357-.262-3.357-.628 0-.439.349-.541 2.072-.606l.015.402c.367.015.791.022 1.27.022.657 0 1.212-.014 1.648-.04l.018-.37c1.227.062 1.688.131 1.688.588 0 .363 0 .628-3.357.628l.003.004Zm7.956-2.574a.83.83 0 0 1 .827.828v8.728h-1.651v-8.728a.83.83 0 0 1 .827-.828m0-.82c-.911 0-1.647.737-1.647 1.648v9.552h3.295v-9.552c0-.911-.737-1.648-1.648-1.648Z" />
			<path d="M214.555 172.452c-3.357 0-3.357-.261-3.357-.628 0-.439.348-.541 2.072-.602l.014.402c.367.015.792.022 1.271.022.656 0 1.212-.014 1.647-.04l.018-.37c1.227.062 1.688.131 1.688.588 0 .363 0 .628-3.357.628h.004Zm0-2.011c-3.357 0-3.357-.261-3.357-.628 0-.439.348-.54 2.072-.606l.014.403c.367.015.792.022 1.271.022.656 0 1.212-.014 1.647-.04l.018-.37c1.227.062 1.688.131 1.688.588 0 .363 0 .628-3.357.628l.004.003Zm0-2.01c-3.357 0-3.357-.262-3.357-.628 0-.439.348-.541 2.072-.606l.014.403c.367.014.792.021 1.271.021.656 0 1.212-.014 1.647-.039l.018-.371c1.227.062 1.688.131 1.688.588 0 .363 0 .628-3.357.628l.004.004Zm0-2.014c-3.357 0-3.357-.262-3.357-.628 0-.439.348-.541 2.072-.606l.014.402c.367.015.792.022 1.271.022.656 0 1.212-.014 1.647-.04l.018-.37c1.227.062 1.688.131 1.688.588 0 .363 0 .628-3.357.628l.004.004Z" />
			<path d="M206.545 202.538c-13.519 0-24.519-11-24.519-24.519 0-13.519 11-24.519 24.519-24.519 13.519 0 24.519 11 24.519 24.519 0 13.519-11 24.519-24.519 24.519Zm0-47.986c-12.942 0-23.467 10.525-23.467 23.467 0 12.942 10.529 23.467 23.467 23.467 12.938 0 23.467-10.528 23.467-23.467 0-12.938-10.529-23.467-23.467-23.467Z" />
		</g>
		<g fill="#27D9E5" clipPath="url(#w)">
			<path d="M218.808 311.723v15.276h-24.53v-15.276h24.53Zm.486-.929h-25.502c-.247 0-.443.2-.443.443v16.248c0 .247.199.443.443.443h25.502c.247 0 .443-.2.443-.443v-16.248a.442.442 0 0 0-.443-.443Z" />
			<path d="M220.594 327.928v1.451h-28.098v-1.451h28.098Zm.722-.929h-29.542a.207.207 0 0 0-.207.206v2.893c0 .116.094.207.207.207h29.542a.207.207 0 0 0 .207-.207v-2.893a.207.207 0 0 0-.207-.206Zm-22.781-25.848a.83.83 0 0 1 .828.828v8.728h-1.652v-8.728a.83.83 0 0 1 .828-.828m0-.82c-.911 0-1.648.737-1.648 1.648v9.552h3.295v-9.552c0-.911-.736-1.648-1.647-1.648Z" />
			<path d="M198.535 309.76c-3.357 0-3.357-.262-3.357-.628 0-.439.348-.541 2.072-.603l.015.403c.366.015.791.022 1.27.022.657 0 1.212-.015 1.648-.04l.018-.37c1.227.062 1.687.13 1.687.588 0 .363 0 .628-3.357.628h.004Zm0-2.011c-3.357 0-3.357-.261-3.357-.628 0-.439.348-.541 2.072-.606l.015.403c.366.014.791.022 1.27.022.657 0 1.212-.015 1.648-.04l.018-.37c1.227.061 1.687.13 1.687.588 0 .362 0 .627-3.357.627l.004.004Zm0-2.011c-3.357 0-3.357-.261-3.357-.627 0-.44.348-.541 2.072-.607l.015.403c.366.015.791.022 1.27.022.657 0 1.212-.014 1.648-.04l.018-.37c1.227.062 1.687.131 1.687.588 0 .363 0 .628-3.357.628l.004.003Zm0-2.014c-3.357 0-3.357-.261-3.357-.628 0-.439.348-.54 2.072-.606l.015.403c.366.015.791.022 1.27.022.657 0 1.212-.015 1.648-.04l.018-.37c1.227.061 1.687.13 1.687.588 0 .363 0 .628-3.357.628l.004.003Zm8.064-2.573a.83.83 0 0 1 .828.828v8.728h-1.651v-8.728a.83.83 0 0 1 .827-.828m0-.82c-.911 0-1.648.737-1.648 1.648v9.552h3.296v-9.552c0-.911-.737-1.648-1.648-1.648Z" />
			<path d="M206.599 309.76c-3.357 0-3.357-.262-3.357-.628 0-.439.349-.541 2.072-.603l.015.403c.367.015.791.022 1.27.022.657 0 1.212-.015 1.648-.04l.018-.37c1.227.062 1.688.13 1.688.588 0 .363 0 .628-3.357.628h.003Zm0-2.011c-3.357 0-3.357-.261-3.357-.628 0-.439.349-.541 2.072-.606l.015.403a33.6 33.6 0 0 0 1.27.022c.657 0 1.212-.015 1.648-.04l.018-.37c1.227.061 1.688.13 1.688.588 0 .362 0 .627-3.357.627l.003.004Zm0-2.011c-3.357 0-3.357-.261-3.357-.627 0-.44.349-.541 2.072-.607l.015.403c.367.015.791.022 1.27.022.657 0 1.212-.014 1.648-.04l.018-.37c1.227.062 1.688.131 1.688.588 0 .363 0 .628-3.357.628l.003.003Zm0-2.014c-3.357 0-3.357-.261-3.357-.628 0-.439.349-.54 2.072-.606l.015.403c.367.015.791.022 1.27.022.657 0 1.212-.015 1.648-.04l.018-.37c1.227.061 1.688.13 1.688.588 0 .363 0 .628-3.357.628l.003.003Zm7.956-2.573a.83.83 0 0 1 .827.828v8.728h-1.651v-8.728a.83.83 0 0 1 .827-.828m0-.82c-.911 0-1.647.737-1.647 1.648v9.552h3.295v-9.552c0-.911-.737-1.648-1.648-1.648Z" />
			<path d="M214.555 309.76c-3.357 0-3.357-.262-3.357-.628 0-.439.348-.541 2.072-.603l.014.403c.367.015.792.022 1.271.022.656 0 1.212-.015 1.647-.04l.018-.37c1.227.062 1.688.13 1.688.588 0 .363 0 .628-3.357.628h.004Zm0-2.011c-3.357 0-3.357-.261-3.357-.628 0-.439.348-.541 2.072-.606l.014.403c.367.014.792.022 1.271.022.656 0 1.212-.015 1.647-.04l.018-.37c1.227.061 1.688.13 1.688.588 0 .362 0 .627-3.357.627l.004.004Zm0-2.011c-3.357 0-3.357-.261-3.357-.627 0-.44.348-.541 2.072-.607l.014.403c.367.015.792.022 1.271.022.656 0 1.212-.014 1.647-.04l.018-.37c1.227.062 1.688.131 1.688.588 0 .363 0 .628-3.357.628l.004.003Zm0-2.014c-3.357 0-3.357-.261-3.357-.628 0-.439.348-.54 2.072-.606l.014.403c.367.015.792.022 1.271.022.656 0 1.212-.015 1.647-.04l.018-.37c1.227.061 1.688.13 1.688.588 0 .363 0 .628-3.357.628l.004.003Z" />
			<path d="M206.545 339.846c-13.519 0-24.519-11-24.519-24.519 0-13.519 11-24.519 24.519-24.519 13.519 0 24.519 11 24.519 24.519 0 13.519-11 24.519-24.519 24.519Zm0-47.986c-12.942 0-23.467 10.525-23.467 23.467 0 12.942 10.529 23.467 23.467 23.467 12.938 0 23.467-10.529 23.467-23.467 0-12.938-10.529-23.467-23.467-23.467Z" />
		</g>
		<defs>
			<clipPath id="a">
				<path fill="#fff" d="M79 0h47.469v49H79z" />
			</clipPath>
			<clipPath id="b">
				<path fill="#fff" d="M182.026 222.154h49.038v49.038h-49.038z" />
			</clipPath>
			<clipPath id="c">
				<path fill="#fff" d="M77.936 76.936h49.038v49.038H77.936z" />
			</clipPath>
			<clipPath id="d">
				<path fill="#fff" d="M26 22h49v49H26z" />
			</clipPath>
			<clipPath id="e">
				<path fill="#fff" d="M1 77h49v49H1z" />
			</clipPath>
			<clipPath id="f">
				<path fill="#fff" d="M78 492h47.469v-49H78z" />
			</clipPath>
			<clipPath id="g">
				<path fill="#fff" d="M125.974 366.026H76.936v49.038h49.038z" />
			</clipPath>
			<clipPath id="h">
				<path fill="#fff" d="M25 470h49v-49H25z" />
			</clipPath>
			<clipPath id="i">
				<path fill="#fff" d="M0 415h49v-49H0z" />
			</clipPath>
			<clipPath id="j">
				<path fill="#fff" d="M336.385 492h-47.469v-49h47.469z" />
			</clipPath>
			<clipPath id="k">
				<path fill="#fff" d="M288.411 366.026h49.038v49.038h-49.038z" />
			</clipPath>
			<clipPath id="l">
				<path fill="#fff" d="M389.385 470h-49v-49h49z" />
			</clipPath>
			<clipPath id="m">
				<path fill="#fff" d="M414.385 415h-49v-49h49z" />
			</clipPath>
			<clipPath id="n">
				<path fill="#fff" d="M79 0h47.469v49H79z" />
			</clipPath>
			<clipPath id="o">
				<path fill="#fff" d="M77.936 76.936h49.038v49.038H77.936z" />
			</clipPath>
			<clipPath id="p">
				<path fill="#fff" d="M26 22h49v49H26z" />
			</clipPath>
			<clipPath id="q">
				<path fill="#fff" d="M1 77h49v49H1z" />
			</clipPath>
			<clipPath id="r">
				<path fill="#fff" d="M334.469 0H287v49h47.469z" />
			</clipPath>
			<clipPath id="s">
				<path fill="#fff" d="M335.449 76.936h-49.038v49.038h49.038z" />
			</clipPath>
			<clipPath id="t">
				<path fill="#fff" d="M387.385 22h-49v49h49z" />
			</clipPath>
			<clipPath id="u">
				<path fill="#fff" d="M412.385 77h-49v49h49z" />
			</clipPath>
			<clipPath id="v">
				<path fill="#fff" d="M182.026 153.5h49.038v49.038h-49.038z" />
			</clipPath>
			<clipPath id="w">
				<path fill="#fff" d="M182.026 290.808h49.038v49.038h-49.038z" />
			</clipPath>
		</defs>
	</svg>
);
