import { useOkta } from "@/app/hooks/use-okta";
import { useUserProfileQuery } from "@/app/types/generated/graphql";

import { CescLogo, EndeavourEnergyLogo, TataPowerLogo } from "../logos";
import { UsePageHeaderType } from "./page-headert.types";

export const getClientLogo = (client: string | null) => {
	if (client === "endeavour") {
		return <EndeavourEnergyLogo height={54} />;
	} else if (client === "tata") {
		return <TataPowerLogo height={54} />;
	} else if (client === "cesc") {
		return <CescLogo height={54} />;
	}

	return null;
};

const usePageHeader: UsePageHeaderType = () => {
	const { identityProviderRef } = useOkta();
	const { data } = useUserProfileQuery({
		variables: { identityProviderRef },
	});

	const demoClient = localStorage.getItem("demoClient");
	const clientLogo = getClientLogo(demoClient);

	return {
		clientLogo,
		userProfile: data?.userProfile,
	};
};

export { usePageHeader };
