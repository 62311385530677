import {
	Box,
	Button,
	DatePickerCustomInputProps,
	Flex,
	IconCalendar,
	IconLoading,
	Tabs,
	Text,
	useTheme,
} from "@powerledger/ui-component-lib";
import { format } from "date-fns";
import { FC, forwardRef, lazy, Suspense, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Redirect, Route, Switch } from "react-router";

import { LemDatePicker } from "@/app/components/lem-date-picker";
import { FullContainerLoader } from "@/app/components/lem-loading";

import { UserAlert } from "./components/user-alert/user-alert";
import { DashboardViewProps } from "./dashboard.types";
import { isDemoView } from "./demo-stubbed-data";
// JIRA-1160 -> To remove FE Demo Stubbed Data
import { Overview } from "./tabs";

const Network = lazy(() => import("./tabs/network-tab/network"));

const DateInput = forwardRef(({ onClick, value: date }: DatePickerCustomInputProps, ref) => (
	<Button
		onClick={onClick}
		sx={{
			display: "flex",
			alignItems: "center",
			justifyContent: "space-around",
			backgroundColor: "input",
			borderRadius: 4,
			height: 7,
		}}
		data-testid="dashboard--date-input"
	>
		<Text color="text" mr={3}>
			{date}
		</Text>
		<IconCalendar width={6} height={6} color="text" />
	</Button>
));

export const DashboardView: FC<DashboardViewProps> = ({
	userIsAdmin,
	tradeSummaryData,
	tradeComparisonSummaryData,
	switchTab,
	currentTab,
	start,
	end,
	date,
	setDate,
	showUserAlert,
	showBidAlert,
	isLoading,
	demoDataIndex,
	setDemoDataIndex,
	dateRange,
}) => {
	const { t } = useTranslation();
	const {
		theme: { layouts },
	}: { theme: any } = useTheme();

	const headerHeight = layouts.layoutHeader.height;

	const tabs = useMemo(() => {
		return userIsAdmin
			? [
					{
						title: t("Overview"),
						key: "/dashboard",
						component: (
							<Overview
								userIsAdmin={userIsAdmin}
								tradeSummaryData={tradeSummaryData}
								demoDataSelection={demoDataIndex}
							/>
						),
					},
					{
						title: t("Network"),
						key: "/dashboard/network",
						component: (
							<Network tradeComparisonSummaryData={tradeComparisonSummaryData} demoDataSelection={demoDataIndex} />
						),
					},
			  ]
			: [
					{
						title: t("Overview"),
						key: "/dashboard",
						component: (
							<Overview
								userIsAdmin={userIsAdmin}
								tradeSummaryData={tradeSummaryData}
								demoDataSelection={demoDataIndex}
							/>
						),
					},
			  ];
	}, [t, userIsAdmin, tradeSummaryData, tradeComparisonSummaryData, demoDataIndex]);

	return (
		<Flex
			sx={{
				flexDirection: "column",
			}}
		>
			<Flex
				sx={{
					flexDirection: "row",
					width: "100%",
					borderBottom: "1px solid",
					borderBottomColor: "foregroundLight",
					px: 4,
					pt: 4,
					justifyContent: "space-between",
				}}
			>
				<Tabs
					activeKey={currentTab}
					onChange={switchTab}
					activeStyles={{ backgroundColor: "primary" }}
					sx={{
						maxHeight: "inherit",
						flexGrow: 1,
						position: "relative",
						mr: 3,
					}}
				>
					{tabs.map(({ title, key }) => (
						<Tabs.TabPane
							tab={
								<Flex
									data-testid={`dashboard--${title}`}
									sx={{
										color: key === currentTab ? "background" : "text",
									}}
								>
									{title}
								</Flex>
							}
							key={key}
						/>
					))}
				</Tabs>

				<Box sx={{ width: "min-width" }}>
					<LemDatePicker
						id={"dashboard-date"}
						// JIRA-1160 -> To remove FE Demo Stubbed Data
						onChange={(date: Date) => {
							setDate(date);
							setDemoDataIndex(dateRange.indexOf(format(Date.parse(date?.toString() || ""), "yyyy-MM-dd")));
						}}
						{...(isDemoView() && { minDate: start, maxDate: end })}
						value={date ? date.toString() : undefined}
						customButtonComponent={<DateInput />}
						maxDate={new Date()}
					/>
				</Box>
			</Flex>
			{showUserAlert && (
				<Box sx={{ pt: 4, px: 4, width: "min-content" }}>
					<UserAlert
						message={t("You currently have no onboarded participants. Click here to invite users.")}
						link={"/admin"}
						linkState={{ openInvite: true }}
					/>
				</Box>
			)}
			{showBidAlert && (
				<Box sx={{ pt: 4, px: 4 }}>
					<UserAlert message={t("You have not setup your bid. Click here to set your bid.")} link={"/bids"} />
				</Box>
			)}
			{isLoading ? (
				<IconLoading sx={{ margin: "auto" }} />
			) : (
				<Flex
					sx={{
						pt: 4,
						px: 4,
						flexGrow: 1,
						height: "100%",
					}}
				>
					<Switch>
						<Suspense fallback={<FullContainerLoader />}>
							{tabs.map(({ key, component }) => (
								<Route exact key={key} path={key}>
									{component}
								</Route>
							))}
						</Suspense>
						<Route path={"*"}>
							<Redirect to={"/not-found"} />
						</Route>
					</Switch>
				</Flex>
			)}
		</Flex>
	);
};
