import { Modal } from "@powerledger/ui-component-lib";
import { FC } from "react";

import { DeleteBaselineProfileModalProps } from "./delete-baseline-profile-modal.types";
import { DeleteBaselineProfileView } from "./delete-baseline-profile-view";
import { useDeleteBaselineProfile } from "./use-delete-baseline-profile";

/**
 * Renders a confirmation modal for deleting the baseline profile.
 *
 * @param showModal - Boolean field to either hide or display the modal
 * @param onCancel - The callback to handle cancel action.
 * @param onConfirm - The callback to handle confirmation action.
 * @param baselineProfileId - The ID of the baseline profile to delete.
 *
 * @returns The React component that renders the modal.
 */
export const DeleteBaselineProfileModal: FC<DeleteBaselineProfileModalProps> = ({
	showModal,
	onCancel,
	onConfirm,
	baselineProfileId,
}) => {
	const logic = useDeleteBaselineProfile({ onConfirm, baselineProfileId });

	return (
		<Modal visible={showModal} hideCloseButton>
			<DeleteBaselineProfileView {...logic} onCancel={onCancel} />
		</Modal>
	);
};
