import { useCallback, useState } from "react";
import { useHistory } from "react-router";

import { Profile } from "@/app/types/generated/graphql";

import { UseBaselineForecastProfileType } from "./baseline-forecast-profile.types";
import { convertProfileDataToFormData } from "./helpers";

/**
 * A hook that returns an object with various functions and state variables
 * related to the baseline forecast profile feature.
 *
 * @return An object with the following properties:
 * - `shouldShowCreateEditProfileModal`: A state variable indicating whether the create/edit profile modal should be shown.
 * - `editMode`: A state variable indicating whether the profile is in edit mode.
 * - `onEditProfile`: A function to edit a specific profile.
 * - `initialValues`: The initial form values for the selected profile.
 * - `showCreateEditMeterProfileModal`: A function to show the create/edit profile modal.
 * - `hideCreateEditMeterProfileModal`: A function to hide the create/edit profile modal.
 * - `goBack`: A function to navigate back to the previous page.
 */
export const useBaselineForecastProfile: UseBaselineForecastProfileType = () => {
	// Add a new profile create form modal
	const [shouldShowCreateEditProfileModal, setShouldShowCreateEditProfileModal] = useState(false);
	const [selectedProfileData, setSelectedProfileData] = useState<Profile>();
	const history = useHistory();

	const onEditProfile = useCallback((profile: Profile) => {
		setSelectedProfileData(profile);
		setShouldShowCreateEditProfileModal(true);
	}, []);

	const showCreateEditMeterProfileModal = useCallback(() => {
		setShouldShowCreateEditProfileModal(true);
	}, []);

	const hideCreateEditMeterProfileModal = useCallback(() => {
		setSelectedProfileData(undefined);
		setShouldShowCreateEditProfileModal(false);
	}, []);

	const goBack = useCallback(() => {
		history.goBack();
	}, [history]);

	return {
		shouldShowCreateEditProfileModal,
		editMode: !!selectedProfileData,
		onEditProfile,
		initialValues: convertProfileDataToFormData(selectedProfileData),
		showCreateEditMeterProfileModal,
		hideCreateEditMeterProfileModal,
		goBack,
	};
};
