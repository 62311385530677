import { useApolloClient } from "@apollo/client";
import { Box, Button, Flex, IconLoading } from "@powerledger/ui-component-lib";
import { FC, useCallback, useEffect, useState } from "react";
import { useBeforeunload } from "react-beforeunload";
import { useTranslation } from "react-i18next";
import { Prompt } from "react-router";
import { useHistory, useLocation } from "react-router-dom";

import { LemLogo } from "@/app/components";

import { useOkta } from "../../../hooks";
import { obtainStateToken } from "../../../services/OktaApi";
import { UserRole, UserStatus, useUserProfileQuery } from "../../../types/generated/graphql";
import { oktaLogout } from "../../../util";
import { AccountDetails } from "./account-details";
import { InvalidTokenError } from "./invalid-token-error";
import { SetPassword } from "./set-password";
import { SignupStepper } from "./stepper";
import { Welcome } from "./welcome";

const backgroundIllustration = "url(/background-illustration.svg)";

export type OktaTokenType = {
	stateToken: string;
	userId: string;
	email: string;
};

// We need to set the current step based off of our UserProfile.UserStatus
const getStepFromUserStatus = (userStatus: UserStatus) => {
	switch (userStatus) {
		case UserStatus.Verified:
			return 2;
		case UserStatus.Unverified:
			return 1;
		default:
			return 0;
	}
};

function useObtainOktaViaTokenParam(tokenValue: string, currentStep: number, triggerOktaObtain: boolean) {
	const [oktaToken, setOktaToken] = useState<OktaTokenType>({
		stateToken: "",
		userId: "",
		email: "",
	});

	const [loadingOktaToken, setLoadingOktaToken] = useState(true);

	const [oktaTokenError, setOktaTokenError] = useState(false);

	useEffect(() => {
		if (tokenValue && currentStep === 0 && triggerOktaObtain) {
			obtainStateToken({
				token: tokenValue,
			})
				.then(({ body }) => {
					setOktaToken({
						stateToken: body.stateToken,
						userId: body._embedded.user.id,
						email: body._embedded.user.profile.login,
					});
				})
				.catch((e) => {
					// TODO: Replace with client-side error handler (e.g. Sentry) when available
					if (process.env.NODE_ENV === "development") {
						console.error(e);
					}
					setOktaTokenError(true);
				})
				.finally(() => {
					setLoadingOktaToken(false);
				});
		}
		if (!tokenValue) {
			setLoadingOktaToken(false);
		}
	}, [triggerOktaObtain, tokenValue, currentStep]);

	return { oktaToken, loadingOktaToken, oktaTokenError };
}

export const SignupPage: FC = () => {
	const history = useHistory();
	const [_, setTryingToLogin] = useState(false);

	useBeforeunload((event) => {
		if (shouldNotLeave) event.preventDefault();
	});
	const { t } = useTranslation();
	const location = useLocation<any>();

	const { identityProviderRef, authState, oktaAuth } = useOkta();

	const apolloClient = useApolloClient();

	const [triggered, setTriggered] = useState(false);

	const [currentStep, setCurrentStep] = useState(0);

	const userProfileQueryResponse = useUserProfileQuery({
		variables: {
			identityProviderRef,
		},
		skip: !identityProviderRef,
		onCompleted: (data) => {
			if (data.userProfile.status) {
				// Handle a redirection here if someone tries to go through the onboarding process again.
				// If the profile status is === "ACTIVE", then they have completed onboarding.
				if (data.userProfile.status === UserStatus.Active) {
					if (data.userProfile.roles?.includes(UserRole.ParticipantUser)) {
						history.push("/dashboard");
					} else {
						// When a non-participant user completes onboarding for the first time
						// send them to the participant-management/admin screen.
						history.push("/admin");
					}
				} else {
					setCurrentStep(getStepFromUserStatus(data?.userProfile?.status));
				}
			}
		},
		onError: (error: Error) => {
			// TODO: Sentry
			console.error(error);
			setTriggered(true);
		},
	});

	const navigate = useCallback(
		(url: string) => {
			history.push(url);
		},
		[history],
	);

	const goToNextStep = () => setCurrentStep(currentStep + 1);

	const [tokenValue] = useState(() => new URLSearchParams(location.search).get("token") || "");

	// If we have a token, we need to setTriggered to true
	// This will trigger the useObtainOktaViaTokenParam hook to run
	useEffect(() => {
		if (tokenValue && !triggered) {
			setTriggered(true);
		}
	}, [tokenValue, triggered]);

	const { oktaToken, loadingOktaToken, oktaTokenError } = useObtainOktaViaTokenParam(
		tokenValue,
		currentStep,
		triggered,
	);

	if (userProfileQueryResponse.loading || loadingOktaToken) {
		return (
			<Flex
				sx={{
					alignItems: "center",
					justifyContent: "center",
					height: "100vh",
					width: "100vw",
				}}
			>
				<IconLoading />
			</Flex>
		);
	}

	const steps = [
		{
			icon: "LockDotted",
			title: t("Set Password"),
			component: <SetPassword oktaToken={oktaToken} goToNextStep={goToNextStep} />,
		},
		{
			icon: "SingleUser",
			title: t("Profile"),
			component: <AccountDetails goToNextStep={goToNextStep} />,
		},
		{
			icon: "Handshake",
			title: t("Welcome"),
			component: <Welcome />,
		},
	];

	const shouldNotLeave = currentStep === 0 || currentStep !== steps.length - 1;

	const currentStepComponent = steps?.[currentStep]?.component || null;

	const handleLoginIfHasAccountLink = () => {
		setTryingToLogin(true);
		oktaLogout({
			oktaAuth,
			apolloClient,
		});
	};

	// We should show an error if we had a token parameter, and the ObtainOktaToken failed
	// We should show an error if we had no token to begin with AND we were unable to query for user data
	const showError =
		oktaTokenError || ((userProfileQueryResponse.error || !userProfileQueryResponse.data) && !tokenValue);

	return (
		<>
			<Prompt
				when={shouldNotLeave}
				message={t("If you leave this page, you must login through the homepage to complete the remaining steps")}
			/>
			<Flex
				sx={{
					width: "100%",
					minHeight: "100vh",
					background: backgroundIllustration,
					backgroundRepeat: "no-repeat",
					backgroundPosition: "bottom right",
					display: "flex",
					backgroundSize: ["auto", null, null, null, "50%"],
					pb: 5,
				}}
			>
				<Flex
					sx={{
						width: "25%",
						flexDirection: "column",
						justifyContent: "space-between",
						alignItems: "flex-start",
					}}
				>
					<Box sx={{ pt: 3, px: [3, null, 5] }}>
						<Box sx={{ pb: [4, null, 5] }}>
							<Box onClick={() => navigate("/")} sx={{ cursor: "pointer" }}>
								<LemLogo width={130} />
							</Box>
						</Box>
						<SignupStepper steps={steps} currentStep={currentStep} />
					</Box>
				</Flex>
				<Flex sx={{ flexDirection: "column", width: ["75%"] }}>
					<Flex
						sx={{
							px: 4,
							py: 3,
							justifyContent: "flex-end",
						}}
					>
						{!authState?.isAuthenticated ? (
							<>
								<Box
									sx={{
										color: "textDarker",
										fontSize: 0,
										fontFamily: "MintGroteskV08",
									}}
								>
									{t("Already a participant?")}
								</Box>
								<Button variant="secondary" sx={{ fontSize: 0, ml: 3 }} onClick={handleLoginIfHasAccountLink}>
									{t("Log in to existing account")}
								</Button>
							</>
						) : (
							<Button
								variant="secondary"
								sx={{ fontSize: 0, ml: 3 }}
								onClick={() => oktaLogout({ oktaAuth, apolloClient })}
							>
								{t("Log out")}
							</Button>
						)}
					</Flex>
					<Box
						sx={{
							pt: [4, null, 5],
							pl: [4, null, 5],
							pr: [4, null, 0],
							flexGrow: 1,
						}}
					>
						{showError ? <InvalidTokenError location={location} /> : currentStepComponent}
					</Box>
				</Flex>
			</Flex>
		</>
	);
};
