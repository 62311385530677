import { Button, Flex, LegacySelect, Option, PencilSVG, Text } from "@powerledger/ui-component-lib";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Row } from "react-table";

import { StatusBox } from "@/app/components/status-box";
import { ProfileClass } from "@/app/types/generated/graphql";
import { camelCaseToTitleCase, getDefaultSelectTranslation } from "@/app/util";

import {
	MeterProfileAssignmentStatus,
	MeterProfileAssignmentStatusColorCode,
	MeterProfileType,
} from "../meter-profile.types";
import { getEditMeterProfileClassOptions } from "../meter-profile-helper";

/**
 * Renders an editable option with a label and icon.
 *
 * @param id The id of the option to be used with tooltip.
 * @param label The label to be displayed.
 * @param allowInteraction Whether to allow interaction with tooltip and display edit icon.
 * @param status The meter profile assignment status.
 * @param onEdit The function to be called when the edit icon is clicked.
 * @return The EditOption component.
 */
const EditOption = ({
	id,
	label,
	onEdit,
	allowInteraction = true,
	status,
}: {
	id: string;
	label: string;
	allowInteraction?: boolean;
	status?: MeterProfileAssignmentStatus;
	onEdit: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}) => {
	const { t } = useTranslation();
	return (
		<Flex
			sx={{
				justifyContent: "flex-start",
				alignItems: "center",
				gap: "8px",
				height: 30,

				// padding in left = status box default size + original padding,
				// so that when there is no status box, the label don't shift to the left
				...(!status && { pl: "calc(0.625rem + 8px)" }),
			}}
		>
			{status && (
				<StatusBox
					id={id}
					color={MeterProfileAssignmentStatusColorCode[status]}
					tooltipContent={t(camelCaseToTitleCase(status))}
					allowTooltip={allowInteraction}
				/>
			)}
			<Text>{t(label)}</Text>
			{allowInteraction && (
				<Button sx={{ border: "none", p: 0 }} variant="secondary" onClick={onEdit} aria-label={`Edit Profile - ${id}`}>
					<PencilSVG width={20} height={20} />
				</Button>
			)}
		</Flex>
	);
};

/**
 * Renders a row item for editing a meter and assigning it to some profile.
 *
 * @param row The row that the component is rendering.
 * @param onEdit Callback function when the user selects to edit based on selected option.
 * @return The row item for editing a meter profile
 */
export const EditMeterProfileRowItem = ({
	row,
	onEdit,
}: {
	row: Row<MeterProfileType>;
	onEdit: (selectedProfileClass: ProfileClass) => void;
}) => {
	const editOptions = getEditMeterProfileClassOptions(row.original.participantType);
	const [selectedOption, setSelectedOption] = useState<Option>(editOptions[0]);
	return editOptions.length ? (
		<Flex
			sx={{
				justifyContent: "center",
				height: 40,
			}}
		>
			{editOptions.length > 1 ? (
				<Flex sx={{ justifyContent: "flex-start", alignItems: "center", width: 240 }}>
					<LegacySelect
						sx={{
							width: "100%",
						}}
						value={selectedOption}
						openMenuOnClick={false}
						isSearchable={false}
						options={editOptions}
						// Renders a custom defined component for the option
						formatOptionLabel={(option) => {
							return (
								<EditOption
									id={row.original.id}
									label={option.label}
									status={row.original.meterProfileStatus}
									onEdit={() => onEdit(selectedOption.value)}
									allowInteraction={option.value === selectedOption.value}
								/>
							);
						}}
						onChange={(option) => setSelectedOption(option as Option)}
						translation={getDefaultSelectTranslation()}
						// Control input component's styling - refer to getStyles in shared lib to know how it works
						inputStyles={{
							background: "transparent",
							borderColor: "transparent",
						}}
					/>
				</Flex>
			) : (
				<Flex sx={{ justifyContent: "flex-start", alignItems: "center", width: 240, pl: "10px" }}>
					<EditOption
						id={row.original.id}
						label={editOptions[0]?.label}
						status={row.original.meterProfileStatus}
						onEdit={() => onEdit(selectedOption.value)}
					/>
				</Flex>
			)}
		</Flex>
	) : null;
};
