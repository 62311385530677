import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { notifications } from "@/app/container/notifications";
import { useUserProfile } from "@/app/hooks/use-user-profile";
import { useProfileTemplatesQuery } from "@/app/types/generated/graphql";

import { UseProfileOverviewType } from "./profile-overview-table.types";

/**
 * Returns an object containing functions for creating, editing, toggling default status, and deleting meter profiles.
 *
 * @return An object containing the following functions:
 * - `profileTemplates`: An array of baseline profile objects
 * - `loading`: A boolean indicating whether or not the data is loading
 * - `onDeleteProfile`: Deletes a meter profile from the associated meter
 * - `selectedBaselineProfileId`: Sets the selected baseline profile id
 * - `deleteBaselineProfileModalVisible`: Shows or hides the delete baseline profile modal
 * - `hideDeleteBaselineProfileModal`: Hides the delete baseline profile modal
 */
export const useProfileOverview: UseProfileOverviewType = () => {
	const { t } = useTranslation();
	const [deleteBaselineProfileModalVisible, setDeleteBaselineProfileModalVisible] = useState(false);
	const [selectedBaselineProfileId, setSelectedBaselineProfileId] = useState<string>();

	const { userProfile, loading: userProfileLoading } = useUserProfile();
	const {
		data,
		loading: baselineProfilesLoading,
		refetch: refetchProfileTemplates,
	} = useProfileTemplatesQuery({
		variables: {
			where: {
				applicationHostId: userProfile?.applicationHostId ?? "",
				tenantId: userProfile?.tenantId ?? "",
				tradingGroupId: userProfile?.tradingGroupId ?? "",
			},
		},
		skip: userProfileLoading,
		onError: () => {
			notifications.error({
				message: t("Failed to fetch the list of baseline profiles"),
			});
		},
		fetchPolicy: "cache-and-network",
	});

	const showDeleteBaselineProfileModal = useCallback(() => setDeleteBaselineProfileModalVisible(true), []);
	const hideDeleteBaselineProfileModal = useCallback(() => {
		setSelectedBaselineProfileId(undefined);
		setDeleteBaselineProfileModalVisible(false);
	}, []);

	const onDeleteProfile = useCallback(
		(id: string) => {
			setSelectedBaselineProfileId(id);
			showDeleteBaselineProfileModal();
		},
		[showDeleteBaselineProfileModal],
	);

	return {
		profileTemplates: data?.profileTemplates || [],
		loading: userProfileLoading || baselineProfilesLoading,
		refetchProfileTemplates,
		onDeleteProfile,
		selectedBaselineProfileId,
		deleteBaselineProfileModalVisible,
		hideDeleteBaselineProfileModal,
	};
};
