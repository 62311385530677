import "react-toastify/dist/ReactToastify.css";

import { createContext, ReactElement, useCallback, useMemo, useState } from "react";

import { ContactSupportModal } from "./modals/contact-support-modal";

interface ModalHandler {
	currentActiveModal?: string;
	showContactSupportModal: () => void;
}

const ModalContext = createContext({} as ModalHandler);

const ModalProvider = ({ children }: { children: ReactElement }) => {
	const [currentActiveModal, setCurrentActiveModal] = useState<string | undefined>("");
	const [, setDefaultTab] = useState<string | undefined>("");
	const [, setDefaultAsset] = useState<any>();

	const onSetShowModal = useCallback(
		({ activeModal, tabState, prefilledAsset }: { activeModal?: string; tabState?: string; prefilledAsset?: any }) => {
			setDefaultTab(tabState);
			setCurrentActiveModal(activeModal);
			setDefaultAsset(prefilledAsset);
		},
		[setDefaultTab, setCurrentActiveModal],
	);

	const modalHandlers = {
		currentActiveModal,
		showContactSupportModal: () => onSetShowModal({ activeModal: "contactSupportModal" }),
	};

	const renderedModal = useMemo(() => {
		switch (currentActiveModal) {
			case "contactSupportModal":
				return (
					<ContactSupportModal
						visible={currentActiveModal === "contactSupportModal"}
						closeModal={() => onSetShowModal({})}
					/>
				);
			default:
				return null;
		}
	}, [currentActiveModal, onSetShowModal]);

	return (
		<ModalContext.Provider value={modalHandlers}>
			{renderedModal}
			{children}
		</ModalContext.Provider>
	);
};

export { ModalContext, ModalProvider };
