import { FC } from "react";

import { AssignedMeterProfilesProps } from "./assigned-meter-profiles-table.types";
import { AssignedMeterProfilesTableView } from "./assigned-meter-profiles-table-view";
import { useAssignedMeterProfilesTable } from "./use-assigned-meter-profiles-table";

export const AssignedMeterProfilesTable: FC<AssignedMeterProfilesProps> = ({ selectedMeterId }) => {
	const logic = useAssignedMeterProfilesTable({ selectedMeterId });
	return <AssignedMeterProfilesTableView {...logic} selectedMeterId={selectedMeterId} />;
};
