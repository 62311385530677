import { ActionButton, IconCalendar, Text } from "@powerledger/ui-component-lib";
import { pdf } from "@react-pdf/renderer";
import FileSaver from "file-saver";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { PdfExportType } from "./";
import { TableDocument } from "./pdf-file/pdf-document";

export const PdfExport: FC<PdfExportType> = ({ selectedAccount, startDate }) => {
	const { t } = useTranslation();
	const MyDocument = useMemo(() => {
		return <TableDocument selectedAccount={selectedAccount} startDate={startDate} />;
	}, [selectedAccount, startDate]);
	const disabled = selectedAccount.bills.length === 0;
	const handleClick = async () => {
		pdf(MyDocument)
			.toBlob()
			.then((e) => {
				FileSaver.saveAs(e, "statement_of_use.pdf");
			});
	};
	return (
		<ActionButton
			icon={<IconCalendar size={5} color={!disabled ? "primary" : "input"} />}
			iconOnRight
			iconVariant=""
			disabled={disabled}
			sx={{ p: 0, fontSize: "small", color: !disabled ? "primary" : "input" }}
			onClick={handleClick}
		>
			<Text as="p">{t("Export\u00A0PDF")}</Text>
		</ActionButton>
	);
};
