import { Modal } from "@powerledger/ui-component-lib";
import { FC } from "react";

import { ConfirmationModalPropType } from "./confirmation-modal.types";
import { ConfirmationModalView } from "./confirmation-modal-view";

/**
 * Renders a confirmation modal for triggered action.
 *
 * @param confirmationTitle - The text to be displayed as the confirmation modal title.
 * @param description - The text to be displayed as the confirmation modal description.
 * @param showModal - Determines whether the modal should be shown or hidden.
 * @param disabled - Determines whether the buttons in the modal should be disabled.
 * @param errorMessage - The error message to be displayed in the modal.
 * @param confirmationButtonText - The confirmation text to be displayed in the modal.
 * @param onConfirm - The callback function to be called when the confirm button is clicked.
 * @param onCancel - The callback function to be called when the cancel button is clicked.
 * @returns The confirmation modal component.
 */
export const ConfirmationModal: FC<ConfirmationModalPropType> = ({ showModal, onCancel, ...rest }) => {
	return (
		<Modal visible={showModal} hideCloseButton onCancel={onCancel}>
			<ConfirmationModalView onCancel={onCancel} {...rest} />
		</Modal>
	);
};
