import { Box, Flex, Link } from "@powerledger/ui-component-lib";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";

import { PRIVACY_POLICY_URL, TERMS_OF_ACCESS_URL } from "../../../../lib/endpoints";
import { LemLogo } from "../../../logos/lem-logo";
import { useUserRole } from "../../use-user-role";
import { SidebarLinks } from "./sidebar-links";

export const Sidebar = () => {
	const { userRoles } = useUserRole();

	return (
		<Flex
			as={"aside"}
			sx={{
				flexDirection: "column",
				height: "100%",
				borderRight: "1px solid",
				borderRightColor: "foregroundLight",
			}}
		>
			<SidebarLogo logo={<LemLogo width={130} />} />
			<Box as={"nav"}>{userRoles && <SidebarLinks roles={userRoles} />}</Box>
			<SidebarCopyright />
		</Flex>
	);
};

const SidebarLogo = ({ logo }: { logo: JSX.Element }) => {
	if (!logo) return null;
	return (
		<Flex
			sx={{
				flexDirection: "row",
				height: 80,
				alignItems: "center",
				textAlign: "center",
				px: 4,
			}}
		>
			<Box
				sx={{
					width: "100%",
					mr: 2,
					mt: 4,
					pb: 4,
					display: "flex",
					justifyContent: "flex-start",
					alignItems: "center",
				}}
			>
				<RouterLink to={"/dashboard"}>{logo}</RouterLink>
			</Box>
		</Flex>
	);
};

const LEM_SUPPORT_EMAIL = "support+lemui@powerledger.io";

/**
 * Sidebar copyright and attribution
 */
const SidebarCopyright = () => {
	const { t } = useTranslation();
	return (
		<Box sx={{ p: 2, mt: "auto" }}>
			<Box
				sx={{
					fontSize: 1,
					color: "text",
					mt: 4,
					marginLeft: 1,
					display: "flex",
					flexDirection: "column",
					p: 3,
				}}
			>
				<Link
					href={TERMS_OF_ACCESS_URL}
					target="_blank"
					sx={{ color: "text", textDecoration: "none", "&:hover": { textDecoration: "underline" } }}
				>
					{t("Terms of Service")}
				</Link>
				<Box sx={{ mt: 2 }}>
					<Link
						href={PRIVACY_POLICY_URL}
						target="_blank"
						sx={{
							color: "text",
							textDecoration: "none",
							mr: 3,
							"&:hover": { textDecoration: "underline" },
						}}
					>
						{t("Privacy Policy")}
					</Link>
					<Link
						href={`mailto:${LEM_SUPPORT_EMAIL}`}
						target="_blank"
						sx={{
							color: "text",
							textDecoration: "none",
							"&:hover": { textDecoration: "underline" },
						}}
					>
						{t("Contact Us")}
					</Link>
				</Box>
				<Box sx={{ color: "textDarker", fontSize: 0, mt: 2 }}>
					{t(`©${new Date().getFullYear()} Powerledger. All rights reserved`)}
				</Box>
			</Box>
		</Box>
	);
};
