import { useOktaAuth } from "@okta/okta-react";
import { useMemo } from "react";

export const useOkta = () => {
	const useOktaAuthProps = useOktaAuth();

	const { authState } = useOktaAuthProps;

	const identityProviderRef = useMemo(() => {
		return authState?.idToken?.claims?.sub;
	}, [authState?.idToken?.claims?.sub]);

	return {
		...useOktaAuthProps,
		/** Identifies the user making the GraphQL request */
		identityProviderRef: identityProviderRef || "",
	};
};
