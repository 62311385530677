import { Button, Flex } from "@powerledger/ui-component-lib";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import { Layout } from "@/app/components";

import { MeterProfile } from "./meter-profile/meter-profile";
import { MeterProfileVisualization } from "./meter-profile-visualization";

/**
 * This component renders the forecast profiles page.
 * - User account is associated with some meter and different meter profiles are associated with it.
 * - User can select one of the profiles to view and modify them
 * - User can also create a new profile.
 */
export const ForecastProfiles = () => {
	const { t } = useTranslation();
	const history = useHistory();

	return (
		<Layout sx={{ display: "flex" }} pageTitle="Forecast Profiles">
			<Flex sx={{ flexDirection: "column", flex: 1, p: 4 }}>
				<Flex sx={{ justifyContent: "flex-end", alignItems: "flex-end", height: "64px", mb: 4 }}>
					<Button
						as={"a"}
						variant="primary"
						sx={{ mr: 4, fontStyle: "italic" }}
						onClick={() => history.push("/forecast-profiles/baseline-forecast-profiles")}
					>
						{t("Upload New Baseline Profile")}
					</Button>
				</Flex>

				<Flex sx={{ flexDirection: "column", flexGrow: 1, gap: 4 }}>
					<MeterProfileVisualization />
					<MeterProfile />
				</Flex>
			</Flex>
		</Layout>
	);
};
