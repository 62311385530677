import {
	ActionButton,
	Box,
	Flex,
	getColor,
	Heading,
	IconCrossOutlined,
	IconDoubleTick,
	IconEmailV2,
	IconExportOutlined,
	IconLoading,
	IconLongArrow,
	IconPillButton,
	IconSingleTick,
	Table,
	Text,
	useTheme,
} from "@powerledger/ui-component-lib";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { Column, Row, UseSortByColumnOptions } from "react-table";
import ReactTooltip from "react-tooltip";

import { ResendInvitationModal } from "@/app/components/modals";

import { UserStatus } from "../../../types/generated/graphql";
import { getDefaultTableTranslation, userRoleOptions } from "../../../util";
import { ApolloCursorPagination } from "./apollo-cursor-pagination";
import { PAGE_SIZE } from "./constants";
import { useUserTable } from "./use-user-table";
import { UserDataRow } from "./user-table.types";

const activeActionStyles = {
	backgroundColor: "transparent",
	cursor: "pointer",
	"&:enabled:hover,&:enabled:focus, &:hover, &:focus": {
		textDecoration: "underline",
		backgroundColor: "transparent",
	},
};
const disabledActionStyles = {
	color: "input",
	cursor: "default",
	"&:enabled:hover,&:enabled:focus, &:hover, &:focus": {
		textDecoration: "none",
	},
};

export const UserTable = () => {
	const { t } = useTranslation();
	const { theme } = useTheme();
	const history = useHistory();
	const {
		selectedUserIDs,
		participantsTable,
		csvExport,
		disableResend,
		interactionDisabled,
		loadingProfile,
		loadingUsers,
		showConfirmResendInvite,
		showConfirmInvitationModal,
		hideConfirmInvitationModal,
		profileError,
		usersError,
		fetchMoreUsers,
		getSelectedRowIDs,
		paginationInfo,
	} = useUserTable();

	const tableColumns = useMemo<(Column<UserDataRow> & UseSortByColumnOptions<UserDataRow>)[]>(() => {
		const tooltipContent = (text: string) => {
			return (
				<Box sx={{ maxWidth: 300, whiteSpace: "pre-line" }}>
					<Text>{t(text)}</Text>
				</Box>
			);
		};
		return [
			{
				accessor: "participant",
				Header: t("Name") as string,
				width: "10%",
				Cell: ({ value }: { value: string }) => {
					return (
						<Box data-testid={`invite-users--${value}`} sx={{ color: "white" }}>
							{t(value || "-")}
						</Box>
					);
				},
			},
			{
				accessor: "email",
				Header: t("Email") as string,
				width: "15%",
				Cell: ({ value }: { value: string }) => {
					return (
						<Box data-testid={`invite-users--${value}`} sx={{ color: "white" }}>
							{t(value || "-")}
						</Box>
					);
				},
			},
			{
				accessor: "id",
				disableSortBy: true,
				width: "50%",
				Cell: () => {
					return <></>;
				},
			},
			{
				accessor: "role",
				Header: <Flex sx={{ justifyContent: "flex-end" }}>{t("Role")}</Flex>,
				width: "15%",
				Cell: ({ value }: { value: string }) => {
					const role = userRoleOptions.find((el) => value.includes(el.value));
					return (
						<Box data-testid={`invite-users--${role?.label}`} sx={{ color: "white" }}>
							{t(role?.label || "-")}
						</Box>
					);
				},
			},
			{
				accessor: "status",
				Header: t("Status") as string,
				width: "10%",
				disableSortBy: true,
				Cell: ({ row }: { row: Row<UserDataRow> }) => {
					return (
						<Box
							data-testid={`invite-users--${row.original.status}`}
							sx={{ display: "flex", justifyContent: "flex-start" }}
						>
							{row.original.status === UserStatus.Active && (
								<Box>
									<ReactTooltip
										id={`tooltip-${row.original.id}`}
										backgroundColor={getColor(theme, "secondaryDark")}
										getContent={() => tooltipContent("User has onboarded")}
									/>
									<IconDoubleTick width={40} color={"green"} data-tip data-for={`tooltip-${row.original.id}`} />
								</Box>
							)}
							{row.original.status === UserStatus.New && (
								<Box>
									<ReactTooltip
										id={`tooltip-${row.original.id}`}
										backgroundColor={getColor(theme, "secondaryDark")}
										getContent={() => tooltipContent("Invitation sent")}
									/>
									<IconEmailV2 width={40} color={"yellow"} data-tip data-for={`tooltip-${row.original.id}`} />
								</Box>
							)}
							{(row.original.status === UserStatus.Unverified || row.original.status === UserStatus.Verified) && (
								<Box>
									<ReactTooltip
										id={`tooltip-${row.original.id}`}
										backgroundColor={getColor(theme, "secondaryDark")}
										getContent={() => tooltipContent("User has accepted invite")}
									/>
									<IconSingleTick width={40} color={"green"} data-tip data-for={`tooltip-${row.original.id}`} />
								</Box>
							)}
							{row.original.status === UserStatus.Inactive && (
								<Box>
									<ReactTooltip
										id={`tooltip-${row.original.id}`}
										backgroundColor={getColor(theme, "secondaryDark")}
										getContent={() => tooltipContent("User has declined invite")}
									/>
									<IconCrossOutlined width={40} color={"red"} data-tip data-for={`tooltip-${row.original.id}`} />
								</Box>
							)}
						</Box>
					);
				},
			},
		];
	}, [t, theme]);

	return (
		<Box>
			<Flex sx={{ justifyContent: "space-between", alignItems: "flex-end", height: "64px", mb: 4 }}>
				<Heading as="h2" data-testid="user-table--heading">
					{t("Users")}
				</Heading>

				<IconPillButton
					onClick={() => history.push("/admin/invite")}
					variant="primary"
					icon={<IconLongArrow stroke="background" size={5} />}
				>
					{t("Invite users")}
				</IconPillButton>
			</Flex>
			{loadingProfile ? (
				<IconLoading />
			) : (
				<Table
					columns={tableColumns}
					dataSource={participantsTable}
					translation={getDefaultTableTranslation()}
					selectedColor={"inputDarker"}
					pagination={false}
					customPagination={
						<ApolloCursorPagination
							paginationInfo={paginationInfo}
							fetchMore={fetchMoreUsers}
							loading={loadingUsers}
							pageSize={PAGE_SIZE}
						/>
					}
					isMultiSelect
					noHover
					getSelected={getSelectedRowIDs}
					loading={loadingUsers}
					testId="invite-users--table"
				/>
			)}
			<Flex id="actions-section" sx={{ justifyContent: "space-between", pt: 3, alignItems: "center" }}>
				<Flex
					sx={{
						color: "primary",
						gap: 3,
					}}
				>
					<ActionButton
						disabled={interactionDisabled}
						onClick={csvExport}
						icon={<IconExportOutlined color={interactionDisabled ? "input" : "primary"} />}
						iconOnRight
						iconVariant=""
						data-testid="user-table--export-csv"
						sx={{
							alignItems: "center",
							...(interactionDisabled ? disabledActionStyles : activeActionStyles),
						}}
					>
						<Text>{t("Export to CSV")}</Text>
					</ActionButton>
					<ActionButton
						disabled={interactionDisabled || disableResend}
						onClick={showConfirmInvitationModal}
						icon={<IconEmailV2 color={interactionDisabled ? "input" : "primary"} />}
						iconOnRight
						iconVariant=""
						data-testid="user-table--resend-invite"
						sx={{
							alignItems: "center",
							...(interactionDisabled || disableResend ? disabledActionStyles : activeActionStyles),
						}}
					>
						<Text>{t("Resend invite(s)")}</Text>
					</ActionButton>
				</Flex>
			</Flex>
			{(profileError || usersError) && <Text sx={{ color: "warning" }}>{t("An error has occurred.")}</Text>}

			<ResendInvitationModal
				showModal={showConfirmResendInvite}
				onCancel={hideConfirmInvitationModal}
				onConfirm={hideConfirmInvitationModal}
				userIds={selectedUserIDs}
			/>
		</Box>
	);
};
