import { Bar } from "@nivo/bar";
import { Box, Flex, getColor, PL_DEFAULT_THEME as defaultTheme, Text } from "@powerledger/ui-component-lib";
import { debounce } from "lodash";
import { FC, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { BarGraphDataType, BarGraphType, DefsType, FillType, FormattedDefs, KeyColorType } from "./bar-graph.types";

const mainRed = getColor(defaultTheme, "graphRed");
const mainBlue = getColor(defaultTheme, "graphBlue");
const mainPink = getColor(defaultTheme, "redOrange");

/**
 * Augments passed colour to either lighter or darker
 * @param hexColor
 * @param magnitude
 * @param lighten
 * @returns colour hex as string
 * @link https://natclark.com/tutorials/javascript-lighten-darken-hex-color/
 */
const newShade = (hexColor: string, magnitude: number, lighten = false) => {
	if (lighten) magnitude = magnitude * -1;
	magnitude = magnitude * 50;
	hexColor = hexColor.replace(`#`, ``);
	if (hexColor.length === 6) {
		const decimalColor = parseInt(hexColor, 16);
		let r = (decimalColor >> 16) + magnitude;
		r > 255 && (r = 255);
		r < 0 && (r = 0);
		let g = (decimalColor & 0x0000ff) + magnitude;
		g > 255 && (g = 255);
		g < 0 && (g = 0);
		let b = ((decimalColor >> 8) & 0x00ff) + magnitude;
		b > 255 && (b = 255);
		b < 0 && (b = 0);
		return `#${(g | (b << 8) | (r << 16)).toString(16)}`;
	} else {
		return hexColor;
	}
};

const getBarContext = (data: BarGraphDataType[], keys?: KeyColorType[]): FormattedDefs => {
	let redShades = 0,
		blueShades = 0,
		pinkShades = 0;
	const defs: DefsType[] = [],
		fill: FillType[] = [];
	const flattenedLabels = Array.from(
		new Set(
			data.flatMap((e) => {
				return e.entries.flatMap((entry) => {
					return entry.name;
				});
			}),
		),
	);
	if (!keys) {
		flattenedLabels.forEach((entry) => {
			if (entry.match(/bought/gi)) {
				const color = newShade(mainBlue, blueShades % 2 === 0 ? blueShades : -blueShades);
				fill.push({ match: { id: entry }, id: `blueGradient${blueShades}` });
				defs.push({
					id: `blueGradient${blueShades}`,
					type: "linearGradient",
					color: color,
					colors: [
						{ offset: 0, color: `${color}aa` },
						{ offset: 100, color: "#00000000" },
					],
				});

				defs.push({
					id: `blueBorder${blueShades}`,
					color: color,
				});
				blueShades++;
			} else if (entry.match(/sold/gi)) {
				const color = newShade(mainRed, redShades % 2 === 0 ? redShades : -redShades);

				fill.push({ match: { id: entry }, id: `redGradient${redShades}` });
				defs.push({
					id: `redGradient${redShades}`,
					type: "linearGradient",
					color: color,
					colors: [
						{ offset: 0, color: `${color}aa` },
						{ offset: 100, color: "#00000000" },
					],
				});

				defs.push({
					id: `redBorder${redShades}`,
					color: color,
				});
				redShades++;
			} else {
				const color = newShade(mainPink, pinkShades, true);

				fill.push({ match: { id: entry }, id: `pinkGradient${pinkShades}` });
				defs.push({
					id: `pinkGradient${pinkShades}`,
					type: "linearGradient",
					color: color,
					colors: [
						{ offset: 0, color: `${color}aa` },
						{ offset: 100, color: "#00000000" },
					],
				});
				defs.push({
					id: `redBorder${pinkShades}`,
					color: color,
				});
				pinkShades++;
			}
		});
	} else {
		const colorList: string[] = [];
		flattenedLabels.forEach((entry) => {
			const filteredKeys = keys.filter((key) => entry.toLowerCase() === key.keyName.toLowerCase());
			if (filteredKeys.length > 0) {
				const keyName = filteredKeys[0].keyName.replace(/ /, "").toLowerCase();
				colorList.push(keyName);
				const totalCount = colorList.filter((vals) => vals === keyName).length - 1;
				const keyColor = getColor(defaultTheme, filteredKeys[0].keyColor);
				const color = newShade(keyColor, totalCount % 2 === 0 ? totalCount : -totalCount);
				fill.push({ match: { id: entry }, id: `${keyName}Gradient${totalCount}` });
				defs.push({
					id: `${keyName}Gradient${totalCount}`,
					type: "linearGradient",
					color: keyColor,
					colors: [
						{ offset: 0, color: `${keyColor}aa` },
						{ offset: 100, color: "#00000000" },
					],
				});

				defs.push({
					id: `${keyName}Border${totalCount}`,
					color: color,
				});
			} else {
				const color = newShade(mainPink, pinkShades, true);
				fill.push({ match: { id: entry }, id: `pinkGradient${pinkShades}` });
				defs.push({
					id: `pinkGradient${pinkShades}`,
					type: "linearGradient",
					color: color,
					colors: [
						{ offset: 0, color: `${color}aa` },
						{ offset: 100, color: "#00000000" },
					],
				});
				defs.push({
					id: `pinkBorder${pinkShades}`,
					color: color,
				});
				pinkShades++;
			}
		});
	}

	return {
		defs: defs,
		keys: flattenedLabels,
		fill: fill,
	};
};

export const BarGraph: FC<BarGraphType> = ({ title, legend, data, keys, sideBySide = false }) => {
	const context = getBarContext(data, keys);

	const ref = useRef<HTMLDivElement>(null);
	const [width, setWidth] = useState(600);

	const { t } = useTranslation();

	const updateSize = useMemo(
		() =>
			debounce(() => {
				if (ref.current) {
					setWidth(ref.current.clientWidth);
				}
			}, 125),
		[],
	);

	useEffect(() => {
		updateSize();
	}, [updateSize]);

	useEffect(() => {
		window.addEventListener("resize", updateSize);
		return () => {
			window.removeEventListener("resize", updateSize);
		};
	}, [updateSize]);

	const theme = {
		background: "transparent",
		axis: {
			fontSize: "14px",
			fill: getColor(defaultTheme, "textDarker"),
			ticks: {
				line: {
					fill: getColor(defaultTheme, "textDarker"),
				},
				text: {
					fill: getColor(defaultTheme, "textDarker"),
				},
			},
			legend: {
				text: {
					fill: getColor(defaultTheme, "textDarker"),
				},
			},
		},
		grid: {
			line: {
				stroke: getColor(defaultTheme, "textDarker"),
			},
		},
		legends: {
			text: {
				fill: getColor(defaultTheme, "textDarker"),
			},
			hidden: { text: { textDecoration: "line-through", fill: getColor(defaultTheme, "textDarker") } },
		},
	};

	const formattedData = useMemo(() => {
		return data.map((e) => {
			// has to be any to handle different key values
			const entries: any = {};

			for (const obj of e.entries) {
				entries[obj.name] = obj.value;
			}
			return {
				label: e.label,
				...entries,
			};
		});
	}, [data]);

	return (
		<Box
			ref={ref}
			sx={{
				padding: 3,
				color: "textDarker",
				borderRadius: 4,
				backgroundColor: "graphBackground",
			}}
		>
			{title && <Text>{t(title)}</Text>}
			{data[0] ? (
				<Bar
					width={width}
					height={400}
					groupMode={sideBySide ? "grouped" : "stacked"}
					innerPadding={sideBySide ? 10 : 0}
					colors={({ id }) => {
						const index = context.fill.findIndex((e) => e.match.id === id);
						const defsIndex = context.defs.findIndex((e) => e.id === context.fill[index].id);
						return context.defs[defsIndex].color;
					}}
					defs={context.defs}
					fill={context.fill}
					margin={{ top: 60, right: 80, bottom: 60, left: 80 }}
					data={formattedData}
					keys={context.keys}
					indexBy="label"
					labelTextColor={getColor(defaultTheme, "white")}
					labelSkipWidth={12}
					labelSkipHeight={12}
					enableGridX={false}
					borderWidth={1}
					borderColor={{
						from: "color",
					}}
					axisBottom={{
						tickSize: 5,
						tickPadding: 5,
						tickRotation: 0,
					}}
					axisRight={{
						tickSize: 0,
						tickPadding: 5,
						tickRotation: 0,
						legend: legend,
						legendPosition: "middle",
						legendOffset: 40,
					}}
					axisLeft={null}
					theme={theme}
					legends={[
						{
							dataFrom: "keys",
							anchor: "top-left",
							direction: "row",
							justify: false,
							itemsSpacing: 20,
							itemWidth: 50,
							itemHeight: 10,
							itemDirection: "left-to-right",
							toggleSerie: true,
							itemTextColor: getColor(defaultTheme, "textDarker"),
							itemOpacity: 1,
							symbolSize: 10,
							translateY: -60,
							translateX: -80,
							symbolShape: "circle",
							effects: [
								{
									on: "hover",
									style: {
										itemOpacity: 1,
									},
								},
							],
						},
					]}
					legendLabel={({ id }) =>
						id.toString().length > 9 ? id.toString().substring(0, 6) + "..." : id.toString() || ""
					}
					tooltip={({ id, value, color }) => (
						<div
							style={{
								padding: 12,
								color,
								background: "#222222",
							}}
						>
							<Text>
								{/* eslint-disable-next-line i18next/no-literal-string */}
								{id}: {value}
							</Text>
						</div>
					)}
				/>
			) : (
				<Flex sx={{ width: "100%", height: 400, alignItems: "center", justifyContent: "center" }}>
					<Text>{t("No data found")}</Text>
				</Flex>
			)}
		</Box>
	);
};
