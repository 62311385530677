import { faker } from "@faker-js/faker";

type OverviewSummaryParticipantType = {
	p2pVolumePurchased: number;
	p2pPurchasePrice: number;
	gridVolumePurchased: number;
	gridPurchasePrice: number;
	p2pVolumeSold: number;
	p2pSalePrice: number;
	selfSufficiencyPercentage: number;
	selfConsumptionPercentage: number;
	averageSavingsBAU: number;
	averageSavingsLEM: number;
};

export const stubbedOverviewSummaryParticipant: OverviewSummaryParticipantType[] = [
	{
		p2pSalePrice: 0.138,
		p2pPurchasePrice: 0.24,
		p2pVolumeSold: 509.8,
		p2pVolumePurchased: 487.8,
		selfSufficiencyPercentage: 0.533,
		selfConsumptionPercentage: 0.927,
		gridVolumePurchased: faker.datatype.number({ min: 2, max: 6 }),
		gridPurchasePrice: faker.datatype.number({ max: 0.5, precision: 0.1 }),
		averageSavingsBAU: 4.49,
		averageSavingsLEM: 4.39,
	},
	{
		p2pSalePrice: 0.136,
		p2pPurchasePrice: 0.235,
		p2pVolumeSold: 470.8,
		p2pVolumePurchased: 456.8,
		selfSufficiencyPercentage: 0.511,
		selfConsumptionPercentage: 0.907,
		gridVolumePurchased: faker.datatype.number({ min: 2, max: 6 }),
		gridPurchasePrice: faker.datatype.number({ max: 0.5, precision: 0.1 }),
		averageSavingsBAU: 4.81,
		averageSavingsLEM: 4.52,
	},
	{
		p2pSalePrice: 0.141,
		p2pPurchasePrice: 0.243,
		p2pVolumeSold: 103.5,
		p2pVolumePurchased: 107.3,
		selfSufficiencyPercentage: 0.255,
		selfConsumptionPercentage: 1,
		gridVolumePurchased: faker.datatype.number({ min: 2, max: 6 }),
		gridPurchasePrice: faker.datatype.number({ max: 0.5, precision: 0.1 }),
		averageSavingsBAU: 3.67,
		averageSavingsLEM: 3.45,
	},
	{
		p2pSalePrice: 0.127,
		p2pPurchasePrice: 0.232,
		p2pVolumeSold: 440.6,
		p2pVolumePurchased: 445.3,
		selfSufficiencyPercentage: 0.529,
		selfConsumptionPercentage: 0.939,
		gridVolumePurchased: faker.datatype.number({ min: 2, max: 6 }),
		gridPurchasePrice: faker.datatype.number({ max: 0.5, precision: 0.1 }),
		averageSavingsBAU: 3.98,
		averageSavingsLEM: 3.7,
	},
	{
		p2pSalePrice: 0.133,
		p2pPurchasePrice: 0.237,
		p2pVolumeSold: 381.2,
		p2pVolumePurchased: 383.2,
		selfSufficiencyPercentage: 0.55,
		selfConsumptionPercentage: 0.672,
		gridVolumePurchased: faker.datatype.number({ min: 2, max: 6 }),
		gridPurchasePrice: faker.datatype.number({ max: 0.5, precision: 0.1 }),
		averageSavingsBAU: 4.34,
		averageSavingsLEM: 4.25,
	},
];
