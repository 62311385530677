/* NOTE: Disabling the following as this component should encompass i18next */
/* eslint-disable i18next/no-literal-string */

import { Box, Flex, Heading, IconPillButton, IconRefresh, Link, Text } from "@powerledger/ui-component-lib";
import { captureException, withScope } from "@sentry/react";
import React from "react";

import { supportEmail } from "../../hooks";
import { PRIVACY_POLICY_URL, TERMS_OF_ACCESS_URL } from "../../lib/endpoints";

const reloadPage = () => window.location.reload();

type GlobalErrorBoundaryState = {
	hasError: boolean;
};

export class GlobalErrorBoundary extends React.Component<any, GlobalErrorBoundaryState> {
	constructor(props: any) {
		super(props);
		this.state = { hasError: false };
	}

	componentDidCatch(error: any, errorInfo: any) {
		this.setState({ hasError: true });
		withScope((scope) => {
			scope.setExtras(errorInfo);
			captureException(error);
		});
	}

	render() {
		if (this.state.hasError) {
			return (
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						justifyContent: "center",
						fontFamily: "Arial, Helvetica, sans-serif",
						position: "fixed",
						top: 0,
						left: 0,
						height: "100%",
						width: "100%",
						backgroundImage: "url(/background-illustration.svg)",
						backgroundPositionY: "bottom",
						backgroundPositionX: "right",
						backgroundRepeat: "no-repeat",
						backgroundSize: ["90%", "63%", "53%", "37%"],
						backgroundColor: "#171638",
						textAlign: "center",
						fontSize: "18px",
					}}
				>
					<Flex
						sx={{
							gap: 3,
							alignItems: "center",
							flexDirection: "column",
							maxWidth: ["100%", "75%", "60%"],
							color: "white",
						}}
					>
						<Heading sx={{ fontSize: "2rem", lineHeight: "1.35", mr: 3, fontFamily: "inherit" }}>
							<span>{"Unfortunately an error occurred on our end."}</span>
							<br />
							{"The system may be down for maintenance."}
						</Heading>

						<Text
							sx={{
								pt: 4,
								fontSize: "1.2rem",
								mb: 4,
							}}
						>
							{"Please reload the page and try again."}
						</Text>
						<IconPillButton
							onClick={reloadPage}
							sx={{
								borderRadius: 4,
								backgroundColor: "#00CDDA",
								border: "1px solid #00CDDA",
								color: "black",
								cursor: "pointer",
								fill: "black",
								"&:hover, &:focus": {
									opacity: 0.9,
									backgroundColor: "transparent",
									border: "1px solid #00CDDA",
									color: "#00CDDA",
									fill: "#00CDDA",
								},
							}}
							icon={<IconRefresh size={20} color={"inherit"} sx={{ ml: 3, marginBottom: "-4px" }} />}
						>
							{"Reload"}
						</IconPillButton>
					</Flex>
					<Box sx={{ p: 2, position: "absolute", bottom: 0, left: 0 }}>
						<Box
							sx={{
								fontSize: 1,
								color: "white",
								mt: 4,
								marginLeft: 1,
								display: "flex",
								flexDirection: "column",
								p: 3,
								alignItems: "flex-start",
								gap: 2,
							}}
						>
							<Link
								href={TERMS_OF_ACCESS_URL}
								target="_blank"
								sx={{ color: "white", textDecoration: "none", "&:hover": { textDecoration: "underline" } }}
							>
								{"Terms of Service"}
							</Link>
							<Box sx={{ mt: 2 }}>
								<Link
									href={PRIVACY_POLICY_URL}
									target="_blank"
									sx={{
										color: "white",
										textDecoration: "none",
										mr: 3,
										"&:hover": { textDecoration: "underline" },
									}}
								>
									{"Privacy Policy"}
								</Link>
								<Link
									href={`mailto:${supportEmail}`}
									target="_blank"
									sx={{
										color: "white",
										textDecoration: "none",
										"&:hover": { textDecoration: "underline" },
									}}
								>
									{"Contact Us"}
								</Link>
							</Box>
							<Box sx={{ color: "#9E99C8", fontSize: 0, mt: 2 }}>
								{`©${new Date().getFullYear()} Powerledger. All rights reserved`}
							</Box>
						</Box>
					</Box>
				</Box>
			);
		}

		return this.props.children;
	}
}
