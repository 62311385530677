export const defaultData = [
	{
		id: "LEM",
		data: [],
	},
];

export const demoData = [
	{
		id: "BAU",
		data: [
			{ x: "2018-01-01 0:00", y: 37.8533121 },
			{ x: "2018-01-01 0:15", y: 35.50465605 },
			{ x: "2018-01-01 0:30", y: 33.156 },
			{ x: "2018-01-01 0:45", y: 30.435 },
			{ x: "2018-01-01 1:00", y: 27.714 },
			{ x: "2018-01-01 1:15", y: 27.209 },
			{ x: "2018-01-01 1:30", y: 26.704 },
			{ x: "2018-01-01 1:45", y: 24.424 },
			{ x: "2018-01-01 2:00", y: 22.144 },
			{ x: "2018-01-01 2:15", y: 25.66 },
			{ x: "2018-01-01 2:30", y: 29.176 },
			{ x: "2018-01-01 2:45", y: 29.179 },
			{ x: "2018-01-01 3:00", y: 29.182 },
			{ x: "2018-01-01 3:15", y: 28.80876471 },
			{ x: "2018-01-01 3:30", y: 28.43552941 },
			{ x: "2018-01-01 3:45", y: 26.61176471 },
			{ x: "2018-01-01 4:00", y: 24.788 },
			{ x: "2018-01-01 4:15", y: 25.4052 },
			{ x: "2018-01-01 4:30", y: 26.0224 },
			{ x: "2018-01-01 4:45", y: 28.7932 },
			{ x: "2018-01-01 5:00", y: 30.874 },
			{ x: "2018-01-01 5:15", y: 32.07557143 },
			{ x: "2018-01-01 5:30", y: 33.43414286 },
			{ x: "2018-01-01 5:45", y: 35.01957143 },
			{ x: "2018-01-01 6:00", y: 39.911 },
			{ x: "2018-01-01 6:15", y: 44.327 },
			{ x: "2018-01-01 6:30", y: 50.993 },
			{ x: "2018-01-01 6:45", y: 51.97465446 },
			{ x: "2018-01-01 7:00", y: 53.85430892 },
			{ x: "2018-01-01 7:15", y: 50.76687647 },
			{ x: "2018-01-01 7:30", y: 46.64695383 },
			{ x: "2018-01-01 7:45", y: 45.70641584 },
			{ x: "2018-01-01 8:00", y: 44.67664764 },
			{ x: "2018-01-01 8:15", y: 45.30748055 },
			{ x: "2018-01-01 8:30", y: 46.22403019 },
			{ x: "2018-01-01 8:45", y: 41.02735777 },
			{ x: "2018-01-01 9:00", y: 36.42537815 },
			{ x: "2018-01-01 9:15", y: 34.58365531 },
			{ x: "2018-01-01 9:30", y: 32.97673248 },
			{ x: "2018-01-01 9:45", y: 28.01630303 },
			{ x: "2018-01-01 10:00", y: 23.45422761 },
			{ x: "2018-01-01 10:15", y: 16.06529575 },
			{ x: "2018-01-01 10:30", y: 7.631796853 },
			{ x: "2018-01-01 10:45", y: 2.659947774 },
			{ x: "2018-01-01 11:00", y: -8.557888228 },
			{ x: "2018-01-01 11:15", y: -13.26516264 },
			{ x: "2018-01-01 11:30", y: -17.64816467 },
			{ x: "2018-01-01 11:45", y: -15.12320886 },
			{ x: "2018-01-01 12:00", y: -14.4276591 },
			{ x: "2018-01-01 12:15", y: -17.65031328 },
			{ x: "2018-01-01 12:30", y: -18.20636127 },
			{ x: "2018-01-01 12:45", y: -14.7450733 },
			{ x: "2018-01-01 13:00", y: -19.84779198 },
			{ x: "2018-01-01 13:15", y: -19.00566512 },
			{ x: "2018-01-01 13:30", y: -22.9079703 },
			{ x: "2018-01-01 13:45", y: -24.12227454 },
			{ x: "2018-01-01 14:00", y: -23.79720598 },
			{ x: "2018-01-01 14:15", y: -28.31071267 },
			{ x: "2018-01-01 14:30", y: -34.76220208 },
			{ x: "2018-01-01 14:45", y: -37.90522101 },
			{ x: "2018-01-01 15:00", y: -45.84198348 },
			{ x: "2018-01-01 15:15", y: -40.15308058 },
			{ x: "2018-01-01 15:30", y: -31.78074491 },
			{ x: "2018-01-01 15:45", y: -21.78741737 },
			{ x: "2018-01-01 16:00", y: -12.43528983 },
			{ x: "2018-01-01 16:15", y: 2.731273171 },
			{ x: "2018-01-01 16:30", y: 17.73983617 },
			{ x: "2018-01-01 16:45", y: 28.7807671 },
			{ x: "2018-01-01 17:00", y: 42.97424948 },
			{ x: "2018-01-01 17:15", y: 48.81331141 },
			{ x: "2018-01-01 17:30", y: 54.59637335 },
			{ x: "2018-01-01 17:45", y: 60.26035675 },
			{ x: "2018-01-01 18:00", y: 65.92434014 },
			{ x: "2018-01-01 18:15", y: 64.2917415 },
			{ x: "2018-01-01 18:30", y: 63.64114286 },
			{ x: "2018-01-01 18:45", y: 61.24177143 },
			{ x: "2018-01-01 19:00", y: 59.6864 },
			{ x: "2018-01-01 19:15", y: 61.90022703 },
			{ x: "2018-01-01 19:30", y: 64.11405405 },
			{ x: "2018-01-01 19:45", y: 61.08202703 },
			{ x: "2018-01-01 20:00", y: 58.05 },
			{ x: "2018-01-01 20:15", y: 57.468 },
			{ x: "2018-01-01 20:30", y: 56.886 },
			{ x: "2018-01-01 20:45", y: 57.166 },
			{ x: "2018-01-01 21:00", y: 57.446 },
			{ x: "2018-01-01 21:15", y: 56.126 },
			{ x: "2018-01-01 21:30", y: 54.806 },
			{ x: "2018-01-01 21:45", y: 52.678 },
			{ x: "2018-01-01 22:00", y: 50.55 },
			{ x: "2018-01-01 22:15", y: 54.34794268 },
			{ x: "2018-01-01 22:30", y: 58.14588535 },
			{ x: "2018-01-01 22:45", y: 56.6245141 },
			{ x: "2018-01-01 23:00", y: 54.748 },
			{ x: "2018-01-01 23:15", y: 51.33 },
			{ x: "2018-01-01 23:30", y: 47.912 },
			{ x: "2018-01-01 23:45", y: 47.912 },
			{ x: "2018-01-02 0:00", y: 47.912 },
		],
	},
	{
		id: "LEM",
		data: [
			{ x: "2018-01-01 0:00", y: 37.8533121 },
			{ x: "2018-01-01 0:15", y: 35.50465605 },
			{ x: "2018-01-01 0:30", y: 33.156 },
			{ x: "2018-01-01 0:45", y: 30.435 },
			{ x: "2018-01-01 1:00", y: 27.714 },
			{ x: "2018-01-01 1:15", y: 27.209 },
			{ x: "2018-01-01 1:30", y: 26.704 },
			{ x: "2018-01-01 1:45", y: 24.424 },
			{ x: "2018-01-01 2:00", y: 22.144 },
			{ x: "2018-01-01 2:15", y: 25.66 },
			{ x: "2018-01-01 2:30", y: 29.176 },
			{ x: "2018-01-01 2:45", y: 29.179 },
			{ x: "2018-01-01 3:00", y: 29.182 },
			{ x: "2018-01-01 3:15", y: 28.80876471 },
			{ x: "2018-01-01 3:30", y: 28.43552941 },
			{ x: "2018-01-01 3:45", y: 26.61176471 },
			{ x: "2018-01-01 4:00", y: 24.788 },
			{ x: "2018-01-01 4:15", y: 25.4052 },
			{ x: "2018-01-01 4:30", y: 26.0224 },
			{ x: "2018-01-01 4:45", y: 28.7932 },
			{ x: "2018-01-01 5:00", y: 30.874 },
			{ x: "2018-01-01 5:15", y: 32.07557143 },
			{ x: "2018-01-01 5:30", y: 33.43414286 },
			{ x: "2018-01-01 5:45", y: 35.01957143 },
			{ x: "2018-01-01 6:00", y: 39.911 },
			{ x: "2018-01-01 6:15", y: 44.327 },
			{ x: "2018-01-01 6:30", y: 50.993 },
			{ x: "2018-01-01 6:45", y: 51.97465446 },
			{ x: "2018-01-01 7:00", y: 53.85430892 },
			{ x: "2018-01-01 7:15", y: 50.76687647 },
			{ x: "2018-01-01 7:30", y: 46.64695383 },
			{ x: "2018-01-01 7:45", y: 45.70641584 },
			{ x: "2018-01-01 8:00", y: 44.67664764 },
			{ x: "2018-01-01 8:15", y: 45.30748055 },
			{ x: "2018-01-01 8:30", y: 46.22403019 },
			{ x: "2018-01-01 8:45", y: 41.02735777 },
			{ x: "2018-01-01 9:00", y: 36.42537815 },
			{ x: "2018-01-01 9:15", y: 34.58365531 },
			{ x: "2018-01-01 9:30", y: 32.97673248 },
			{ x: "2018-01-01 9:45", y: 28.01630303 },
			{ x: "2018-01-01 10:00", y: 23.45422761 },
			{ x: "2018-01-01 10:15", y: 16.06529575 },
			{ x: "2018-01-01 10:30", y: 7.631796853 },
			{ x: "2018-01-01 10:45", y: 2.659947774 },
			{ x: "2018-01-01 11:00", y: -8.557888228 },
			{ x: "2018-01-01 11:15", y: -4.705642638 },
			{ x: "2018-01-01 11:30", y: -8.886924508 },
			{ x: "2018-01-01 11:45", y: -6.175888861 },
			{ x: "2018-01-01 12:00", y: -5.347939017 },
			{ x: "2018-01-01 12:15", y: -8.711513261 },
			{ x: "2018-01-01 12:30", y: -9.471201107 },
			{ x: "2018-01-01 12:45", y: -6.291273304 },
			{ x: "2018-01-01 13:00", y: -11.9734719 },
			{ x: "2018-01-01 13:15", y: -10.70378496 },
			{ x: "2018-01-01 13:30", y: -15.44369029 },
			{ x: "2018-01-01 13:45", y: -18.16191454 },
			{ x: "2018-01-01 14:00", y: -18.51900598 },
			{ x: "2018-01-01 14:15", y: -23.9228326 },
			{ x: "2018-01-01 14:30", y: -32.22860208 },
			{ x: "2018-01-01 14:45", y: -35.10082105 },
			{ x: "2018-01-01 15:00", y: -38.3757433 },
			{ x: "2018-01-01 15:15", y: -37.08384058 },
			{ x: "2018-01-01 15:30", y: -29.33766501 },
			{ x: "2018-01-01 15:45", y: -20.49913737 },
			{ x: "2018-01-01 16:00", y: -10.56096982 },
			{ x: "2018-01-01 16:15", y: 2.731273171 },
			{ x: "2018-01-01 16:30", y: 17.73983617 },
			{ x: "2018-01-01 16:45", y: 7.283132299 },
			{ x: "2018-01-01 17:00", y: 23.45659028 },
			{ x: "2018-01-01 17:15", y: 30.50493341 },
			{ x: "2018-01-01 17:30", y: 36.93975982 },
			{ x: "2018-01-01 17:45", y: 45.02938275 },
			{ x: "2018-01-01 18:00", y: 54.87286214 },
			{ x: "2018-01-01 18:15", y: 53.7778883 },
			{ x: "2018-01-01 18:30", y: 54.60966166 },
			{ x: "2018-01-01 18:45", y: 52.89811743 },
			{ x: "2018-01-01 19:00", y: 53.3083788 },
			{ x: "2018-01-01 19:15", y: 58.37103023 },
			{ x: "2018-01-01 19:30", y: 58.87888085 },
			{ x: "2018-01-01 19:45", y: 58.03190223 },
			{ x: "2018-01-01 20:00", y: 54.782 },
			{ x: "2018-01-01 20:15", y: 54.1681252 },
			{ x: "2018-01-01 20:30", y: 53.63441 },
			{ x: "2018-01-01 20:45", y: 54.12218 },
			{ x: "2018-01-01 21:00", y: 55.173612 },
			{ x: "2018-01-01 21:15", y: 54.5734 },
			{ x: "2018-01-01 21:30", y: 52.5616 },
			{ x: "2018-01-01 21:45", y: 51.12525 },
			{ x: "2018-01-01 22:00", y: 48.0756 },
			{ x: "2018-01-01 22:15", y: 52.24344268 },
			{ x: "2018-01-01 22:30", y: 56.67228535 },
			{ x: "2018-01-01 22:45", y: 56.6245141 },
			{ x: "2018-01-01 23:00", y: 54.344 },
			{ x: "2018-01-01 23:15", y: 51.33 },
			{ x: "2018-01-01 23:30", y: 47.912 },
			{ x: "2018-01-01 23:45", y: 47.912 },
			{ x: "2018-01-02 0:00", y: 47.912 },
		],
	},
];

export const positiveDemoData = [
	{
		id: "BAU",
		data: [
			{ x: "2018-01-01 0:00", y: 37.8533121 },
			{ x: "2018-01-01 0:15", y: 35.50465605 },
			{ x: "2018-01-01 0:30", y: 33.156 },
			{ x: "2018-01-01 0:45", y: 30.435 },
			{ x: "2018-01-01 1:00", y: 27.714 },
			{ x: "2018-01-01 1:15", y: 27.209 },
			{ x: "2018-01-01 1:30", y: 26.704 },
			{ x: "2018-01-01 1:45", y: 24.424 },
			{ x: "2018-01-01 2:00", y: 22.144 },
			{ x: "2018-01-01 2:15", y: 25.66 },
			{ x: "2018-01-01 2:30", y: 29.176 },
			{ x: "2018-01-01 2:45", y: 29.179 },
			{ x: "2018-01-01 3:00", y: 29.182 },
			{ x: "2018-01-01 3:15", y: 28.80876471 },
			{ x: "2018-01-01 3:30", y: 28.43552941 },
			{ x: "2018-01-01 3:45", y: 26.61176471 },
			{ x: "2018-01-01 4:00", y: 24.788 },
			{ x: "2018-01-01 4:15", y: 25.4052 },
			{ x: "2018-01-01 4:30", y: 26.0224 },
			{ x: "2018-01-01 4:45", y: 28.7932 },
			{ x: "2018-01-01 5:00", y: 30.874 },
			{ x: "2018-01-01 5:15", y: 32.07557143 },
			{ x: "2018-01-01 5:30", y: 33.43414286 },
			{ x: "2018-01-01 5:45", y: 35.01957143 },
			{ x: "2018-01-01 6:00", y: 39.911 },
			{ x: "2018-01-01 6:15", y: 44.327 },
			{ x: "2018-01-01 6:30", y: 50.993 },
			{ x: "2018-01-01 6:45", y: 51.97465446 },
			{ x: "2018-01-01 7:00", y: 53.85430892 },
			{ x: "2018-01-01 7:15", y: 50.76687647 },
			{ x: "2018-01-01 7:30", y: 46.64695383 },
			{ x: "2018-01-01 7:45", y: 45.70641584 },
			{ x: "2018-01-01 8:00", y: 44.67664764 },
			{ x: "2018-01-01 8:15", y: 45.30748055 },
			{ x: "2018-01-01 8:30", y: 46.22403019 },
			{ x: "2018-01-01 8:45", y: 41.02735777 },
			{ x: "2018-01-01 9:00", y: 36.42537815 },
			{ x: "2018-01-01 9:15", y: 34.58365531 },
			{ x: "2018-01-01 9:30", y: 32.97673248 },
			{ x: "2018-01-01 9:45", y: 28.01630303 },
			{ x: "2018-01-01 10:00", y: 23.45422761 },
			{ x: "2018-01-01 10:15", y: 16.06529575 },
			{ x: "2018-01-01 10:30", y: 7.631796853 },
			{ x: "2018-01-01 10:45", y: 2.659947774 },
			{ x: "2018-01-01 11:00", y: 8.557888228 },
			{ x: "2018-01-01 11:15", y: 13.26516264 },
			{ x: "2018-01-01 11:30", y: 17.64816467 },
			{ x: "2018-01-01 11:45", y: 15.12320886 },
			{ x: "2018-01-01 12:00", y: 14.4276591 },
			{ x: "2018-01-01 12:15", y: 17.65031328 },
			{ x: "2018-01-01 12:30", y: 18.20636127 },
			{ x: "2018-01-01 12:45", y: 14.7450733 },
			{ x: "2018-01-01 13:00", y: 19.84779198 },
			{ x: "2018-01-01 13:15", y: 19.00566512 },
			{ x: "2018-01-01 13:30", y: 22.9079703 },
			{ x: "2018-01-01 13:45", y: 24.12227454 },
			{ x: "2018-01-01 14:00", y: 23.79720598 },
			{ x: "2018-01-01 14:15", y: 28.31071267 },
			{ x: "2018-01-01 14:30", y: 34.76220208 },
			{ x: "2018-01-01 14:45", y: 37.90522101 },
			{ x: "2018-01-01 15:00", y: 45.84198348 },
			{ x: "2018-01-01 15:15", y: 40.15308058 },
			{ x: "2018-01-01 15:30", y: 31.78074491 },
			{ x: "2018-01-01 15:45", y: 21.78741737 },
			{ x: "2018-01-01 16:00", y: 12.43528983 },
			{ x: "2018-01-01 16:15", y: 2.731273171 },
			{ x: "2018-01-01 16:30", y: 17.73983617 },
			{ x: "2018-01-01 16:45", y: 28.7807671 },
			{ x: "2018-01-01 17:00", y: 42.97424948 },
			{ x: "2018-01-01 17:15", y: 48.81331141 },
			{ x: "2018-01-01 17:30", y: 54.59637335 },
			{ x: "2018-01-01 17:45", y: 60.26035675 },
			{ x: "2018-01-01 18:00", y: 65.92434014 },
			{ x: "2018-01-01 18:15", y: 64.2917415 },
			{ x: "2018-01-01 18:30", y: 63.64114286 },
			{ x: "2018-01-01 18:45", y: 61.24177143 },
			{ x: "2018-01-01 19:00", y: 59.6864 },
			{ x: "2018-01-01 19:15", y: 61.90022703 },
			{ x: "2018-01-01 19:30", y: 64.11405405 },
			{ x: "2018-01-01 19:45", y: 61.08202703 },
			{ x: "2018-01-01 20:00", y: 58.05 },
			{ x: "2018-01-01 20:15", y: 57.468 },
			{ x: "2018-01-01 20:30", y: 56.886 },
			{ x: "2018-01-01 20:45", y: 57.166 },
			{ x: "2018-01-01 21:00", y: 57.446 },
			{ x: "2018-01-01 21:15", y: 56.126 },
			{ x: "2018-01-01 21:30", y: 54.806 },
			{ x: "2018-01-01 21:45", y: 52.678 },
			{ x: "2018-01-01 22:00", y: 50.55 },
			{ x: "2018-01-01 22:15", y: 54.34794268 },
			{ x: "2018-01-01 22:30", y: 58.14588535 },
			{ x: "2018-01-01 22:45", y: 56.6245141 },
			{ x: "2018-01-01 23:00", y: 54.748 },
			{ x: "2018-01-01 23:15", y: 51.33 },
			{ x: "2018-01-01 23:30", y: 47.912 },
			{ x: "2018-01-01 23:45", y: 47.912 },
			{ x: "2018-01-02 0:00", y: 47.912 },
		],
	},
	{
		id: "LEM",
		data: [
			{ x: "2018-01-01 0:00", y: 37.8533121 },
			{ x: "2018-01-01 0:15", y: 35.50465605 },
			{ x: "2018-01-01 0:30", y: 33.156 },
			{ x: "2018-01-01 0:45", y: 30.435 },
			{ x: "2018-01-01 1:00", y: 27.714 },
			{ x: "2018-01-01 1:15", y: 27.209 },
			{ x: "2018-01-01 1:30", y: 26.704 },
			{ x: "2018-01-01 1:45", y: 24.424 },
			{ x: "2018-01-01 2:00", y: 22.144 },
			{ x: "2018-01-01 2:15", y: 25.66 },
			{ x: "2018-01-01 2:30", y: 29.176 },
			{ x: "2018-01-01 2:45", y: 29.179 },
			{ x: "2018-01-01 3:00", y: 29.182 },
			{ x: "2018-01-01 3:15", y: 28.80876471 },
			{ x: "2018-01-01 3:30", y: 28.43552941 },
			{ x: "2018-01-01 3:45", y: 26.61176471 },
			{ x: "2018-01-01 4:00", y: 24.788 },
			{ x: "2018-01-01 4:15", y: 25.4052 },
			{ x: "2018-01-01 4:30", y: 26.0224 },
			{ x: "2018-01-01 4:45", y: 28.7932 },
			{ x: "2018-01-01 5:00", y: 30.874 },
			{ x: "2018-01-01 5:15", y: 32.07557143 },
			{ x: "2018-01-01 5:30", y: 33.43414286 },
			{ x: "2018-01-01 5:45", y: 35.01957143 },
			{ x: "2018-01-01 6:00", y: 39.911 },
			{ x: "2018-01-01 6:15", y: 44.327 },
			{ x: "2018-01-01 6:30", y: 50.993 },
			{ x: "2018-01-01 6:45", y: 51.97465446 },
			{ x: "2018-01-01 7:00", y: 53.85430892 },
			{ x: "2018-01-01 7:15", y: 50.76687647 },
			{ x: "2018-01-01 7:30", y: 46.64695383 },
			{ x: "2018-01-01 7:45", y: 45.70641584 },
			{ x: "2018-01-01 8:00", y: 44.67664764 },
			{ x: "2018-01-01 8:15", y: 45.30748055 },
			{ x: "2018-01-01 8:30", y: 46.22403019 },
			{ x: "2018-01-01 8:45", y: 41.02735777 },
			{ x: "2018-01-01 9:00", y: 36.42537815 },
			{ x: "2018-01-01 9:15", y: 34.58365531 },
			{ x: "2018-01-01 9:30", y: 32.97673248 },
			{ x: "2018-01-01 9:45", y: 28.01630303 },
			{ x: "2018-01-01 10:00", y: 23.45422761 },
			{ x: "2018-01-01 10:15", y: 16.06529575 },
			{ x: "2018-01-01 10:30", y: 7.631796853 },
			{ x: "2018-01-01 10:45", y: 2.659947774 },
			{ x: "2018-01-01 11:00", y: 8.557888228 },
			{ x: "2018-01-01 11:15", y: 4.705642638 },
			{ x: "2018-01-01 11:30", y: 8.886924508 },
			{ x: "2018-01-01 11:45", y: 6.175888861 },
			{ x: "2018-01-01 12:00", y: 5.347939017 },
			{ x: "2018-01-01 12:15", y: 8.711513261 },
			{ x: "2018-01-01 12:30", y: 9.471201107 },
			{ x: "2018-01-01 12:45", y: 6.291273304 },
			{ x: "2018-01-01 13:00", y: 11.9734719 },
			{ x: "2018-01-01 13:15", y: 10.70378496 },
			{ x: "2018-01-01 13:30", y: 15.44369029 },
			{ x: "2018-01-01 13:45", y: 18.16191454 },
			{ x: "2018-01-01 14:00", y: 18.51900598 },
			{ x: "2018-01-01 14:15", y: 23.9228326 },
			{ x: "2018-01-01 14:30", y: 32.22860208 },
			{ x: "2018-01-01 14:45", y: 35.10082105 },
			{ x: "2018-01-01 15:00", y: 38.3757433 },
			{ x: "2018-01-01 15:15", y: 37.08384058 },
			{ x: "2018-01-01 15:30", y: 29.33766501 },
			{ x: "2018-01-01 15:45", y: 20.49913737 },
			{ x: "2018-01-01 16:00", y: 10.56096982 },
			{ x: "2018-01-01 16:15", y: 2.731273171 },
			{ x: "2018-01-01 16:30", y: 17.73983617 },
			{ x: "2018-01-01 16:45", y: 7.283132299 },
			{ x: "2018-01-01 17:00", y: 23.45659028 },
			{ x: "2018-01-01 17:15", y: 30.50493341 },
			{ x: "2018-01-01 17:30", y: 36.93975982 },
			{ x: "2018-01-01 17:45", y: 45.02938275 },
			{ x: "2018-01-01 18:00", y: 54.87286214 },
			{ x: "2018-01-01 18:15", y: 53.7778883 },
			{ x: "2018-01-01 18:30", y: 54.60966166 },
			{ x: "2018-01-01 18:45", y: 52.89811743 },
			{ x: "2018-01-01 19:00", y: 53.3083788 },
			{ x: "2018-01-01 19:15", y: 58.37103023 },
			{ x: "2018-01-01 19:30", y: 58.87888085 },
			{ x: "2018-01-01 19:45", y: 58.03190223 },
			{ x: "2018-01-01 20:00", y: 54.782 },
			{ x: "2018-01-01 20:15", y: 54.1681252 },
			{ x: "2018-01-01 20:30", y: 53.63441 },
			{ x: "2018-01-01 20:45", y: 54.12218 },
			{ x: "2018-01-01 21:00", y: 55.173612 },
			{ x: "2018-01-01 21:15", y: 54.5734 },
			{ x: "2018-01-01 21:30", y: 52.5616 },
			{ x: "2018-01-01 21:45", y: 51.12525 },
			{ x: "2018-01-01 22:00", y: 48.0756 },
			{ x: "2018-01-01 22:15", y: 52.24344268 },
			{ x: "2018-01-01 22:30", y: 56.67228535 },
			{ x: "2018-01-01 22:45", y: 56.6245141 },
			{ x: "2018-01-01 23:00", y: 54.344 },
			{ x: "2018-01-01 23:15", y: 51.33 },
			{ x: "2018-01-01 23:30", y: 47.912 },
			{ x: "2018-01-01 23:45", y: 47.912 },
			{ x: "2018-01-02 0:00", y: 47.912 },
		],
	},
];

export const bigDemoData = [
	{
		id: "BAU",
		data: [
			{ x: "2018-01-01 0:00", y: 137.8533121 },
			{ x: "2018-01-01 0:15", y: 135.50465605 },
			{ x: "2018-01-01 0:30", y: 133.156 },
			{ x: "2018-01-01 0:45", y: 130.435 },
			{ x: "2018-01-01 1:00", y: 127.714 },
			{ x: "2018-01-01 1:15", y: 127.209 },
			{ x: "2018-01-01 1:30", y: 126.704 },
			{ x: "2018-01-01 1:45", y: 124.424 },
			{ x: "2018-01-01 2:00", y: 122.144 },
			{ x: "2018-01-01 2:15", y: 125.66 },
			{ x: "2018-01-01 2:30", y: 129.176 },
			{ x: "2018-01-01 2:45", y: 129.179 },
			{ x: "2018-01-01 3:00", y: 129.182 },
			{ x: "2018-01-01 3:15", y: 128.80876471 },
			{ x: "2018-01-01 3:30", y: 128.43552941 },
			{ x: "2018-01-01 3:45", y: 126.61176471 },
			{ x: "2018-01-01 4:00", y: 124.788 },
			{ x: "2018-01-01 4:15", y: 125.4052 },
			{ x: "2018-01-01 4:30", y: 126.0224 },
			{ x: "2018-01-01 4:45", y: 128.7932 },
			{ x: "2018-01-01 5:00", y: 130.874 },
			{ x: "2018-01-01 5:15", y: 132.07557143 },
			{ x: "2018-01-01 5:30", y: 133.43414286 },
			{ x: "2018-01-01 5:45", y: 135.01957143 },
			{ x: "2018-01-01 6:00", y: 139.911 },
			{ x: "2018-01-01 6:15", y: 144.327 },
			{ x: "2018-01-01 6:30", y: 150.993 },
			{ x: "2018-01-01 6:45", y: 151.97465446 },
			{ x: "2018-01-01 7:00", y: 153.85430892 },
			{ x: "2018-01-01 7:15", y: 150.76687647 },
			{ x: "2018-01-01 7:30", y: 146.64695383 },
			{ x: "2018-01-01 7:45", y: 145.70641584 },
			{ x: "2018-01-01 8:00", y: 144.67664764 },
			{ x: "2018-01-01 8:15", y: 145.30748055 },
			{ x: "2018-01-01 8:30", y: 146.22403019 },
			{ x: "2018-01-01 8:45", y: 141.02735777 },
			{ x: "2018-01-01 9:00", y: 136.42537815 },
			{ x: "2018-01-01 9:15", y: 134.58365531 },
			{ x: "2018-01-01 9:30", y: 132.97673248 },
			{ x: "2018-01-01 9:45", y: 128.01630303 },
			{ x: "2018-01-01 10:00", y: 123.45422761 },
			{ x: "2018-01-01 10:15", y: 116.06529575 },
			{ x: "2018-01-01 10:30", y: 17.631796853 },
			{ x: "2018-01-01 10:45", y: 12.659947774 },
			{ x: "2018-01-01 11:00", y: -18.557888228 },
			{ x: "2018-01-01 11:15", y: -113.26516264 },
			{ x: "2018-01-01 11:30", y: -117.64816467 },
			{ x: "2018-01-01 11:45", y: -115.12320886 },
			{ x: "2018-01-01 12:00", y: -114.4276591 },
			{ x: "2018-01-01 12:15", y: -117.65031328 },
			{ x: "2018-01-01 12:30", y: -118.20636127 },
			{ x: "2018-01-01 12:45", y: -114.7450733 },
			{ x: "2018-01-01 13:00", y: -119.84779198 },
			{ x: "2018-01-01 13:15", y: -119.00566512 },
			{ x: "2018-01-01 13:30", y: -122.9079703 },
			{ x: "2018-01-01 13:45", y: -124.12227454 },
			{ x: "2018-01-01 14:00", y: -123.79720598 },
			{ x: "2018-01-01 14:15", y: -128.31071267 },
			{ x: "2018-01-01 14:30", y: -134.76220208 },
			{ x: "2018-01-01 14:45", y: -137.90522101 },
			{ x: "2018-01-01 15:00", y: -145.84198348 },
			{ x: "2018-01-01 15:15", y: -140.15308058 },
			{ x: "2018-01-01 15:30", y: -131.78074491 },
			{ x: "2018-01-01 15:45", y: -121.78741737 },
			{ x: "2018-01-01 16:00", y: -112.43528983 },
			{ x: "2018-01-01 16:15", y: 12.731273171 },
			{ x: "2018-01-01 16:30", y: 117.73983617 },
			{ x: "2018-01-01 16:45", y: 128.7807671 },
			{ x: "2018-01-01 17:00", y: 142.97424948 },
			{ x: "2018-01-01 17:15", y: 148.81331141 },
			{ x: "2018-01-01 17:30", y: 154.59637335 },
			{ x: "2018-01-01 17:45", y: 160.26035675 },
			{ x: "2018-01-01 18:00", y: 165.92434014 },
			{ x: "2018-01-01 18:15", y: 164.2917415 },
			{ x: "2018-01-01 18:30", y: 163.64114286 },
			{ x: "2018-01-01 18:45", y: 161.24177143 },
			{ x: "2018-01-01 19:00", y: 159.6864 },
			{ x: "2018-01-01 19:15", y: 161.90022703 },
			{ x: "2018-01-01 19:30", y: 164.11405405 },
			{ x: "2018-01-01 19:45", y: 161.08202703 },
			{ x: "2018-01-01 20:00", y: 158.05 },
			{ x: "2018-01-01 20:15", y: 157.468 },
			{ x: "2018-01-01 20:30", y: 156.886 },
			{ x: "2018-01-01 20:45", y: 157.166 },
			{ x: "2018-01-01 21:00", y: 157.446 },
			{ x: "2018-01-01 21:15", y: 156.126 },
			{ x: "2018-01-01 21:30", y: 154.806 },
			{ x: "2018-01-01 21:45", y: 152.678 },
			{ x: "2018-01-01 22:00", y: 150.55 },
			{ x: "2018-01-01 22:15", y: 154.34794268 },
			{ x: "2018-01-01 22:30", y: 158.14588535 },
			{ x: "2018-01-01 22:45", y: 156.6245141 },
			{ x: "2018-01-01 23:00", y: 154.748 },
			{ x: "2018-01-01 23:15", y: 151.33 },
			{ x: "2018-01-01 23:30", y: 147.912 },
			{ x: "2018-01-01 23:45", y: 147.912 },
			{ x: "2018-01-02 0:00", y: 147.912 },
		],
	},
	{
		id: "LEM",
		data: [
			{ x: "2018-01-01 0:00", y: 137.8533121 },
			{ x: "2018-01-01 0:15", y: 135.50465605 },
			{ x: "2018-01-01 0:30", y: 133.156 },
			{ x: "2018-01-01 0:45", y: 130.435 },
			{ x: "2018-01-01 1:00", y: 127.714 },
			{ x: "2018-01-01 1:15", y: 127.209 },
			{ x: "2018-01-01 1:30", y: 126.704 },
			{ x: "2018-01-01 1:45", y: 124.424 },
			{ x: "2018-01-01 2:00", y: 122.144 },
			{ x: "2018-01-01 2:15", y: 125.66 },
			{ x: "2018-01-01 2:30", y: 129.176 },
			{ x: "2018-01-01 2:45", y: 129.179 },
			{ x: "2018-01-01 3:00", y: 129.182 },
			{ x: "2018-01-01 3:15", y: 128.80876471 },
			{ x: "2018-01-01 3:30", y: 128.43552941 },
			{ x: "2018-01-01 3:45", y: 126.61176471 },
			{ x: "2018-01-01 4:00", y: 124.788 },
			{ x: "2018-01-01 4:15", y: 125.4052 },
			{ x: "2018-01-01 4:30", y: 126.0224 },
			{ x: "2018-01-01 4:45", y: 128.7932 },
			{ x: "2018-01-01 5:00", y: 130.874 },
			{ x: "2018-01-01 5:15", y: 132.07557143 },
			{ x: "2018-01-01 5:30", y: 133.43414286 },
			{ x: "2018-01-01 5:45", y: 135.01957143 },
			{ x: "2018-01-01 6:00", y: 139.911 },
			{ x: "2018-01-01 6:15", y: 144.327 },
			{ x: "2018-01-01 6:30", y: 150.993 },
			{ x: "2018-01-01 6:45", y: 151.97465446 },
			{ x: "2018-01-01 7:00", y: 153.85430892 },
			{ x: "2018-01-01 7:15", y: 150.76687647 },
			{ x: "2018-01-01 7:30", y: 146.64695383 },
			{ x: "2018-01-01 7:45", y: 145.70641584 },
			{ x: "2018-01-01 8:00", y: 144.67664764 },
			{ x: "2018-01-01 8:15", y: 145.30748055 },
			{ x: "2018-01-01 8:30", y: 146.22403019 },
			{ x: "2018-01-01 8:45", y: 141.02735777 },
			{ x: "2018-01-01 9:00", y: 136.42537815 },
			{ x: "2018-01-01 9:15", y: 134.58365531 },
			{ x: "2018-01-01 9:30", y: 132.97673248 },
			{ x: "2018-01-01 9:45", y: 128.01630303 },
			{ x: "2018-01-01 10:00", y: 123.45422761 },
			{ x: "2018-01-01 10:15", y: 116.06529575 },
			{ x: "2018-01-01 10:30", y: 17.631796853 },
			{ x: "2018-01-01 10:45", y: 12.659947774 },
			{ x: "2018-01-01 11:00", y: -18.557888228 },
			{ x: "2018-01-01 11:15", y: -14.705642638 },
			{ x: "2018-01-01 11:30", y: -18.886924508 },
			{ x: "2018-01-01 11:45", y: -16.175888861 },
			{ x: "2018-01-01 12:00", y: -15.347939017 },
			{ x: "2018-01-01 12:15", y: -18.711513261 },
			{ x: "2018-01-01 12:30", y: -19.471201107 },
			{ x: "2018-01-01 12:45", y: -16.291273304 },
			{ x: "2018-01-01 13:00", y: -111.9734719 },
			{ x: "2018-01-01 13:15", y: -110.70378496 },
			{ x: "2018-01-01 13:30", y: -115.44369029 },
			{ x: "2018-01-01 13:45", y: -118.16191454 },
			{ x: "2018-01-01 14:00", y: -118.51900598 },
			{ x: "2018-01-01 14:15", y: -123.9228326 },
			{ x: "2018-01-01 14:30", y: -132.22860208 },
			{ x: "2018-01-01 14:45", y: -135.10082105 },
			{ x: "2018-01-01 15:00", y: -138.3757433 },
			{ x: "2018-01-01 15:15", y: -137.08384058 },
			{ x: "2018-01-01 15:30", y: -129.33766501 },
			{ x: "2018-01-01 15:45", y: -120.49913737 },
			{ x: "2018-01-01 16:00", y: -110.56096982 },
			{ x: "2018-01-01 16:15", y: 12.731273171 },
			{ x: "2018-01-01 16:30", y: 117.73983617 },
			{ x: "2018-01-01 16:45", y: 17.283132299 },
			{ x: "2018-01-01 17:00", y: 123.45659028 },
			{ x: "2018-01-01 17:15", y: 130.50493341 },
			{ x: "2018-01-01 17:30", y: 136.93975982 },
			{ x: "2018-01-01 17:45", y: 145.02938275 },
			{ x: "2018-01-01 18:00", y: 154.87286214 },
			{ x: "2018-01-01 18:15", y: 153.7778883 },
			{ x: "2018-01-01 18:30", y: 154.60966166 },
			{ x: "2018-01-01 18:45", y: 152.89811743 },
			{ x: "2018-01-01 19:00", y: 153.3083788 },
			{ x: "2018-01-01 19:15", y: 158.37103023 },
			{ x: "2018-01-01 19:30", y: 158.87888085 },
			{ x: "2018-01-01 19:45", y: 158.03190223 },
			{ x: "2018-01-01 20:00", y: 154.782 },
			{ x: "2018-01-01 20:15", y: 154.1681252 },
			{ x: "2018-01-01 20:30", y: 153.63441 },
			{ x: "2018-01-01 20:45", y: 154.12218 },
			{ x: "2018-01-01 21:00", y: 155.173612 },
			{ x: "2018-01-01 21:15", y: 154.5734 },
			{ x: "2018-01-01 21:30", y: 152.5616 },
			{ x: "2018-01-01 21:45", y: 151.12525 },
			{ x: "2018-01-01 22:00", y: 148.0756 },
			{ x: "2018-01-01 22:15", y: 152.24344268 },
			{ x: "2018-01-01 22:30", y: 156.67228535 },
			{ x: "2018-01-01 22:45", y: 156.6245141 },
			{ x: "2018-01-01 23:00", y: 154.344 },
			{ x: "2018-01-01 23:15", y: 151.33 },
			{ x: "2018-01-01 23:30", y: 147.912 },
			{ x: "2018-01-01 23:45", y: 147.912 },
			{ x: "2018-01-02 0:00", y: 147.912 },
		],
	},
];
export const sameData = [
	{
		id: "BAU",
		data: [
			{ x: "2018-01-01 0:00", y: 37.8533121 },
			{ x: "2018-01-01 0:15", y: 35.50465605 },
			{ x: "2018-01-01 0:30", y: 33.156 },
			{ x: "2018-01-01 0:45", y: 30.435 },
			{ x: "2018-01-01 1:00", y: 27.714 },
			{ x: "2018-01-01 1:15", y: 27.209 },
			{ x: "2018-01-01 1:30", y: 26.704 },
			{ x: "2018-01-01 1:45", y: 24.424 },
			{ x: "2018-01-01 2:00", y: 22.144 },
			{ x: "2018-01-01 2:15", y: 25.66 },
			{ x: "2018-01-01 2:30", y: 29.176 },
			{ x: "2018-01-01 2:45", y: 29.179 },
			{ x: "2018-01-01 3:00", y: 29.182 },
			{ x: "2018-01-01 3:15", y: 28.80876471 },
			{ x: "2018-01-01 3:30", y: 28.43552941 },
			{ x: "2018-01-01 3:45", y: 26.61176471 },
			{ x: "2018-01-01 4:00", y: 24.788 },
			{ x: "2018-01-01 4:15", y: 25.4052 },
			{ x: "2018-01-01 4:30", y: 26.0224 },
			{ x: "2018-01-01 4:45", y: 28.7932 },
			{ x: "2018-01-01 5:00", y: 30.874 },
			{ x: "2018-01-01 5:15", y: 32.07557143 },
			{ x: "2018-01-01 5:30", y: 33.43414286 },
			{ x: "2018-01-01 5:45", y: 35.01957143 },
			{ x: "2018-01-01 6:00", y: 39.911 },
			{ x: "2018-01-01 6:15", y: 44.327 },
			{ x: "2018-01-01 6:30", y: 50.993 },
			{ x: "2018-01-01 6:45", y: 51.97465446 },
			{ x: "2018-01-01 7:00", y: 53.85430892 },
			{ x: "2018-01-01 7:15", y: 50.76687647 },
			{ x: "2018-01-01 7:30", y: 46.64695383 },
			{ x: "2018-01-01 7:45", y: 45.70641584 },
			{ x: "2018-01-01 8:00", y: 44.67664764 },
			{ x: "2018-01-01 8:15", y: 45.30748055 },
			{ x: "2018-01-01 8:30", y: 46.22403019 },
			{ x: "2018-01-01 8:45", y: 41.02735777 },
			{ x: "2018-01-01 9:00", y: 36.42537815 },
			{ x: "2018-01-01 9:15", y: 34.58365531 },
			{ x: "2018-01-01 9:30", y: 32.97673248 },
			{ x: "2018-01-01 9:45", y: 28.01630303 },
			{ x: "2018-01-01 10:00", y: 23.45422761 },
			{ x: "2018-01-01 10:15", y: 16.06529575 },
			{ x: "2018-01-01 10:30", y: 7.631796853 },
			{ x: "2018-01-01 10:45", y: 2.659947774 },
			{ x: "2018-01-01 11:00", y: -8.557888228 },
			{ x: "2018-01-01 11:15", y: -4.705642638 },
			{ x: "2018-01-01 11:30", y: -8.886924508 },
			{ x: "2018-01-01 11:45", y: -6.175888861 },
			{ x: "2018-01-01 12:00", y: -5.347939017 },
			{ x: "2018-01-01 12:15", y: -8.711513261 },
			{ x: "2018-01-01 12:30", y: -9.471201107 },
			{ x: "2018-01-01 12:45", y: -6.291273304 },
			{ x: "2018-01-01 13:00", y: -11.9734719 },
			{ x: "2018-01-01 13:15", y: -10.70378496 },
			{ x: "2018-01-01 13:30", y: -15.44369029 },
			{ x: "2018-01-01 13:45", y: -18.16191454 },
			{ x: "2018-01-01 14:00", y: -18.51900598 },
			{ x: "2018-01-01 14:15", y: -23.9228326 },
			{ x: "2018-01-01 14:30", y: -32.22860208 },
			{ x: "2018-01-01 14:45", y: -35.10082105 },
			{ x: "2018-01-01 15:00", y: -38.3757433 },
			{ x: "2018-01-01 15:15", y: -37.08384058 },
			{ x: "2018-01-01 15:30", y: -29.33766501 },
			{ x: "2018-01-01 15:45", y: -20.49913737 },
			{ x: "2018-01-01 16:00", y: -10.56096982 },
			{ x: "2018-01-01 16:15", y: 2.731273171 },
			{ x: "2018-01-01 16:30", y: 17.73983617 },
			{ x: "2018-01-01 16:45", y: 7.283132299 },
			{ x: "2018-01-01 17:00", y: 23.45659028 },
			{ x: "2018-01-01 17:15", y: 30.50493341 },
			{ x: "2018-01-01 17:30", y: 36.93975982 },
			{ x: "2018-01-01 17:45", y: 45.02938275 },
			{ x: "2018-01-01 18:00", y: 54.87286214 },
			{ x: "2018-01-01 18:15", y: 53.7778883 },
			{ x: "2018-01-01 18:30", y: 54.60966166 },
			{ x: "2018-01-01 18:45", y: 52.89811743 },
			{ x: "2018-01-01 19:00", y: 53.3083788 },
			{ x: "2018-01-01 19:15", y: 58.37103023 },
			{ x: "2018-01-01 19:30", y: 58.87888085 },
			{ x: "2018-01-01 19:45", y: 58.03190223 },
			{ x: "2018-01-01 20:00", y: 54.782 },
			{ x: "2018-01-01 20:15", y: 54.1681252 },
			{ x: "2018-01-01 20:30", y: 53.63441 },
			{ x: "2018-01-01 20:45", y: 54.12218 },
			{ x: "2018-01-01 21:00", y: 55.173612 },
			{ x: "2018-01-01 21:15", y: 54.5734 },
			{ x: "2018-01-01 21:30", y: 52.5616 },
			{ x: "2018-01-01 21:45", y: 51.12525 },
			{ x: "2018-01-01 22:00", y: 48.0756 },
			{ x: "2018-01-01 22:15", y: 52.24344268 },
			{ x: "2018-01-01 22:30", y: 56.67228535 },
			{ x: "2018-01-01 22:45", y: 56.6245141 },
			{ x: "2018-01-01 23:00", y: 54.344 },
			{ x: "2018-01-01 23:15", y: 51.33 },
			{ x: "2018-01-01 23:30", y: 47.912 },
			{ x: "2018-01-01 23:45", y: 47.912 },
			{ x: "2018-01-02 0:00", y: 47.912 },
		],
	},
	{
		id: "LEM",
		data: [
			{ x: "2018-01-01 0:00", y: 37.8533121 },
			{ x: "2018-01-01 0:15", y: 35.50465605 },
			{ x: "2018-01-01 0:30", y: 33.156 },
			{ x: "2018-01-01 0:45", y: 30.435 },
			{ x: "2018-01-01 1:00", y: 27.714 },
			{ x: "2018-01-01 1:15", y: 27.209 },
			{ x: "2018-01-01 1:30", y: 26.704 },
			{ x: "2018-01-01 1:45", y: 24.424 },
			{ x: "2018-01-01 2:00", y: 22.144 },
			{ x: "2018-01-01 2:15", y: 25.66 },
			{ x: "2018-01-01 2:30", y: 29.176 },
			{ x: "2018-01-01 2:45", y: 29.179 },
			{ x: "2018-01-01 3:00", y: 29.182 },
			{ x: "2018-01-01 3:15", y: 28.80876471 },
			{ x: "2018-01-01 3:30", y: 28.43552941 },
			{ x: "2018-01-01 3:45", y: 26.61176471 },
			{ x: "2018-01-01 4:00", y: 24.788 },
			{ x: "2018-01-01 4:15", y: 25.4052 },
			{ x: "2018-01-01 4:30", y: 26.0224 },
			{ x: "2018-01-01 4:45", y: 28.7932 },
			{ x: "2018-01-01 5:00", y: 30.874 },
			{ x: "2018-01-01 5:15", y: 32.07557143 },
			{ x: "2018-01-01 5:30", y: 33.43414286 },
			{ x: "2018-01-01 5:45", y: 35.01957143 },
			{ x: "2018-01-01 6:00", y: 39.911 },
			{ x: "2018-01-01 6:15", y: 44.327 },
			{ x: "2018-01-01 6:30", y: 50.993 },
			{ x: "2018-01-01 6:45", y: 51.97465446 },
			{ x: "2018-01-01 7:00", y: 53.85430892 },
			{ x: "2018-01-01 7:15", y: 50.76687647 },
			{ x: "2018-01-01 7:30", y: 46.64695383 },
			{ x: "2018-01-01 7:45", y: 45.70641584 },
			{ x: "2018-01-01 8:00", y: 44.67664764 },
			{ x: "2018-01-01 8:15", y: 45.30748055 },
			{ x: "2018-01-01 8:30", y: 46.22403019 },
			{ x: "2018-01-01 8:45", y: 41.02735777 },
			{ x: "2018-01-01 9:00", y: 36.42537815 },
			{ x: "2018-01-01 9:15", y: 34.58365531 },
			{ x: "2018-01-01 9:30", y: 32.97673248 },
			{ x: "2018-01-01 9:45", y: 28.01630303 },
			{ x: "2018-01-01 10:00", y: 23.45422761 },
			{ x: "2018-01-01 10:15", y: 16.06529575 },
			{ x: "2018-01-01 10:30", y: 7.631796853 },
			{ x: "2018-01-01 10:45", y: 2.659947774 },
			{ x: "2018-01-01 11:00", y: -8.557888228 },
			{ x: "2018-01-01 11:15", y: -4.705642638 },
			{ x: "2018-01-01 11:30", y: -8.886924508 },
			{ x: "2018-01-01 11:45", y: -6.175888861 },
			{ x: "2018-01-01 12:00", y: -5.347939017 },
			{ x: "2018-01-01 12:15", y: -8.711513261 },
			{ x: "2018-01-01 12:30", y: -9.471201107 },
			{ x: "2018-01-01 12:45", y: -6.291273304 },
			{ x: "2018-01-01 13:00", y: -11.9734719 },
			{ x: "2018-01-01 13:15", y: -10.70378496 },
			{ x: "2018-01-01 13:30", y: -15.44369029 },
			{ x: "2018-01-01 13:45", y: -18.16191454 },
			{ x: "2018-01-01 14:00", y: -18.51900598 },
			{ x: "2018-01-01 14:15", y: -23.9228326 },
			{ x: "2018-01-01 14:30", y: -32.22860208 },
			{ x: "2018-01-01 14:45", y: -35.10082105 },
			{ x: "2018-01-01 15:00", y: -38.3757433 },
			{ x: "2018-01-01 15:15", y: -37.08384058 },
			{ x: "2018-01-01 15:30", y: -29.33766501 },
			{ x: "2018-01-01 15:45", y: -20.49913737 },
			{ x: "2018-01-01 16:00", y: -10.56096982 },
			{ x: "2018-01-01 16:15", y: 2.731273171 },
			{ x: "2018-01-01 16:30", y: 17.73983617 },
			{ x: "2018-01-01 16:45", y: 7.283132299 },
			{ x: "2018-01-01 17:00", y: 23.45659028 },
			{ x: "2018-01-01 17:15", y: 30.50493341 },
			{ x: "2018-01-01 17:30", y: 36.93975982 },
			{ x: "2018-01-01 17:45", y: 45.02938275 },
			{ x: "2018-01-01 18:00", y: 54.87286214 },
			{ x: "2018-01-01 18:15", y: 53.7778883 },
			{ x: "2018-01-01 18:30", y: 54.60966166 },
			{ x: "2018-01-01 18:45", y: 52.89811743 },
			{ x: "2018-01-01 19:00", y: 53.3083788 },
			{ x: "2018-01-01 19:15", y: 58.37103023 },
			{ x: "2018-01-01 19:30", y: 58.87888085 },
			{ x: "2018-01-01 19:45", y: 58.03190223 },
			{ x: "2018-01-01 20:00", y: 54.782 },
			{ x: "2018-01-01 20:15", y: 54.1681252 },
			{ x: "2018-01-01 20:30", y: 53.63441 },
			{ x: "2018-01-01 20:45", y: 54.12218 },
			{ x: "2018-01-01 21:00", y: 55.173612 },
			{ x: "2018-01-01 21:15", y: 54.5734 },
			{ x: "2018-01-01 21:30", y: 52.5616 },
			{ x: "2018-01-01 21:45", y: 51.12525 },
			{ x: "2018-01-01 22:00", y: 48.0756 },
			{ x: "2018-01-01 22:15", y: 52.24344268 },
			{ x: "2018-01-01 22:30", y: 56.67228535 },
			{ x: "2018-01-01 22:45", y: 56.6245141 },
			{ x: "2018-01-01 23:00", y: 54.344 },
			{ x: "2018-01-01 23:15", y: 51.33 },
			{ x: "2018-01-01 23:30", y: 47.912 },
			{ x: "2018-01-01 23:45", y: 47.912 },
			{ x: "2018-01-02 0:00", y: 47.912 },
		],
	},
];

export const randomData = [
	{
		id: "BAU",
		data: [
			{ x: "2018-01-01 0:00", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 0:15", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 0:30", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 0:45", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 1:00", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 1:15", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 1:30", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 1:45", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 2:00", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 2:15", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 2:30", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 2:45", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 3:00", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 3:15", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 3:30", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 3:45", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 4:00", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 4:15", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 4:30", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 4:45", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 5:00", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 5:15", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 5:30", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 5:45", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 6:00", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 6:15", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 6:30", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 6:45", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 7:00", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 7:15", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 7:30", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 7:45", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 8:00", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 8:15", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 8:30", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 8:45", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 9:00", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 9:15", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 9:30", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 9:45", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 10:00", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 10:15", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 10:30", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 10:45", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 11:00", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 11:15", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 11:30", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 11:45", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 12:00", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 12:15", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 12:30", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 12:45", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 13:00", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 13:15", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 13:30", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 13:45", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 14:00", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 14:15", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 14:30", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 14:45", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 15:00", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 15:15", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 15:30", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 15:45", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 16:00", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 16:15", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 16:30", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 16:45", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 17:00", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 17:15", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 17:30", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 17:45", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 18:00", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 18:15", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 18:30", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 18:45", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 19:00", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 19:15", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 19:30", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 19:45", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 20:00", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 20:15", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 20:30", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 20:45", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 21:00", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 21:15", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 21:30", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 21:45", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 22:00", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 22:15", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 22:30", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 22:45", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 23:00", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 23:15", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 23:30", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 23:45", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-02 0:00", y: Math.round(Math.random() * 10) },
		],
	},
	{
		id: "LEM",
		data: [
			{ x: "2018-01-01 0:00", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 0:15", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 0:30", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 0:45", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 1:00", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 1:15", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 1:30", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 1:45", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 2:00", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 2:15", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 2:30", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 2:45", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 3:00", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 3:15", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 3:30", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 3:45", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 4:00", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 4:15", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 4:30", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 4:45", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 5:00", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 5:15", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 5:30", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 5:45", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 6:00", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 6:15", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 6:30", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 6:45", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 7:00", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 7:15", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 7:30", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 7:45", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 8:00", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 8:15", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 8:30", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 8:45", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 9:00", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 9:15", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 9:30", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 9:45", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 10:00", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 10:15", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 10:30", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 10:45", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 11:00", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 11:15", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 11:30", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 11:45", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 12:00", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 12:15", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 12:30", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 12:45", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 13:00", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 13:15", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 13:30", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 13:45", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 14:00", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 14:15", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 14:30", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 14:45", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 15:00", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 15:15", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 15:30", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 15:45", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 16:00", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 16:15", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 16:30", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 16:45", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 17:00", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 17:15", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 17:30", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 17:45", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 18:00", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 18:15", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 18:30", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 18:45", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 19:00", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 19:15", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 19:30", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 19:45", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 20:00", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 20:15", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 20:30", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 20:45", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 21:00", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 21:15", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 21:30", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 21:45", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 22:00", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 22:15", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 22:30", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 22:45", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 23:00", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 23:15", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 23:30", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-01 23:45", y: Math.round(Math.random() * 10) },
			{ x: "2018-01-02 0:00", y: Math.round(Math.random() * 10) },
		],
	},
];
