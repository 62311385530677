import { Flex } from "@powerledger/ui-component-lib";
import * as Sentry from "@sentry/react";
import { useCallback, useMemo } from "react";

import { LemLogo } from "../../../components";
import { useResetPasswordMutation } from "../../../types/generated/graphql";
import { ForgotPasswordEmailForm } from "./email-form";
import { ResendEmail } from "./resend-email";

export const ForgotPassword = () => {
	const [resetPasswordMutation, { data, reset, error }] = useResetPasswordMutation();

	const sendForgotPasswordCallback = useCallback(
		async (username) => {
			reset();
			try {
				await resetPasswordMutation({
					variables: {
						username,
					},
					errorPolicy: "all",
				});
			} catch (e: any) {
				Sentry.captureException(new Error(e));
			}
		},
		[reset, resetPasswordMutation],
	);

	// If we get a network error, we should show the user the error message.
	// We don't want the UI to know if our response was successful or not.

	const networkError = error?.networkError;
	const showSuccessScreen = data && !networkError;

	const renderStep = useMemo(
		() =>
			showSuccessScreen ? (
				<ResendEmail />
			) : (
				<ForgotPasswordEmailForm onSuccess={sendForgotPasswordCallback} onError={error} />
			),
		[showSuccessScreen, error, sendForgotPasswordCallback],
	);

	return (
		<Flex
			sx={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "center",
				height: "100vh",
				backgroundImage: "url(/ground-illustration.svg)",
				backgroundPositionY: "bottom",
				backgroundPositionX: "right",
				backgroundRepeat: "no-repeat",
				backgroundSize: ["90%", "63%", "53%", "37%"],
			}}
		>
			<Flex sx={{ width: "100%", px: 4, pt: 4, justifyContent: ["center", "flex-start"] }}>
				<LemLogo width={130} />
			</Flex>
			{renderStep}
		</Flex>
	);
};

export default ForgotPassword;
