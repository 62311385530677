import { useApolloClient } from "@apollo/client";
import {
	Box,
	Flex,
	Heading,
	IconArrow,
	IconLoadingOutlined,
	IconPillButton,
	Text,
} from "@powerledger/ui-component-lib";
import { FC, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink, useHistory } from "react-router-dom";

import { Footer, LemLogo } from "../../components";
import { useOkta } from "../../hooks";

export const PageNotFound: FC = () => {
	const { t } = useTranslation();
	const history = useHistory();
	const [logoutLoading, setLogoutLoading] = useState(false);

	const { authState, oktaAuth } = useOkta();
	const client = useApolloClient();
	const isAuthenticated = authState?.isAuthenticated;

	const logout = useCallback(async () => {
		setLogoutLoading(true);
		try {
			await oktaAuth?.signOut({
				revokeAccessToken: true,
				revokeRefreshToken: true,
			});
			await client.clearStore();
		} finally {
			setLogoutLoading(false);
		}
	}, [client, oktaAuth]);

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "center",
				height: "100vh",
				backgroundImage: "url(/background-illustration.svg)",
				backgroundPositionY: "bottom",
				backgroundPositionX: "right",
				backgroundRepeat: "no-repeat",
				backgroundSize: ["90%", "63%", "53%", "37%"],
			}}
		>
			<Flex
				sx={{
					position: "absolute",
					top: 0,
					left: 0,
					p: 4,
					width: "100%",
					justifyContent: "space-between",
				}}
			>
				<RouterLink to={"/dashboard"}>
					<LemLogo height={43} />
				</RouterLink>
				{isAuthenticated && (
					<Flex sx={{ alignItems: "center" }}>
						<IconPillButton
							outlined
							icon={!logoutLoading && <IconLoadingOutlined size={4} sx={{ ml: 2 }} />}
							sx={{ mr: 5 }}
							onClick={logout}
							disabled={logoutLoading}
						>
							{t("Log out")}
						</IconPillButton>
					</Flex>
				)}
			</Flex>
			<Flex
				sx={{
					"*": {
						fontSize: [20, 25, 40],
					},
					alignItems: "center",
				}}
			>
				<IconArrow size={90} sx={{ fill: "text", cursor: "pointer" }} onClick={() => history.push("/")} />
				<Heading>
					<Text sx={{ color: "warning", mr: 3 }}>{t("404!")}</Text>
					{t("This page could not be found.")}
				</Heading>
			</Flex>
			<Footer />
		</Box>
	);
};
