import { Flex, Modal } from "@powerledger/ui-component-lib";
import { FC } from "react";

import { CreateEditMeterProfile } from "./create-edit-meter-profile";
import { CreateEditMeterProfileModalProps } from "./create-edit-meter-profile-modal.types";

export const CreateEditMeterProfileModal: FC<CreateEditMeterProfileModalProps> = ({
	initialValues,
	editMode,
	showModal,
	onSubmit,
	onCancel,
}) => {
	return (
		<Modal
			visible={showModal}
			hideCloseButton
			sx={{
				display: "flex",
				justifyContent: "center",
				backgroundColor: "transparent",
			}}
		>
			<Flex
				sx={{
					width: 540,
					padding: 26,
					backgroundColor: "secondaryDark",
				}}
			>
				<CreateEditMeterProfile
					editMode={editMode}
					initialValues={initialValues}
					onSubmit={onSubmit}
					onCancel={onCancel}
				/>
			</Flex>
		</Modal>
	);
};
