import "react-dates/initialize";

import { BaselineProfileCriteria } from "./baseline-profile-criteria/baseline-profile-criteria";
import { BASELINE_PROFILE_CREATE_EDIT_STEPS, CreateEditMeterProfileViewProps } from "./create-edit-meter-profile.types";
import { UploadCsv } from "./upload-csv";

/**
 * Generates the view for creating or editing a baseline profile for meter.
 *
 * @param editMode - Flag indicating whether the view is in edit mode.
 * @param onCancel - Callback function for canceling the operation.
 * @param initialValues - Initial values for the profile being edited.
 * @param saveProfile - Callback function for saving the baseline profile for meter.
 * @param createEditProfileStep - The current step in the create/edit process.
 * @param uploadCsvFile - Callback function for uploading a CSV file.
 * @param skipToFileUpload - Callback function for skipping to the file upload step.
 * @return The generated view component.
 */
export const CreateEditMeterProfileView: React.FC<CreateEditMeterProfileViewProps> = ({
	editMode,
	onCancel,
	initialValues = {
		id: null,
		name: "",
		profileClass: null,
		startDate: null,
		endDate: null,
		month: null,
		type: null,
		isDefault: false,
	},
	saveProfile,
	createEditProfileStep,
	uploadCsvFile,
	skipToFileUpload,
}) => {
	if (createEditProfileStep === BASELINE_PROFILE_CREATE_EDIT_STEPS.DEFINE_PROFILE_CRITERIA) {
		return (
			<BaselineProfileCriteria
				initialValues={initialValues}
				editMode={editMode}
				onSkip={skipToFileUpload}
				onSave={saveProfile}
				onClose={onCancel}
			/>
		);
	}

	if (createEditProfileStep === BASELINE_PROFILE_CREATE_EDIT_STEPS.UPLOAD_CSV) {
		/**
		 * @todo - pass dynamic value to `fileAlreadyUploaded`
		 *
		 * Based on baseline profile status, decide the value for `fileAlreadyUploaded`,
		 * only being in edit mode doesn't provide enough information
		 * if the file was already uploaded or not
		 */
		return <UploadCsv fileAlreadyUploaded={false} onSave={uploadCsvFile} onClose={onCancel} />;
	}

	return null;
};
