import {
	Button,
	Flex,
	Heading,
	IconEdit,
	IconRefreshCircle,
	IconTrash,
	InfoSVG,
	Table,
	Text,
	Tooltip,
} from "@powerledger/ui-component-lib";
import { format } from "date-fns";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Column, Row } from "react-table";

import { Profile, ProfileClass, ProfileType } from "@/app/types/generated/graphql";
import { getDefaultTableTranslation } from "@/app/util";

import { MONTHS_OPTIONS } from "../create-edit-meter-profile-modal";
import { DeleteBaselineProfileModal } from "./delete-baseline-profile-modal";
import { ProfileClassLabel, ProfileOverviewTableViewProps, ProfileTypeLabel } from "./profile-overview-table.types";

/**
 * React component that displays a table of profile overviews.
 *
 * @param profileTemplates - An array of profile data to be displayed.
 * @param loading - A boolean indicating whether the table is currently loading data.
 * @param onEditProfile - A function that handles the event of clicking the "Edit Profile" button for a specific profile.
 * @param onDeleteProfile - A function that handles the event of clicking the "Delete Profile" button for a specific profile.
 * @param selectedBaselineProfileId - The ID of the selected baseline profile.
 * @param deleteBaselineProfileModalVisible - A boolean indicating whether the delete baseline profile modal is visible.
 * @param hideDeleteBaselineProfileModal - A function that handles the event of hiding the delete baseline profile modal.
 * @returns A React component that displays a table of profile overviews.
 */
export const ProfileOverviewTableView: FC<ProfileOverviewTableViewProps> = ({
	profileTemplates,
	loading,
	refetchProfileTemplates,
	onEditProfile,
	onDeleteProfile,
	selectedBaselineProfileId,
	deleteBaselineProfileModalVisible,
	hideDeleteBaselineProfileModal,
}) => {
	const { t } = useTranslation();

	const tableColumns = useMemo<Column<Profile>[]>(
		() => [
			{
				accessor: "name",
				Header: t("Profile Name"),
				Cell: ({ value }) => {
					return (
						<Flex title={t(value)}>
							<Text
								sx={{
									width: ["100px", "150px", "150px", "200px"],
									overflow: "hidden",
									textOverflow: "ellipsis",
								}}
							>
								{t(value)}
							</Text>
						</Flex>
					);
				},
			},
			{
				accessor: "profileClass",
				disableSortBy: true,
				Header: <Flex sx={{ justifyContent: "center", flex: 1 }}>{t("Category")}</Flex>,
				Cell: ({ value }: { value: ProfileClass }) => {
					return (
						<Flex sx={{ justifyContent: "center" }}>
							<Text>{t(ProfileClassLabel[value])}</Text>
						</Flex>
					);
				},
			},
			{
				accessor: "type",
				disableSortBy: true,
				Header: <Flex sx={{ justifyContent: "center", flex: 1 }}>{t("Type")}</Flex>,
				Cell: ({ value }: { value: ProfileType }) => {
					return <Flex sx={{ justifyContent: "center" }}>{t(ProfileTypeLabel[value])}</Flex>;
				},
			},
			{
				id: "timeframe",
				Header: <Flex sx={{ justifyContent: "center", flex: 1 }}>{t("Applied Time Range")}</Flex>,
				Cell: ({ row }: { row: Row<Profile> }) => {
					if (row.original.type === ProfileType.Day) {
						return (
							<Flex sx={{ justifyContent: "center" }}>
								<Text>{row.original.day ? format(new Date(row.original.day), "dd.MM") : "-"}</Text>
							</Flex>
						);
					} else if (row.original.type === ProfileType.Month) {
						return (
							<Flex sx={{ justifyContent: "center" }}>
								<Text>{row.original.month ? t(MONTHS_OPTIONS[row.original.month - 1].label) : "-"}</Text>
							</Flex>
						);
					} else {
						return (
							<Flex sx={{ justifyContent: "center" }}>
								<Text>
									{row.original.seasonStart && row.original.seasonEnd
										? `${t(format(new Date(row.original.seasonStart), "dd.MMM"))} - ${t(
												format(new Date(row.original.seasonEnd), "dd.MMM"),
										  )}`
										: "-"}
								</Text>
							</Flex>
						);
					}
				},
			},
			{
				accessor: "numberOfMeters",
				disableSortBy: true,
				Header: (
					<Flex sx={{ justifyContent: "center", flex: 1 }}>
						<Text>{t("Assigned meters")}</Text>
					</Flex>
				),
				Cell: ({ value }: { value: Profile["numberOfMeters"] }) => {
					return <Flex sx={{ justifyContent: "center", paddingRight: 3 }}>{t(value?.toString() ?? "-")}</Flex>;
				},
			},
			{
				accessor: "energyData",
				disableSortBy: true,
				Header: (
					<Flex sx={{ justifyContent: "center", flex: 1 }}>
						<Text>{t("CSV file status")}</Text>
					</Flex>
				),
				Cell: ({ value }: { value: Profile["energyData"] }) => {
					const dataExist = (value?.length ?? 0) > 0;
					return (
						<Flex sx={{ justifyContent: "center", width: "100%" }}>
							<Button
								variant="pill.compact"
								as="div"
								sx={{
									fontWeight: "normal",
									cursor: "text",
									padding: "0.25rem 0.5rem",
									width: "100px",
									...(dataExist ? {} : { backgroundColor: "#FF7283", border: "none" }),
								}}
							>
								{t(dataExist ? "Added" : "Not Added")}
							</Button>
						</Flex>
					);
				},
			},
			{
				Header: <Flex sx={{ justifyContent: "center", flex: 1 }}>{t("Edit Profile")}</Flex>,
				id: "edit-profile",
				Cell: ({ row }: { row: Row<Profile> }) => {
					return (
						<Button
							variant="secondary"
							onClick={() => onEditProfile(row.original)}
							data-testid="edit-profile-button"
							sx={{
								display: "flex",
								justifyContent: "center",
								borderBottom: 0,
								backgroundColor: "transparent",
								width: "100%",
							}}
						>
							<IconEdit />
						</Button>
					);
				},
			},
			{
				accessor: "isDefault",
				Header: (
					<Flex sx={{ justifyContent: "center", flex: 1 }}>
						<Text>{t("Default Profile")}</Text>
						<Tooltip
							id={"profile-overview-set-as-default-tooltip"}
							content={
								<Text>
									{t(
										"Setting a profile as default will assign this profile as a starting point to every meter of the trading group. To change this selecting, select 'Edit Profile' or add a new profile.",
									)}
								</Text>
							}
						>
							<InfoSVG />
						</Tooltip>
					</Flex>
				),
				disableSortBy: true,
				Cell: ({ value }: { value: boolean }) => {
					return (
						<Flex sx={{ justifyContent: "center", width: "100%" }}>
							{value ? (
								<Button
									variant="pill.compact"
									as="div"
									sx={{
										fontWeight: "normal",
										cursor: "text",
										padding: "0.25rem 0.5rem",
										width: "100px",
									}}
								>
									{t("Default")}
								</Button>
							) : (
								<Button
									variant="pill.compact"
									as="div"
									sx={{
										fontWeight: "normal",
										cursor: "text",
										padding: "0.25rem 0.5rem",
										width: "100px",
										backgroundColor: "#B7A5FF",
										border: "none",
									}}
								>
									{t("Non Default")}
								</Button>
							)}
						</Flex>
					);
				},
			},
			{
				id: "delete-profile",
				Cell: ({ row }: { row: Row<Profile> }) => {
					return (
						<Button
							variant="secondary"
							onClick={() => {
								onDeleteProfile(row.id);
							}}
							data-testid="delete-profile-button"
							sx={{
								borderBottom: 0,
								backgroundColor: "transparent",
							}}
						>
							<IconTrash color={"#FF7283"} stroke={"#FF7283"} />
						</Button>
					);
				},
			},
		],
		[t, onDeleteProfile, onEditProfile],
	);

	return (
		<>
			<Flex sx={{ height: "64px", mb: 4, alignItems: "flex-end", justifyContent: "space-between" }}>
				<Heading as="h2">{t("Profile Overview")}</Heading>
				<Button
					variant="underlined"
					onClick={() => refetchProfileTemplates()}
					sx={{
						fontSize: 14,
					}}
				>
					<Text>{t("Refresh Table")}</Text>
					<IconRefreshCircle
						sx={{
							height: 28,
							width: 28,
							transform: "rotate(45deg)",
							mr: -2,
						}}
						stroke={"green"}
					/>
				</Button>
			</Flex>
			<Table
				loading={loading}
				columns={tableColumns}
				dataSource={profileTemplates ?? []}
				pagination={false}
				translation={getDefaultTableTranslation()}
				showScrollArrows
			/>
			{selectedBaselineProfileId && (
				<DeleteBaselineProfileModal
					onConfirm={hideDeleteBaselineProfileModal}
					onCancel={hideDeleteBaselineProfileModal}
					showModal={deleteBaselineProfileModalVisible}
					baselineProfileId={selectedBaselineProfileId}
				/>
			)}
		</>
	);
};
