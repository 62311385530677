import { Box, Button, Flex } from "@powerledger/ui-component-lib";
import { FC, Fragment } from "react";
import { useTranslation } from "react-i18next";

import { BaselineForecastProfileViewProps } from "./baseline-forecast-profile.types";
import { BaselineProfileVisualization } from "./baseline-profile-visualization";
import { CreateEditMeterProfileModal } from "./create-edit-meter-profile-modal";
import { ProfileOverviewTable } from "./profile-overview-table";

export const BaselineForecastProfileView: FC<BaselineForecastProfileViewProps> = ({
	editMode,
	onEditProfile,
	initialValues,
	shouldShowCreateEditProfileModal,
	showCreateEditMeterProfileModal,
	hideCreateEditMeterProfileModal,
	goBack,
}) => {
	const { t } = useTranslation();

	return (
		<Fragment>
			<Flex sx={{ flexDirection: "column", height: "100%", p: 4 }}>
				<Box sx={{ mb: 4 }}>
					<ProfileOverviewTable onEditProfile={onEditProfile} />
				</Box>
				<Flex sx={{ justifyContent: "space-between" }}>
					<Button as="a" variant="primary" onClick={goBack} sx={{ paddingX: 4 }}>
						{t("Back")}
					</Button>

					<Button variant="primary" onClick={showCreateEditMeterProfileModal}>
						{t("Add new profile")}
					</Button>
				</Flex>

				<Box pt={4}>
					<BaselineProfileVisualization />
				</Box>
			</Flex>

			<CreateEditMeterProfileModal
				editMode={editMode}
				{...(initialValues ? { initialValues } : {})}
				showModal={shouldShowCreateEditProfileModal}
				onSubmit={hideCreateEditMeterProfileModal}
				onCancel={hideCreateEditMeterProfileModal}
			/>
		</Fragment>
	);
};
