import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BigDecimal: number;
  DateTime: string;
  LocalTime: any;
  MonthDay: any;
  PositiveInt: number;
  UUID: string;
};

export type ActivateRoleInput = {
  id: Scalars['UUID'];
};

export type ActivateRolePayload = {
  __typename?: 'ActivateRolePayload';
  activeStatus?: Maybe<Scalars['Boolean']>;
};

export type ApplicationHost = {
  __typename?: 'ApplicationHost';
  /** Currently, the value of this will be UUID String user, or the Auditor Type i.e. 'PLATFORM' */
  createdBy?: Maybe<Scalars['String']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['UUID'];
  /** Currently, the value of this will be UUID String user, or the Auditor Type i.e. 'PLATFORM' */
  lastModifiedBy?: Maybe<Scalars['String']>;
  lastModifiedDate?: Maybe<Scalars['DateTime']>;
  location?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  tenantId: Scalars['UUID'];
  tenantName: Scalars['String'];
  urlPrefix?: Maybe<Scalars['String']>;
};

export type ApplicationHostFilter = {
  applicationHostIds?: InputMaybe<Array<Scalars['UUID']>>;
  keyword?: InputMaybe<Scalars['String']>;
  tenantIds?: InputMaybe<Array<Scalars['UUID']>>;
};

/** Mutation data to create Application Host */
export type ApplicationHostInput = {
  /** Physical location where application host is located */
  location?: InputMaybe<Scalars['String']>;
  /** Application host name */
  name: Scalars['String'];
  /** Identifier for tenant that this application host is associated to */
  tenantId: Scalars['UUID'];
  /** Name of tenant that this application host is associated to */
  tenantName: Scalars['String'];
  /** URL prefix for the application host. Will be used to create url as: {protocol}{urlPrefix}.{domain}. */
  urlPrefix?: InputMaybe<Scalars['String']>;
};

export type ApplicationHostsResult = {
  __typename?: 'ApplicationHostsResult';
  applicationHosts: Array<ApplicationHost>;
  pageInfo?: Maybe<OffsetPaginationInfo>;
};

export enum AssetGenerationType {
  Biomass = 'BIOMASS',
  Grid = 'GRID',
  Hydro = 'HYDRO',
  Nuclear = 'NUCLEAR',
  SolarPv = 'SOLAR_PV',
  Unknown = 'UNKNOWN',
  Wind = 'WIND'
}

export enum AssetSubType {
  Battery = 'BATTERY',
  Household = 'HOUSEHOLD',
  Inverter = 'INVERTER',
  Load = 'LOAD'
}

export type BillingAccount = {
  __typename?: 'BillingAccount';
  accountDisplayName?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  address?: Maybe<BillingAccountAddress>;
  applicationHostName?: Maybe<Scalars['String']>;
  billingInfo?: Maybe<BillingAccountInfo>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  tenantName?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  tradingGroupName?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['UUID']>;
};

export type BillingAccountAddress = {
  __typename?: 'BillingAccountAddress';
  addressLine?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  suburb?: Maybe<Scalars['String']>;
};

export type BillingAccountAddressInput = {
  addressLine?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  postcode?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  suburb?: InputMaybe<Scalars['String']>;
};

export type BillingAccountInfo = {
  __typename?: 'BillingAccountInfo';
  dateOfFirstBill?: Maybe<Scalars['DateTime']>;
  invoiceInterval?: Maybe<InvoiceInterval>;
  lastInvoiceDateTime?: Maybe<Scalars['DateTime']>;
  manualInvoice?: Maybe<Scalars['Boolean']>;
  nextInvoiceDateTime?: Maybe<Scalars['DateTime']>;
};

export type BillingAccountInfoInput = {
  dateOfFirstBill?: InputMaybe<Scalars['DateTime']>;
  invoiceInterval?: InputMaybe<InvoiceInterval>;
  lastInvoiceDateTime?: InputMaybe<Scalars['DateTime']>;
  manualInvoice?: InputMaybe<Scalars['Boolean']>;
  nextInvoiceDateTime?: InputMaybe<Scalars['DateTime']>;
};

export type BillingAccountInput = {
  accountDisplayName?: InputMaybe<Scalars['String']>;
  accountNumber?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<BillingAccountAddressInput>;
  applicationHostName?: InputMaybe<Scalars['String']>;
  billingInfo?: InputMaybe<BillingAccountInfoInput>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  ownershipDateRange?: InputMaybe<OwnershipDateRangeInput>;
  tenantName?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  tradingGroupName?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['UUID']>;
};

export type BillingAccountOwnership = {
  __typename?: 'BillingAccountOwnership';
  accountNumber?: Maybe<Scalars['String']>;
  billingAccount?: Maybe<BillingAccount>;
  tradingGroupName?: Maybe<Scalars['String']>;
};

export type BillingAccountRef = {
  __typename?: 'BillingAccountRef';
  accountNumber?: Maybe<Scalars['String']>;
  tradingGroupName?: Maybe<Scalars['String']>;
};

export type Command = {
  __typename?: 'Command';
  applicationHostId?: Maybe<Scalars['UUID']>;
  commandEnd: Scalars['DateTime'];
  commandStart: Scalars['DateTime'];
  id: Scalars['UUID'];
  meterId: Scalars['UUID'];
  operation?: Maybe<Operation>;
  power: Scalars['BigDecimal'];
  settlementId: Scalars['UUID'];
  status: CommandStatus;
  tenantId?: Maybe<Scalars['UUID']>;
  transactionId: Scalars['UUID'];
  type: CommandType;
  vendor: Scalars['String'];
  vendorDispatchId?: Maybe<Scalars['String']>;
};

export type CommandFilter = {
  applicationHostId: Scalars['UUID'];
  date?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['UUID']>;
  meterId: Scalars['UUID'];
  status?: InputMaybe<CommandStatus>;
  tenantId: Scalars['UUID'];
  transactionId: Scalars['UUID'];
};

export enum CommandStatus {
  Created = 'CREATED',
  ExecutedFailure = 'EXECUTED_FAILURE',
  ExecutedSuccess = 'EXECUTED_SUCCESS'
}

export enum CommandType {
  Created = 'CREATED',
  Updated = 'UPDATED'
}

/** Input definition to create new role */
export type CreateNewRoleInput = {
  /** Priority level of role */
  level: Scalars['Int'];
  /** Name of role */
  name: Scalars['String'];
  /** Permissions associated with role */
  permissions: Array<Scalars['String']>;
  /** Does this requires tenancy checks? */
  requiresTenancyCheck: Scalars['Boolean'];
  /** Status of Role */
  status: RoleStatus;
  /** Is this a system role? */
  systemRoleEnabled: Scalars['Boolean'];
};

export type CreateNewTenantInput = {
  features?: InputMaybe<Array<TenantFeature>>;
  location?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type CreateRolePayload = {
  __typename?: 'CreateRolePayload';
  role?: Maybe<Role>;
};

export type CreateUserError = {
  __typename?: 'CreateUserError';
  message: Scalars['String'];
  username: Scalars['String'];
};

export type DateRange = {
  __typename?: 'DateRange';
  from?: Maybe<Scalars['DateTime']>;
  to?: Maybe<Scalars['DateTime']>;
};

export type DeactivateRoleInput = {
  id: Scalars['UUID'];
};

export type DeactivateRolePayload = {
  __typename?: 'DeactivateRolePayload';
  deactiveStatus?: Maybe<Scalars['Boolean']>;
};

export enum DefaultEnergyUnit {
  GWh = 'GWh',
  MWh = 'MWh',
  Wh = 'Wh',
  KWh = 'kWh'
}

export type DefaultProfilesInput = {
  applicationHostId: Scalars['UUID'];
  defaultProfiles: Array<Scalars['UUID']>;
  tenantId: Scalars['UUID'];
  tradingGroupId: Scalars['UUID'];
};

export type DeleteMeterProfileInput = {
  meterId: Scalars['UUID'];
  profileId: Scalars['UUID'];
  tenantId: Scalars['UUID'];
};

export type EmissionsIntensityTuple = {
  __typename?: 'EmissionsIntensityTuple';
  key: Scalars['String'];
  value: Scalars['Float'];
};

export type EmissionsIntensityTupleMutationInput = {
  key: Scalars['String'];
  value: Scalars['Float'];
};

export type EnergyTransaction = {
  __typename?: 'EnergyTransaction';
  amount: Scalars['BigDecimal'];
  amountExTax: Scalars['BigDecimal'];
  applicationHostId: Scalars['UUID'];
  blockchainTxId?: Maybe<Scalars['String']>;
  co2Grid?: Maybe<Scalars['BigDecimal']>;
  co2Saved?: Maybe<Scalars['BigDecimal']>;
  counterPartyMeterId: Scalars['UUID'];
  counterPartyUserId: Scalars['UUID'];
  energyTraded: Scalars['BigDecimal'];
  generatorType: GeneratorType;
  gridRate?: Maybe<Scalars['BigDecimal']>;
  id: Scalars['UUID'];
  isInitiator: Scalars['Boolean'];
  meterId: Scalars['UUID'];
  meterType: MeterType;
  rateId?: Maybe<Scalars['UUID']>;
  ruleType: RuleType;
  settlementId: Scalars['UUID'];
  settlementPeriodIndex: Scalars['Int'];
  taxRate: Scalars['BigDecimal'];
  tenantId: Scalars['UUID'];
  tradingGroupId: Scalars['UUID'];
  transactionCategory: TransactionCategory;
  transactionEndDateTime: Scalars['DateTime'];
  transactionStartDateTime: Scalars['DateTime'];
  transactionType: TransactionType;
  unitPrice: Scalars['BigDecimal'];
  userId: Scalars['UUID'];
};

export enum EnergyUnit {
  Gwh = 'Gwh',
  Mwh = 'Mwh',
  Wh = 'Wh',
  KWh = 'kWh',
  MW = 'mW',
  UW = 'uW'
}

export enum GeneratorType {
  Battery = 'BATTERY',
  Biomass = 'BIOMASS',
  FossilFuel = 'FOSSIL_FUEL',
  Grid = 'GRID',
  Hydro = 'HYDRO',
  Nuclear = 'NUCLEAR',
  SolarPv = 'SOLAR_PV',
  Unknown = 'UNKNOWN',
  Wind = 'WIND'
}

export type GetEnergyDataUploadUrlInput = {
  profileId: Scalars['UUID'];
  tenancyInfo: TenancyInfo;
};

export type GetEnergyDataUploadUrlPayload = {
  __typename?: 'GetEnergyDataUploadURLPayload';
  url: Scalars['String'];
};

export type InboxMessage = {
  __typename?: 'InboxMessage';
  body?: Maybe<Scalars['String']>;
  dateRead?: Maybe<Scalars['DateTime']>;
  dateReceived: Scalars['DateTime'];
  id: Scalars['String'];
  recipient: MessageContact;
  sender?: Maybe<MessageContact>;
  status: MessageStatus;
  subject?: Maybe<Scalars['String']>;
};

export enum InvoiceInterval {
  Bimonthly = 'BIMONTHLY',
  Fortnightly = 'FORTNIGHTLY',
  HalfYearly = 'HALF_YEARLY',
  Monthly = 'MONTHLY',
  Quarterly = 'QUARTERLY',
  Weekly = 'WEEKLY',
  Yearly = 'YEARLY'
}

export type MessageContact = {
  __typename?: 'MessageContact';
  contactId?: Maybe<Scalars['UUID']>;
  contactName?: Maybe<Scalars['String']>;
};

export enum MessageStatus {
  Read = 'READ',
  Unread = 'UNREAD'
}

export type Meter = {
  __typename?: 'Meter';
  activeRanges?: Maybe<Array<Maybe<MeterActiveRange>>>;
  address?: Maybe<MeterAddress>;
  applicationHostId?: Maybe<Scalars['UUID']>;
  applicationHostName?: Maybe<Scalars['String']>;
  assetCapacityInWatts?: Maybe<Scalars['Int']>;
  assetGenerationType?: Maybe<AssetGenerationType>;
  assetSubType?: Maybe<AssetSubType>;
  assetType: MeterAssetType;
  description?: Maybe<Scalars['String']>;
  energyUnit?: Maybe<EnergyUnit>;
  expectedReadingsIntervalInSecs?: Maybe<Scalars['Int']>;
  isKeyMeter?: Maybe<Scalars['Boolean']>;
  maxReadingValue?: Maybe<Scalars['String']>;
  meterDisplayName?: Maybe<Scalars['String']>;
  meterExternalRef: Scalars['String'];
  meterId: Scalars['UUID'];
  minReadingValue?: Maybe<Scalars['String']>;
  ownerships?: Maybe<Array<Maybe<Ownership>>>;
  tenantId?: Maybe<Scalars['UUID']>;
  tenantName?: Maybe<Scalars['String']>;
  tradingGroupId?: Maybe<Scalars['UUID']>;
  tradingGroupName?: Maybe<Scalars['String']>;
};

export type MeterActiveRange = {
  __typename?: 'MeterActiveRange';
  from?: Maybe<Scalars['DateTime']>;
  to?: Maybe<Scalars['DateTime']>;
};

export type MeterActiveRangeInput = {
  from?: InputMaybe<Scalars['DateTime']>;
  to?: InputMaybe<Scalars['DateTime']>;
};

export type MeterAddress = {
  __typename?: 'MeterAddress';
  addressLine?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  postcode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  suburb?: Maybe<Scalars['String']>;
  timeZone?: Maybe<Scalars['String']>;
};

export type MeterAddressInput = {
  addressLine?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
  postcode?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  suburb?: InputMaybe<Scalars['String']>;
  timeZone?: InputMaybe<Scalars['String']>;
};

export enum MeterAssetType {
  Consumer = 'CONSUMER',
  Grid = 'GRID',
  Producer = 'PRODUCER',
  Prosumer = 'PROSUMER',
  ProsumerBess = 'PROSUMER_BESS'
}

export type MeterForecast = {
  __typename?: 'MeterForecast';
  applicationHostId: Scalars['UUID'];
  bess?: Maybe<Array<Scalars['BigDecimal']>>;
  generation?: Maybe<Array<Scalars['BigDecimal']>>;
  id: Scalars['UUID'];
  load?: Maybe<Array<Scalars['BigDecimal']>>;
  tenantId: Scalars['UUID'];
  tradingGroupId: Scalars['UUID'];
};

export type MeterInput = {
  activeRanges?: InputMaybe<Array<InputMaybe<MeterActiveRangeInput>>>;
  address?: InputMaybe<MeterAddressInput>;
  applicationHostId?: InputMaybe<Scalars['UUID']>;
  applicationHostName?: InputMaybe<Scalars['String']>;
  assetCapacityInWatts?: InputMaybe<Scalars['Int']>;
  assetGenerationType?: InputMaybe<AssetGenerationType>;
  assetSubType?: InputMaybe<AssetSubType>;
  assetType: MeterAssetType;
  description?: InputMaybe<Scalars['String']>;
  energyUnit?: InputMaybe<EnergyUnit>;
  expectedReadingsIntervalInSecs?: InputMaybe<Scalars['Int']>;
  isKeyMeter?: InputMaybe<Scalars['Boolean']>;
  maxReadingValue?: InputMaybe<Scalars['String']>;
  meterDisplayName?: InputMaybe<Scalars['String']>;
  meterExternalRef?: InputMaybe<Scalars['String']>;
  meterId: Scalars['UUID'];
  minReadingValue?: InputMaybe<Scalars['String']>;
  ownerships?: InputMaybe<Array<InputMaybe<OwnershipInput>>>;
  tenantId?: InputMaybe<Scalars['UUID']>;
  tenantName?: InputMaybe<Scalars['String']>;
  tradingGroupId?: InputMaybe<Scalars['UUID']>;
  tradingGroupName?: InputMaybe<Scalars['String']>;
};

export type MeterProfile = {
  __typename?: 'MeterProfile';
  applicationHostId: Scalars['UUID'];
  day?: Maybe<Scalars['DateTime']>;
  energyData?: Maybe<Array<Scalars['BigDecimal']>>;
  id: Scalars['UUID'];
  interval: Scalars['PositiveInt'];
  isDefault?: Maybe<Scalars['Boolean']>;
  maxEnergyBess?: Maybe<Scalars['BigDecimal']>;
  maxEnergyGeneration?: Maybe<Scalars['BigDecimal']>;
  maxEnergyLoad?: Maybe<Scalars['BigDecimal']>;
  month?: Maybe<Scalars['PositiveInt']>;
  name: Scalars['String'];
  numberOfMeters?: Maybe<Scalars['Int']>;
  profileClass: ProfileClass;
  seasonEnd?: Maybe<Scalars['MonthDay']>;
  seasonStart?: Maybe<Scalars['MonthDay']>;
  tenantId: Scalars['UUID'];
  tradingGroupId: Scalars['UUID'];
  type: ProfileType;
};

export type MeterProfileValuesInput = {
  date: Scalars['DateTime'];
  meterId: Scalars['UUID'];
  profileClass: ProfileClass;
  tenantId: Scalars['UUID'];
};

export type MeterProfiles = {
  __typename?: 'MeterProfiles';
  applicationHostId: Scalars['UUID'];
  id: Scalars['UUID'];
  profiles?: Maybe<Array<MeterProfile>>;
  tenantId: Scalars['UUID'];
  tradingGroupId: Scalars['UUID'];
};

export type MeterProfilesFilter = {
  applicationHostId: Scalars['UUID'];
  id: Scalars['UUID'];
  profileClass?: InputMaybe<ProfileClass>;
  tenantId: Scalars['UUID'];
  tradingGroupId: Scalars['UUID'];
};

export type MeterProfilesInput = {
  applicationHostId: Scalars['UUID'];
  id: Scalars['UUID'];
  maxEnergyBess?: InputMaybe<Scalars['BigDecimal']>;
  maxEnergyGeneration?: InputMaybe<Scalars['BigDecimal']>;
  maxEnergyLoad?: InputMaybe<Scalars['BigDecimal']>;
  profileId: Scalars['UUID'];
  tenantId: Scalars['UUID'];
  tradingGroupId: Scalars['UUID'];
};

export type MeterRef = {
  __typename?: 'MeterRef';
  applicationHostId?: Maybe<Scalars['UUID']>;
  applicationHostName?: Maybe<Scalars['String']>;
  meterId?: Maybe<Scalars['UUID']>;
  tenantId?: Maybe<Scalars['UUID']>;
  tenantName?: Maybe<Scalars['String']>;
  tradingGroupId?: Maybe<Scalars['UUID']>;
  tradingGroupName?: Maybe<Scalars['String']>;
};

export enum MeterType {
  Bess = 'BESS',
  Grid = 'GRID',
  Inverter = 'INVERTER',
  Load = 'LOAD',
  Master = 'MASTER'
}

export type Mutation = {
  __typename?: 'Mutation';
  activateRole?: Maybe<ActivateRolePayload>;
  /** Change of user password */
  changePassword?: Maybe<Scalars['String']>;
  createApplicationHost: ApplicationHost;
  createBillingAccount?: Maybe<BillingAccountRef>;
  createMeter?: Maybe<MeterRef>;
  createNewTenant: TenantV2;
  createRole?: Maybe<CreateRolePayload>;
  createSettlement?: Maybe<Settlement>;
  createTradingGroup: TradingGroup;
  createUpdateDefaultProfiles?: Maybe<Array<Profile>>;
  createUpdateProfileTemplate?: Maybe<Profile>;
  createUpdateTariffTemplate?: Maybe<Scalars['Boolean']>;
  createUpdateUserPrice?: Maybe<Scalars['Boolean']>;
  /** Creates one user based on UserInput */
  createUser: User;
  /** Creates users and returns the list */
  createUsers: UserResult;
  deactivateRole?: Maybe<DeactivateRolePayload>;
  deactivateTradingGroup?: Maybe<Scalars['Boolean']>;
  /** Using identityProviderRef, user can deactivate its account */
  deactivateUser?: Maybe<Scalars['Boolean']>;
  deleteDefaultProfiles?: Maybe<Scalars['Boolean']>;
  /** Delete the profile that is assigned to the meter. */
  deleteMeterProfile?: Maybe<Scalars['Boolean']>;
  deleteProfileTemplate?: Maybe<Scalars['Boolean']>;
  deleteTariffTemplate?: Maybe<Scalars['Boolean']>;
  /** Any user is able to request resend of the activation email for their own account. */
  generalResendUserActivationEmail?: Maybe<Scalars['Boolean']>;
  markAsRead?: Maybe<InboxMessage>;
  /** Creates a meter as part of the user on-boarding process */
  onboardUserMeter?: Maybe<Scalars['Boolean']>;
  reactivateTradingGroup?: Maybe<Scalars['Boolean']>;
  /** Admin who has proper permission can resend activation email for another user */
  resendUserActivationEmail?: Maybe<Scalars['Boolean']>;
  /** Reset of user password via username */
  resetPassword?: Maybe<Scalars['Boolean']>;
  resetToDefaultProfile?: Maybe<Scalars['Boolean']>;
  updateMeter?: Maybe<MeterRef>;
  updateMeterProfiles?: Maybe<MeterProfiles>;
  /** Update of user status based on their identityProviderRef */
  updateOnboardingStep: User;
  /** User with same userId can update its own data including basic properties only */
  updateOwnAccount: User;
  updateRole?: Maybe<UpdateRolePayload>;
  updateTenant: TenantV2;
  updateTradingGroup: TradingGroup;
  /** Admin who has proper permission can update another user. */
  updateUser: User;
};


export type MutationActivateRoleArgs = {
  input: ActivateRoleInput;
};


export type MutationChangePasswordArgs = {
  passwords: PasswordsInput;
};


export type MutationCreateApplicationHostArgs = {
  applicationHost: ApplicationHostInput;
};


export type MutationCreateBillingAccountArgs = {
  billingAccount: BillingAccountInput;
};


export type MutationCreateMeterArgs = {
  meter: MeterInput;
};


export type MutationCreateNewTenantArgs = {
  tenant: CreateNewTenantInput;
};


export type MutationCreateRoleArgs = {
  input: CreateNewRoleInput;
};


export type MutationCreateSettlementArgs = {
  settlement: SettlementInput;
};


export type MutationCreateTradingGroupArgs = {
  tradingGroup: TradingGroupInput;
};


export type MutationCreateUpdateDefaultProfilesArgs = {
  defaultProfiles?: InputMaybe<DefaultProfilesInput>;
};


export type MutationCreateUpdateProfileTemplateArgs = {
  template: ProfileInput;
};


export type MutationCreateUpdateTariffTemplateArgs = {
  tariffTemplateInput: TariffTemplateInput;
};


export type MutationCreateUpdateUserPriceArgs = {
  userPrice: UserTariffInput;
};


export type MutationCreateUserArgs = {
  user: UserInput;
};


export type MutationCreateUsersArgs = {
  users: Array<UserInput>;
};


export type MutationDeactivateRoleArgs = {
  input: DeactivateRoleInput;
};


export type MutationDeactivateTradingGroupArgs = {
  applicationHostId: Scalars['UUID'];
  deactivateOn?: InputMaybe<Scalars['DateTime']>;
  tenantId: Scalars['UUID'];
  tradingGroupId: Scalars['UUID'];
};


export type MutationDeactivateUserArgs = {
  deactivateOn?: InputMaybe<Scalars['DateTime']>;
  identityProviderRef: Scalars['String'];
};


export type MutationDeleteDefaultProfilesArgs = {
  tenancyInfo: TenancyInfo;
};


export type MutationDeleteMeterProfileArgs = {
  deleteMeterProfileInput: DeleteMeterProfileInput;
};


export type MutationDeleteProfileTemplateArgs = {
  id: Scalars['UUID'];
  tenancyInfo: TenancyInfo;
};


export type MutationDeleteTariffTemplateArgs = {
  applicationHostId: Scalars['UUID'];
  id: Scalars['UUID'];
  tenantId: Scalars['UUID'];
};


export type MutationGeneralResendUserActivationEmailArgs = {
  userId: Scalars['UUID'];
};


export type MutationMarkAsReadArgs = {
  id: Scalars['String'];
};


export type MutationOnboardUserMeterArgs = {
  meterAssetType?: InputMaybe<MeterAssetType>;
  meterExternalRef: Scalars['String'];
  userId: Scalars['UUID'];
};


export type MutationReactivateTradingGroupArgs = {
  applicationHostId: Scalars['UUID'];
  tenantId: Scalars['UUID'];
  tradingGroupId: Scalars['UUID'];
};


export type MutationResendUserActivationEmailArgs = {
  userId: Scalars['UUID'];
};


export type MutationResetPasswordArgs = {
  username: Scalars['String'];
};


export type MutationResetToDefaultProfileArgs = {
  toDefaultProfileInput?: InputMaybe<ResetToDefaultProfileInput>;
};


export type MutationUpdateMeterArgs = {
  meter: MeterInput;
};


export type MutationUpdateMeterProfilesArgs = {
  meterProfiles: MeterProfilesInput;
};


export type MutationUpdateOnboardingStepArgs = {
  step?: InputMaybe<Scalars['Int']>;
};


export type MutationUpdateOwnAccountArgs = {
  user: UpdateOwnUserInput;
  userId: Scalars['UUID'];
};


export type MutationUpdateRoleArgs = {
  input: UpdateRoleInput;
};


export type MutationUpdateTenantArgs = {
  tenant: UpdateTenantInput;
};


export type MutationUpdateTradingGroupArgs = {
  applicationHostId: Scalars['UUID'];
  tenantId: Scalars['UUID'];
  tradingGroup: UpdateTradingGroupInput;
  tradingGroupId: Scalars['UUID'];
};


export type MutationUpdateUserArgs = {
  user: UpdateUserInput;
  userId: Scalars['UUID'];
};

/**
 * DEPRECATED! Will be removed at a future release.
 *
 * Use TradingGroupComparisonSummary as the replacement.
 */
export type NetworkSummary = {
  __typename?: 'NetworkSummary';
  /**
   * These 3 properties are not appropriately named and should be
   * consumerTotalAmountBAU (without LEM), consumerTotalAmount (with LEM),
   * and consumerAverageSavings. Similarly for the rest.
   */
  consumerReductionBAU: Scalars['BigDecimal'];
  consumerReductionBill: Scalars['BigDecimal'];
  consumerReductionLEM: Scalars['BigDecimal'];
  hourlyGridExportBAU?: Maybe<Array<Scalars['BigDecimal']>>;
  hourlyGridExportLEM?: Maybe<Array<Scalars['BigDecimal']>>;
  /**
   * These should not be hourly but instead should be according
   * to the settlement period.
   */
  hourlyGridImportBAU?: Maybe<Array<Scalars['BigDecimal']>>;
  hourlyGridImportLEM?: Maybe<Array<Scalars['BigDecimal']>>;
  prosumerBESSReductionBill: Scalars['BigDecimal'];
  prosumerBessReductionBAU: Scalars['BigDecimal'];
  prosumerBessReductionLEM: Scalars['BigDecimal'];
  prosumerReductionBAU: Scalars['BigDecimal'];
  prosumerReductionBill: Scalars['BigDecimal'];
  prosumerReductionLEM: Scalars['BigDecimal'];
  timeOfUseUsages?: Maybe<Array<TimeOfUseUsage>>;
  totalGridExportBAU: Scalars['BigDecimal'];
  totalGridExportLEM: Scalars['BigDecimal'];
  totalGridImportBAU: Scalars['BigDecimal'];
  totalGridImportLEM: Scalars['BigDecimal'];
};

export type NotificationConfig = {
  __typename?: 'NotificationConfig';
  makeTheCompilerShutUp?: Maybe<Scalars['String']>;
};

export type NotificationConfigMutationInput = {
  makeTheCompilerShutUp?: InputMaybe<Scalars['String']>;
};

/** Extra Pagination information returned. */
export type OffsetPaginationInfo = {
  __typename?: 'OffsetPaginationInfo';
  /** The total records that matches the criteria */
  count?: Maybe<Scalars['Int']>;
  /** The amount of record that a page can have */
  limit?: Maybe<Scalars['Int']>;
  /** The current page */
  page?: Maybe<Scalars['Int']>;
  /** The total pages of the record that matches the filter */
  totalPages?: Maybe<Scalars['Int']>;
};

export enum Operation {
  Charge = 'CHARGE',
  Discharge = 'DISCHARGE'
}

/**
 * DEPRECATED! Will be removed at a future release.
 *
 * Use TradingGroupTradeSummary as the replacement.
 */
export type OverviewSummary = {
  __typename?: 'OverviewSummary';
  averageSavingsBAU: Scalars['BigDecimal'];
  averageSavingsLEM: Scalars['BigDecimal'];
  carbonEmissionSaving: Scalars['Int'];
  numberOfCommunityBatteries: Scalars['Int'];
  numberOfCorporateIndustryBessProsumers: Scalars['Int'];
  numberOfCorporateIndustryConsumers: Scalars['Int'];
  numberOfCorporateIndustryProsumers: Scalars['Int'];
  numberOfRetailBessProsumers: Scalars['Int'];
  numberOfRetailConsumers: Scalars['Int'];
  numberOfRetailProsumers: Scalars['Int'];
  p2pPurchasePrice: Scalars['BigDecimal'];
  p2pSalePrice: Scalars['BigDecimal'];
  p2pVolumePurchased: Scalars['BigDecimal'];
  p2pVolumeSold: Scalars['BigDecimal'];
  selfConsumptionPercentage: Scalars['BigDecimal'];
  selfSufficiencyPercentage: Scalars['BigDecimal'];
};

export type Ownership = {
  __typename?: 'Ownership';
  accountNumber?: Maybe<Scalars['String']>;
  applicationHostId?: Maybe<Scalars['UUID']>;
  applicationHostName?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['DateTime']>;
  tenantId?: Maybe<Scalars['UUID']>;
  tenantName?: Maybe<Scalars['String']>;
  to?: Maybe<Scalars['DateTime']>;
  tradingGroupId?: Maybe<Scalars['UUID']>;
  tradingGroupName?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['UUID']>;
};

export type OwnershipDateRangeInput = {
  from?: InputMaybe<Scalars['DateTime']>;
  meterUid?: InputMaybe<Scalars['String']>;
  to?: InputMaybe<Scalars['DateTime']>;
  tradingGroupName?: InputMaybe<Scalars['String']>;
};

export type OwnershipInput = {
  accountNumber?: InputMaybe<Scalars['String']>;
  applicationHostId?: InputMaybe<Scalars['UUID']>;
  applicationHostName?: InputMaybe<Scalars['String']>;
  from?: InputMaybe<Scalars['DateTime']>;
  tenantId?: InputMaybe<Scalars['UUID']>;
  tenantName?: InputMaybe<Scalars['String']>;
  to?: InputMaybe<Scalars['DateTime']>;
  tradingGroupId?: InputMaybe<Scalars['UUID']>;
  tradingGroupName?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['UUID']>;
};

/**
 * Pagination information for handling cursor based forwards and backwards pagination.
 * See spec: https://relay.dev/graphql/connections.htm#sec-undefined.PageInfo
 */
export type PaginationInfo = {
  __typename?: 'PaginationInfo';
  /** Cursor corresponding to the last node in the edges array */
  endCursor?: Maybe<Scalars['String']>;
  /** Indicates if there are more edges following the provided cursor */
  hasNextPage?: Maybe<Scalars['Boolean']>;
  /** Indicates if there are more edges prior to the provided cursor */
  hasPreviousPage?: Maybe<Scalars['Boolean']>;
  /** Cursor corresponding to the first node in the edges array */
  startCursor?: Maybe<Scalars['String']>;
};

/**
 * A trade summary of a settlement for an end-user/participant
 * of a trading group.
 */
export type ParticipantTradeSummary = {
  __typename?: 'ParticipantTradeSummary';
  averageSavings: Scalars['BigDecimal'];
  endDateTime: Scalars['DateTime'];
  gridBuyPrice: Scalars['BigDecimal'];
  gridEnergyBought: Scalars['BigDecimal'];
  gridEnergySold: Scalars['BigDecimal'];
  gridSellPrice: Scalars['BigDecimal'];
  numCommunityBatteries: Scalars['Int'];
  numCorporateIndustryBessProsumers: Scalars['Int'];
  numCorporateIndustryConsumers: Scalars['Int'];
  numCorporateIndustryProsumers: Scalars['Int'];
  numRetailBessProsumers: Scalars['Int'];
  numRetailConsumers: Scalars['Int'];
  numRetailProsumers: Scalars['Int'];
  p2pBuyPrice: Scalars['BigDecimal'];
  p2pEnergyBought: Scalars['BigDecimal'];
  p2pEnergySold: Scalars['BigDecimal'];
  p2pSellPrice: Scalars['BigDecimal'];
  selfConsumptionPercentage: Scalars['BigDecimal'];
  selfSufficiencyPercentage: Scalars['BigDecimal'];
  settlementId: Scalars['UUID'];
  startDateTime: Scalars['DateTime'];
  topBuyers?: Maybe<Array<TopTraderInfo>>;
  topSellers?: Maybe<Array<TopTraderInfo>>;
  totalSavings: Scalars['BigDecimal'];
};

export type ParticipantTradeSummaryFilter = {
  applicationHostId: Scalars['UUID'];
  settlementId?: InputMaybe<Scalars['UUID']>;
  tenantId: Scalars['UUID'];
  tradingGroupId: Scalars['UUID'];
  userId: Scalars['UUID'];
};

export type PasswordsInput = {
  newPassword: Scalars['String'];
  oldPassword: Scalars['String'];
};

export type Profile = {
  __typename?: 'Profile';
  applicationHostId: Scalars['UUID'];
  day?: Maybe<Scalars['DateTime']>;
  energyData?: Maybe<Array<Scalars['BigDecimal']>>;
  id: Scalars['UUID'];
  interval: Scalars['PositiveInt'];
  isDefault?: Maybe<Scalars['Boolean']>;
  month?: Maybe<Scalars['PositiveInt']>;
  name: Scalars['String'];
  numberOfMeters?: Maybe<Scalars['Int']>;
  profileClass: ProfileClass;
  seasonEnd?: Maybe<Scalars['MonthDay']>;
  seasonStart?: Maybe<Scalars['MonthDay']>;
  tenantId: Scalars['UUID'];
  tradingGroupId: Scalars['UUID'];
  type: ProfileType;
};

export enum ProfileClass {
  Bess = 'BESS',
  Generation = 'GENERATION',
  Load = 'LOAD'
}

export type ProfileFilter = {
  applicationHostId: Scalars['UUID'];
  day?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['UUID']>;
  interval?: InputMaybe<Scalars['PositiveInt']>;
  month?: InputMaybe<Scalars['PositiveInt']>;
  name?: InputMaybe<Scalars['String']>;
  profileClass?: InputMaybe<ProfileClass>;
  seasonEnd?: InputMaybe<Scalars['MonthDay']>;
  seasonStart?: InputMaybe<Scalars['MonthDay']>;
  tenantId: Scalars['UUID'];
  tradingGroupId: Scalars['UUID'];
  type?: InputMaybe<ProfileType>;
};

export type ProfileInput = {
  applicationHostId: Scalars['UUID'];
  day?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['UUID']>;
  interval: Scalars['PositiveInt'];
  isDefault: Scalars['Boolean'];
  month?: InputMaybe<Scalars['PositiveInt']>;
  name: Scalars['String'];
  profileClass: ProfileClass;
  seasonEnd?: InputMaybe<Scalars['MonthDay']>;
  seasonStart?: InputMaybe<Scalars['MonthDay']>;
  tenantId: Scalars['UUID'];
  tradingGroupId: Scalars['UUID'];
  type: ProfileType;
};

export enum ProfileType {
  Day = 'Day',
  Month = 'Month',
  Season = 'Season',
  Weekday = 'Weekday',
  Weekend = 'Weekend'
}

export type Query = {
  __typename?: 'Query';
  applicationHost: ApplicationHost;
  /**
   * The spec of offset-based pagination is based on https://www.apollographql.com/docs/react/pagination/offset-based
   * Instead of list of items, we wrap the return to be a type for extra pagination info
   */
  applicationHosts: ApplicationHostsResult;
  /**
   * DEPRECATED! Use tradingGroupTradeOverview query.
   *
   * Get a trading group summary showing a comparison between the usage
   * and dollar amounts with and without LEM.
   */
  dashboardNetworkSummary?: Maybe<NetworkSummary>;
  /**
   * DEPRECATED! Use tradingGroupTradeOverview query.
   *
   * Get a participant summary for a settlement. If a settlement id is not
   * provided, the latest settlement summary will be returned
   *
   * See the equivalent settlementSummary to get trading group
   * settlement summary for admin, operators and corporate staff.
   */
  dashboardOverview?: Maybe<OverviewSummary>;
  defaultProfiles?: Maybe<Array<Profile>>;
  getBillingAccount?: Maybe<BillingAccount>;
  getBillingAccounts?: Maybe<Array<BillingAccount>>;
  getCommands: Array<Maybe<Command>>;
  /**
   * Get URL where the energy data for profile will be uploaded
   * File should be in CSV format
   */
  getEnergyDataUploadURL: GetEnergyDataUploadUrlPayload;
  getMeter?: Maybe<Meter>;
  getMeters?: Maybe<Array<Meter>>;
  getUserMeter?: Maybe<Meter>;
  /**
   * Get a list of the last 30 settlement info such as the settlement id and start and end
   * date times. Can be used for creating a drop-list list of available settlements.
   */
  lastSettlementInfos?: Maybe<Array<TradeSettlementInfo>>;
  messages?: Maybe<Array<Maybe<InboxMessage>>>;
  meterForecastForNextDay: MeterForecast;
  meterProfileValues?: Maybe<Array<Scalars['BigDecimal']>>;
  meterProfiles?: Maybe<MeterProfiles>;
  /**
   * Get a participant trade summary for a settlement. If a settlement id is not
   * provided, the latest settlement summary will be returned.
   */
  participantTradeSummary?: Maybe<ParticipantTradeSummary>;
  profileTemplates?: Maybe<Array<Profile>>;
  /** Returns list of retailers and their userTariffs */
  retailers?: Maybe<Array<Maybe<User>>>;
  searchTenants: SearchTenantsResult;
  /**
   * DEPRECATED! Use tradingGroupTradeOverview query.
   *
   * Get a trading group summary for a settlement. If a settlementId
   * is not provided, the latest settlement data will be returned.
   */
  settlementSummary?: Maybe<SettlementSummary>;
  settlements?: Maybe<Array<Settlement>>;
  tariffTemplates?: Maybe<Array<Maybe<TariffTemplate>>>;
  tenantInfo: TenantV2;
  tradingGroup: TradingGroup;
  /**
   * Get a trading group summary showing a comparison of the usages and dollar amounts
   * with and without LEM. If a settlement id is not provided in the filter, the latest
   * settlement summary will be returned.
   */
  tradingGroupTradeComparison?: Maybe<TradingGroupComparisonSummary>;
  /**
   * Get a trading group trade summary for a settlement. If a settlement id is not
   * provided in the filter, the latest settlement summary will be returned.
   */
  tradingGroupTradeSummary?: Maybe<TradingGroupTradeSummary>;
  tradingGroups: Array<Maybe<TradingGroup>>;
  /** Get the details of a trade transaction given an id of the transaction. */
  transaction?: Maybe<EnergyTransaction>;
  /** Get a list of trade transactions. */
  transactions?: Maybe<Array<Maybe<EnergyTransaction>>>;
  unassignedProfiles?: Maybe<Array<MeterProfile>>;
  /** Access to user data via id, assuming sufficient permission based on role */
  user: User;
  userAccess?: Maybe<UserAccess>;
  /** Returns user data for authenticated user with same identityProviderRef */
  userProfile: User;
  userTariffs?: Maybe<Array<Maybe<UserTariff>>>;
  /** Get a list of trade transactions for a end-user/participant. */
  userTransactions?: Maybe<Array<Maybe<EnergyTransaction>>>;
  /** Returns users data for admins based on role */
  users: UserConnection;
};


export type QueryApplicationHostArgs = {
  id: Scalars['UUID'];
  tenantId: Scalars['UUID'];
};


export type QueryApplicationHostsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ApplicationHostFilter>;
};


export type QueryDashboardNetworkSummaryArgs = {
  filter: SettlementSummaryFilter;
};


export type QueryDashboardOverviewArgs = {
  settlementId?: InputMaybe<Scalars['UUID']>;
};


export type QueryDefaultProfilesArgs = {
  tenancyInfo: TenancyInfo;
};


export type QueryGetBillingAccountArgs = {
  accountNumber?: InputMaybe<Scalars['String']>;
  applicationHostName?: InputMaybe<Scalars['String']>;
  tenantName?: InputMaybe<Scalars['String']>;
  tradingGroupName?: InputMaybe<Scalars['String']>;
};


export type QueryGetBillingAccountsArgs = {
  applicationHostName?: InputMaybe<Scalars['String']>;
  tenantName?: InputMaybe<Scalars['String']>;
  tradingGroupName?: InputMaybe<Scalars['String']>;
};


export type QueryGetCommandsArgs = {
  where?: InputMaybe<CommandFilter>;
};


export type QueryGetEnergyDataUploadUrlArgs = {
  input: GetEnergyDataUploadUrlInput;
};


export type QueryGetMeterArgs = {
  applicationHostName?: InputMaybe<Scalars['String']>;
  meterExternalRef?: InputMaybe<Scalars['String']>;
  tenantName?: InputMaybe<Scalars['String']>;
  tradingGroupName?: InputMaybe<Scalars['String']>;
};


export type QueryGetMetersArgs = {
  applicationHostName?: InputMaybe<Scalars['String']>;
  tenantName?: InputMaybe<Scalars['String']>;
  tradingGroupName?: InputMaybe<Scalars['String']>;
};


export type QueryGetUserMeterArgs = {
  userId?: InputMaybe<Scalars['UUID']>;
};


export type QueryLastSettlementInfosArgs = {
  filter: TradeSettlementFilter;
};


export type QueryMeterForecastForNextDayArgs = {
  meterProfilesFilter?: InputMaybe<MeterProfilesFilter>;
};


export type QueryMeterProfileValuesArgs = {
  meterProfileValuesInput: MeterProfileValuesInput;
};


export type QueryMeterProfilesArgs = {
  meterProfilesFilter: MeterProfilesFilter;
};


export type QueryParticipantTradeSummaryArgs = {
  settlementId?: InputMaybe<Scalars['UUID']>;
};


export type QueryProfileTemplatesArgs = {
  where: ProfileFilter;
};


export type QueryRetailersArgs = {
  tenancyInfo?: InputMaybe<TenancyInfo>;
};


export type QuerySearchTenantsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  searchTenantsInput?: InputMaybe<SearchTenantsInput>;
};


export type QuerySettlementSummaryArgs = {
  filter: SettlementSummaryFilter;
};


export type QuerySettlementsArgs = {
  where?: InputMaybe<SettlementFilter>;
};


export type QueryTariffTemplatesArgs = {
  where?: InputMaybe<TariffTemplatesFilter>;
};


export type QueryTenantInfoArgs = {
  id: Scalars['UUID'];
};


export type QueryTradingGroupArgs = {
  applicationHostId: Scalars['UUID'];
  id: Scalars['UUID'];
  tenantId: Scalars['UUID'];
};


export type QueryTradingGroupTradeComparisonArgs = {
  filter: TradingGroupTradeSummaryFilter;
};


export type QueryTradingGroupTradeSummaryArgs = {
  filter: TradingGroupTradeSummaryFilter;
};


export type QueryTradingGroupsArgs = {
  where?: InputMaybe<TradingGroupFilter>;
};


export type QueryTransactionArgs = {
  id: Scalars['UUID'];
};


export type QueryTransactionsArgs = {
  where: TransactionFilter;
};


export type QueryUnassignedProfilesArgs = {
  meterProfilesFilter: MeterProfilesFilter;
};


export type QueryUserArgs = {
  id: Scalars['UUID'];
};


export type QueryUserProfileArgs = {
  identityProviderRef: Scalars['String'];
};


export type QueryUserTariffsArgs = {
  where?: InputMaybe<UserTariffFilter>;
};


export type QueryUserTransactionsArgs = {
  transactionEndDateTime: Scalars['DateTime'];
  transactionStartDateTime: Scalars['DateTime'];
  userId: Scalars['UUID'];
};


export type QueryUsersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserFilter>;
};

export type ReadingsConfig = {
  __typename?: 'ReadingsConfig';
  defaultEnergyUnit?: Maybe<DefaultEnergyUnit>;
  defaultIntervalInSeconds?: Maybe<Scalars['Int']>;
  filePathRegex: Scalars['String'];
  rawReadingsConfig?: Maybe<Scalars['String']>;
  rawReadingsOverrideConfig?: Maybe<Scalars['String']>;
  readingsFormatType: ReadingsFormatType;
  readingsType: ReadingsType;
  regexFilePostfix: Scalars['String'];
  regexFilePrefix: Scalars['String'];
  regexFolderList: Array<Scalars['String']>;
};

export type ReadingsConfigMutationInput = {
  defaultEnergyUnit?: InputMaybe<DefaultEnergyUnit>;
  defaultIntervalInSeconds?: InputMaybe<Scalars['Int']>;
  rawReadingsConfig?: InputMaybe<Scalars['String']>;
  rawReadingsOverrideConfig?: InputMaybe<Scalars['String']>;
  readingsFormatType: ReadingsFormatType;
  readingsType: ReadingsType;
  regexFilePostfix: Scalars['String'];
  regexFilePrefix: Scalars['String'];
  regexFolderList: Array<Scalars['String']>;
};

export enum ReadingsFormatType {
  Json = 'json',
  Nem12 = 'nem12',
  SingleMeterPerLine = 'single_meter_per_line',
  Standard = 'standard'
}

export enum ReadingsType {
  Cumulative = 'cumulative',
  Interval = 'interval'
}

export type ResetToDefaultProfileInput = {
  applicationHostId: Scalars['UUID'];
  meterId: Scalars['UUID'];
  profileId: Scalars['UUID'];
  tenantId: Scalars['UUID'];
  tradingGroupId: Scalars['UUID'];
};

export type Role = {
  __typename?: 'Role';
  id: Scalars['UUID'];
  level: Scalars['Int'];
  name: Scalars['String'];
  permissions: Array<Scalars['String']>;
  requiresTenancyCheck: Scalars['Boolean'];
  status: RoleStatus;
  systemRoleEnabled: Scalars['Boolean'];
};

export enum RoleStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export enum RuleType {
  Adjustment = 'ADJUSTMENT',
  Aggregator = 'AGGREGATOR',
  Allocation = 'ALLOCATION',
  CatchUp = 'CATCH_UP',
  Correction = 'CORRECTION',
  DynamicP2P = 'DYNAMIC_P2P',
  Grid = 'GRID',
  MicroGrid = 'MICRO_GRID',
  P2P = 'P2P',
  Ppa = 'PPA',
  Preferential = 'PREFERENTIAL',
  SelfTrade = 'SELF_TRADE',
  Vpp = 'VPP'
}

export type SearchTenantsInput = {
  nameKeyword?: InputMaybe<Scalars['String']>;
  tenantIds?: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>;
};

export type SearchTenantsResult = {
  __typename?: 'SearchTenantsResult';
  pageInfo?: Maybe<OffsetPaginationInfo>;
  tenants: Array<TenantV2>;
};

export type Settlement = {
  __typename?: 'Settlement';
  applicationHostId: Scalars['UUID'];
  failureReason?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  intervalPeriod: Scalars['Int'];
  settlementCompletedOn?: Maybe<Scalars['DateTime']>;
  settlementCreatedOn?: Maybe<Scalars['DateTime']>;
  settlementEndDateTime: Scalars['DateTime'];
  settlementStartDateTime: Scalars['DateTime'];
  status: SettlementStatus;
  tenantId: Scalars['UUID'];
  tradingGroupId: Scalars['UUID'];
};

export type SettlementFilter = {
  applicationHostId?: InputMaybe<Scalars['UUID']>;
  settlementId?: InputMaybe<Array<Scalars['UUID']>>;
  status?: InputMaybe<Array<SettlementStatus>>;
  tenantId?: InputMaybe<Scalars['UUID']>;
  tradingGroupId?: InputMaybe<Scalars['UUID']>;
};

export type SettlementInput = {
  applicationHostId: Scalars['UUID'];
  intervalPeriod: Scalars['Int'];
  settlementEndDateTime: Scalars['DateTime'];
  settlementStartDateTime: Scalars['DateTime'];
  tenantId: Scalars['UUID'];
  tradingGroupId: Scalars['UUID'];
};

export type SettlementMeterInfo = {
  __typename?: 'SettlementMeterInfo';
  amount?: Maybe<Scalars['BigDecimal']>;
  description?: Maybe<Scalars['String']>;
};

export type SettlementPeriodTrade = {
  __typename?: 'SettlementPeriodTrade';
  bessProsumerAverageSavings: Scalars['BigDecimal'];
  bessProsumerTotalAmount: Scalars['BigDecimal'];
  bessProsumerTotalAmountBAU: Scalars['BigDecimal'];
  bessProsumerTotalSavings: Scalars['BigDecimal'];
  consumerAverageSavings: Scalars['BigDecimal'];
  consumerTotalAmount: Scalars['BigDecimal'];
  consumerTotalAmountBAU: Scalars['BigDecimal'];
  consumerTotalSavings: Scalars['BigDecimal'];
  endDateTime: Scalars['DateTime'];
  gridExport: Scalars['BigDecimal'];
  gridExportBAU: Scalars['BigDecimal'];
  gridImport: Scalars['BigDecimal'];
  gridImportBAU: Scalars['BigDecimal'];
  prosumerAverageSavings: Scalars['BigDecimal'];
  prosumerTotalAmount: Scalars['BigDecimal'];
  prosumerTotalAmountBAU: Scalars['BigDecimal'];
  prosumerTotalSavings: Scalars['BigDecimal'];
  settlementPeriod: Scalars['Int'];
  startDateTime: Scalars['DateTime'];
};

export enum SettlementStatus {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Dispatched = 'DISPATCHED',
  DispatchedWithErrors = 'DISPATCHED_WITH_ERRORS',
  Error = 'ERROR',
  Forecasting = 'FORECASTING',
  ForecastCompleted = 'FORECAST_COMPLETED',
  ForecastCompletedWithErrors = 'FORECAST_COMPLETED_WITH_ERRORS',
  New = 'NEW',
  Pricing = 'PRICING',
  ReadyToTrade = 'READY_TO_TRADE',
  Traded = 'TRADED',
  Trading = 'TRADING'
}

/**
 * DEPRECATED! Will be removed at a future release.
 *
 * Use TradingGroupTradeSummary as the replacement.
 */
export type SettlementSummary = {
  __typename?: 'SettlementSummary';
  averageSavings: Scalars['BigDecimal'];
  gridPurchasePrice: Scalars['BigDecimal'];
  gridSalePrice: Scalars['BigDecimal'];
  gridVolumePurchased: Scalars['BigDecimal'];
  gridVolumeSold: Scalars['BigDecimal'];
  numberOfCommunityBatteries: Scalars['Int'];
  numberOfCorporateIndustryBessProsumers: Scalars['Int'];
  numberOfCorporateIndustryConsumers: Scalars['Int'];
  numberOfCorporateIndustryProsumers: Scalars['Int'];
  numberOfRetailBessProsumers: Scalars['Int'];
  numberOfRetailConsumers: Scalars['Int'];
  numberOfRetailProsumers: Scalars['Int'];
  p2pPurchasePrice: Scalars['BigDecimal'];
  p2pSalePrice: Scalars['BigDecimal'];
  p2pVolumePurchased: Scalars['BigDecimal'];
  p2pVolumeSold: Scalars['BigDecimal'];
  selfConsumptionPercentage: Scalars['BigDecimal'];
  selfSufficiencyPercentage: Scalars['BigDecimal'];
  topBuyers?: Maybe<Array<SettlementMeterInfo>>;
  topSellers?: Maybe<Array<SettlementMeterInfo>>;
  totalSavings: Scalars['BigDecimal'];
};

export type SettlementSummaryFilter = {
  applicationHostId: Scalars['UUID'];
  settlementId?: InputMaybe<Scalars['UUID']>;
  tenantId: Scalars['UUID'];
  tradingGroupId: Scalars['UUID'];
};

export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type StringSearchFilter = {
  contain?: InputMaybe<Scalars['String']>;
  startWith?: InputMaybe<Scalars['String']>;
};

export type Tariff = {
  __typename?: 'Tariff';
  buyRate?: Maybe<Scalars['BigDecimal']>;
  dayFrom?: Maybe<Scalars['PositiveInt']>;
  dayTo?: Maybe<Scalars['PositiveInt']>;
  sellRate?: Maybe<Scalars['BigDecimal']>;
  timePeriods?: Maybe<Array<Maybe<TimePeriod>>>;
};

export type TariffInput = {
  buyRate?: InputMaybe<Scalars['BigDecimal']>;
  dayFrom?: InputMaybe<Scalars['PositiveInt']>;
  dayTo?: InputMaybe<Scalars['PositiveInt']>;
  sellRate?: InputMaybe<Scalars['BigDecimal']>;
  timePeriods?: InputMaybe<Array<InputMaybe<TimePeriodInput>>>;
};

export type TariffTemplate = {
  __typename?: 'TariffTemplate';
  applicationHostId?: Maybe<Scalars['UUID']>;
  id?: Maybe<Scalars['UUID']>;
  tariffs?: Maybe<Array<Maybe<TariffTemplateTariff>>>;
  tenantId?: Maybe<Scalars['UUID']>;
  timeZone?: Maybe<Scalars['String']>;
  tradingGroupId?: Maybe<Scalars['UUID']>;
  validFrom?: Maybe<Scalars['DateTime']>;
  validTo?: Maybe<Scalars['DateTime']>;
};

export type TariffTemplateInput = {
  applicationHostId: Scalars['UUID'];
  id: Scalars['UUID'];
  tariffs?: InputMaybe<Array<InputMaybe<TariffTemplateTariffInput>>>;
  tenantId: Scalars['UUID'];
  timeZone?: InputMaybe<Scalars['String']>;
  tradingGroupId: Scalars['UUID'];
  validFrom?: InputMaybe<Scalars['DateTime']>;
  validTo?: InputMaybe<Scalars['DateTime']>;
};

export type TariffTemplateTariff = {
  __typename?: 'TariffTemplateTariff';
  buyRate?: Maybe<Scalars['BigDecimal']>;
  dayFrom?: Maybe<Scalars['PositiveInt']>;
  dayTo?: Maybe<Scalars['PositiveInt']>;
  displayName?: Maybe<Scalars['String']>;
  sellRate?: Maybe<Scalars['BigDecimal']>;
  timePeriods?: Maybe<Array<Maybe<TimePeriod>>>;
  type?: Maybe<TariffType>;
};

export type TariffTemplateTariffInput = {
  buyRate?: InputMaybe<Scalars['BigDecimal']>;
  dayFrom?: InputMaybe<Scalars['PositiveInt']>;
  dayTo?: InputMaybe<Scalars['PositiveInt']>;
  displayName?: InputMaybe<Scalars['String']>;
  sellRate?: InputMaybe<Scalars['BigDecimal']>;
  timePeriods?: InputMaybe<Array<InputMaybe<TimePeriodInput>>>;
  type?: InputMaybe<TariffType>;
};

export type TariffTemplatesFilter = {
  applicationHostId?: InputMaybe<Scalars['UUID']>;
  tenantId?: InputMaybe<Scalars['UUID']>;
  tradingGroupId?: InputMaybe<Scalars['UUID']>;
};

export enum TariffType {
  AnyTime = 'ANY_TIME',
  OffPeak = 'OFF_PEAK',
  Peak = 'PEAK',
  Shoulder = 'SHOULDER',
  Unclassified = 'UNCLASSIFIED',
  Weekend = 'WEEKEND'
}

export type TenancyInfo = {
  applicationHostId: Scalars['UUID'];
  tenantId: Scalars['UUID'];
  tradingGroupId: Scalars['UUID'];
};

export enum TenantFeature {
  Lem = 'LEM',
  Tracex = 'TRACEX',
  Transactive = 'TRANSACTIVE'
}

export type TenantV2 = {
  __typename?: 'TenantV2';
  /** Currently, the value of this will be UUID String user, or the Auditor Type i.e. 'PLATFORM' */
  createdBy?: Maybe<Scalars['String']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  features?: Maybe<Array<TenantFeature>>;
  id: Scalars['UUID'];
  /** Currently, the value of this will be UUID String user, or the Auditor Type i.e. 'PLATFORM' */
  lastModifiedBy?: Maybe<Scalars['String']>;
  lastModifiedDate?: Maybe<Scalars['DateTime']>;
  /** For localization. Example: en_au, en_us */
  location?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type TimeOfUseUsage = {
  __typename?: 'TimeOfUseUsage';
  name?: Maybe<TimeOfUseUsageType>;
  usage?: Maybe<Scalars['Int']>;
};

export enum TimeOfUseUsageType {
  OffPeak = 'OFF_PEAK',
  Peak = 'PEAK',
  Shoulder = 'SHOULDER'
}

export type TimePeriod = {
  __typename?: 'TimePeriod';
  timeFrom?: Maybe<Scalars['LocalTime']>;
  timeTo?: Maybe<Scalars['LocalTime']>;
};

export type TimePeriodInput = {
  timeFrom?: InputMaybe<Scalars['LocalTime']>;
  timeTo?: InputMaybe<Scalars['LocalTime']>;
};

export type TopTraderInfo = {
  __typename?: 'TopTraderInfo';
  amount: Scalars['BigDecimal'];
  description: Scalars['String'];
};

export type TradeSettlementFilter = {
  applicationHostId: Scalars['UUID'];
  tenantId: Scalars['UUID'];
  tradingGroupId: Scalars['UUID'];
};

export type TradeSettlementInfo = {
  __typename?: 'TradeSettlementInfo';
  endDateTime: Scalars['DateTime'];
  settlementId: Scalars['UUID'];
  startDateTime: Scalars['DateTime'];
};

export type TradingGroup = {
  __typename?: 'TradingGroup';
  applicationHostId: Scalars['UUID'];
  applicationHostName: Scalars['String'];
  co2EmissionsIntensity: Array<EmissionsIntensityTuple>;
  dateRange?: Maybe<DateRange>;
  features?: Maybe<Array<TenantFeature>>;
  /** Forward settlement gate closure in local time for the trading group. */
  gateClosureTime?: Maybe<Scalars['LocalTime']>;
  id: Scalars['UUID'];
  minNumMeters?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  notificationConfig?: Maybe<NotificationConfig>;
  readingsConfig: Array<ReadingsConfig>;
  runBalanceCheck?: Maybe<Scalars['Boolean']>;
  settlementIntervalInSeconds?: Maybe<Scalars['Int']>;
  /**
   * The period interval in seconds for the settlement interval.
   * For example, if the settlement interval is 24 hours and the settlement period interval is 30 minutes,
   * then there will be 48 settlement periods.
   */
  settlementPeriodInSeconds?: Maybe<Scalars['Int']>;
  /** Settlement start time in local time for the trading group. */
  settlementStartTime?: Maybe<Scalars['LocalTime']>;
  tenantId: Scalars['UUID'];
  tenantName: Scalars['String'];
  timeZone?: Maybe<Scalars['String']>;
  vendor: Scalars['String'];
  writeToBlockchain?: Maybe<Scalars['Boolean']>;
};

/**
 * This shows a trade summary concentrating on the total
 * amount with and without LEM (Business-As-Usual) cases
 * in a settlement which is usually 24-hour.
 *
 * It also contains the data for each settlement period
 * to allow changes to be plotted over time for the entire
 * settlement.
 */
export type TradingGroupComparisonSummary = {
  __typename?: 'TradingGroupComparisonSummary';
  bessProsumerAverageSavings: Scalars['BigDecimal'];
  bessProsumerTotalAmount: Scalars['BigDecimal'];
  bessProsumerTotalAmountBAU: Scalars['BigDecimal'];
  bessProsumerTotalSavings: Scalars['BigDecimal'];
  consumerAverageSavings: Scalars['BigDecimal'];
  consumerTotalAmount: Scalars['BigDecimal'];
  consumerTotalAmountBAU: Scalars['BigDecimal'];
  consumerTotalSavings: Scalars['BigDecimal'];
  prosumerAverageSavings: Scalars['BigDecimal'];
  prosumerTotalAmount: Scalars['BigDecimal'];
  prosumerTotalAmountBAU: Scalars['BigDecimal'];
  prosumerTotalSavings: Scalars['BigDecimal'];
  totalGridExport: Scalars['BigDecimal'];
  totalGridExportBAU: Scalars['BigDecimal'];
  totalGridImport: Scalars['BigDecimal'];
  totalGridImportBAU: Scalars['BigDecimal'];
  tradesByPeriod?: Maybe<Array<SettlementPeriodTrade>>;
};

export type TradingGroupFilter = {
  applicationHostName?: InputMaybe<Scalars['String']>;
  names?: InputMaybe<StringSearchFilter>;
  tenantName?: InputMaybe<Scalars['String']>;
};

export type TradingGroupInput = {
  applicationHostName: Scalars['String'];
  co2EmissionsIntensity?: InputMaybe<Array<EmissionsIntensityTupleMutationInput>>;
  features: Array<TenantFeature>;
  /** Forward settlement gate closure in local time for the trading group. */
  gateClosureTime?: InputMaybe<Scalars['LocalTime']>;
  minNumMeters?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  notificationConfig?: InputMaybe<NotificationConfigMutationInput>;
  readingsConfig: Array<ReadingsConfigMutationInput>;
  runBalanceCheck?: InputMaybe<Scalars['Boolean']>;
  settlementIntervalInSeconds?: InputMaybe<Scalars['Int']>;
  /**
   * The period interval in seconds for the settlement interval.
   * For example, if the settlement interval is 24 hours and the settlement period interval is 30 minutes,
   * then there will be 48 settlement periods.
   */
  settlementPeriodInSeconds?: InputMaybe<Scalars['Int']>;
  /** Settlement start time in local time for the trading group. */
  settlementStartTime?: InputMaybe<Scalars['LocalTime']>;
  tenantName: Scalars['String'];
  timeZone: Scalars['String'];
  vendor: Scalars['String'];
  writeToBlockchain?: InputMaybe<Scalars['Boolean']>;
};

/**
 * A trade summary of a settlement for a trading group
 * usually over a 24-hour interval.
 */
export type TradingGroupTradeSummary = {
  __typename?: 'TradingGroupTradeSummary';
  averageSavings: Scalars['BigDecimal'];
  endDateTime: Scalars['DateTime'];
  gridBuyPrice: Scalars['BigDecimal'];
  gridEnergyBought: Scalars['BigDecimal'];
  gridEnergySold: Scalars['BigDecimal'];
  gridSellPrice: Scalars['BigDecimal'];
  numCommunityBatteries: Scalars['Int'];
  numCorporateIndustryBessProsumers: Scalars['Int'];
  numCorporateIndustryConsumers: Scalars['Int'];
  numCorporateIndustryProsumers: Scalars['Int'];
  numRetailBessProsumers: Scalars['Int'];
  numRetailConsumers: Scalars['Int'];
  numRetailProsumers: Scalars['Int'];
  p2pBuyPrice: Scalars['BigDecimal'];
  p2pEnergyBought: Scalars['BigDecimal'];
  p2pEnergySold: Scalars['BigDecimal'];
  p2pSellPrice: Scalars['BigDecimal'];
  selfConsumptionPercentage: Scalars['BigDecimal'];
  selfSufficiencyPercentage: Scalars['BigDecimal'];
  settlementId: Scalars['UUID'];
  startDateTime: Scalars['DateTime'];
  topBuyers?: Maybe<Array<TopTraderInfo>>;
  topSellers?: Maybe<Array<TopTraderInfo>>;
  totalSavings: Scalars['BigDecimal'];
};

export type TradingGroupTradeSummaryFilter = {
  applicationHostId: Scalars['UUID'];
  settlementId?: InputMaybe<Scalars['UUID']>;
  tenantId: Scalars['UUID'];
  tradingGroupId: Scalars['UUID'];
};

export enum TransactionCategory {
  Forward = 'FORWARD',
  Settlement = 'SETTLEMENT'
}

export type TransactionFilter = {
  applicationHostId: Scalars['UUID'];
  generatorType?: InputMaybe<GeneratorType>;
  meterType?: InputMaybe<MeterType>;
  ruleType?: InputMaybe<RuleType>;
  tenantId: Scalars['UUID'];
  tradingGroupId: Scalars['UUID'];
  transactionCategory?: InputMaybe<TransactionCategory>;
  transactionEndDateTime?: InputMaybe<Scalars['DateTime']>;
  transactionStartDateTime?: InputMaybe<Scalars['DateTime']>;
  transactionType?: InputMaybe<TransactionType>;
  userId?: InputMaybe<Scalars['UUID']>;
};

export enum TransactionType {
  Adjustment = 'ADJUSTMENT',
  Credit = 'CREDIT',
  Debit = 'DEBIT',
  Refund = 'REFUND'
}

export type UpdateOwnUserInput = {
  acceptedTermsAndConditions?: InputMaybe<Scalars['Boolean']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  retailerId?: InputMaybe<Scalars['UUID']>;
  status?: InputMaybe<UserStatus>;
  title?: InputMaybe<Scalars['String']>;
};

/** Input definition to update role */
export type UpdateRoleInput = {
  /** Priority level of role */
  level?: InputMaybe<Scalars['Int']>;
  /** Name of role */
  name?: InputMaybe<Scalars['String']>;
  /** Permissions associated with role */
  permissions?: InputMaybe<Array<Scalars['String']>>;
  /** Does this requires tenancy checks? */
  requiresTenancyCheck?: InputMaybe<Scalars['Boolean']>;
  /** Id of role that is updating */
  roleId: Scalars['UUID'];
  /** Status of Role */
  status?: InputMaybe<RoleStatus>;
  /** Is this a system role? */
  systemRoleEnabled?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateRolePayload = {
  __typename?: 'UpdateRolePayload';
  role?: Maybe<Role>;
};

/** Mutation input data to update Tenant */
export type UpdateTenantInput = {
  /** For localization. Example values: en_AU, en_US, it_IT */
  location?: InputMaybe<Scalars['String']>;
  /** Tenant name (unique) */
  name: Scalars['String'];
  /** Tenant id */
  tenantId: Scalars['UUID'];
};

export type UpdateTradingGroupInput = {
  co2EmissionsIntensity?: InputMaybe<Array<EmissionsIntensityTupleMutationInput>>;
  features?: InputMaybe<Array<TenantFeature>>;
  /** Forward settlement gate closure in local time for the trading group. */
  gateClosureTime?: InputMaybe<Scalars['LocalTime']>;
  minNumMeters?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  notificationConfig?: InputMaybe<NotificationConfigMutationInput>;
  readingsConfig?: InputMaybe<Array<ReadingsConfigMutationInput>>;
  runBalanceCheck?: InputMaybe<Scalars['Boolean']>;
  settlementIntervalInSeconds?: InputMaybe<Scalars['Int']>;
  /**
   * The period interval in seconds for the settlement interval.
   * For example, if the settlement interval is 24 hours and the settlement period interval is 30 minutes,
   * then there will be 48 settlement periods.
   */
  settlementPeriodInSeconds?: InputMaybe<Scalars['Int']>;
  /** Settlement start time in local time for the trading group. */
  settlementStartTime?: InputMaybe<Scalars['LocalTime']>;
  timeZone?: InputMaybe<Scalars['String']>;
  vendor?: InputMaybe<Scalars['String']>;
  writeToBlockchain?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateUserInput = {
  acceptedTermsAndConditions?: InputMaybe<Scalars['Boolean']>;
  applicationHostId: Scalars['UUID'];
  applicationHostName?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  roles?: InputMaybe<Array<InputMaybe<UserRole>>>;
  status?: InputMaybe<UserStatus>;
  tenantId: Scalars['UUID'];
  tenantName?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  tradingGroupId: Scalars['UUID'];
  tradingGroupName?: InputMaybe<Scalars['String']>;
  username?: InputMaybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  acceptedTermsAndConditions?: Maybe<Scalars['Boolean']>;
  applicationHostId?: Maybe<Scalars['UUID']>;
  applicationHostName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  identityProviderRef?: Maybe<Scalars['String']>;
  isProsumer?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  retailerId?: Maybe<Scalars['UUID']>;
  roles?: Maybe<Array<UserRole>>;
  status: UserStatus;
  tenant?: Maybe<TenantV2>;
  tenantId?: Maybe<Scalars['UUID']>;
  tenantName?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  tradingGroupId?: Maybe<Scalars['UUID']>;
  tradingGroupName?: Maybe<Scalars['String']>;
  userTariff?: Maybe<UserTariff>;
  username?: Maybe<Scalars['String']>;
};

export type UserAccess = {
  __typename?: 'UserAccess';
  /** Indicates if user has accepted terms and conditions */
  acceptedTermsAndConditions?: Maybe<Scalars['Boolean']>;
  /** Granted Authority of current authenticated user */
  grants?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** URL of the pages that current authenticated user has access to */
  urls?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UserConnection = {
  __typename?: 'UserConnection';
  edges?: Maybe<Array<Maybe<UserEdge>>>;
  pageInfo?: Maybe<PaginationInfo>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type UserEdge = {
  __typename?: 'UserEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<User>;
};

export type UserFilter = {
  applicationHostId?: InputMaybe<Scalars['UUID']>;
  orderBy?: InputMaybe<UserFilterOrder>;
  tenantId?: InputMaybe<Scalars['UUID']>;
  tradingGroupId?: InputMaybe<Scalars['UUID']>;
  userStatusIn?: InputMaybe<Array<InputMaybe<UserStatus>>>;
  usernames?: InputMaybe<UsernameSearchFilter>;
};

export type UserFilterOrder = {
  username?: InputMaybe<SortDirection>;
};

export type UserInput = {
  acceptedTermsAndConditions?: InputMaybe<Scalars['Boolean']>;
  applicationHostId: Scalars['UUID'];
  applicationHostName?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  identityProviderRef?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  roles: Array<UserRole>;
  status: UserStatus;
  tenantId: Scalars['UUID'];
  tenantName: Scalars['String'];
  title?: InputMaybe<Scalars['String']>;
  tradingGroupId: Scalars['UUID'];
  tradingGroupName: Scalars['String'];
  userId?: InputMaybe<Scalars['UUID']>;
  username: Scalars['String'];
};

export type UserResult = {
  __typename?: 'UserResult';
  userErrors?: Maybe<Array<Maybe<CreateUserError>>>;
  users: Array<Maybe<User>>;
};

export enum UserRole {
  AppHostAdmin = 'AppHostAdmin',
  CorporateUser = 'CorporateUser',
  Operator = 'Operator',
  ParticipantUser = 'ParticipantUser',
  PlatformAdmin = 'PlatformAdmin',
  RetailerUser = 'RetailerUser',
  TenantAdmin = 'TenantAdmin'
}

export enum UserStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  New = 'NEW',
  Unverified = 'UNVERIFIED',
  Verified = 'VERIFIED'
}

export type UserTariff = {
  __typename?: 'UserTariff';
  applicationHostId?: Maybe<Scalars['UUID']>;
  displayName?: Maybe<Scalars['String']>;
  gridMeterId?: Maybe<Scalars['UUID']>;
  id: Scalars['UUID'];
  isDefaultTariff?: Maybe<Scalars['Boolean']>;
  meterIds?: Maybe<Array<Scalars['UUID']>>;
  tariffs?: Maybe<Array<Tariff>>;
  tenantId?: Maybe<Scalars['UUID']>;
  timeZone?: Maybe<Scalars['String']>;
  tradingGroupId?: Maybe<Scalars['UUID']>;
  userId: Scalars['UUID'];
  validFrom?: Maybe<Scalars['DateTime']>;
  validTo?: Maybe<Scalars['DateTime']>;
};

export type UserTariffFilter = {
  active?: InputMaybe<Scalars['Boolean']>;
  userId?: InputMaybe<Scalars['UUID']>;
};

export type UserTariffInput = {
  applicationHostId?: InputMaybe<Scalars['UUID']>;
  displayName: Scalars['String'];
  gridMeterId?: InputMaybe<Scalars['UUID']>;
  tariffs?: InputMaybe<Array<TariffInput>>;
  tenantId?: InputMaybe<Scalars['UUID']>;
  timeZone?: InputMaybe<Scalars['String']>;
  tradingGroupId?: InputMaybe<Scalars['UUID']>;
  userId: Scalars['UUID'];
};

export type UsernameSearchFilter = {
  contain?: InputMaybe<Scalars['String']>;
  startWith?: InputMaybe<Scalars['String']>;
};

export type UpdateOwnAccountMutationVariables = Exact<{
  userId: Scalars['UUID'];
  user: UpdateOwnUserInput;
}>;


export type UpdateOwnAccountMutation = { __typename?: 'Mutation', updateOwnAccount: { __typename?: 'User', title?: string | null | undefined, firstName?: string | null | undefined, lastName?: string | null | undefined, status: UserStatus, acceptedTermsAndConditions?: boolean | null | undefined } };

export type UpdateOnboardingStepMutationVariables = Exact<{
  step?: Maybe<Scalars['Int']>;
}>;


export type UpdateOnboardingStepMutation = { __typename?: 'Mutation', updateOnboardingStep: { __typename?: 'User', status: UserStatus } };

export type OnboardUserMeterMutationVariables = Exact<{
  userId: Scalars['UUID'];
  meterExternalRef: Scalars['String'];
  meterAssetType?: Maybe<MeterAssetType>;
}>;


export type OnboardUserMeterMutation = { __typename?: 'Mutation', onboardUserMeter?: boolean | null | undefined };

export type ResendUserActivationEmailMutationVariables = Exact<{
  userId: Scalars['UUID'];
}>;


export type ResendUserActivationEmailMutation = { __typename?: 'Mutation', resendUserActivationEmail?: boolean | null | undefined };

export type GeneralResendUserActivationEmailMutationVariables = Exact<{
  userId: Scalars['UUID'];
}>;


export type GeneralResendUserActivationEmailMutation = { __typename?: 'Mutation', generalResendUserActivationEmail?: boolean | null | undefined };

export type ChangePasswordMutationVariables = Exact<{
  passwords: PasswordsInput;
}>;


export type ChangePasswordMutation = { __typename?: 'Mutation', changePassword?: string | null | undefined };

export type CreateUserMutationVariables = Exact<{
  user: UserInput;
}>;


export type CreateUserMutation = { __typename?: 'Mutation', createUser: { __typename?: 'User', username?: string | null | undefined, email?: string | null | undefined, title?: string | null | undefined, firstName?: string | null | undefined, lastName?: string | null | undefined, tenantId?: string | null | undefined, tenantName?: string | null | undefined, applicationHostId?: string | null | undefined, tradingGroupId?: string | null | undefined, tradingGroupName?: string | null | undefined, status: UserStatus, roles?: Array<UserRole> | null | undefined } };

export type ResetPasswordMutationVariables = Exact<{
  username: Scalars['String'];
}>;


export type ResetPasswordMutation = { __typename?: 'Mutation', resetPassword?: boolean | null | undefined };

export type CreateUsersMutationVariables = Exact<{
  users: Array<UserInput> | UserInput;
}>;


export type CreateUsersMutation = { __typename?: 'Mutation', createUsers: { __typename?: 'UserResult', userErrors?: Array<{ __typename?: 'CreateUserError', message: string, username: string } | null | undefined> | null | undefined } };

export type DeleteProfileTemplateMutationVariables = Exact<{
  tenancyInfo: TenancyInfo;
  deleteProfileTemplateId: Scalars['UUID'];
}>;


export type DeleteProfileTemplateMutation = { __typename?: 'Mutation', deleteProfileTemplate?: boolean | null | undefined };

export type CreateUpdateProfileTemplateMutationVariables = Exact<{
  template: ProfileInput;
}>;


export type CreateUpdateProfileTemplateMutation = { __typename?: 'Mutation', createUpdateProfileTemplate?: { __typename?: 'Profile', id: string, name: string, profileClass: ProfileClass, type: ProfileType, day?: string | null | undefined, seasonStart?: any | null | undefined, seasonEnd?: any | null | undefined, month?: number | null | undefined, interval: number, isDefault?: boolean | null | undefined } | null | undefined };

export type UpdateMeterProfilesMutationVariables = Exact<{
  meterProfiles: MeterProfilesInput;
}>;


export type UpdateMeterProfilesMutation = { __typename?: 'Mutation', updateMeterProfiles?: { __typename?: 'MeterProfiles', id: string, tenantId: string, applicationHostId: string, tradingGroupId: string, profiles?: Array<{ __typename?: 'MeterProfile', name: string, id: string }> | null | undefined } | null | undefined };

export type DeleteMeterProfileMutationVariables = Exact<{
  deleteMeterProfileInput: DeleteMeterProfileInput;
}>;


export type DeleteMeterProfileMutation = { __typename?: 'Mutation', deleteMeterProfile?: boolean | null | undefined };

export type ResetToDefaultProfileMutationVariables = Exact<{
  toDefaultProfileInput?: Maybe<ResetToDefaultProfileInput>;
}>;


export type ResetToDefaultProfileMutation = { __typename?: 'Mutation', resetToDefaultProfile?: boolean | null | undefined };

export type UserProfileQueryVariables = Exact<{
  identityProviderRef: Scalars['String'];
}>;


export type UserProfileQuery = { __typename?: 'Query', userProfile: { __typename?: 'User', id?: string | null | undefined, username?: string | null | undefined, identityProviderRef?: string | null | undefined, tenantId?: string | null | undefined, tenantName?: string | null | undefined, applicationHostId?: string | null | undefined, applicationHostName?: string | null | undefined, tradingGroupId?: string | null | undefined, tradingGroupName?: string | null | undefined, firstName?: string | null | undefined, lastName?: string | null | undefined, email?: string | null | undefined, status: UserStatus, roles?: Array<UserRole> | null | undefined, acceptedTermsAndConditions?: boolean | null | undefined, isProsumer?: boolean | null | undefined } };

export type UserProfileRoleQueryVariables = Exact<{
  identityProviderRef: Scalars['String'];
}>;


export type UserProfileRoleQuery = { __typename?: 'Query', userProfile: { __typename?: 'User', id?: string | null | undefined, roles?: Array<UserRole> | null | undefined } };

export type TradingGroupTradeSummaryQueryVariables = Exact<{
  filter: TradingGroupTradeSummaryFilter;
}>;


export type TradingGroupTradeSummaryQuery = { __typename?: 'Query', tradingGroupTradeSummary?: { __typename?: 'TradingGroupTradeSummary', settlementId: string, startDateTime: string, endDateTime: string, p2pEnergyBought: number, p2pBuyPrice: number, p2pEnergySold: number, p2pSellPrice: number, gridEnergyBought: number, gridBuyPrice: number, gridEnergySold: number, gridSellPrice: number, selfSufficiencyPercentage: number, selfConsumptionPercentage: number, totalSavings: number, averageSavings: number, numRetailConsumers: number, numRetailProsumers: number, numRetailBessProsumers: number, numCorporateIndustryConsumers: number, numCorporateIndustryProsumers: number, numCorporateIndustryBessProsumers: number, numCommunityBatteries: number, topBuyers?: Array<{ __typename?: 'TopTraderInfo', description: string, amount: number }> | null | undefined, topSellers?: Array<{ __typename?: 'TopTraderInfo', description: string, amount: number }> | null | undefined } | null | undefined };

export type ParticipantTradeSummaryQueryVariables = Exact<{
  settlementId?: Maybe<Scalars['UUID']>;
}>;


export type ParticipantTradeSummaryQuery = { __typename?: 'Query', participantTradeSummary?: { __typename?: 'ParticipantTradeSummary', settlementId: string, startDateTime: string, endDateTime: string, p2pEnergyBought: number, p2pBuyPrice: number, p2pEnergySold: number, p2pSellPrice: number, gridEnergyBought: number, gridBuyPrice: number, gridEnergySold: number, gridSellPrice: number, selfSufficiencyPercentage: number, selfConsumptionPercentage: number, totalSavings: number, averageSavings: number, numRetailConsumers: number, numRetailProsumers: number, numRetailBessProsumers: number, numCorporateIndustryConsumers: number, numCorporateIndustryProsumers: number, numCorporateIndustryBessProsumers: number, numCommunityBatteries: number, topBuyers?: Array<{ __typename?: 'TopTraderInfo', description: string, amount: number }> | null | undefined, topSellers?: Array<{ __typename?: 'TopTraderInfo', description: string, amount: number }> | null | undefined } | null | undefined };

export type TradingGroupTradeComparisonQueryVariables = Exact<{
  filter: TradingGroupTradeSummaryFilter;
}>;


export type TradingGroupTradeComparisonQuery = { __typename?: 'Query', tradingGroupTradeComparison?: { __typename?: 'TradingGroupComparisonSummary', consumerTotalAmountBAU: number, consumerTotalAmount: number, consumerTotalSavings: number, consumerAverageSavings: number, prosumerTotalAmountBAU: number, prosumerTotalAmount: number, prosumerTotalSavings: number, prosumerAverageSavings: number, bessProsumerTotalAmountBAU: number, bessProsumerTotalAmount: number, bessProsumerTotalSavings: number, bessProsumerAverageSavings: number, totalGridImportBAU: number, totalGridImport: number, totalGridExportBAU: number, totalGridExport: number, tradesByPeriod?: Array<{ __typename?: 'SettlementPeriodTrade', settlementPeriod: number, startDateTime: string, endDateTime: string, consumerTotalAmountBAU: number, consumerTotalAmount: number, consumerTotalSavings: number, consumerAverageSavings: number, prosumerTotalAmountBAU: number, prosumerTotalAmount: number, prosumerTotalSavings: number, prosumerAverageSavings: number, bessProsumerTotalAmountBAU: number, bessProsumerTotalAmount: number, bessProsumerTotalSavings: number, bessProsumerAverageSavings: number, gridImportBAU: number, gridImport: number, gridExportBAU: number, gridExport: number }> | null | undefined } | null | undefined };

export type UserTariffsQueryVariables = Exact<{
  where?: Maybe<UserTariffFilter>;
}>;


export type UserTariffsQuery = { __typename?: 'Query', userTariffs?: Array<{ __typename?: 'UserTariff', id: string, tariffs?: Array<{ __typename?: 'Tariff', dayFrom?: number | null | undefined }> | null | undefined } | null | undefined> | null | undefined };

export type GetUserMeterQueryVariables = Exact<{
  userId?: Maybe<Scalars['UUID']>;
}>;


export type GetUserMeterQuery = { __typename?: 'Query', getUserMeter?: { __typename?: 'Meter', meterId: string, assetType: MeterAssetType, meterExternalRef: string } | null | undefined };

export type UsersQueryVariables = Exact<{
  where?: Maybe<UserFilter>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
}>;


export type UsersQuery = { __typename?: 'Query', users: { __typename?: 'UserConnection', totalCount?: number | null | undefined, edges?: Array<{ __typename?: 'UserEdge', cursor?: string | null | undefined, node?: { __typename?: 'User', id?: string | null | undefined, username?: string | null | undefined, email?: string | null | undefined, title?: string | null | undefined, firstName?: string | null | undefined, lastName?: string | null | undefined, tenantName?: string | null | undefined, tradingGroupName?: string | null | undefined, roles?: Array<UserRole> | null | undefined, status: UserStatus } | null | undefined } | null | undefined> | null | undefined, pageInfo?: { __typename?: 'PaginationInfo', startCursor?: string | null | undefined, endCursor?: string | null | undefined, hasNextPage?: boolean | null | undefined, hasPreviousPage?: boolean | null | undefined } | null | undefined } };

export type MeterProfilesQueryVariables = Exact<{
  meterProfilesFilter: MeterProfilesFilter;
}>;


export type MeterProfilesQuery = { __typename?: 'Query', meterProfiles?: { __typename?: 'MeterProfiles', id: string, tenantId: string, applicationHostId: string, tradingGroupId: string, profiles?: Array<{ __typename?: 'MeterProfile', id: string, name: string, profileClass: ProfileClass, type: ProfileType, day?: string | null | undefined, seasonStart?: any | null | undefined, seasonEnd?: any | null | undefined, month?: number | null | undefined, interval: number, energyData?: Array<number> | null | undefined }> | null | undefined } | null | undefined };

export type ProfileTemplatesQueryVariables = Exact<{
  where: ProfileFilter;
}>;


export type ProfileTemplatesQuery = { __typename?: 'Query', profileTemplates?: Array<{ __typename?: 'Profile', id: string, name: string, profileClass: ProfileClass, type: ProfileType, day?: string | null | undefined, seasonStart?: any | null | undefined, seasonEnd?: any | null | undefined, month?: number | null | undefined, interval: number, isDefault?: boolean | null | undefined, numberOfMeters?: number | null | undefined, tenantId: string, tradingGroupId: string, applicationHostId: string, energyData?: Array<number> | null | undefined }> | null | undefined };

export type ProfileTemplateEnergyDataQueryVariables = Exact<{
  where: ProfileFilter;
}>;


export type ProfileTemplateEnergyDataQuery = { __typename?: 'Query', profileTemplates?: Array<{ __typename?: 'Profile', id: string, energyData?: Array<number> | null | undefined }> | null | undefined };

export type EnergyDataUploadUrlQueryVariables = Exact<{
  input: GetEnergyDataUploadUrlInput;
}>;


export type EnergyDataUploadUrlQuery = { __typename?: 'Query', getEnergyDataUploadURL: { __typename?: 'GetEnergyDataUploadURLPayload', url: string } };

export type ListOfMetersQueryVariables = Exact<{
  tradingGroupName?: Maybe<Scalars['String']>;
  tenantName?: Maybe<Scalars['String']>;
  applicationHostName?: Maybe<Scalars['String']>;
}>;


export type ListOfMetersQuery = { __typename?: 'Query', getMeters?: Array<{ __typename?: 'Meter', meterId: string, meterExternalRef: string, assetType: MeterAssetType }> | null | undefined };

export type UnassignedProfilesQueryVariables = Exact<{
  meterProfilesFilter: MeterProfilesFilter;
}>;


export type UnassignedProfilesQuery = { __typename?: 'Query', unassignedProfiles?: Array<{ __typename?: 'MeterProfile', id: string, name: string }> | null | undefined };

export type MeterForecastForNextDayQueryVariables = Exact<{
  meterProfilesFilter?: Maybe<MeterProfilesFilter>;
}>;


export type MeterForecastForNextDayQuery = { __typename?: 'Query', meterForecastForNextDay: { __typename?: 'MeterForecast', id: string, load?: Array<number> | null | undefined, generation?: Array<number> | null | undefined, bess?: Array<number> | null | undefined } };

export type RetailersQueryVariables = Exact<{
  tenancyInfo?: Maybe<TenancyInfo>;
}>;


export type RetailersQuery = { __typename?: 'Query', retailers?: Array<{ __typename?: 'User', id?: string | null | undefined, firstName?: string | null | undefined, lastName?: string | null | undefined, userTariff?: { __typename?: 'UserTariff', displayName?: string | null | undefined } | null | undefined } | null | undefined> | null | undefined };


export const UpdateOwnAccountDocument = gql`
    mutation UpdateOwnAccount($userId: UUID!, $user: UpdateOwnUserInput!) {
  updateOwnAccount(userId: $userId, user: $user) {
    title
    firstName
    lastName
    status
    acceptedTermsAndConditions
  }
}
    `;
export type UpdateOwnAccountMutationFn = Apollo.MutationFunction<UpdateOwnAccountMutation, UpdateOwnAccountMutationVariables>;

/**
 * __useUpdateOwnAccountMutation__
 *
 * To run a mutation, you first call `useUpdateOwnAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOwnAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOwnAccountMutation, { data, loading, error }] = useUpdateOwnAccountMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      user: // value for 'user'
 *   },
 * });
 */
export function useUpdateOwnAccountMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOwnAccountMutation, UpdateOwnAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOwnAccountMutation, UpdateOwnAccountMutationVariables>(UpdateOwnAccountDocument, options);
      }
export type UpdateOwnAccountMutationHookResult = ReturnType<typeof useUpdateOwnAccountMutation>;
export type UpdateOwnAccountMutationResult = Apollo.MutationResult<UpdateOwnAccountMutation>;
export type UpdateOwnAccountMutationOptions = Apollo.BaseMutationOptions<UpdateOwnAccountMutation, UpdateOwnAccountMutationVariables>;
export const UpdateOnboardingStepDocument = gql`
    mutation UpdateOnboardingStep($step: Int) {
  updateOnboardingStep(step: $step) {
    status
  }
}
    `;
export type UpdateOnboardingStepMutationFn = Apollo.MutationFunction<UpdateOnboardingStepMutation, UpdateOnboardingStepMutationVariables>;

/**
 * __useUpdateOnboardingStepMutation__
 *
 * To run a mutation, you first call `useUpdateOnboardingStepMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOnboardingStepMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOnboardingStepMutation, { data, loading, error }] = useUpdateOnboardingStepMutation({
 *   variables: {
 *      step: // value for 'step'
 *   },
 * });
 */
export function useUpdateOnboardingStepMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOnboardingStepMutation, UpdateOnboardingStepMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOnboardingStepMutation, UpdateOnboardingStepMutationVariables>(UpdateOnboardingStepDocument, options);
      }
export type UpdateOnboardingStepMutationHookResult = ReturnType<typeof useUpdateOnboardingStepMutation>;
export type UpdateOnboardingStepMutationResult = Apollo.MutationResult<UpdateOnboardingStepMutation>;
export type UpdateOnboardingStepMutationOptions = Apollo.BaseMutationOptions<UpdateOnboardingStepMutation, UpdateOnboardingStepMutationVariables>;
export const OnboardUserMeterDocument = gql`
    mutation OnboardUserMeter($userId: UUID!, $meterExternalRef: String!, $meterAssetType: MeterAssetType) {
  onboardUserMeter(
    userId: $userId
    meterExternalRef: $meterExternalRef
    meterAssetType: $meterAssetType
  )
}
    `;
export type OnboardUserMeterMutationFn = Apollo.MutationFunction<OnboardUserMeterMutation, OnboardUserMeterMutationVariables>;

/**
 * __useOnboardUserMeterMutation__
 *
 * To run a mutation, you first call `useOnboardUserMeterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOnboardUserMeterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [onboardUserMeterMutation, { data, loading, error }] = useOnboardUserMeterMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      meterExternalRef: // value for 'meterExternalRef'
 *      meterAssetType: // value for 'meterAssetType'
 *   },
 * });
 */
export function useOnboardUserMeterMutation(baseOptions?: Apollo.MutationHookOptions<OnboardUserMeterMutation, OnboardUserMeterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OnboardUserMeterMutation, OnboardUserMeterMutationVariables>(OnboardUserMeterDocument, options);
      }
export type OnboardUserMeterMutationHookResult = ReturnType<typeof useOnboardUserMeterMutation>;
export type OnboardUserMeterMutationResult = Apollo.MutationResult<OnboardUserMeterMutation>;
export type OnboardUserMeterMutationOptions = Apollo.BaseMutationOptions<OnboardUserMeterMutation, OnboardUserMeterMutationVariables>;
export const ResendUserActivationEmailDocument = gql`
    mutation ResendUserActivationEmail($userId: UUID!) {
  resendUserActivationEmail(userId: $userId)
}
    `;
export type ResendUserActivationEmailMutationFn = Apollo.MutationFunction<ResendUserActivationEmailMutation, ResendUserActivationEmailMutationVariables>;

/**
 * __useResendUserActivationEmailMutation__
 *
 * To run a mutation, you first call `useResendUserActivationEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendUserActivationEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendUserActivationEmailMutation, { data, loading, error }] = useResendUserActivationEmailMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useResendUserActivationEmailMutation(baseOptions?: Apollo.MutationHookOptions<ResendUserActivationEmailMutation, ResendUserActivationEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResendUserActivationEmailMutation, ResendUserActivationEmailMutationVariables>(ResendUserActivationEmailDocument, options);
      }
export type ResendUserActivationEmailMutationHookResult = ReturnType<typeof useResendUserActivationEmailMutation>;
export type ResendUserActivationEmailMutationResult = Apollo.MutationResult<ResendUserActivationEmailMutation>;
export type ResendUserActivationEmailMutationOptions = Apollo.BaseMutationOptions<ResendUserActivationEmailMutation, ResendUserActivationEmailMutationVariables>;
export const GeneralResendUserActivationEmailDocument = gql`
    mutation GeneralResendUserActivationEmail($userId: UUID!) {
  generalResendUserActivationEmail(userId: $userId)
}
    `;
export type GeneralResendUserActivationEmailMutationFn = Apollo.MutationFunction<GeneralResendUserActivationEmailMutation, GeneralResendUserActivationEmailMutationVariables>;

/**
 * __useGeneralResendUserActivationEmailMutation__
 *
 * To run a mutation, you first call `useGeneralResendUserActivationEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGeneralResendUserActivationEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generalResendUserActivationEmailMutation, { data, loading, error }] = useGeneralResendUserActivationEmailMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGeneralResendUserActivationEmailMutation(baseOptions?: Apollo.MutationHookOptions<GeneralResendUserActivationEmailMutation, GeneralResendUserActivationEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GeneralResendUserActivationEmailMutation, GeneralResendUserActivationEmailMutationVariables>(GeneralResendUserActivationEmailDocument, options);
      }
export type GeneralResendUserActivationEmailMutationHookResult = ReturnType<typeof useGeneralResendUserActivationEmailMutation>;
export type GeneralResendUserActivationEmailMutationResult = Apollo.MutationResult<GeneralResendUserActivationEmailMutation>;
export type GeneralResendUserActivationEmailMutationOptions = Apollo.BaseMutationOptions<GeneralResendUserActivationEmailMutation, GeneralResendUserActivationEmailMutationVariables>;
export const ChangePasswordDocument = gql`
    mutation ChangePassword($passwords: PasswordsInput!) {
  changePassword(passwords: $passwords)
}
    `;
export type ChangePasswordMutationFn = Apollo.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      passwords: // value for 'passwords'
 *   },
 * });
 */
export function useChangePasswordMutation(baseOptions?: Apollo.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, options);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = Apollo.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<ChangePasswordMutation, ChangePasswordMutationVariables>;
export const CreateUserDocument = gql`
    mutation CreateUser($user: UserInput!) {
  createUser(user: $user) {
    username
    email
    title
    firstName
    lastName
    tenantId
    tenantName
    applicationHostId
    tradingGroupId
    tradingGroupName
    status
    roles
  }
}
    `;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation ResetPassword($username: String!) {
  resetPassword(username: $username)
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      username: // value for 'username'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const CreateUsersDocument = gql`
    mutation CreateUsers($users: [UserInput!]!) {
  createUsers(users: $users) {
    userErrors {
      message
      username
    }
  }
}
    `;
export type CreateUsersMutationFn = Apollo.MutationFunction<CreateUsersMutation, CreateUsersMutationVariables>;

/**
 * __useCreateUsersMutation__
 *
 * To run a mutation, you first call `useCreateUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUsersMutation, { data, loading, error }] = useCreateUsersMutation({
 *   variables: {
 *      users: // value for 'users'
 *   },
 * });
 */
export function useCreateUsersMutation(baseOptions?: Apollo.MutationHookOptions<CreateUsersMutation, CreateUsersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUsersMutation, CreateUsersMutationVariables>(CreateUsersDocument, options);
      }
export type CreateUsersMutationHookResult = ReturnType<typeof useCreateUsersMutation>;
export type CreateUsersMutationResult = Apollo.MutationResult<CreateUsersMutation>;
export type CreateUsersMutationOptions = Apollo.BaseMutationOptions<CreateUsersMutation, CreateUsersMutationVariables>;
export const DeleteProfileTemplateDocument = gql`
    mutation DeleteProfileTemplate($tenancyInfo: TenancyInfo!, $deleteProfileTemplateId: UUID!) {
  deleteProfileTemplate(tenancyInfo: $tenancyInfo, id: $deleteProfileTemplateId)
}
    `;
export type DeleteProfileTemplateMutationFn = Apollo.MutationFunction<DeleteProfileTemplateMutation, DeleteProfileTemplateMutationVariables>;

/**
 * __useDeleteProfileTemplateMutation__
 *
 * To run a mutation, you first call `useDeleteProfileTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProfileTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProfileTemplateMutation, { data, loading, error }] = useDeleteProfileTemplateMutation({
 *   variables: {
 *      tenancyInfo: // value for 'tenancyInfo'
 *      deleteProfileTemplateId: // value for 'deleteProfileTemplateId'
 *   },
 * });
 */
export function useDeleteProfileTemplateMutation(baseOptions?: Apollo.MutationHookOptions<DeleteProfileTemplateMutation, DeleteProfileTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteProfileTemplateMutation, DeleteProfileTemplateMutationVariables>(DeleteProfileTemplateDocument, options);
      }
export type DeleteProfileTemplateMutationHookResult = ReturnType<typeof useDeleteProfileTemplateMutation>;
export type DeleteProfileTemplateMutationResult = Apollo.MutationResult<DeleteProfileTemplateMutation>;
export type DeleteProfileTemplateMutationOptions = Apollo.BaseMutationOptions<DeleteProfileTemplateMutation, DeleteProfileTemplateMutationVariables>;
export const CreateUpdateProfileTemplateDocument = gql`
    mutation CreateUpdateProfileTemplate($template: ProfileInput!) {
  createUpdateProfileTemplate(template: $template) {
    id
    name
    profileClass
    type
    day
    seasonStart
    seasonEnd
    month
    interval
    isDefault
  }
}
    `;
export type CreateUpdateProfileTemplateMutationFn = Apollo.MutationFunction<CreateUpdateProfileTemplateMutation, CreateUpdateProfileTemplateMutationVariables>;

/**
 * __useCreateUpdateProfileTemplateMutation__
 *
 * To run a mutation, you first call `useCreateUpdateProfileTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUpdateProfileTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUpdateProfileTemplateMutation, { data, loading, error }] = useCreateUpdateProfileTemplateMutation({
 *   variables: {
 *      template: // value for 'template'
 *   },
 * });
 */
export function useCreateUpdateProfileTemplateMutation(baseOptions?: Apollo.MutationHookOptions<CreateUpdateProfileTemplateMutation, CreateUpdateProfileTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUpdateProfileTemplateMutation, CreateUpdateProfileTemplateMutationVariables>(CreateUpdateProfileTemplateDocument, options);
      }
export type CreateUpdateProfileTemplateMutationHookResult = ReturnType<typeof useCreateUpdateProfileTemplateMutation>;
export type CreateUpdateProfileTemplateMutationResult = Apollo.MutationResult<CreateUpdateProfileTemplateMutation>;
export type CreateUpdateProfileTemplateMutationOptions = Apollo.BaseMutationOptions<CreateUpdateProfileTemplateMutation, CreateUpdateProfileTemplateMutationVariables>;
export const UpdateMeterProfilesDocument = gql`
    mutation UpdateMeterProfiles($meterProfiles: MeterProfilesInput!) {
  updateMeterProfiles(meterProfiles: $meterProfiles) {
    id
    tenantId
    applicationHostId
    tradingGroupId
    profiles {
      name
      id
    }
  }
}
    `;
export type UpdateMeterProfilesMutationFn = Apollo.MutationFunction<UpdateMeterProfilesMutation, UpdateMeterProfilesMutationVariables>;

/**
 * __useUpdateMeterProfilesMutation__
 *
 * To run a mutation, you first call `useUpdateMeterProfilesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMeterProfilesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMeterProfilesMutation, { data, loading, error }] = useUpdateMeterProfilesMutation({
 *   variables: {
 *      meterProfiles: // value for 'meterProfiles'
 *   },
 * });
 */
export function useUpdateMeterProfilesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMeterProfilesMutation, UpdateMeterProfilesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMeterProfilesMutation, UpdateMeterProfilesMutationVariables>(UpdateMeterProfilesDocument, options);
      }
export type UpdateMeterProfilesMutationHookResult = ReturnType<typeof useUpdateMeterProfilesMutation>;
export type UpdateMeterProfilesMutationResult = Apollo.MutationResult<UpdateMeterProfilesMutation>;
export type UpdateMeterProfilesMutationOptions = Apollo.BaseMutationOptions<UpdateMeterProfilesMutation, UpdateMeterProfilesMutationVariables>;
export const DeleteMeterProfileDocument = gql`
    mutation DeleteMeterProfile($deleteMeterProfileInput: DeleteMeterProfileInput!) {
  deleteMeterProfile(deleteMeterProfileInput: $deleteMeterProfileInput)
}
    `;
export type DeleteMeterProfileMutationFn = Apollo.MutationFunction<DeleteMeterProfileMutation, DeleteMeterProfileMutationVariables>;

/**
 * __useDeleteMeterProfileMutation__
 *
 * To run a mutation, you first call `useDeleteMeterProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMeterProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMeterProfileMutation, { data, loading, error }] = useDeleteMeterProfileMutation({
 *   variables: {
 *      deleteMeterProfileInput: // value for 'deleteMeterProfileInput'
 *   },
 * });
 */
export function useDeleteMeterProfileMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMeterProfileMutation, DeleteMeterProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteMeterProfileMutation, DeleteMeterProfileMutationVariables>(DeleteMeterProfileDocument, options);
      }
export type DeleteMeterProfileMutationHookResult = ReturnType<typeof useDeleteMeterProfileMutation>;
export type DeleteMeterProfileMutationResult = Apollo.MutationResult<DeleteMeterProfileMutation>;
export type DeleteMeterProfileMutationOptions = Apollo.BaseMutationOptions<DeleteMeterProfileMutation, DeleteMeterProfileMutationVariables>;
export const ResetToDefaultProfileDocument = gql`
    mutation ResetToDefaultProfile($toDefaultProfileInput: ResetToDefaultProfileInput) {
  resetToDefaultProfile(toDefaultProfileInput: $toDefaultProfileInput)
}
    `;
export type ResetToDefaultProfileMutationFn = Apollo.MutationFunction<ResetToDefaultProfileMutation, ResetToDefaultProfileMutationVariables>;

/**
 * __useResetToDefaultProfileMutation__
 *
 * To run a mutation, you first call `useResetToDefaultProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetToDefaultProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetToDefaultProfileMutation, { data, loading, error }] = useResetToDefaultProfileMutation({
 *   variables: {
 *      toDefaultProfileInput: // value for 'toDefaultProfileInput'
 *   },
 * });
 */
export function useResetToDefaultProfileMutation(baseOptions?: Apollo.MutationHookOptions<ResetToDefaultProfileMutation, ResetToDefaultProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetToDefaultProfileMutation, ResetToDefaultProfileMutationVariables>(ResetToDefaultProfileDocument, options);
      }
export type ResetToDefaultProfileMutationHookResult = ReturnType<typeof useResetToDefaultProfileMutation>;
export type ResetToDefaultProfileMutationResult = Apollo.MutationResult<ResetToDefaultProfileMutation>;
export type ResetToDefaultProfileMutationOptions = Apollo.BaseMutationOptions<ResetToDefaultProfileMutation, ResetToDefaultProfileMutationVariables>;
export const UserProfileDocument = gql`
    query UserProfile($identityProviderRef: String!) {
  userProfile(identityProviderRef: $identityProviderRef) {
    id
    username
    identityProviderRef
    tenantId
    tenantName
    applicationHostId
    applicationHostName
    tradingGroupId
    tradingGroupName
    firstName
    lastName
    email
    status
    roles
    acceptedTermsAndConditions
    isProsumer @client
  }
}
    `;

/**
 * __useUserProfileQuery__
 *
 * To run a query within a React component, call `useUserProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserProfileQuery({
 *   variables: {
 *      identityProviderRef: // value for 'identityProviderRef'
 *   },
 * });
 */
export function useUserProfileQuery(baseOptions: Apollo.QueryHookOptions<UserProfileQuery, UserProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserProfileQuery, UserProfileQueryVariables>(UserProfileDocument, options);
      }
export function useUserProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserProfileQuery, UserProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserProfileQuery, UserProfileQueryVariables>(UserProfileDocument, options);
        }
export type UserProfileQueryHookResult = ReturnType<typeof useUserProfileQuery>;
export type UserProfileLazyQueryHookResult = ReturnType<typeof useUserProfileLazyQuery>;
export type UserProfileQueryResult = Apollo.QueryResult<UserProfileQuery, UserProfileQueryVariables>;
export const UserProfileRoleDocument = gql`
    query UserProfileRole($identityProviderRef: String!) {
  userProfile(identityProviderRef: $identityProviderRef) {
    id
    roles
  }
}
    `;

/**
 * __useUserProfileRoleQuery__
 *
 * To run a query within a React component, call `useUserProfileRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserProfileRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserProfileRoleQuery({
 *   variables: {
 *      identityProviderRef: // value for 'identityProviderRef'
 *   },
 * });
 */
export function useUserProfileRoleQuery(baseOptions: Apollo.QueryHookOptions<UserProfileRoleQuery, UserProfileRoleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserProfileRoleQuery, UserProfileRoleQueryVariables>(UserProfileRoleDocument, options);
      }
export function useUserProfileRoleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserProfileRoleQuery, UserProfileRoleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserProfileRoleQuery, UserProfileRoleQueryVariables>(UserProfileRoleDocument, options);
        }
export type UserProfileRoleQueryHookResult = ReturnType<typeof useUserProfileRoleQuery>;
export type UserProfileRoleLazyQueryHookResult = ReturnType<typeof useUserProfileRoleLazyQuery>;
export type UserProfileRoleQueryResult = Apollo.QueryResult<UserProfileRoleQuery, UserProfileRoleQueryVariables>;
export const TradingGroupTradeSummaryDocument = gql`
    query TradingGroupTradeSummary($filter: TradingGroupTradeSummaryFilter!) {
  tradingGroupTradeSummary(filter: $filter) {
    settlementId
    startDateTime
    endDateTime
    p2pEnergyBought
    p2pBuyPrice
    p2pEnergySold
    p2pSellPrice
    gridEnergyBought
    gridBuyPrice
    gridEnergySold
    gridSellPrice
    selfSufficiencyPercentage
    selfConsumptionPercentage
    totalSavings
    averageSavings
    numRetailConsumers
    numRetailProsumers
    numRetailBessProsumers
    numCorporateIndustryConsumers
    numCorporateIndustryProsumers
    numCorporateIndustryBessProsumers
    numCommunityBatteries
    topBuyers {
      description
      amount
    }
    topSellers {
      description
      amount
    }
  }
}
    `;

/**
 * __useTradingGroupTradeSummaryQuery__
 *
 * To run a query within a React component, call `useTradingGroupTradeSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useTradingGroupTradeSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTradingGroupTradeSummaryQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useTradingGroupTradeSummaryQuery(baseOptions: Apollo.QueryHookOptions<TradingGroupTradeSummaryQuery, TradingGroupTradeSummaryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TradingGroupTradeSummaryQuery, TradingGroupTradeSummaryQueryVariables>(TradingGroupTradeSummaryDocument, options);
      }
export function useTradingGroupTradeSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TradingGroupTradeSummaryQuery, TradingGroupTradeSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TradingGroupTradeSummaryQuery, TradingGroupTradeSummaryQueryVariables>(TradingGroupTradeSummaryDocument, options);
        }
export type TradingGroupTradeSummaryQueryHookResult = ReturnType<typeof useTradingGroupTradeSummaryQuery>;
export type TradingGroupTradeSummaryLazyQueryHookResult = ReturnType<typeof useTradingGroupTradeSummaryLazyQuery>;
export type TradingGroupTradeSummaryQueryResult = Apollo.QueryResult<TradingGroupTradeSummaryQuery, TradingGroupTradeSummaryQueryVariables>;
export const ParticipantTradeSummaryDocument = gql`
    query ParticipantTradeSummary($settlementId: UUID) {
  participantTradeSummary(settlementId: $settlementId) {
    settlementId
    startDateTime
    endDateTime
    p2pEnergyBought
    p2pBuyPrice
    p2pEnergySold
    p2pSellPrice
    gridEnergyBought
    gridBuyPrice
    gridEnergySold
    gridSellPrice
    selfSufficiencyPercentage
    selfConsumptionPercentage
    totalSavings
    averageSavings
    numRetailConsumers
    numRetailProsumers
    numRetailBessProsumers
    numCorporateIndustryConsumers
    numCorporateIndustryProsumers
    numCorporateIndustryBessProsumers
    numCommunityBatteries
    topBuyers {
      description
      amount
    }
    topSellers {
      description
      amount
    }
  }
}
    `;

/**
 * __useParticipantTradeSummaryQuery__
 *
 * To run a query within a React component, call `useParticipantTradeSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useParticipantTradeSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useParticipantTradeSummaryQuery({
 *   variables: {
 *      settlementId: // value for 'settlementId'
 *   },
 * });
 */
export function useParticipantTradeSummaryQuery(baseOptions?: Apollo.QueryHookOptions<ParticipantTradeSummaryQuery, ParticipantTradeSummaryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ParticipantTradeSummaryQuery, ParticipantTradeSummaryQueryVariables>(ParticipantTradeSummaryDocument, options);
      }
export function useParticipantTradeSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ParticipantTradeSummaryQuery, ParticipantTradeSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ParticipantTradeSummaryQuery, ParticipantTradeSummaryQueryVariables>(ParticipantTradeSummaryDocument, options);
        }
export type ParticipantTradeSummaryQueryHookResult = ReturnType<typeof useParticipantTradeSummaryQuery>;
export type ParticipantTradeSummaryLazyQueryHookResult = ReturnType<typeof useParticipantTradeSummaryLazyQuery>;
export type ParticipantTradeSummaryQueryResult = Apollo.QueryResult<ParticipantTradeSummaryQuery, ParticipantTradeSummaryQueryVariables>;
export const TradingGroupTradeComparisonDocument = gql`
    query TradingGroupTradeComparison($filter: TradingGroupTradeSummaryFilter!) {
  tradingGroupTradeComparison(filter: $filter) {
    consumerTotalAmountBAU
    consumerTotalAmount
    consumerTotalSavings
    consumerAverageSavings
    prosumerTotalAmountBAU
    prosumerTotalAmount
    prosumerTotalSavings
    prosumerAverageSavings
    bessProsumerTotalAmountBAU
    bessProsumerTotalAmount
    bessProsumerTotalSavings
    bessProsumerAverageSavings
    totalGridImportBAU
    totalGridImport
    totalGridExportBAU
    totalGridExport
    tradesByPeriod {
      settlementPeriod
      startDateTime
      endDateTime
      consumerTotalAmountBAU
      consumerTotalAmount
      consumerTotalSavings
      consumerAverageSavings
      prosumerTotalAmountBAU
      prosumerTotalAmount
      prosumerTotalSavings
      prosumerAverageSavings
      bessProsumerTotalAmountBAU
      bessProsumerTotalAmount
      bessProsumerTotalSavings
      bessProsumerAverageSavings
      gridImportBAU
      gridImport
      gridExportBAU
      gridExport
    }
  }
}
    `;

/**
 * __useTradingGroupTradeComparisonQuery__
 *
 * To run a query within a React component, call `useTradingGroupTradeComparisonQuery` and pass it any options that fit your needs.
 * When your component renders, `useTradingGroupTradeComparisonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTradingGroupTradeComparisonQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useTradingGroupTradeComparisonQuery(baseOptions: Apollo.QueryHookOptions<TradingGroupTradeComparisonQuery, TradingGroupTradeComparisonQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TradingGroupTradeComparisonQuery, TradingGroupTradeComparisonQueryVariables>(TradingGroupTradeComparisonDocument, options);
      }
export function useTradingGroupTradeComparisonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TradingGroupTradeComparisonQuery, TradingGroupTradeComparisonQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TradingGroupTradeComparisonQuery, TradingGroupTradeComparisonQueryVariables>(TradingGroupTradeComparisonDocument, options);
        }
export type TradingGroupTradeComparisonQueryHookResult = ReturnType<typeof useTradingGroupTradeComparisonQuery>;
export type TradingGroupTradeComparisonLazyQueryHookResult = ReturnType<typeof useTradingGroupTradeComparisonLazyQuery>;
export type TradingGroupTradeComparisonQueryResult = Apollo.QueryResult<TradingGroupTradeComparisonQuery, TradingGroupTradeComparisonQueryVariables>;
export const UserTariffsDocument = gql`
    query UserTariffs($where: UserTariffFilter) {
  userTariffs(where: $where) {
    id
    tariffs {
      dayFrom
    }
  }
}
    `;

/**
 * __useUserTariffsQuery__
 *
 * To run a query within a React component, call `useUserTariffsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserTariffsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserTariffsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUserTariffsQuery(baseOptions?: Apollo.QueryHookOptions<UserTariffsQuery, UserTariffsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserTariffsQuery, UserTariffsQueryVariables>(UserTariffsDocument, options);
      }
export function useUserTariffsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserTariffsQuery, UserTariffsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserTariffsQuery, UserTariffsQueryVariables>(UserTariffsDocument, options);
        }
export type UserTariffsQueryHookResult = ReturnType<typeof useUserTariffsQuery>;
export type UserTariffsLazyQueryHookResult = ReturnType<typeof useUserTariffsLazyQuery>;
export type UserTariffsQueryResult = Apollo.QueryResult<UserTariffsQuery, UserTariffsQueryVariables>;
export const GetUserMeterDocument = gql`
    query GetUserMeter($userId: UUID) {
  getUserMeter(userId: $userId) {
    meterId
    assetType
    meterExternalRef
  }
}
    `;

/**
 * __useGetUserMeterQuery__
 *
 * To run a query within a React component, call `useGetUserMeterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserMeterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserMeterQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserMeterQuery(baseOptions?: Apollo.QueryHookOptions<GetUserMeterQuery, GetUserMeterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserMeterQuery, GetUserMeterQueryVariables>(GetUserMeterDocument, options);
      }
export function useGetUserMeterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserMeterQuery, GetUserMeterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserMeterQuery, GetUserMeterQueryVariables>(GetUserMeterDocument, options);
        }
export type GetUserMeterQueryHookResult = ReturnType<typeof useGetUserMeterQuery>;
export type GetUserMeterLazyQueryHookResult = ReturnType<typeof useGetUserMeterLazyQuery>;
export type GetUserMeterQueryResult = Apollo.QueryResult<GetUserMeterQuery, GetUserMeterQueryVariables>;
export const UsersDocument = gql`
    query Users($where: UserFilter, $first: Int, $after: String, $last: Int, $before: String) {
  users(where: $where, first: $first, after: $after, last: $last, before: $before) {
    edges {
      cursor
      node {
        id
        username
        email
        title
        firstName
        lastName
        tenantName
        tradingGroupName
        roles
        status
      }
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
    totalCount
  }
}
    `;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      where: // value for 'where'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useUsersQuery(baseOptions?: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
      }
export function useUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = Apollo.QueryResult<UsersQuery, UsersQueryVariables>;
export const MeterProfilesDocument = gql`
    query MeterProfiles($meterProfilesFilter: MeterProfilesFilter!) {
  meterProfiles(meterProfilesFilter: $meterProfilesFilter) {
    id
    tenantId
    applicationHostId
    tradingGroupId
    profiles {
      id
      name
      profileClass
      type
      day
      seasonStart
      seasonEnd
      month
      interval
      energyData
    }
  }
}
    `;

/**
 * __useMeterProfilesQuery__
 *
 * To run a query within a React component, call `useMeterProfilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeterProfilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeterProfilesQuery({
 *   variables: {
 *      meterProfilesFilter: // value for 'meterProfilesFilter'
 *   },
 * });
 */
export function useMeterProfilesQuery(baseOptions: Apollo.QueryHookOptions<MeterProfilesQuery, MeterProfilesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeterProfilesQuery, MeterProfilesQueryVariables>(MeterProfilesDocument, options);
      }
export function useMeterProfilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeterProfilesQuery, MeterProfilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeterProfilesQuery, MeterProfilesQueryVariables>(MeterProfilesDocument, options);
        }
export type MeterProfilesQueryHookResult = ReturnType<typeof useMeterProfilesQuery>;
export type MeterProfilesLazyQueryHookResult = ReturnType<typeof useMeterProfilesLazyQuery>;
export type MeterProfilesQueryResult = Apollo.QueryResult<MeterProfilesQuery, MeterProfilesQueryVariables>;
export const ProfileTemplatesDocument = gql`
    query ProfileTemplates($where: ProfileFilter!) {
  profileTemplates(where: $where) {
    id
    name
    profileClass
    type
    day
    seasonStart
    seasonEnd
    month
    interval
    isDefault
    numberOfMeters
    tenantId
    tradingGroupId
    applicationHostId
    energyData
  }
}
    `;

/**
 * __useProfileTemplatesQuery__
 *
 * To run a query within a React component, call `useProfileTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfileTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfileTemplatesQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useProfileTemplatesQuery(baseOptions: Apollo.QueryHookOptions<ProfileTemplatesQuery, ProfileTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProfileTemplatesQuery, ProfileTemplatesQueryVariables>(ProfileTemplatesDocument, options);
      }
export function useProfileTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProfileTemplatesQuery, ProfileTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProfileTemplatesQuery, ProfileTemplatesQueryVariables>(ProfileTemplatesDocument, options);
        }
export type ProfileTemplatesQueryHookResult = ReturnType<typeof useProfileTemplatesQuery>;
export type ProfileTemplatesLazyQueryHookResult = ReturnType<typeof useProfileTemplatesLazyQuery>;
export type ProfileTemplatesQueryResult = Apollo.QueryResult<ProfileTemplatesQuery, ProfileTemplatesQueryVariables>;
export const ProfileTemplateEnergyDataDocument = gql`
    query ProfileTemplateEnergyData($where: ProfileFilter!) {
  profileTemplates(where: $where) {
    id
    energyData
  }
}
    `;

/**
 * __useProfileTemplateEnergyDataQuery__
 *
 * To run a query within a React component, call `useProfileTemplateEnergyDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfileTemplateEnergyDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfileTemplateEnergyDataQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useProfileTemplateEnergyDataQuery(baseOptions: Apollo.QueryHookOptions<ProfileTemplateEnergyDataQuery, ProfileTemplateEnergyDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProfileTemplateEnergyDataQuery, ProfileTemplateEnergyDataQueryVariables>(ProfileTemplateEnergyDataDocument, options);
      }
export function useProfileTemplateEnergyDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProfileTemplateEnergyDataQuery, ProfileTemplateEnergyDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProfileTemplateEnergyDataQuery, ProfileTemplateEnergyDataQueryVariables>(ProfileTemplateEnergyDataDocument, options);
        }
export type ProfileTemplateEnergyDataQueryHookResult = ReturnType<typeof useProfileTemplateEnergyDataQuery>;
export type ProfileTemplateEnergyDataLazyQueryHookResult = ReturnType<typeof useProfileTemplateEnergyDataLazyQuery>;
export type ProfileTemplateEnergyDataQueryResult = Apollo.QueryResult<ProfileTemplateEnergyDataQuery, ProfileTemplateEnergyDataQueryVariables>;
export const EnergyDataUploadUrlDocument = gql`
    query EnergyDataUploadURL($input: GetEnergyDataUploadURLInput!) {
  getEnergyDataUploadURL(input: $input) {
    url
  }
}
    `;

/**
 * __useEnergyDataUploadUrlQuery__
 *
 * To run a query within a React component, call `useEnergyDataUploadUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnergyDataUploadUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnergyDataUploadUrlQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEnergyDataUploadUrlQuery(baseOptions: Apollo.QueryHookOptions<EnergyDataUploadUrlQuery, EnergyDataUploadUrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EnergyDataUploadUrlQuery, EnergyDataUploadUrlQueryVariables>(EnergyDataUploadUrlDocument, options);
      }
export function useEnergyDataUploadUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EnergyDataUploadUrlQuery, EnergyDataUploadUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EnergyDataUploadUrlQuery, EnergyDataUploadUrlQueryVariables>(EnergyDataUploadUrlDocument, options);
        }
export type EnergyDataUploadUrlQueryHookResult = ReturnType<typeof useEnergyDataUploadUrlQuery>;
export type EnergyDataUploadUrlLazyQueryHookResult = ReturnType<typeof useEnergyDataUploadUrlLazyQuery>;
export type EnergyDataUploadUrlQueryResult = Apollo.QueryResult<EnergyDataUploadUrlQuery, EnergyDataUploadUrlQueryVariables>;
export const ListOfMetersDocument = gql`
    query ListOfMeters($tradingGroupName: String, $tenantName: String, $applicationHostName: String) {
  getMeters(
    tradingGroupName: $tradingGroupName
    tenantName: $tenantName
    applicationHostName: $applicationHostName
  ) {
    meterId
    meterExternalRef
    assetType
  }
}
    `;

/**
 * __useListOfMetersQuery__
 *
 * To run a query within a React component, call `useListOfMetersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListOfMetersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListOfMetersQuery({
 *   variables: {
 *      tradingGroupName: // value for 'tradingGroupName'
 *      tenantName: // value for 'tenantName'
 *      applicationHostName: // value for 'applicationHostName'
 *   },
 * });
 */
export function useListOfMetersQuery(baseOptions?: Apollo.QueryHookOptions<ListOfMetersQuery, ListOfMetersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListOfMetersQuery, ListOfMetersQueryVariables>(ListOfMetersDocument, options);
      }
export function useListOfMetersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListOfMetersQuery, ListOfMetersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListOfMetersQuery, ListOfMetersQueryVariables>(ListOfMetersDocument, options);
        }
export type ListOfMetersQueryHookResult = ReturnType<typeof useListOfMetersQuery>;
export type ListOfMetersLazyQueryHookResult = ReturnType<typeof useListOfMetersLazyQuery>;
export type ListOfMetersQueryResult = Apollo.QueryResult<ListOfMetersQuery, ListOfMetersQueryVariables>;
export const UnassignedProfilesDocument = gql`
    query UnassignedProfiles($meterProfilesFilter: MeterProfilesFilter!) {
  unassignedProfiles(meterProfilesFilter: $meterProfilesFilter) {
    id
    name
  }
}
    `;

/**
 * __useUnassignedProfilesQuery__
 *
 * To run a query within a React component, call `useUnassignedProfilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnassignedProfilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnassignedProfilesQuery({
 *   variables: {
 *      meterProfilesFilter: // value for 'meterProfilesFilter'
 *   },
 * });
 */
export function useUnassignedProfilesQuery(baseOptions: Apollo.QueryHookOptions<UnassignedProfilesQuery, UnassignedProfilesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UnassignedProfilesQuery, UnassignedProfilesQueryVariables>(UnassignedProfilesDocument, options);
      }
export function useUnassignedProfilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UnassignedProfilesQuery, UnassignedProfilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UnassignedProfilesQuery, UnassignedProfilesQueryVariables>(UnassignedProfilesDocument, options);
        }
export type UnassignedProfilesQueryHookResult = ReturnType<typeof useUnassignedProfilesQuery>;
export type UnassignedProfilesLazyQueryHookResult = ReturnType<typeof useUnassignedProfilesLazyQuery>;
export type UnassignedProfilesQueryResult = Apollo.QueryResult<UnassignedProfilesQuery, UnassignedProfilesQueryVariables>;
export const MeterForecastForNextDayDocument = gql`
    query MeterForecastForNextDay($meterProfilesFilter: MeterProfilesFilter) {
  meterForecastForNextDay(meterProfilesFilter: $meterProfilesFilter) {
    id
    load
    generation
    bess
  }
}
    `;

/**
 * __useMeterForecastForNextDayQuery__
 *
 * To run a query within a React component, call `useMeterForecastForNextDayQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeterForecastForNextDayQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeterForecastForNextDayQuery({
 *   variables: {
 *      meterProfilesFilter: // value for 'meterProfilesFilter'
 *   },
 * });
 */
export function useMeterForecastForNextDayQuery(baseOptions?: Apollo.QueryHookOptions<MeterForecastForNextDayQuery, MeterForecastForNextDayQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeterForecastForNextDayQuery, MeterForecastForNextDayQueryVariables>(MeterForecastForNextDayDocument, options);
      }
export function useMeterForecastForNextDayLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeterForecastForNextDayQuery, MeterForecastForNextDayQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeterForecastForNextDayQuery, MeterForecastForNextDayQueryVariables>(MeterForecastForNextDayDocument, options);
        }
export type MeterForecastForNextDayQueryHookResult = ReturnType<typeof useMeterForecastForNextDayQuery>;
export type MeterForecastForNextDayLazyQueryHookResult = ReturnType<typeof useMeterForecastForNextDayLazyQuery>;
export type MeterForecastForNextDayQueryResult = Apollo.QueryResult<MeterForecastForNextDayQuery, MeterForecastForNextDayQueryVariables>;
export const RetailersDocument = gql`
    query Retailers($tenancyInfo: TenancyInfo) {
  retailers(tenancyInfo: $tenancyInfo) {
    id
    firstName
    lastName
    userTariff {
      displayName
    }
  }
}
    `;

/**
 * __useRetailersQuery__
 *
 * To run a query within a React component, call `useRetailersQuery` and pass it any options that fit your needs.
 * When your component renders, `useRetailersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRetailersQuery({
 *   variables: {
 *      tenancyInfo: // value for 'tenancyInfo'
 *   },
 * });
 */
export function useRetailersQuery(baseOptions?: Apollo.QueryHookOptions<RetailersQuery, RetailersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RetailersQuery, RetailersQueryVariables>(RetailersDocument, options);
      }
export function useRetailersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RetailersQuery, RetailersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RetailersQuery, RetailersQueryVariables>(RetailersDocument, options);
        }
export type RetailersQueryHookResult = ReturnType<typeof useRetailersQuery>;
export type RetailersLazyQueryHookResult = ReturnType<typeof useRetailersLazyQuery>;
export type RetailersQueryResult = Apollo.QueryResult<RetailersQuery, RetailersQueryVariables>;