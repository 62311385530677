interface RuntimeConfigType {
	ENV?: string;

	/**
	 * Sentry
	 */
	SENTRY_DSN?: string;

	/**
	 * Host File Config
	 */
	HOSTNAME_OVERRIDE?: string;
	HOST_CONFIG_ENDPOINT?: string;

	/**
	 * Locize Config
	 */
	LOCIZE_APIKEY?: string;
	LOCIZE_PROJECTID?: string;
	LOCIZE_REFLNG?: string;
	LOCIZE_VERSION?: string;

	/**
	 * Default Auth Service Config
	 */
	AUTH_ISSUER?: string;
	AUTH_CLIENT_ID?: string;
	AUTH_BASE_URL?: string;

	/**
	 * Apollo GraphQL Config
	 */
	APOLLO_GRAPH_VARIANT?: string;
	FEDERATED_GATEWAY_URL?: string;
	USE_GRAPHQL_DEMO_DATA?: boolean;

	/**
	 * Miscellaneous Config
	 */
	ENABLE_WDYR?: boolean;
	TERMS_OF_ACCESS_URL?: string;
	PRIVACY_POLICY_URL?: string;
	SENTRY_ENVIRONMENT?: string;
	SENTRY_RELEASE_ID?: string;
	AUTH_SERVER_URL?: string;
	USER_GUIDE_URL?: string;
	LEM_RULES_URL?: string;
}

declare global {
	interface Window {
		_RT_CONFIG_?: RuntimeConfigType;
	}
}

export type AppConfigType = {
	VERSION: string;
} & Partial<RuntimeConfigType>;

const RuntimeConfig = window._RT_CONFIG_ || {};

export const AppConfig: AppConfigType = {
	...RuntimeConfig,
	VERSION: import.meta.env.VITE_PLUGIN_SENTRY_CONFIG?.release,
};
