import { toast, ToastContent, ToastOptions } from "react-toastify";

import i18n from "../lib/i18n";

export type NotificationParams = {
	message?: string;
	duration?: number;
	description?: string;
};

export type ErrorNotificationParams = {
	code?: number;
	action?: string;
	component?: string;
	errorCode?: number | string;
	key?: number | string;
} & NotificationParams;

const createToastArgs = ({
	message,
	description,
	duration,
}: NotificationParams): { content: ToastContent; options: ToastOptions } => {
	const options: ToastOptions = {};
	if (duration) options.autoClose = duration * 1000;
	const content = `${message || i18n.t("Success!")}${description ? `\n${description}` : ""}`;
	return {
		content,
		options: { ...options },
	};
};

export type NotificationsType = {
	success: (params: NotificationParams) => any;
	info: (params: NotificationParams) => any;
	error: (params: ErrorNotificationParams) => any;
	destroy: () => any;
};

export const notifications = {
	success: (params: NotificationParams) => {
		const { content, options } = createToastArgs(params);
		toast.success(content, options);
	},
	info: (params: NotificationParams) => {
		const { content, options } = createToastArgs(params);
		toast.info(content, options);
	},
	error: (params: ErrorNotificationParams) => {
		const { content, options } = createToastArgs(params);
		toast.error(content, options);
	},
	destroy: () => {
		toast.dismiss();
	},
};

export default notifications;
