import { Flex, Text, ThemeUICSSProperties } from "@powerledger/ui-component-lib";
import { FC, useEffect, useState } from "react";

import { DataType, StylesObject } from "../nivo-graphs/nivo-graphs.types";

export type LegendType = {
	data: Array<DataType>;
	colors: StylesObject;
	sx?: ThemeUICSSProperties;
	allowLegendToggle?: boolean;
	defaultHiddenDataIds?: Array<DataType["id"]>;
	onChangeHiddenData?: (id: Array<DataType["id"]>) => void;
};

const STRIKE_THROUGH_LEGEND_COLOR = "#333333";
const LEGEND_LABEL_COLOR = "#9E99C8";

export const CustomLegend: FC<LegendType> = ({
	data,
	colors,
	sx,
	allowLegendToggle,
	defaultHiddenDataIds,
	onChangeHiddenData,
}) => {
	const [hiddenLegends, setHiddenLegends] = useState(defaultHiddenDataIds ?? []);

	useEffect(() => {
		onChangeHiddenData && onChangeHiddenData(hiddenLegends);
	}, [hiddenLegends, onChangeHiddenData]);

	const onToggleLegend = (id: DataType["id"]) => {
		const hiddenLegend = hiddenLegends?.find((hiddenLegendId) => hiddenLegendId === id);

		if (hiddenLegend) {
			setHiddenLegends(hiddenLegends.filter((hiddenLegendId) => hiddenLegendId !== id));
		} else {
			setHiddenLegends([...hiddenLegends, id]);
		}
	};

	const getColor = (id: string) => {
		return colors[id]?.stroke || colors?.default?.stroke;
	};
	return (
		<Flex sx={{ gap: 3, ...sx }}>
			{data.map((item, index) => {
				const hiddenLegend = hiddenLegends?.some((id) => id === item.id);
				return (
					item && (
						<Flex
							key={item.id || index}
							sx={{
								gap: 2,
								alignItems: "center",
								flexDirection: "row",
								cursor: allowLegendToggle ? "pointer" : "default",
							}}
							onClick={() => allowLegendToggle && onToggleLegend(item.id)}
						>
							<svg width="12px" viewBox="0 0 6 6" xmlns="http://www.w3.org/2000/svg">
								<circle
									r="3"
									cx="3"
									cy="3"
									height="6px"
									width="6px"
									fill={hiddenLegend ? STRIKE_THROUGH_LEGEND_COLOR : getColor(item.id)}
								/>
							</svg>
							<Text
								sx={{
									fontSize: "11px",
									color: LEGEND_LABEL_COLOR,
									...(hiddenLegend && {
										textDecoration: "line-through",
										opacity: 0.6,
									}),
								}}
							>
								{item.name || item.id || index}
							</Text>
						</Flex>
					)
				);
			})}
		</Flex>
	);
};
