import { Box, Flex, Grid, Text } from "@powerledger/ui-component-lib";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import { BarGraph, BarGraphDataType, KeyColorType } from "../../../../../components/comparison-bar-graph";
// JIRA-1160 -> To remove FE Demo Stubbed Data
import { isDemoView as checkDemo, stubbedOverviewSummaryParticipant } from "../../../demo-stubbed-data";

const averageBillSavingsKeys: KeyColorType[] = [
	{
		keyName: "BAU",
		keyColor: "accent",
	},
	{
		keyName: "LEM",
		keyColor: "yellowLight",
	},
];

export type AverageBillReductionType = {
	averageSavingsBAU?: number;
	averageSavingsLEM?: number;
	demoDataSelection?: number;
};

export const AverageBillReduction: FC<AverageBillReductionType> = ({
	averageSavingsBAU,
	averageSavingsLEM,
	demoDataSelection = 4,
}) => {
	const { t } = useTranslation();
	const isDemoView = checkDemo();

	const savingsAmount = isDemoView
		? stubbedOverviewSummaryParticipant[demoDataSelection]?.averageSavingsBAU -
		  stubbedOverviewSummaryParticipant[demoDataSelection]?.averageSavingsLEM
		: averageSavingsBAU && averageSavingsLEM && averageSavingsBAU - averageSavingsLEM;
	const savingsPercentage = isDemoView
		? savingsAmount && (savingsAmount / stubbedOverviewSummaryParticipant[demoDataSelection]?.averageSavingsBAU) * 100
		: averageSavingsBAU && savingsAmount && (savingsAmount / averageSavingsBAU) * 100;

	const averageBillSavingsData: BarGraphDataType[] = [
		{
			label: "BAU",
			entries: [
				{
					name: "BAU",
					value: isDemoView
						? stubbedOverviewSummaryParticipant[demoDataSelection]?.averageSavingsBAU
						: averageSavingsBAU,
				},
			],
		},
		{
			label: "LEM",
			entries: [
				{
					name: "LEM",
					value: isDemoView
						? stubbedOverviewSummaryParticipant[demoDataSelection]?.averageSavingsLEM
						: averageSavingsLEM,
				},
			],
		},
	];

	return (
		<Grid columns={[1, 2, "3.5fr 1.5fr"]} gap={2}>
			<Flex sx={{ flexDirection: "column" }}>
				<Text sx={{ fontSize: 3, mb: 2 }}>{t("Your Electricity Cost")}</Text>
				<Text color="green" mb={2}>
					{t(
						`You have saved a total of ${
							averageSavingsBAU && averageSavingsLEM ? savingsPercentage?.toFixed(2) + "%" : "-"
						} on your bill, which amounts to $${
							"$" + averageSavingsBAU && averageSavingsLEM ? savingsAmount?.toFixed(2) + "!" : "-"
						}`,
					)}
				</Text>
				<Box mb={2}>
					<BarGraph
						legend="Daily Electricity Cost ($)"
						keys={averageBillSavingsKeys}
						data={averageSavingsBAU && averageSavingsLEM ? averageBillSavingsData : []}
					/>
				</Box>
			</Flex>
		</Grid>
	);
};
