import FileSaver from "file-saver";
import { useCallback, useMemo, useRef, useState } from "react";

import { useOkta } from "@/app/hooks/use-okta";
import { useUserProfileQuery, useUsersQuery } from "@/app/types/generated/graphql";

import { PAGE_SIZE } from "./constants";
import { getUserDataRowFromUserQueryNode } from "./helper";
import { noData } from "./no-data";
import { FullyOnboardedUserStatuses, UserDataRow, UseUserTable } from "./user-table.types";

export const useUserTable: UseUserTable = () => {
	const previouslySelectedUsersRef = useRef<{ [key: string]: UserDataRow }>({});
	const [selectedUserIDs, setSelectedUserIDs] = useState<string[]>([]);
	const [showConfirmResendInvite, setShowConfirmResendInvite] = useState(false);

	const interactionDisabled = selectedUserIDs.length === 0;

	const showConfirmInvitationModal = useCallback(() => setShowConfirmResendInvite(true), []);
	const hideConfirmInvitationModal = useCallback(() => setShowConfirmResendInvite(false), []);

	const csvExport = () => {
		const csvContent =
			"Participant,Email,Status" +
			"\n" +
			selectedUsers
				.map((user) => {
					const { participant, email, status } = user;
					return [participant, email, status].join(",");
				})
				.join("\n");
		const blob = new Blob([csvContent], { type: "data:text/csv;charset=utf-8" });

		FileSaver.saveAs(blob, "participants_list.csv");
	};

	const { identityProviderRef } = useOkta();

	const {
		data: profileData,
		loading: loadingProfile,
		error: profileError,
	} = useUserProfileQuery({
		variables: {
			identityProviderRef,
		},
	});

	const {
		data: usersData,
		loading: loadingUsers,
		error: usersError,
		fetchMore: fetchMoreUsers,
	} = useUsersQuery({
		variables: {
			where: {
				tenantId: profileData?.userProfile?.tenantId,
			},
			first: PAGE_SIZE,
		},
		skip: !profileData?.userProfile?.tenantId,
		// notifyOnNetworkStatusChange is required to update our loading state
		// when fetching more data
		notifyOnNetworkStatusChange: true,
	});

	const participantsTable = useMemo(() => {
		return (
			usersData?.users?.edges
				?.filter((f) => f?.node?.username)
				.map<UserDataRow>((userNode, index) => {
					return getUserDataRowFromUserQueryNode(userNode?.node, index);
				}) || noData
		);
	}, [usersData?.users?.edges]);

	/**
	 * Need to rely on the app level state to store the selected user IDs and use it with useEffect
	 * as the table component doesn't update the instance of `getSelectedRowIDs` with the latest one passed
	 * and hence the old callback function is triggered, regardless of passing new instance of callback
	 */
	const getSelectedRowIDs = useCallback((userIDs: readonly string[]) => {
		setSelectedUserIDs([...userIDs]);
	}, []);

	const selectedUsers = selectedUserIDs.map((userID) => {
		/**
		 * If the selected user is already available in the `previouslySelectedUsersRef` find it and return it from there
		 * Otherwise it should be available in the current page data i.e `usersData`
		 *
		 * Why searching from `previouslySelectedUsersRef`?
		 * Because `usersData` gets new set of data on each page and hence user data from previous page is lost
		 */
		const previouslySelectedUser = previouslySelectedUsersRef.current[userID];
		if (previouslySelectedUser) {
			return previouslySelectedUser;
		}

		const user = usersData?.users?.edges?.find((user) => user?.node?.id === userID);
		const userDataRow = getUserDataRowFromUserQueryNode(user?.node, userID);
		previouslySelectedUsersRef.current[userID] = userDataRow;

		return userDataRow;
	});

	const disableResend =
		selectedUserIDs.length > 1 || // TODO: Currently limiting resending invite to single user as mutation query doesn't support an array of users, allow once available
		selectedUsers?.some(({ status }) => {
			return !status || FullyOnboardedUserStatuses.includes(status);
		});

	const paginationInfo = {
		hasNextPage: Boolean(usersData?.users?.pageInfo?.hasNextPage),
		hasPreviousPage: Boolean(usersData?.users?.pageInfo?.hasPreviousPage),
		startCursor: usersData?.users?.pageInfo?.startCursor ?? null,
		endCursor: usersData?.users?.pageInfo?.endCursor ?? null,
	};

	return {
		selectedUserIDs,
		participantsTable,
		csvExport,
		disableResend,
		interactionDisabled,
		loadingProfile,
		loadingUsers,
		showConfirmResendInvite,
		setShowConfirmResendInvite,
		profileError,
		usersError,
		fetchMoreUsers,
		getSelectedRowIDs,
		paginationInfo,
		showConfirmInvitationModal,
		hideConfirmInvitationModal,
	};
};
