import { ApolloQueryResult } from "@apollo/client";

import {
	Exact,
	Profile,
	ProfileClass,
	ProfileFilter,
	ProfileTemplatesQuery,
	ProfileType,
} from "@/app/types/generated/graphql";

export const ProfileClassLabel = {
	[ProfileClass.Load]: "Load",
	[ProfileClass.Generation]: "Generation",
	[ProfileClass.Bess]: "BESS",
};

export const ProfileTypeLabel = {
	[ProfileType.Day]: "Day",
	[ProfileType.Weekday]: "WD",
	[ProfileType.Weekend]: "WE",
	[ProfileType.Season]: "Season",
	[ProfileType.Month]: "Month",
};

export type ProfileOverviewTableProps = {
	onEditProfile: (profile: Profile) => void;
};

export type UseProfileOverviewType = () => {
	loading: boolean;
	profileTemplates: Profile[];
	refetchProfileTemplates: (
		variables?:
			| Partial<
					Exact<{
						where: ProfileFilter;
					}>
			  >
			| undefined,
	) => Promise<ApolloQueryResult<ProfileTemplatesQuery>>;
	onDeleteProfile: (id: string) => void;
	selectedBaselineProfileId?: string;
	deleteBaselineProfileModalVisible: boolean;
	hideDeleteBaselineProfileModal: () => void;
};

export type ProfileOverviewTableViewProps = ReturnType<UseProfileOverviewType> & ProfileOverviewTableProps;
