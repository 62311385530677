import { Box, Flex, Heading, LegacySelect } from "@powerledger/ui-component-lib";
import { useTranslation } from "react-i18next";

import { EnergyDataGraph } from "@/app/components/nivo-graphs";
import { ProfileClass } from "@/app/types/generated/graphql";
import { getDefaultSelectTranslation } from "@/app/util";

import { ProfileClassLabel } from "../profile-overview-table";
import { BaselineProfileVisualizationViewProps } from "./baseline-profile-visualization.types";

export const BaselineProfileVisualizationView: React.FC<BaselineProfileVisualizationViewProps> = ({
	baselineProfilesLoading,
	baselineProfileOptions,
	selectedProfile,
	energyDataGraphDataSet,
	selectedProfileEnergyDataLoading,
	setSelectedProfile,
}) => {
	const { t } = useTranslation();
	return (
		<Box>
			<Flex sx={{ gap: 4, alignItems: "center" }}>
				<Heading as={"h2"}>{t("Profile Visualization")}</Heading>
				<LegacySelect
					isLoading={baselineProfilesLoading}
					options={baselineProfileOptions}
					value={selectedProfile}
					hideSelectedOptions={false}
					onChange={setSelectedProfile}
					placeholder={t("Select a profile")}
					translation={getDefaultSelectTranslation()}
					getOptionLabel={(option) => t(option.label)}
					sx={{
						width: 250,
					}}
				/>
			</Flex>
			<Box pt={4}>
				<EnergyDataGraph
					loading={selectedProfileEnergyDataLoading}
					// When profile of one profile class is selected rest has empty data
					dataSet={Object.values(ProfileClass).map((profileClass) => {
						if (profileClass === selectedProfile?.value.profileClass) {
							return {
								id: ProfileClassLabel[profileClass],
								data: energyDataGraphDataSet,
							};
						} else {
							return {
								id: ProfileClassLabel[profileClass],
								data: [],
							};
						}
					})}
					minMaxYRange={{
						max: 1,
						min: -1,
					}}
					labels={{
						xAxis: t("Time (hours)"),
						yAxis: t("Energy ( p.u.)"),
					}}
				/>
			</Box>
		</Box>
	);
};
