import { FC } from "react";

import { ProfileOverviewTableProps } from "./profile-overview-table.types";
import { ProfileOverviewTableView } from "./profile-overview-table-view";
import { useProfileOverview } from "./use-profile-overview";

/**
 * React component that displays a table of profile overviews.
 *
 * @param onEditProfile - Function that is called when the edit profile button is clicked.
 * @returns A React component that displays a table of profile overviews.
 */
export const ProfileOverviewTable: FC<ProfileOverviewTableProps> = ({ onEditProfile }) => {
	const logic = useProfileOverview();
	return <ProfileOverviewTableView {...logic} onEditProfile={onEditProfile} />;
};
