import { ApolloError } from "@apollo/client";

import { useOkta } from "../../hooks";
import { UserRole, useUserProfileRoleQuery } from "../../types/generated/graphql";

type UseUserRole = {
	userRoles: UserRole[];
	loading: boolean;
	error?: ApolloError;
};

export const useUserRole = (): UseUserRole => {
	const { identityProviderRef } = useOkta();

	const { loading, error, data } = useUserProfileRoleQuery({
		variables: {
			identityProviderRef,
		},
		skip: !identityProviderRef,
	});

	return {
		userRoles: data?.userProfile.roles || [],
		loading,
		error,
	};
};
