import { Box, Flex, useTheme } from "@powerledger/ui-component-lib";
import { useMemo } from "react";

import { PageHeader } from "../page-header";
import { Sidebar } from "./components";
import { LayoutType } from "./layout.types";

export const Layout: LayoutType = ({ children, header, footer, sx, pageTitle }) => {
	const {
		theme: { layouts },
	}: { theme: any } = useTheme();

	const siderWidth = layouts.layoutSider.width;
	const headerHeight = layouts.layoutHeader.height;
	const layoutHeader = useMemo(() => header || <PageHeader pageTitle={pageTitle} />, [header, pageTitle]);

	return useMemo(
		() => (
			<Box sx={{ minHeight: "100vh", paddingLeft: siderWidth }}>
				<Flex
					sx={{
						flexDirection: "column",
						minHeight: "100vh",
					}}
				>
					<Box
						sx={{
							marginTop: headerHeight,
							minHeight: `calc(100vh - ${headerHeight}px)`,
							flex: 1,
							...sx,
						}}
					>
						{children}
					</Box>
					{footer}
				</Flex>
				<Box
					sx={{
						left: siderWidth,
						right: 0,
						top: 0,
						position: "fixed",
						zIndex: 1,
					}}
				>
					{layoutHeader}
				</Box>
				<Box
					sx={{
						width: siderWidth,
						left: 0,
						top: 0,
						right: "auto",
						height: "100vh",
						position: "fixed",
					}}
				>
					<Sidebar />
				</Box>
			</Box>
		),
		[children, footer, headerHeight, layoutHeader, siderWidth, sx],
	);
};
