import { Option } from "@powerledger/ui-component-lib";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { SingleValue } from "react-select";

import { notifications } from "@/app/container/notifications";
import { useUserProfile } from "@/app/hooks/use-user-profile";
import {
	MeterForecastForNextDayDocument,
	MeterProfilesDocument,
	UnassignedProfilesDocument,
	useUnassignedProfilesQuery,
	useUpdateMeterProfilesMutation,
} from "@/app/types/generated/graphql";

import { UseAssignMeterToProfileFn } from "./assign-meter-to-profiles.types";
import { getAssignMeterProfileClassOptions } from "./helper";

/**
 * Returns an object containing all necessary properties and functions to assign a given meter to a profile.
 *
 * @param meterData - An object containing all necessary information about the meter
 * @return An object containing properties and functions related to the assign meter to profile functionality
 */
export const useAssignMeterToProfile: UseAssignMeterToProfileFn = ({ meterData }) => {
	const { t } = useTranslation();
	const profileTypeOptions = getAssignMeterProfileClassOptions(meterData.participantType);
	const [selectedProfileClass, setSelectedProfileClass] = useState<SingleValue<(typeof profileTypeOptions)[0]>>(
		profileTypeOptions.find((profileType) => profileType.value === meterData.profileClass) || profileTypeOptions[0],
	);
	const [selectedBaselineProfile, setSelectedBaselineProfile] = useState<SingleValue<Option>>(null);
	const { userProfile, loading: userProfileLoading } = useUserProfile();
	const { data, loading: baselineProfilesLoading } = useUnassignedProfilesQuery({
		fetchPolicy: "cache-and-network",
		variables: {
			meterProfilesFilter: {
				applicationHostId: userProfile?.applicationHostId ?? "",
				tenantId: userProfile?.tenantId ?? "",
				tradingGroupId: userProfile?.tradingGroupId ?? "",
				id: meterData.meterId,
				profileClass: selectedProfileClass?.value,
			},
		},
		skip: userProfileLoading || !selectedProfileClass?.value || !meterData.meterId,
		onError: () => {
			notifications.error({
				message: t("Failed to fetch the list of unassigned baseline profiles"),
			});
		},
	});

	const [assignBaselineProfileToMeter, { loading: assignBaselineProfileToMeterLoading }] =
		useUpdateMeterProfilesMutation({
			onCompleted: () => {
				notifications.success({
					message: t("Successfully assigned the selected baseline profile to the meter"),
				});
				setSelectedBaselineProfile(null);
			},
			onError: () => {
				notifications.error({
					message: t("Failed to assign the selected baseline profile to the meter"),
				});
			},
			refetchQueries: [MeterProfilesDocument, UnassignedProfilesDocument, MeterForecastForNextDayDocument],
		});

	const baselineProfiles = useMemo(
		() =>
			data?.unassignedProfiles?.map((profile) => ({
				label: profile.name,
				value: profile.id,
			})) ?? [],
		[data?.unassignedProfiles],
	);

	const onAssignMeterToBaselineProfile = useCallback(async () => {
		await assignBaselineProfileToMeter({
			variables: {
				meterProfiles: {
					id: meterData.meterId,
					profileId: selectedBaselineProfile?.value,
					applicationHostId: userProfile?.applicationHostId ?? "",
					tenantId: userProfile?.tenantId ?? "",
					tradingGroupId: userProfile?.tradingGroupId ?? "",
				},
			},
		});
	}, [assignBaselineProfileToMeter, meterData.meterId, selectedBaselineProfile, userProfile]);

	return {
		profileTypeOptions,
		selectedProfileClass,
		setSelectedProfileClass,
		baselineProfiles,
		baselineProfilesLoading,
		selectedBaselineProfile,
		setSelectedBaselineProfile,
		onAssignMeterToBaselineProfile,
		assignBaselineProfileToMeterLoading,
	};
};
