import { Document, StyleSheet, Text, View } from "@react-pdf/renderer";
import { format } from "date-fns";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import { formatFinalData } from "../../tables/common-functions";
import { formatFeesData } from "../../tables/fees-table/fees-table-functions";
import { PdfExportType } from "../pdf-export.types";
import { PdfAccountSummary } from "./account-summary";
import { FormattedPage } from "./formatted-page";
import { ItemsTable } from "./items-table";

const styles = StyleSheet.create({
	page: {
		fontSize: 11,
		flexDirection: "column",
		padding: "2%",
	},
	heading: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-end",
	},
	table: {
		width: "100%",
		marginVertical: "10px",
	},
	title: {
		fontWeight: "bold",
		fontSize: "24px",
		paddingTop: "8px",
		paddingBottom: "2px",
	},
	subTitle: {
		fontWeight: "bold",
		fontSize: "20px",
		marginBottom: "3px",
	},
	hr: {
		width: "100%",
		borderTop: "1px solid black",
	},
	list: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-around",
	},
	listEntry: {
		fontWeight: "bold",
		fontSize: "16px",
	},
});

export const TableDocument: FC<PdfExportType> = ({ selectedAccount, startDate }) => {
	const { t } = useTranslation();
	const { bills } = selectedAccount;
	const boughtData = formatFinalData(bills, "Purchased");
	const soldData = formatFinalData(bills, "Sold");
	const feesData = formatFeesData(bills);

	return (
		<Document>
			<FormattedPage>
				<View style={styles.heading}>
					<View style={{ width: "60%", marginLeft: "40%" }}>
						<Text style={{ textAlign: "right", ...styles.title }}>{t("Statement of Use")}</Text>
						<View style={styles.list}>
							<Text style={{ width: "40%", margin: "auto", textAlign: "left", ...styles.listEntry }}>
								{t("Bill Period")}
							</Text>
							<Text style={{ width: "60%", textAlign: "right", margin: "auto", ...styles.listEntry }}>
								{format(new Date(startDate), "dd/MM/yyyy")}
							</Text>
						</View>
						<View style={styles.list}>
							<Text style={{ width: "50%", margin: "auto", textAlign: "left", ...styles.listEntry }}>
								{t("Generated On")}
							</Text>
							<Text style={{ width: "50%", textAlign: "right", margin: "auto", ...styles.listEntry }}>
								{format(new Date(), "dd/MM/yyyy")}
							</Text>
						</View>
					</View>
				</View>
				<Text style={styles.title}>{t("Account Summary")}</Text>
				<View style={styles.hr} />
				<PdfAccountSummary bills={bills} />
				{boughtData.length < 25 && ( // at over 24 entries the table will overflow so must be on a new page to keep headings and styling
					<>
						<Text style={styles.title}>{t("Usage and Supply Charges")}</Text>
						<View style={styles.hr} />
						<View style={styles.table}>
							<Text style={styles.subTitle}>{t("Electricity Bought")}</Text>
							<ItemsTable data={boughtData} key="bought" />
						</View>
					</>
				)}
			</FormattedPage>
			{boughtData.length >= 25 && (
				<FormattedPage>
					<Text style={styles.title}>{t("Usage and Supply Charges")}</Text>
					<View style={styles.hr} />
					<View style={styles.table}>
						<Text style={styles.subTitle}>{t("Electricity Bought")}</Text>
						<ItemsTable data={boughtData} key="bought" />
					</View>
				</FormattedPage>
			)}
			<FormattedPage>
				<View style={styles.table}>
					<Text style={styles.subTitle}>{t("Electricity Sold")}</Text>
					<ItemsTable data={soldData} key="sold" />
				</View>

				{soldData.length + feesData.length < 33 && ( // at over 33 combined entries the tables overflow too much
					<View style={styles.table}>
						<Text style={styles.subTitle}>{t("Fees")}</Text>
						<ItemsTable data={feesData} key="fee" fees />
					</View>
				)}
			</FormattedPage>

			{soldData.length + feesData.length >= 33 && (
				<FormattedPage>
					<View style={styles.table}>
						<Text style={styles.subTitle}>{t("Fees")}</Text>
						<ItemsTable data={feesData} key="fee" fees />
					</View>
				</FormattedPage>
			)}
		</Document>
	);
};
