import {
	Box,
	Button,
	ControlledToggle,
	DatePickerCustomInputProps,
	Flex,
	Heading,
	IconCalendar,
	LegacySelect,
	Tabs,
	Text,
} from "@powerledger/ui-component-lib";
import { sub } from "date-fns";
import { FC, forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { Route, Switch } from "react-router";

import { LemDatePicker } from "@/app/components/lem-date-picker";
import { getDefaultSelectTranslation } from "@/app/util";

import { AccountSummary } from "./account-summary";
import { PdfExport } from "./pdf-export";
import { SettlementsViewPropType } from "./settlements.types";
import { BoughtTable, FeesTable, SoldTable } from "./tables";

const DateInput = forwardRef(function DatePickerValueRendered({ onClick, value: date }: DatePickerCustomInputProps, _) {
	return (
		<Button
			onClick={onClick}
			sx={{
				display: "flex",
				alignItems: "center",
				justifyContent: "space-around",
				backgroundColor: "input",
				borderRadius: 4,
				height: 7,
			}}
		>
			<Text color="text" mr={3}>
				{date}
			</Text>
			<IconCalendar width={6} height={6} color="text" />
		</Button>
	);
});

export const SettlementsView: FC<SettlementsViewPropType> = ({
	selectedAccount,
	startDate,
	setStartDate,
	accountOptions,
	displaySoldTable,
	setDisplaySoldTable,
	onAccountChange,
}) => {
	const { t } = useTranslation();

	const tabs = [
		{
			title: t("Statement of Use"),
			key: "/settlements",
			component: (
				<>
					<Flex sx={{ flex: 1, justifyContent: "space-between", alignItems: "flex-start", width: "100%", mb: 4 }}>
						<Flex
							sx={{
								flexDirection: "column",
								width: 300,
							}}
						>
							<Heading as="h2" mb={3}>
								{t("Participant Details")}
							</Heading>
							<LegacySelect
								data-testid="meterSelect"
								value={accountOptions.find((option) => option.value === selectedAccount)}
								options={accountOptions}
								translation={getDefaultSelectTranslation()}
								onChange={onAccountChange}
								sx={{ maxWidth: 300, width: "100%" }}
								isClearable
								hideSelectedOptions={false}
							/>
							{selectedAccount?.meterNumber && (
								<Text mt={3}>{`${t("Meter Number")} : ${selectedAccount?.meterNumber}`}</Text>
							)}
						</Flex>
						{selectedAccount && (
							<Flex
								sx={{
									justifyContent: [null, "flex-start", "flex-end"],
								}}
							>
								<PdfExport selectedAccount={selectedAccount} startDate={startDate} />
							</Flex>
						)}
					</Flex>

					<Flex sx={{ flexDirection: "column" }}>
						<Heading as="h2" mb={3}>
							{t("Summary")}
						</Heading>
						<AccountSummary startDate={startDate} bills={selectedAccount?.bills || []} />
					</Flex>

					<Box sx={{ mt: 4 }}>
						<Heading as="h2" mb={2}>
							{t("Usage and Supply Details")}
						</Heading>
						<Flex sx={{ gap: 4, flexDirection: "column" }}>
							<Box>
								<Flex
									sx={{
										fontSize: 16,
										mb: 2,
										flexDirection: "row",
										justifyContent: "space-between",
										alignItems: "center",
									}}
								>
									<Text>{displaySoldTable ? t("Electricity Sold") : t("Electricity Bought")}</Text>
									<ControlledToggle
										optionOne={t("Bought")}
										optionTwo={t("Sold")}
										onClick={() => setDisplaySoldTable(!displaySoldTable)}
										selected={displaySoldTable}
										style={{ minWidth: 145, height: 40 }}
									/>
								</Flex>

								{displaySoldTable ? (
									<SoldTable settlements={selectedAccount?.bills || []} />
								) : (
									<BoughtTable settlements={selectedAccount?.bills || []} />
								)}
							</Box>
							<Box>
								<Box sx={{ fontSize: 16, mb: 2 }}>
									<Text>{t("Additional Charges")}</Text>
								</Box>
								<FeesTable settlements={selectedAccount?.bills || []} />
							</Box>
						</Flex>
					</Box>
				</>
			),
		},
	];

	return (
		<Flex
			sx={{
				flexDirection: "column",
			}}
		>
			<Flex
				sx={{
					flexDirection: "row",
					width: "100%",
					borderBottom: "1px solid",
					borderBottomColor: "foregroundLight",
					px: 4,
					pt: 4,
					justifyContent: "space-between",
				}}
			>
				<Tabs
					activeKey="/settlements"
					activeStyles={{ backgroundColor: "primary" }}
					sx={{
						mr: 3,
						flexGrow: 1,
						maxHeight: "inherit",
						position: "relative",
					}}
				>
					{tabs.map(({ key, title }) => (
						<Tabs.TabPane
							key={key}
							tab={
								<Flex
									sx={{
										color: "background",
									}}
								>
									{title}
								</Flex>
							}
						/>
					))}
				</Tabs>

				<Box sx={{ width: "min-width" }}>
					<LemDatePicker
						id={"dashboard-date"}
						onChange={(date: Date) => setStartDate(new Date(date))}
						value={startDate?.toString()}
						customButtonComponent={<DateInput />}
						minDate={sub(new Date(), { years: 3 })}
						maxDate={new Date()}
					/>
				</Box>
			</Flex>
			<Box sx={{ p: 4 }}>
				<Switch>
					{tabs.map(({ key, component }) => (
						<Route exact key={key} path={key}>
							{component}
						</Route>
					))}
				</Switch>
			</Box>
		</Flex>
	);
};
