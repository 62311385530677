import { useOktaAuth } from "@okta/okta-react";
import { Flex } from "@powerledger/ui-component-lib";
import { useCallback, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";

import { LemLogo } from "../../../components";
import { changePassword } from "../../../services/OktaApi";
import { ForgotPasswordNewPasswordForm, ResetPasswordConfirmation } from ".";

export const ResetPassword = () => {
	const location = useLocation();
	const [submitted, setSubmitted] = useState(false);
	const [error, setError] = useState("");

	const { oktaAuth } = useOktaAuth();

	const urlParams = useMemo(() => new URLSearchParams(location.search), [location]);
	const recoveryToken = urlParams.get("token") || "";

	const submitNewPasswordCallback = useCallback(
		async (newPassword: string) => {
			try {
				await changePassword(oktaAuth, recoveryToken, newPassword).then(() => setSubmitted(true));
			} catch (error: any) {
				setError(error?.message ? error?.message : `Something went wrong, please try again later.`);
			}
		},
		[oktaAuth, recoveryToken],
	);

	const renderStep = useMemo(
		() =>
			submitted ? (
				<ResetPasswordConfirmation />
			) : (
				<ForgotPasswordNewPasswordForm onSuccess={submitNewPasswordCallback} error={error} />
			),
		[error, submitNewPasswordCallback, submitted],
	);

	return (
		<Flex
			sx={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "center",
				height: "100vh",
				backgroundImage: "url(/background-illustration.svg)",
				backgroundPositionY: "bottom",
				backgroundPositionX: "right",
				backgroundRepeat: "no-repeat",
				backgroundSize: ["90%", "63%", "53%", "37%"],
			}}
		>
			<Flex sx={{ width: "100%", px: 4, pt: 4, justifyContent: ["center", "flex-start"] }}>
				<LemLogo width={130} />
			</Flex>
			{renderStep}
		</Flex>
	);
};
