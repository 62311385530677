import { UserDataRow } from "./user-table.types";

export const noData: UserDataRow[] = [
	{
		id: "1",
		participant: "-",
		email: "-",
		status: undefined,
		role: "-",
	},
];
