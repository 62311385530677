import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { notifications } from "@/app/container/notifications";
import { useUserRoles } from "@/app/hooks";
import { useUserProfile } from "@/app/hooks/use-user-profile";
import {
	useOnboardUserMeterMutation,
	useRetailersQuery,
	useUpdateOnboardingStepMutation,
	useUpdateOwnAccountMutation,
} from "@/app/types/generated/graphql";

import { FormValues, UseAccountDetails } from "./account-details.types";

/**
 * A function that hooks into the account details of a user.
 *
 * @param options - An object containing the following properties:
 *   - goToNextStep: A function to navigate to the next step.
 * @return An object containing the following properties:
 *   - loading: A flag indicating if the user profile data is being loaded.
 *   - userProfile: The user profile data.
 *   - retailersLoading: A flag indicating if the retailers data is being loaded.
 *   - retailerOptions: An array of retailer options.
 *   - handleSetDetails: A function to handle setting the user details.
 *   - onboardUserMeterError: An error that occurred during onboarding the user meter.
 *   - updateOwnAccountError: An error that occurred during updating the user account.
 *   - isParticipant: A flag indicating if the user is a participant.
 */
export const useAccountDetails: UseAccountDetails = ({ goToNextStep }) => {
	const { t } = useTranslation();
	const { userProfile, loading: userLoading, identityProviderRef } = useUserProfile();

	const { data: retailersData, loading: retailersLoading } = useRetailersQuery({
		variables: {
			tenancyInfo: {
				applicationHostId: userProfile?.applicationHostId ?? "",
				tenantId: userProfile?.tenantId ?? "",
				tradingGroupId: userProfile?.tradingGroupId ?? "",
			},
		},
		skip: userLoading,
		onError: () => {
			notifications.error({
				message: t("Failed to fetch the list of retailers and their tariff"),
			});
		},
	});

	const retailerOptions = useMemo(
		() => [
			...(retailersData?.retailers?.map((retailer) => ({
				label: `${retailer?.firstName} ${retailer?.lastName}${
					retailer?.userTariff?.displayName ? ` - ${retailer?.userTariff?.displayName}` : ""
				}`,
				value: retailer?.id ?? "",
			})) ?? []),
			{ label: "None/Not Sure/Not in this list", value: "" },
		],
		[retailersData],
	);

	const [updateUser, { error: updateOwnAccountError }] = useUpdateOwnAccountMutation();

	const isParticipant = useUserRoles(identityProviderRef).isParticipant;
	const [updateOnboardingToStepTwo] = useUpdateOnboardingStepMutation({
		variables: {
			step: 2,
		},
	});

	const [onboardUserMeter, { error: onboardUserMeterError }] = useOnboardUserMeterMutation();
	const handleSetDetails = async (formValues: FormValues) => {
		try {
			await updateUser({
				variables: {
					userId: userProfile?.id || "",
					user: {
						firstName: formValues.firstName,
						lastName: formValues.lastName,
						...(formValues.retailerId.length > 0 && { retailerId: formValues.retailerId }),
					},
				},
			});

			if (formValues.nmi) {
				await onboardUserMeter({
					variables: {
						userId: userProfile?.id || "",
						meterExternalRef: formValues.nmi.toString(),
						...(formValues.meterAssetType ? { meterAssetType: formValues.meterAssetType } : {}),
					},
				});
			}
			await updateOnboardingToStepTwo();

			goToNextStep();
		} catch (e) {
			// TODO: Sentry implementation
			console.error(e);
		}
	};

	return {
		loading: userLoading,
		userProfile,
		retailersLoading,
		retailerOptions,
		handleSetDetails,
		onboardUserMeterError,
		updateOwnAccountError,
		isParticipant,
	};
};
