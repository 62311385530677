import { faker } from "@faker-js/faker";

type SettlementMeterInfo = {
	amount: number;
	description: string;
};

type OverviewSummaryAdminType = {
	p2pVolumePurchased: number;
	p2pPurchasePrice: number;
	p2pVolumeSold: number;
	p2pSalePrice: number;
	selfSufficiencyPercentage: number;
	selfConsumptionPercentage: number;
	numberOfRetailConsumers: number;
	numberOfRetailProsumers: number;
	numberOfRetailBessProsumers: number;
	numberOfCorporateIndustryConsumers: number;
	numberOfCorporateIndustryProsumers: number;
	numberOfCorporateIndustryBessProsumers: number;
	numberOfCommunityBatteries: number;
	topBuyers: SettlementMeterInfo[];
	topSellers: SettlementMeterInfo[];
};

export const stubbedOverviewSummaryAdmin: OverviewSummaryAdminType[] = [
	{
		p2pSalePrice: 0.138,
		p2pPurchasePrice: 0.24,
		p2pVolumeSold: 509.8,
		p2pVolumePurchased: 487.8,
		selfSufficiencyPercentage: 0.533,
		selfConsumptionPercentage: 0.927,
		numberOfRetailConsumers: 60,
		numberOfRetailProsumers: 20,
		numberOfRetailBessProsumers: 20,
		numberOfCorporateIndustryConsumers: 0,
		numberOfCorporateIndustryProsumers: 0,
		numberOfCorporateIndustryBessProsumers: 0,
		numberOfCommunityBatteries: 0,
		topBuyers: [
			{
				description: faker.name.fullName(),
				amount: 10.9,
			},
			{
				description: faker.name.fullName(),
				amount: 6.8,
			},
			{
				description: faker.name.fullName(),
				amount: 6.4,
			},
		],
		topSellers: [
			{
				description: faker.name.fullName(),
				amount: 2.7,
			},
			{
				description: faker.name.fullName(),
				amount: 2.3,
			},
			{
				description: faker.name.fullName(),
				amount: 2.2,
			},
		],
	},
	{
		p2pSalePrice: 0.136,
		p2pPurchasePrice: 0.235,
		p2pVolumeSold: 470.8,
		p2pVolumePurchased: 456.8,
		selfSufficiencyPercentage: 0.511,
		selfConsumptionPercentage: 0.907,
		numberOfRetailConsumers: 60,
		numberOfRetailProsumers: 20,
		numberOfRetailBessProsumers: 20,
		numberOfCorporateIndustryConsumers: 0,
		numberOfCorporateIndustryProsumers: 0,
		numberOfCorporateIndustryBessProsumers: 0,
		numberOfCommunityBatteries: 0,
		topBuyers: [
			{
				description: faker.name.fullName(),
				amount: 9.6,
			},
			{
				description: faker.name.fullName(),
				amount: 7.8,
			},
			{
				description: faker.name.fullName(),
				amount: 5.9,
			},
		],
		topSellers: [
			{
				description: faker.name.fullName(),
				amount: 4.5,
			},
			{
				description: faker.name.fullName(),
				amount: 2.6,
			},
			{
				description: faker.name.fullName(),
				amount: 2.3,
			},
		],
	},
	{
		p2pSalePrice: 0.141,
		p2pPurchasePrice: 0.243,
		p2pVolumeSold: 103.5,
		p2pVolumePurchased: 107.3,
		selfSufficiencyPercentage: 0.255,
		selfConsumptionPercentage: 1,
		numberOfRetailConsumers: 60,
		numberOfRetailProsumers: 20,
		numberOfRetailBessProsumers: 20,
		numberOfCorporateIndustryConsumers: 0,
		numberOfCorporateIndustryProsumers: 0,
		numberOfCorporateIndustryBessProsumers: 0,
		numberOfCommunityBatteries: 0,
		topBuyers: [
			{
				description: faker.name.fullName(),
				amount: 4.4,
			},
			{
				description: faker.name.fullName(),
				amount: 4.1,
			},
			{
				description: faker.name.fullName(),
				amount: 2.8,
			},
		],
		topSellers: [
			{
				description: faker.name.fullName(),
				amount: 2.3,
			},
			{
				description: faker.name.fullName(),
				amount: 1.4,
			},
			{
				description: faker.name.fullName(),
				amount: 1.1,
			},
		],
	},
	{
		p2pSalePrice: 0.127,
		p2pPurchasePrice: 0.232,
		p2pVolumeSold: 440.6,
		p2pVolumePurchased: 445.3,
		selfSufficiencyPercentage: 0.529,
		selfConsumptionPercentage: 0.939,
		numberOfRetailConsumers: 60,
		numberOfRetailProsumers: 20,
		numberOfRetailBessProsumers: 20,
		numberOfCorporateIndustryConsumers: 0,
		numberOfCorporateIndustryProsumers: 0,
		numberOfCorporateIndustryBessProsumers: 0,
		numberOfCommunityBatteries: 0,
		topBuyers: [
			{
				description: faker.name.fullName(),
				amount: 10.5,
			},
			{
				description: faker.name.fullName(),
				amount: 9.7,
			},
			{
				description: faker.name.fullName(),
				amount: 7.4,
			},
		],
		topSellers: [
			{
				description: faker.name.fullName(),
				amount: 2.8,
			},
			{
				description: faker.name.fullName(),
				amount: 2.7,
			},
			{
				description: faker.name.fullName(),
				amount: 2.1,
			},
		],
	},
	{
		p2pSalePrice: 0.133,
		p2pPurchasePrice: 0.237,
		p2pVolumeSold: 381.2,
		p2pVolumePurchased: 383.2,
		selfSufficiencyPercentage: 0.55,
		selfConsumptionPercentage: 0.672,
		numberOfRetailConsumers: 60,
		numberOfRetailProsumers: 20,
		numberOfRetailBessProsumers: 20,
		numberOfCorporateIndustryConsumers: 0,
		numberOfCorporateIndustryProsumers: 0,
		numberOfCorporateIndustryBessProsumers: 0,
		numberOfCommunityBatteries: 0,
		topBuyers: [
			{
				description: faker.name.fullName(),
				amount: 9.8,
			},
			{
				description: faker.name.fullName(),
				amount: 9.4,
			},
			{
				description: faker.name.fullName(),
				amount: 7.3,
			},
		],
		topSellers: [
			{
				description: faker.name.fullName(),
				amount: 3.6,
			},
			{
				description: faker.name.fullName(),
				amount: 3.1,
			},
			{
				description: faker.name.fullName(),
				amount: 1.9,
			},
		],
	},
];
