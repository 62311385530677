import {
	Box,
	Button,
	Flex,
	Heading,
	IconEye,
	IconLoading,
	IconNoEye,
	Paragraph,
	Text,
} from "@powerledger/ui-component-lib";
import { Formik } from "formik";
import { FC, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { object, ref, string } from "yup";

import { ErrorMessage, Footer, Form, InputWithAction } from "../../../components";
import { confirmPasswordValidationSchemaMessages, passwordValidationSchemaMessages } from "../onboarding/set-password";
import { ForgotPasswordType } from "./new-password-form.types";

export const ForgotPasswordNewPasswordForm: FC<ForgotPasswordType> = ({ onSuccess, error }) => {
	const { t } = useTranslation();
	const [showPassword, setShowPassword] = useState(false);
	const history = useHistory();

	const EyeComponent = useMemo(
		() => (
			<Flex onClick={() => setShowPassword(!showPassword)} sx={{ cursor: "pointer", alignItems: "center" }}>
				{showPassword ? <IconNoEye size={9} color="" /> : <IconEye size={9} color="" />}
			</Flex>
		),
		[showPassword],
	);

	return (
		<Flex
			sx={{
				flexDirection: "column",
				alignItems: "center",
				justifyContent: ["flex-start", "center"],
				pt: [4, 0],
				height: "100vh",
				mb: [4, 0],
				width: "100%",
				maxWidth: 580,
			}}
		>
			<Flex sx={{ width: "100%", justifyContent: "center", mb: 4 }}>
				<Heading as="h1" sx={{ fontWeight: "normal", fontSize: [5, 6] }}>
					{t("Reset your password")}
				</Heading>
			</Flex>
			<Formik
				initialValues={{ password: "", confirmPassword: "" }}
				validationSchema={object().shape({
					password: string()
						.required(t(passwordValidationSchemaMessages.required))
						.min(12, t(passwordValidationSchemaMessages.minLength))
						.matches(/^.*[A-Z]+.*$/, t(passwordValidationSchemaMessages.oneUpperCase))
						.matches(/^.*[a-z]+.*$/, t(passwordValidationSchemaMessages.oneLowerCase))
						.matches(/^.*[0-9]+.*$/, t(passwordValidationSchemaMessages.oneNumber))
						.matches(/^.*[?=.*?[\]#?!@$%^&*-]+.*$/, t(passwordValidationSchemaMessages.oneSpecialCharacter)),
					confirmPassword: string()
						.required(t(confirmPasswordValidationSchemaMessages.required))
						.oneOf([ref("password")], t(confirmPasswordValidationSchemaMessages.notMatch)),
				})}
				onSubmit={async ({ password }) => await onSuccess(password)}
				validateOnMount
			>
				{({ handleSubmit, isSubmitting, setFieldValue, errors, submitCount, values }) => (
					<Box
						sx={{
							width: "100%",
							height: "270px",
							px: [4, 5],
							mb: 6,
						}}
					>
						<Form onSubmit={handleSubmit} sx={{ width: "100%" }}>
							<Form.Item sx={{ width: "100%" }}>
								<InputWithAction
									noButton
									placeholder={t("New Password")}
									variant="forms.inputWithAction.LEM"
									type="password"
									disabled={isSubmitting}
									autoCapitalize="none"
									showStrength
									onInput={(e) => {
										setFieldValue("password", (e.target as HTMLInputElement).value);
									}}
								/>
							</Form.Item>
							<Form.Item>
								<Box>
									<InputWithAction
										noButton
										placeholder={t("Confirm Password")}
										variant="forms.inputWithAction.LEM"
										type={showPassword ? "text" : "password"}
										disabled={isSubmitting}
										suffix={EyeComponent}
										autoCapitalize="none"
										onInput={(e) => {
											setFieldValue("confirmPassword", (e.target as HTMLInputElement).value);
										}}
									/>
								</Box>
							</Form.Item>
							<Form.Item sx={{ width: "100%" }}>
								<Button disabled={isSubmitting} onClick={() => handleSubmit} variant="LEM.action">
									<span data-testid="resetPasswordButton">
										{isSubmitting ? <IconLoading size="small" /> : t("Reset")}
									</span>
								</Button>
							</Form.Item>
							<Form.Item sx={{ width: "100%" }}>
								<Button
									variant={"text"}
									onClick={() => history.push("/")}
									sx={{
										textDecoration: "underline",
										textDecorationColor: "primary",
										fontSize: 1,
										fontWeight: "medium",
										"&:hover": {
											textDecoration: "underline",
											textDecorationColor: "primary",
											color: "primary",
										},
									}}
								>
									{t("Already have an account? Log in here")}
								</Button>
							</Form.Item>
						</Form>
						{(errors.confirmPassword || errors.password || error) && submitCount > 0 && (
							<Box
								sx={{
									position: ["static", "static", "relative"],
									top: values.password ? "-340px" : "-300px",
									left: "480px",
									maxWidth: 350,
								}}
							>
								<Paragraph
									sx={{
										mt: 1,
										maxWidth: "320px",
										color: "warning",
									}}
								>
									{t("Please fix the following issues: ")}
								</Paragraph>
								<Box
									as="ul"
									sx={{
										marginTop: 1,
										marginBottom: 1,
										variant: "forms.errorMessage",
									}}
								>
									{errors.password && (
										<Box as="li" sx={{ mt: 2 }}>
											<ErrorMessage name="password" />
										</Box>
									)}
									{errors.confirmPassword && (
										<Box as="li" sx={{ mt: 2 }}>
											<ErrorMessage name="confirmPassword" />
										</Box>
									)}
									{error !== "" && (
										<Box as="li" sx={{ mt: 2 }}>
											<Text sx={{ color: "warning" }}>{t(error)}</Text>
										</Box>
									)}
								</Box>
							</Box>
						)}
					</Box>
				)}
			</Formik>
			<Footer />
		</Flex>
	);
};
