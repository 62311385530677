import { Box, Flex, Grid, Table, TableVariants, Text } from "@powerledger/ui-component-lib";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Column, UseSortByColumnOptions } from "react-table";

import { commaSeparateNumberToThousandPlaces } from "@/app/util";

import { SettlementMeterInfo } from "../../../../../types/generated/graphql";

type CompetitiveStatsType = {
	topBuyers: SettlementMeterInfo[];
	topSellers: SettlementMeterInfo[];
};

export const CompetitiveStats: FC<CompetitiveStatsType> = ({ topBuyers, topSellers }) => {
	const { t } = useTranslation();

	const tableStrings = {
		item: t("item"),
		items: t("items"),
		noDataText: t("No data"),
		pagination: {
			firstPage: t("first page"),
			previousPage: t("previous page"),
			nextPage: t("next page"),
			lastPage: t("last page"),
		},
	};

	const tableColumns: (Column<SettlementMeterInfo> & UseSortByColumnOptions<SettlementMeterInfo>)[] = [
		{
			Header: t("Participant") as string,
			accessor: "description",
			Cell: ({ value }: { value: string }) => {
				return (
					<Box sx={{ color: "white" }} data-testid={`competitive-stats--participant-${value}`}>
						{t(value || "-")}
					</Box>
				);
			},
			disableSortBy: true,
		},
		{
			Header: t("kWh") as string,
			accessor: "amount",
			Cell: ({ value }: { value: number }) => {
				return (
					<Box sx={{ color: "white" }} data-testid={`competitive-stats--kwh-${value}`}>
						{t(commaSeparateNumberToThousandPlaces(value) || "-")}
					</Box>
				);
			},
			disableSortBy: true,
		},
	];
	return (
		<Box mt={3}>
			<Grid columns={2} gap={3} mb={4}>
				<Flex sx={{ flexDirection: "column" }}>
					<Text sx={{ fontSize: 3, mb: 2 }}>{t("Top 3 Buyers")}</Text>
					<Table
						noHover
						translation={tableStrings}
						variant={TableVariants.Lem}
						columns={tableColumns}
						dataSource={topBuyers}
						pagination={false}
						testId="competitive-stats--top-3-buyers"
					/>
				</Flex>
				<Flex sx={{ flexDirection: "column" }}>
					<Text sx={{ fontSize: 3, mb: 2 }}>{t("Top 3 Sellers")}</Text>
					<Table
						noHover
						translation={tableStrings}
						variant={TableVariants.Lem}
						columns={tableColumns}
						dataSource={topSellers}
						pagination={false}
						testId="competitive-stats--top-3-sellers"
					/>
				</Flex>
			</Grid>
		</Box>
	);
};
