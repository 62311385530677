import { ApolloProvider } from "@apollo/client";
import { PLCLProvider } from "@powerledger/ui-component-lib";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import AppRouter from "./app-router";
import { ModalProvider } from "./components";
import { container } from "./container";

export const App = () => {
	return (
		<PLCLProvider themeOverwrites={container.lemTheme}>
			<ApolloProvider client={container.apolloClient}>
				<Router>
					<ModalProvider>
						<AppRouter />
					</ModalProvider>
				</Router>
				<ToastContainer />
			</ApolloProvider>
		</PLCLProvider>
	);
};

export default App;
