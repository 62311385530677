import { Modal } from "@powerledger/ui-component-lib";
import { FC, memo } from "react";

import { ResendInvitationModalProps } from "./resend-invitation-modal.types";
import { ResendInvitationView } from "./resend-invitation-view";
import { useResendInvitation } from "./use-resend-invitation";

/**
 * Renders a confirmation modal for resending invitation(s) to user(s).
 *
 * @param showModal - Boolean field to either hide or display the modal
 * @param onCancel - The callback to handle cancel action.
 * @param onConfirm - The callback to handle confirmation action.
 * @param userIds - The array of user IDs to resend invitation(s).
 *
 * @returns The React component that renders the modal.
 */
export const ResendInvitationModal: FC<ResendInvitationModalProps> = memo(function ResendInvitation({
	showModal,
	onCancel,
	onConfirm,
	userIds,
}) {
	const logic = useResendInvitation({ onConfirm, userIds });

	return (
		<Modal visible={showModal} hideCloseButton onCancel={onCancel}>
			<ResendInvitationView {...logic} onCancel={onCancel} />
		</Modal>
	);
});
