import "react-dates/initialize";

import {
	Button,
	Flex,
	Input,
	Label as FormLabel,
	LabelProps,
	LegacyCheckbox,
	LegacySelect,
	Text,
	ThemeUIStyleObject,
	Tooltip,
} from "@powerledger/ui-component-lib";
import { Formik } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { boolean, mixed, object, string } from "yup";

import { ErrorMessage, Form } from "@/app/components/form";
import { CustomDatePickerInput, LemDatePicker } from "@/app/components/lem-date-picker";
import { ConfirmationModal } from "@/app/components/modals";
import { profileClassOptions, profileTypeOptions } from "@/app/pages/forecast-profiles/constants";
import { ProfileClass, ProfileType } from "@/app/types/generated/graphql";
import { getDefaultSelectTranslation } from "@/app/util";

import { MONTHS_OPTIONS } from "../constants";
import { DATE_RANGE_SELECTOR_PROFILE_TYPES } from "../create-edit-meter-profile.types";
import { getTimeFrameLabelByProfileType } from "../helpers";
import { BaselineProfileCriteriaProps, SaveActions } from "./baseline-profile-criteria.types";

/**
 * Renders a form item component with custom styling for its children.
 *
 * @param children - The children to be displayed within the form item.
 * @return The rendered form item component.
 */
const FormItem: React.FC<{ sx?: ThemeUIStyleObject }> = ({ children, sx }) => (
	<Form.Item sx={{ display: "flex", flexDirection: "row", alignItems: "center", height: 70, ...sx }}>
		{children}
	</Form.Item>
);

/**
 * Renders a label component with the given children and props, with custom styling.
 *
 * @param children - the children of the label component.
 * @param props - the props passed to the label component.
 * @return FormLabel component with the given children and props.
 */
const Label: React.FC<LabelProps> = ({ children, ...props }) => (
	<FormLabel {...props} style={{ width: "45%", paddingBottom: 21, height: "100%", justifyContent: "center" }}>
		{children}
	</FormLabel>
);

/**
 * Renders a wrapper component that displays its children in a vertical flexbox.
 *
 * @param children - The children components to be rendered within the wrapper.
 * @return The rendered wrapper component.
 */
const FieldWrapper: React.FC<{ sx?: ThemeUIStyleObject }> = ({ children, sx }) => (
	<Flex sx={{ width: "100%", flexDirection: "column", height: "100%", alignItems: "flex-start", ...sx }}>
		{children}
	</Flex>
);

/**
 * Renders the BaselineProfileCriteria component.
 *
 * @param initialValues - the initial values for the formik form
 * @param editMode - whether the component is in edit mode
 * @param onSave - the function to call when the form is submitted
 * @param onClose - the function to call when the form is closed
 * @param onSkip - the function to call when the form is skipped
 * @return the rendered BaselineProfileCriteria component
 */
export const BaselineProfileCriteria: React.FC<BaselineProfileCriteriaProps> = ({
	initialValues = {
		id: null,
		name: "",
		profileClass: null,
		type: null,
		startDate: null,
		endDate: null,
		month: null,
		isDefault: false,
	},
	editMode,
	onSave,
	onClose,
	onSkip,
}) => {
	const { t } = useTranslation();
	const [saveAction, setSaveAction] = useState<SaveActions>();
	const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);

	return (
		<Flex
			sx={{
				flexDirection: "column",
				width: "100%",
			}}
		>
			<Text
				sx={{
					fontSize: "23px",
					fontWeight: 700,
				}}
			>
				{t("Step 1: Define Profile Criteria")}
			</Text>
			<Formik
				validateOnMount
				initialValues={initialValues}
				initialTouched={
					editMode
						? {
								name: true,
								profileClass: true,
								type: true,
								startDate: true,
								endDate: true,
								month: true,
						  }
						: {}
				}
				validationSchema={object().shape({
					name: string().required(t("Profile name is required")),
					profileClass: mixed<ProfileClass>().required(t("Profile type is required")),
					type: mixed<ProfileType>().required(t("Type of day is required")),
					// since the selected dates are provided by the date-picker the date cannot be invalid
					startDate: string().when("type", ([profileType], schema) => {
						if (DATE_RANGE_SELECTOR_PROFILE_TYPES.includes(profileType) || ProfileType.Day === profileType) {
							return schema.required(t("Start date is required"));
						}
						return schema.nullable();
					}),
					endDate: string().when("type", ([profileType], schema) => {
						if (DATE_RANGE_SELECTOR_PROFILE_TYPES.includes(profileType)) {
							return schema.required(t("End date is required"));
						}
						return schema.nullable();
					}),
					month: string()
						.nullable()
						.when("type", ([profileType], schema) => {
							if (profileType === ProfileType.Month) {
								return schema.required(t("Month is required"));
							}
							return schema.nullable();
						}),
					isDefault: boolean(),
				})}
				onSubmit={onSave}
			>
				{({ setFieldValue, submitForm, isSubmitting, isValid, values, setFieldTouched, getFieldProps, dirty }) => {
					const shouldSelectMonth = values.type === ProfileType.Month;
					const shouldSelectDateRange =
						!shouldSelectMonth && (values.type ? DATE_RANGE_SELECTOR_PROFILE_TYPES.includes(values.type) : false);

					return (
						<Form sx={{ display: "flex", rowGap: 2, pt: 3 }}>
							<FormItem>
								<Label htmlFor="name">{t("Profile Name:")}</Label>
								<FieldWrapper>
									<Input
										placeholder={t("Insert Profile Name")}
										sx={{ width: "100%" }}
										disabled={isSubmitting}
										id="name"
										{...getFieldProps("name")}
										onChange={(e) => setFieldValue("name", e.target.value)}
									/>
									<ErrorMessage name="name" sx={{ height: 21 }} />
								</FieldWrapper>
							</FormItem>

							<FormItem>
								<Label htmlFor="profileClass">{t("Profile Category:")}</Label>
								<FieldWrapper>
									<LegacySelect
										{...getFieldProps("profileClass")}
										placeholder={t("Select Profile Category")}
										inputId="profileClass"
										sx={{ width: "100%" }}
										disabled={isSubmitting}
										options={profileClassOptions}
										hideSelectedOptions={false}
										translation={getDefaultSelectTranslation()}
										value={profileClassOptions.find((option) => option.value === values.profileClass)}
										onChange={(option) => setFieldValue("profileClass", option?.value)}
										getOptionLabel={(option) => t(option.label)}
									/>
									<ErrorMessage name="profileClass" />
								</FieldWrapper>
							</FormItem>

							<FormItem>
								<Label htmlFor="type">{t("Profile Type:")}</Label>
								<FieldWrapper>
									<LegacySelect
										{...getFieldProps("type")}
										inputId="type"
										sx={{ width: "100%" }}
										options={profileTypeOptions}
										placeholder={t("Select Profile Type")}
										translation={getDefaultSelectTranslation()}
										disabled={isSubmitting}
										hideSelectedOptions={false}
										value={profileTypeOptions.find((option) => option.value === values.type)}
										onChange={(option) => {
											setFieldValue("type", option?.value);

											if (option?.value === ProfileType.Month) {
												setFieldTouched("startDate", false);
												setFieldTouched("endDate", false);
											}
										}}
										getOptionLabel={(option) => t(option.label)}
									/>
									<ErrorMessage name="type" />
								</FieldWrapper>
							</FormItem>

							<FormItem>
								<Label htmlFor="date-picker">{t(`${getTimeFrameLabelByProfileType(values.type)}:`)}</Label>
								<FieldWrapper>
									{shouldSelectMonth ? (
										<>
											<LegacySelect
												{...getFieldProps("month")}
												inputId="month"
												value={values.month ? MONTHS_OPTIONS.find((month) => month.value === values.month) : null}
												options={MONTHS_OPTIONS}
												hideSelectedOptions={false}
												getOptionLabel={(option) => t(option.label)}
												onChange={(month) => setFieldValue("month", month?.value)}
												translation={getDefaultSelectTranslation()}
												placeholder={t("Select a Month")}
												sx={{
													width: "100%",
												}}
											/>
											<ErrorMessage name="month" />
										</>
									) : (
										<>
											<LemDatePicker
												selectsRange={shouldSelectDateRange}
												{...(shouldSelectDateRange
													? { startDate: values.startDate, endDate: values.endDate }
													: { selected: values.startDate })}
												disabled={isSubmitting}
												placeholderText={shouldSelectDateRange ? t("Select Dates") : t("Select a Date")}
												onChange={async (dates) => {
													if (Array.isArray(dates)) {
														const [start, end] = dates;
														await setFieldValue("startDate", start);
														await setFieldValue("endDate", end);
													} else {
														await setFieldValue("startDate", dates);
														await setFieldValue("endDate", null);
													}
												}}
												onClickOutside={() => {
													setFieldTouched("startDate", true);
													setFieldTouched("endDate", true);
												}}
												dateFormat={"dd.MM"} // 01.05 - 31.05
												customInput={<CustomDatePickerInput showIcon position="right" />}
											/>
											<ErrorMessage name="startDate" />
											<ErrorMessage name="endDate" />
										</>
									)}
								</FieldWrapper>
							</FormItem>

							<FormItem
								sx={{
									height: 50,
									marginBottom: 0,
								}}
							>
								<Label htmlFor="isDefault">
									<Flex>
										{t("Set as Default:")}
										<Tooltip
											id="set-as-default"
											content={
												<Text>
													{t(
														"Baseline profiles set as default will be automatically assigned to meters during onboarding.",
													)}
												</Text>
											}
										/>
									</Flex>
								</Label>
								<FieldWrapper
									sx={{
										justifyContent: "center",
										paddingBottom: 21,
									}}
								>
									<LegacyCheckbox
										disabled={isSubmitting}
										checked={values.isDefault}
										sx={{
											"> div > div > svg": {
												color: "primary",
												fill: "primary",
											},
										}}
										onChange={() => {
											setShowConfirmationModal(true);
										}}
									/>
								</FieldWrapper>
							</FormItem>

							<Form.Item sx={{ justifySelf: "flex-end", mb: 0 }}>
								<Flex sx={{ flexDirection: "column", alignItems: "center", justifyContent: "center", rowGap: 3 }}>
									<Button
										type="submit"
										disabled={!isValid || isSubmitting}
										sx={{
											width: 145,
										}}
										onClick={async (event) => {
											event.preventDefault();
											if (dirty) {
												setSaveAction(SaveActions.SAVE_AND_PROCEED);
												submitForm();
											} else {
												onSkip();
											}
										}}
									>
										{saveAction === SaveActions.SAVE_AND_PROCEED && isSubmitting ? t("Saving Profile...") : t("Next")}
									</Button>

									<Button
										type="submit"
										disabled={!isValid || isSubmitting || !dirty}
										sx={{
											width: 145,
										}}
										onClick={async () => {
											setSaveAction(SaveActions.SAVE_AND_CLOSE);
											await submitForm();
											onClose();
										}}
									>
										{saveAction === SaveActions.SAVE_AND_CLOSE && isSubmitting
											? t("Saving Profile...")
											: t("Save & Close")}
									</Button>

									<Button
										type="button"
										disabled={isSubmitting}
										onClick={onClose}
										sx={{
											width: 145,
											color: "primary",
											border: "2px solid",
											borderColor: "primary",
											backgroundColor: "transparent",
											":disabled": {
												borderColor: "transparent",
											},
										}}
									>
										{t("Cancel")}
									</Button>
								</Flex>
							</Form.Item>

							<ConfirmationModal
								confirmationTitle={
									values.isDefault
										? "You are about to remove this profile from the list of default baseline profiles."
										: "You are about to set this baseline profile as a default baseline profile."
								}
								description={
									values.isDefault
										? "This profile will not be assigned by default to new meters in the trading group. Do you want to continue?"
										: "This will assign this profile to every new meter in the trading group. Do you want to continue?"
								}
								onConfirm={() => {
									setFieldValue("isDefault", !values.isDefault);
									setShowConfirmationModal(false);
								}}
								onCancel={() => {
									setShowConfirmationModal(false);
								}}
								showModal={showConfirmationModal}
								confirmationButtonText="Continue"
							/>
						</Form>
					);
				}}
			</Formik>
		</Flex>
	);
};
