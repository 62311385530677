import { Button, ButtonGroup, Flex, Heading, Text } from "@powerledger/ui-component-lib";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import { ConfirmationModalViewPropType } from "./confirmation-modal.types";

/**
 * Renders a confirmation modal for triggered action.
 *
 * @param confirmationTitle - The text to be displayed as the confirmation modal title.
 * @param description - The text to be displayed as the confirmation modal description.
 * @param disabled - Determines whether the buttons in the modal should be disabled.
 * @param errorMessage - The error message to be displayed in the modal.
 * @param confirmationButtonText - The confirmation text to be displayed in the modal.
 * @param onConfirm - The callback function to be called when the confirm button is clicked.
 * @param onCancel - The callback function to be called when the cancel button is clicked.
 * @returns The confirmation modal component.
 */
export const ConfirmationModalView: FC<ConfirmationModalViewPropType> = ({
	confirmationTitle,
	description,
	disabled,
	errorMessage,
	confirmationButtonText = "Confirm",
	onConfirm,
	onCancel,
}) => {
	const { t } = useTranslation();

	return (
		<Flex sx={{ flexDirection: "column", p: 3, height: description ? 180 : 150, justifyContent: "space-around" }}>
			<Flex sx={{ flexDirection: "column", gap: 1 }}>
				<Heading>{t(confirmationTitle)}</Heading>
				<Text sx={{ mt: 1 }}>{t(description || "")}</Text>
				<Text sx={{ color: "warning", height: 24 }}>{t(errorMessage || "")}</Text>
			</Flex>
			<ButtonGroup>
				<Button
					variant="secondary"
					disabled={disabled}
					onClick={onCancel}
					sx={{
						":disabled": {
							background: "transparent",
						},
					}}
				>
					{t("Cancel")}
				</Button>
				<Button disabled={disabled} onClick={onConfirm}>
					{t(confirmationButtonText)}
				</Button>
			</ButtonGroup>
		</Flex>
	);
};
