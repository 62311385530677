import { Button, ButtonGroup, Flex, Heading, Text } from "@powerledger/ui-component-lib";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import { DeleteBaselineProfileViewPropType } from "./delete-baseline-profile-modal.types";

export const DeleteBaselineProfileView: FC<DeleteBaselineProfileViewPropType> = ({
	errorMessage,
	loading,
	onDelete,
	onCancel,
}) => {
	const { t } = useTranslation();

	return (
		<Flex sx={{ flexDirection: "column", p: 3, height: 150, justifyContent: "space-between" }}>
			<Flex sx={{ flexDirection: "column", gap: 1 }}>
				<Heading>{t("You are about to delete a baseline profile")}</Heading>
				<Text sx={{ mt: 1 }}>{t("Do you want to continue? The selected profile will be permanently deleted.")}</Text>
				<Text sx={{ color: "warning", height: 1 }}>{t(errorMessage || "")}</Text>
			</Flex>
			<ButtonGroup>
				<Button
					variant="secondary"
					disabled={loading}
					onClick={onCancel}
					sx={{
						":disabled": {
							background: "transparent",
						},
					}}
				>
					{t("Cancel")}
				</Button>
				<Button disabled={loading || Boolean(errorMessage)} onClick={onDelete}>
					{loading ? t("Deleting baseline profile...") : t("Confirm")}
				</Button>
			</ButtonGroup>
		</Flex>
	);
};
