import "cross-fetch/polyfill";

import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { AccessToken } from "@okta/okta-auth-js";

import { AppConfig } from "@/app/services/config";

import { oktaAuthClient } from "../../services/okta";
import { apolloAppCacheTypePolicies } from "./type-policies";

/**
 * Export the cache to use within our tests
 * @link https://www.apollographql.com/docs/react/development-testing/testing/#testing-local-state
 */
export const cache = new InMemoryCache({
	typePolicies: AppConfig.USE_GRAPHQL_DEMO_DATA ? apolloAppCacheTypePolicies : {},
});

const tokenManager = oktaAuthClient.tokenManager;

async function getAccessTokenFromAuth(): Promise<AccessToken["accessToken"] | undefined> {
	const accessToken = (await tokenManager.get("accessToken")) as AccessToken;
	return accessToken?.accessToken;
}

const apolloClient = () => {
	const httpLink = createHttpLink({
		uri: AppConfig.FEDERATED_GATEWAY_URL,
		credentials: "include",
	});

	const authLink = setContext(async (_, { headers }) => {
		const accessToken = await getAccessTokenFromAuth();
		return {
			headers: {
				...headers,
				authorization: accessToken ? `Bearer ${accessToken}` : "",
			},
		};
	});

	return new ApolloClient({
		link: authLink.concat(httpLink),
		cache,
		connectToDevTools: typeof AppConfig.USE_GRAPHQL_DEMO_DATA !== "undefined",
		credentials: "include",
		defaultOptions: {
			query: {
				fetchPolicy: typeof AppConfig.USE_GRAPHQL_DEMO_DATA !== "undefined" ? "cache-only" : "cache-first",
			},
		},
	});
};

if (typeof AppConfig.USE_GRAPHQL_DEMO_DATA !== "undefined") {
	console.log("Using GraphQL Demo Data");
}

export default apolloClient;
