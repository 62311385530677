import { Dispatch, SetStateAction } from "react";

export type AssignedMeterProfilesProps = {
	selectedMeterId: string;
};

export type MeterProfileType = {
	id?: string;
	name?: string;
};

export enum AssignedMeterProfileConfirmationModal {
	DELETE_METER_PROFILE,
	RESET_METER_PROFILE,
}

export type UseAssignedMeterProfiles = (prop: AssignedMeterProfilesProps) => {
	assignedMeterProfiles?: MeterProfileType[];
	loading: boolean;

	selectedMeterProfileId: string | undefined;
	setSelectedMeterProfileId: Dispatch<SetStateAction<string | undefined>>;

	visibleModal?: AssignedMeterProfileConfirmationModal;
	showDeleteMeterProfileConfirmationModal: () => void;
	showResetMeterProfileConfirmationModal: () => void;
	hideConfirmationModal: () => void;

	confirmDeleteMeterProfile: () => Promise<void>;
	deleteMeterProfileLoading: boolean;

	confirmResetMeterProfile: () => Promise<void>;
	resetToBaselineLoading: boolean;
};

export type AssignedMeterProfilesViewProps = AssignedMeterProfilesProps & ReturnType<UseAssignedMeterProfiles>;
