import { Flex, Heading, Table } from "@powerledger/ui-component-lib";
import { FC, Fragment, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Column, Row } from "react-table";

import { getDefaultTableTranslation } from "@/app/util";

import { AssignMeterToProfilesModal } from "./assign-meter-to-profiles-modal";
import { EditMeterProfileRowItem } from "./edit-meter-profile-row-item";
import { MeterProfileType, MeterProfileViewProps, PARTICIPANT_TYPE_LABEL } from "./meter-profile.types";

/**
 * Renders the Meter table to which different baseline profiles can be assigned.
 *
 * @param loading - Indicates if the data is loading.
 * @param dataSource - The data source for the component with a list of meters.
 * @param selectedMeterData - The selected meter data that can get assigned to baseline profiles.
 * @param setSelectedMeterData - The function to set the selected meter data.
 * @returns - The rendered MeterProfileView component.
 */
export const MeterProfileView: FC<MeterProfileViewProps> = ({
	loading,
	dataSource,
	selectedMeterData,
	setSelectedMeterData,
}) => {
	const { t } = useTranslation();

	const tableColumns = useMemo<Column<MeterProfileType>[]>(
		() => [
			{
				Header: t("Meter Number"),
				disableSortBy: true,
				accessor: "meterNumber",
			},
			{
				Header: t("Type of participant"),
				disableSortBy: true,
				accessor: "participantType",
				Cell: ({ row }: { row: Row<MeterProfileType> }) => {
					return t(PARTICIPANT_TYPE_LABEL[row.original.participantType]);
				},
			},
			{
				id: "profile",
				Header: <Flex sx={{ justifyContent: "center", width: "100%" }}>{t("Profile")}</Flex>,
				Cell: ({ row }: { row: Row<MeterProfileType> }) => (
					<EditMeterProfileRowItem
						row={row}
						onEdit={(selectedProfileClass) => {
							setSelectedMeterData({
								meterId: row.original.id,
								profileClass: selectedProfileClass,
								participantType: row.original.participantType,
							});
						}}
					/>
				),
			},
		],
		[setSelectedMeterData, t],
	);

	return (
		<Fragment>
			<Heading as="h2" sx={{ mb: 3 }}>
				{t("Assign Profile to Meters ")}
			</Heading>
			<Table
				containerSx={{
					height: "100%",
					"> div > table": {
						overflow: "visible",
						width: "99%",
					},
				}}
				loading={loading}
				columns={tableColumns}
				dataSource={dataSource ?? []}
				//TODO: Pagination need to be added later
				pagination={false}
				translation={getDefaultTableTranslation()}
			/>
			<AssignMeterToProfilesModal
				visible={Boolean(selectedMeterData)}
				onClose={() => setSelectedMeterData(null)}
				meterData={selectedMeterData}
			/>
		</Fragment>
	);
};
