import { useMemo } from "react";

import { routes } from "../secure-routes";
import { UserRole } from "../types/generated/graphql";

/**
 * Retrieve the private routes that the user is permitted to access.
 * An array of roles from the user profile is passed in,
 * and we simply check for each property of the route if the user has the required role.
 * If the route contains the role and the user has that role, then the route is permitted.
 * Additionally, the user does not need to have all the roles, just one of them.
 * @param roles The roles of the user
 * @param flatten If true, then we will flatten the routes so all routes are top level. Default is true.
 */
export function usePrivateRoutes(roles: UserRole[], flatten = false) {
	return useMemo(() => {
		// If user has no roles, there's nothing to check!
		if (!roles) {
			return [];
		}

		const filteredRoutes = routes.filter((route) => {
			// We want to return our route and additionally filter out any children routes that the user does not have access to.
			if (route.childRoutes && route.childRoutes.length > 0) {
				// If the user does not have the required role for the child route, then we should not return the child route.
				const filteredRoutes = route.childRoutes.filter((childRoute) => {
					if (childRoute.permittedRoles.length === 0) {
						return true;
					}

					return childRoute.permittedRoles.some((role) => roles.includes(role));
				});

				if (filteredRoutes.length) {
					route.childRoutes = filteredRoutes;
				}
			}

			if (route.permittedRoles.length === 0) {
				return true;
			}

			return route.permittedRoles.some((role) => roles.includes(role));
		});

		// If we have passed flatten, we need to flatten the routes so that all routes are top level.
		if (flatten) {
			return filteredRoutes.reduce((acc, route) => {
				if (route.childRoutes) {
					return [...acc, route, ...route.childRoutes];
				}
				return [...acc, route];
			}, [] as typeof routes);
		}

		return filteredRoutes;
	}, [roles, flatten]);
}
