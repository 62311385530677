/** @jsxImportSource theme-ui */
import {
	Box,
	Button,
	ControlledToggle,
	Flex,
	Grid,
	Heading,
	IconBattery,
	IconCar,
	IconDocument,
	IconEye,
	IconNoEye,
	IconRightArrow,
	IconSolarPanel,
	IconTurbine,
	Input,
	Modal,
	Paragraph,
	Table,
	Text,
} from "@powerledger/ui-component-lib";
import { add, differenceInHours } from "date-fns";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Column, UseSortByColumnOptions } from "react-table";

import { Layout } from "../../components";
import { useOkta } from "../../hooks";
import { useUserProfileQuery } from "../../types/generated/graphql";
import { BidValidation, BuyersData } from "./bids.types";
import { largeMockData, mockData } from "./dummy-data";

const BidHistory: FC<{ isProsumer: boolean }> = ({ isProsumer }) => {
	const [showHistory, setShowHistory] = useState(true);
	const toggleHistoryShown = useCallback(() => setShowHistory(!showHistory), [showHistory]);
	const { t } = useTranslation();

	const tableStrings = {
		item: t("item"),
		items: t("items"),
		noDataText: t("No data"),
		pagination: {
			firstPage: t("first page"),
			previousPage: t("previous page"),
			nextPage: t("next page"),
			lastPage: t("last page"),
		},
	};

	const tableColumns: (Column<BuyersData> & UseSortByColumnOptions<BuyersData>)[] = [
		{
			Header: t("Date") as string,
			accessor: "date",
			Cell: ({ value }: { value: string }) => {
				return <Box sx={{ color: "white" }}>{t(value || "-")}</Box>;
			},
			disableSortBy: true,
		},
		{
			Header: t("$/kWh") as string,
			accessor: "kWh",
			Cell: ({ value }: { value: string }) => {
				return <Box sx={{ color: "white" }}>{t(value || "-")}</Box>;
			},
			disableSortBy: true,
		},
	];

	return (
		<Box>
			<button
				sx={{
					pl: 0,
					display: "flex",
					mt: [2, 4],
					backgroundColor: "transparent",
					border: "none",
					color: "text",
					alignItems: "center",
				}}
				onClick={toggleHistoryShown}
			>
				<Text sx={{ userSelect: "none", cursor: "pointer", fontSize: 2 }}>
					{showHistory ? t("Hide History") : t("Show History")}
				</Text>
				<Box
					aria-label="show-password"
					sx={{
						display: "flex",
						cursor: "pointer",
						alignContent: "center",
						backgroundColor: "transparent",
						border: "none",
						ml: 2,
					}}
				>
					{showHistory ? (
						<IconNoEye size={4} color="" noSvgPositioning />
					) : (
						<IconEye size={4} color="" noSvgPositioning />
					)}
				</Box>
			</button>
			{showHistory &&
				(isProsumer ? (
					<Grid
						sx={{ justifyContent: "flex-start", height: "100%", py: 3, mt: [2, 4] }}
						gap={4}
						columns={[3, "0.7fr 2fr 2fr"]}
					>
						<Heading>{t("")}</Heading>
						<Heading>{t("Buy")}</Heading>
						<Heading>{t("Sell")}</Heading>
						<Text>{t("Current Bid")}</Text>
						<Table translation={tableStrings} columns={tableColumns} noHover dataSource={mockData} pagination={false} />
						<Table translation={tableStrings} columns={tableColumns} noHover dataSource={mockData} pagination={false} />
						<Text>{t("Past Bids")}</Text>
						<Table
							translation={tableStrings}
							columns={tableColumns}
							noHover
							dataSource={largeMockData}
							pagination={false}
						/>
						<Table
							translation={tableStrings}
							columns={tableColumns}
							noHover
							dataSource={largeMockData}
							pagination={false}
						/>
					</Grid>
				) : (
					<Grid sx={{ justifyContent: "flex-start", height: "100%", py: 3 }} gap={4} columns={[2, "0.7fr 2fr"]}>
						<Heading>{t("")}</Heading>
						<Heading>{t("Buy")}</Heading>
						<Text>{t("Current Bid")}</Text>
						<Table translation={tableStrings} columns={tableColumns} noHover dataSource={mockData} pagination={false} />
						<Text>{t("Past Bids")}</Text>
						<Table
							translation={tableStrings}
							columns={tableColumns}
							noHover
							dataSource={largeMockData}
							pagination={false}
						/>
					</Grid>
				))}
		</Box>
	);
};

const PowerSource = () => {
	// Todo: Assume sourceList is queryable from backend
	const sourceList = ["solar", "battery"]; // inc. car + battery
	const { t } = useTranslation();
	return (
		<Flex
			sx={{
				flexDirection: "column",
				fill: "inactiveDark",
				alignSelf: "flex-end",
				alignItems: "flex-start",
			}}
			data-testid="powerSource"
		>
			<Text sx={{ mr: 2 }}>{t("Source")}</Text>
			<Flex>
				<IconSolarPanel width={40} sx={{ mx: 2, fill: sourceList.includes("solar") && "green" }} />
				<IconBattery
					sx={{ transform: "rotate(-90deg)", mx: 2, fill: sourceList.includes("battery") && "green" }}
					width={40}
				/>
				<IconTurbine width={40} sx={{ mx: 2, fill: sourceList.includes("turbine") && "green" }} />
				<IconCar width={40} sx={{ mx: 2, fill: sourceList.includes("ev") && "green" }} />
			</Flex>
		</Flex>
	);
};

// Todo: Remove once bids/tarrif query available
// Hard coded details below
const currentBuyBid = 2.25;
const currentSellBid = 2.4;
const buyBidRange = [0.8, 2.5];
const sellBidRange = [0.9, 2.8];

/**
 * @todo This should be changed to gateClosureTime received from the backend,
 * and probably re-fetch the next gateClosureTime after certain interval,
 * once made available from the backend
 */
const timeToNextSettlement = add(new Date(), { seconds: 43200 });
const remainingHours = differenceInHours(timeToNextSettlement, new Date());

/**
 * Bids allow users to enter a bid for the price they are willing to buy or sell electricity at on the P2P LEM.
 * Prosumers can buy & sell.
 * Consumers can only buy.
 */
export const Bids: FC = () => {
	const { t } = useTranslation();
	const [toggleModal, setToggleModal] = useState(false);
	const modalFocus = useRef<any>(null);

	const [bidValidation, setBidValidation] = useState<BidValidation>({
		isNumber: 0,
		isDifferent: false,
		inRange: false,
	});

	const { identityProviderRef } = useOkta();

	const { data } = useUserProfileQuery({
		variables: {
			identityProviderRef,
		},
	});

	// Todo: Replace usage of @client here on userProfile field
	const isProsumer = data?.userProfile?.isProsumer;

	const [isSell, setIsSell] = useState(false);
	const [buyBid, setBuyBid] = useState(currentBuyBid);
	const [sellBid, setSellBid] = useState(currentSellBid);
	const [adjustedBid, setAdjustedBid] = useState(0);

	useEffect(() => {
		setBidValidation(
			isSell
				? {
						isNumber: adjustedBid,
						isDifferent: adjustedBid !== sellBid,
						inRange: adjustedBid >= sellBidRange[0] && adjustedBid <= sellBidRange[1],
				  }
				: {
						isNumber: adjustedBid,
						isDifferent: adjustedBid !== buyBid,
						inRange: adjustedBid >= buyBidRange[0] && adjustedBid <= buyBidRange[1],
				  },
		);
	}, [sellBid, adjustedBid, buyBid, isSell]);

	useEffect(() => {
		if (modalFocus.current) {
			modalFocus.current.focus();
		}
	}, [toggleModal]);

	function handleBidConfirmation(isSell: boolean, adjustedBid: number) {
		if (isSell) {
			setSellBid(adjustedBid);
		} else {
			setBuyBid(adjustedBid);
		}
		setToggleModal(false);
	}

	return (
		<Layout pageTitle="Bids">
			<Box
				sx={{
					p: 4,
				}}
			>
				{isProsumer && (
					<Flex
						sx={{
							alignItems: "flex-end",
							mb: 3,
							flexWrap: "wrap",
						}}
					>
						<ControlledToggle
							optionOne="Buy"
							optionTwo="Sell"
							icons={[<IconRightArrow sx={{ mt: 1 }} key="" />, <IconDocument key="" />]}
							onClick={() => setIsSell(!isSell)}
							selected={isSell}
							style={{ mr: [4, 6], mb: [4, 0], mt: [4, 0], minWidth: 250 }}
						/>

						{/* TODO: Revisit requirement for showing energy source */}
						<PowerSource />
					</Flex>
				)}
				<Paragraph sx={{ fontSize: [2], lineHeight: [1, 2], whiteSpace: "pre-line", mt: [2, 4], mb: [2, 4] }}>
					{t(`Please set your ${isProsumer ? "buy or sell" : "buy"} bid below.
							Your new bid will come into effect once the next bidding window opens.
							The LEM's next bidding window will occur `)}
					{remainingHours > 1 ? (
						<>
							{t("in ")}
							<strong>{t(`${remainingHours}`)}</strong>
							{t(" hours.")}
						</>
					) : (
						t("within the next hour.")
					)}
				</Paragraph>
				<Flex
					sx={{
						flexDirection: "column",
						alignItems: "center",
						backgroundColor: "secondaryDarker",
						p: 3,
						borderRadius: 5,
					}}
				>
					<Flex sx={{ alignItems: "flex-end", justifyContent: "center", flexDirection: "column" }}>
						<Flex sx={{ flexDirection: "column" }}>
							<Text sx={{ mb: 2 }}>
								{t(`Your`)}
								<strong>{isSell ? t(` sell `) : t(` buy `)}</strong>
								{t(`bid for the next settlement is: $${isSell ? sellBid : buyBid}/kWh`)}
							</Text>
							<Flex sx={{ mb: 2 }}>
								<Input type={"number"} onChange={(e) => setAdjustedBid(parseFloat(e.target.value))} sx={{ mr: 2 }} />
								<Button
									disabled={!Object.values(bidValidation).every((value) => value)}
									onClick={() => {
										setToggleModal(!toggleModal);
									}}
									sx={{ maxHeight: 7, minWidth: 9 }}
								>
									{t(`Submit ${isSell ? "Sell" : "Buy"} Order`)}
								</Button>
							</Flex>
							{!bidValidation.inRange && (
								<Text sx={{ color: "warning", fontSize: 1 }}>
									{isSell
										? t(`Please enter a bid in the range ${sellBidRange[0]}-${sellBidRange[1]}`)
										: t(`Please enter a bid in the range ${buyBidRange[0]}-${buyBidRange[1]}`)}
								</Text>
							)}
						</Flex>
					</Flex>
				</Flex>
				<BidHistory isProsumer />
				<Modal maskClosable onCancel={() => setToggleModal(false)} visible={toggleModal} modalMaxWidth="50vw">
					<Box sx={{ backgroundColor: "secondaryDarker" }}>
						<Flex sx={{ p: 4 }}>
							<Text sx={{ whiteSpace: "pre-line", margin: "0 auto" }}>
								{t(`Are you sure you would like to submit your`)}
								<strong>{isSell ? t(` sell `) : t(` buy `)}</strong>
								{t(`bid at $${adjustedBid}/kWh?`)}
							</Text>
						</Flex>
						<Flex sx={{ flexDirection: "row", justifyContent: "space-around", pb: 4 }}>
							<Button
								sx={{
									backgroundColor: "negative",
									color: "text",
									"&:enabled:hover,&:enabled:focus": { backgroundColor: "negativeDarker" },
								}}
								onClick={() => setToggleModal(false)}
								ref={modalFocus}
							>
								{t("Cancel")}
							</Button>
							<Button
								sx={{
									backgroundColor: "green",
									color: "black",
									"&:enabled:hover,&:enabled:focus": { backgroundColor: "greenDarker", color: "white" },
								}}
								onClick={() => handleBidConfirmation(isSell, adjustedBid)}
							>
								{t("Confirm")}
							</Button>
						</Flex>
					</Box>
				</Modal>
			</Box>
		</Layout>
	);
};
