import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { DataPointType } from "@/app/components/nivo-graphs/nivo-graphs.types";
import { notifications } from "@/app/container/notifications";
import { useUserProfile } from "@/app/hooks/use-user-profile";
import { ProfileClass, useListOfMetersQuery, useMeterForecastForNextDayQuery } from "@/app/types/generated/graphql";
import { convertEnergyDataToEnergyDataGraphDataSet } from "@/app/util/visualization-helper";

import { getParticipantSupportedProfileClassList } from "../meter-profile/meter-profile-helper";
import { MeterOption, UseMeterProfileVisualization } from "./meter-profile-visualization.types";

export const useMeterProfileVisualization: UseMeterProfileVisualization = () => {
	const { t } = useTranslation();
	const [selectedMeter, setSelectedMeter] = useState<MeterOption | null>();

	const { userProfile, loading: userProfileLoading } = useUserProfile();
	const { data: listOfMeters, loading: listOfMeterLoading } = useListOfMetersQuery({
		fetchPolicy: "cache-and-network",
		variables: {
			applicationHostName: userProfile?.applicationHostName,
			tenantName: userProfile?.tenantName,
			tradingGroupName: userProfile?.tradingGroupName,
		},
		skip: userProfileLoading,
		onError: () => {
			notifications.error({
				message: t("Failed to fetch the list of meters"),
			});
		},
		onCompleted: (data) => {
			const firstMeter = data?.getMeters?.[0];
			if (!selectedMeter && firstMeter) {
				setSelectedMeter({
					label: firstMeter.meterExternalRef,
					value: firstMeter,
				});
			}
		},
	});

	const meterOptions = useMemo(() => {
		return (
			listOfMeters?.getMeters?.map((meter) => ({
				label: meter.meterExternalRef,
				value: meter,
			})) ?? []
		);
	}, [listOfMeters?.getMeters]);

	const { data: selectedMeterForecast, loading: forecastedMeterProfileEnergyDataLoading } =
		useMeterForecastForNextDayQuery({
			variables: {
				meterProfilesFilter: {
					applicationHostId: userProfile?.applicationHostId ?? "",
					tenantId: userProfile?.tenantId ?? "",
					tradingGroupId: userProfile?.tradingGroupId ?? "",
					id: selectedMeter?.value.meterId ?? "",
				},
			},
			skip: !selectedMeter?.value,
			onError: () => {
				notifications.error({
					message: t("Failed to fetch the next day forecast for the selected meter"),
				});
			},
		});

	const forecastedMeterProfileEnergyDataGraphDataSet = useMemo(
		() =>
			(selectedMeter?.value.assetType
				? getParticipantSupportedProfileClassList(selectedMeter?.value.assetType)
				: []
			).reduce((dataSet, profileClass) => {
				const key = profileClass.toLowerCase() as "load" | "generation" | "bess";

				dataSet[profileClass] = convertEnergyDataToEnergyDataGraphDataSet(
					selectedMeterForecast?.meterForecastForNextDay?.[key],
				);

				return dataSet;
			}, {} as Record<ProfileClass, DataPointType[]>),
		[selectedMeter?.value.assetType, selectedMeterForecast?.meterForecastForNextDay],
	);

	return {
		selectedMeter,
		setSelectedMeter,
		meterOptions,
		forecastedMeterProfileEnergyDataGraphDataSet,
		forecastedMeterProfileEnergyDataLoading,
		listOfMeterLoading,
	};
};
