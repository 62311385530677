import { Button, Flex, Label, LegacySelect } from "@powerledger/ui-component-lib";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import { getDefaultSelectTranslation } from "@/app/util";

import { AssignMeterToProfilesProps } from "./assign-meter-to-profiles.types";
import { AssignedMeterProfilesTable } from "./assigned-meter-profiles-table";
import { useAssignMeterToProfile } from "./use-assign-meter-to-profiles";

/**
 * Renders a flexbox container with a row layout, vertically centering and spacing its children evenly.
 *
 * @param children - The child components to render within the flexbox container.
 * @return The rendered flexbox container with its child components.
 */
const FlexRow: FC = ({ children }) => {
	return (
		<Flex
			sx={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%", height: 70 }}
		>
			{children}
		</Flex>
	);
};

/**
 * Renders a component that allows users to assign a meter to a baseline profile.
 *
 * @param meterData - data of the meter to be assigned
 * @return the rendered component
 */
export const AssignMeterToProfiles: FC<AssignMeterToProfilesProps> = ({ meterData, onClose }) => {
	const { t } = useTranslation();
	const {
		profileTypeOptions,
		selectedProfileClass,
		setSelectedProfileClass,
		baselineProfiles,
		baselineProfilesLoading,
		selectedBaselineProfile,
		setSelectedBaselineProfile,
		onAssignMeterToBaselineProfile,
		assignBaselineProfileToMeterLoading,
	} = useAssignMeterToProfile({
		meterData,
	});

	return (
		<Flex sx={{ flexDirection: "column", width: "100%", height: "100%", rowGap: 2 }}>
			<FlexRow>
				<Label htmlFor="profileCategory">{t("Profile Category")}</Label>
				<LegacySelect
					name="profileCategory"
					sx={{ width: "100%" }}
					options={profileTypeOptions}
					hideSelectedOptions={false}
					translation={getDefaultSelectTranslation()}
					value={profileTypeOptions.find((option) => option.value === selectedProfileClass?.value)}
					onChange={(option) => setSelectedProfileClass(option)}
					formatOptionLabel={(option) => t(option.label)}
				/>
			</FlexRow>

			<FlexRow>
				<Label htmlFor="baselineProfile">{t("Select Baseline Profile")}</Label>
				<LegacySelect
					name="baselineProfile"
					sx={{ width: "100%" }}
					isLoading={baselineProfilesLoading}
					options={baselineProfiles}
					placeholder={t("Select a baseline profile")}
					hideSelectedOptions={false}
					translation={getDefaultSelectTranslation()}
					isMulti={false}
					value={baselineProfiles.find((option) => option.value === selectedBaselineProfile?.value) || null}
					onChange={setSelectedBaselineProfile}
					formatOptionLabel={(option) => t(option.label)}
				/>
			</FlexRow>

			<Button
				sx={{ width: 145, alignSelf: "flex-end" }}
				onClick={onAssignMeterToBaselineProfile}
				disabled={!selectedBaselineProfile || assignBaselineProfileToMeterLoading}
			>
				{assignBaselineProfileToMeterLoading ? t("Assigning...") : t("Add")}
			</Button>

			<AssignedMeterProfilesTable selectedMeterId={meterData.meterId} />

			<Button sx={{ width: 145, alignSelf: "center", mt: 2 }} onClick={onClose}>
				{t("Close")}
			</Button>
		</Flex>
	);
};
