import { Box, Flex, ThemeUIStyleObject } from "@powerledger/ui-component-lib";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { ContentBoxType } from "./content-box.types";

export const ContentBox: FC<ContentBoxType> = ({
	title,
	titleSecondary,
	value,
	valueSecondary,
	subtitle,
	subtitleSecondary,
	strong = false,
	sx,
	testid,
}) => {
	const { t } = useTranslation();
	const singleLineStyle: ThemeUIStyleObject = {
		borderRadius: 3,
		backgroundColor: "secondaryDarker",
		justifyContent: "center",
		alignItems: "center",
		height: "100%",
		textAlign: "center",
	};
	const valuePresent = useMemo(() => {
		return value || value === 0;
	}, [value]);
	const valueSecondaryPresent = useMemo(() => {
		return valueSecondary || valueSecondary === 0;
	}, [valueSecondary]);
	return (
		<Flex
			sx={{
				borderColor: "secondary",
				borderStyle: "solid",
				gap: 2,
				px: 2,
				borderWidth: strong ? 3 : 0,
				...singleLineStyle,
				...sx,
			}}
			data-testid={testid}
		>
			<Flex
				sx={{
					flexDirection: "column",
				}}
			>
				<Box
					sx={{
						color: valuePresent ? "green" : "textDarker",
						fontSize: 18,
						fontWeight: strong ? "bold" : "standard",
					}}
				>
					{valuePresent ? value : "-"}
				</Box>

				{title ? (
					<Box
						sx={{
							color: valuePresent ? "text" : "textDarker",
							fontSize: 14,
							fontWeight: strong ? "bold" : "standard",
						}}
					>
						{t(title)}
					</Box>
				) : (
					<Box></Box>
				)}
				{subtitle && (
					<Box
						sx={{
							color: valuePresent ? "secondaryLighter" : "textDarker",
							fontSize: "small",
							fontWeight: strong ? "bold" : "standard",
						}}
					>
						{t(subtitle)}
					</Box>
				)}
			</Flex>
			{(titleSecondary || valueSecondaryPresent) && (
				<Flex
					sx={{
						flexDirection: "column",
					}}
				>
					<Box
						sx={{
							color: valueSecondaryPresent ? "green" : "textDarker",
							fontSize: 18,
							fontWeight: strong ? "bold" : "standard",
						}}
					>
						{valueSecondaryPresent ? valueSecondary : "-"}
					</Box>

					{titleSecondary ? (
						<Box
							sx={{
								color: valueSecondaryPresent ? "text" : "textDarker",
								fontSize: 14,
								fontWeight: strong ? "bold" : "standard",
							}}
						>
							{t(titleSecondary)}
						</Box>
					) : (
						<Box></Box>
					)}
					{subtitleSecondary && (
						<Box
							sx={{
								color: valueSecondaryPresent ? "secondaryLighter" : "textDarker",
								fontSize: "small",
								fontWeight: strong ? "bold" : "standard",
							}}
						>
							{t(subtitleSecondary)}
						</Box>
					)}
				</Flex>
			)}
		</Flex>
	);
};
