import { FormikProps } from "formik";
import { useCallback, useEffect, useRef, useState } from "react";

import { validateMeterProfileCsv } from "./import-csv/helper";
import { UploadCsvType } from "./upload-csv.types";

export const useUploadCSV = () => {
	const fileInputRef = useRef<HTMLInputElement | null>(null);
	const formikRef = useRef<FormikProps<UploadCsvType>>(null);
	const [localFilePickerOpened, setLocalFilePickerOpened] = useState(false);
	const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);

	useEffect(() => {
		return () => {
			if (timer) {
				clearTimeout(timer);
			}
		};
	}, [timer]);

	const onFormFocus = useCallback(() => {
		// Detect the local file picker was closed without selecting a file
		// Changing formik state trigger validation to run again, temporarily resetting previous error,
		// so don't call setFieldTouched when the field was already touched
		if (localFilePickerOpened) {
			// TODO: Probably get rid of the setTimeout by some other way like using document.body.onfocus
			/**
			 * Using setTimeout to add some delay in setting this field as touched
			 *
			 * Since the input to select a file takes some time to reflect it's change in it's value property
			 * adding some delay in setting this field as touched helps avoids displaying error for a fraction of second even after a file was selected
			 */
			const timeout = setTimeout(() => {
				if (formikRef.current && !formikRef.current.touched.file) {
					formikRef.current.setFieldTouched("file", true);
				}
			}, 500);

			setTimer(timeout);
			setLocalFilePickerOpened(false);
		}
	}, [formikRef, localFilePickerOpened]);

	return {
		validateCsvFile: validateMeterProfileCsv,
		formikRef,
		onFormFocus,
		timer,
		fileInputRef,
		setLocalFilePickerOpened,
	};
};
