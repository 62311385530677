import { AccountSummaryType } from "./settlements.types";

export const demoData: Array<AccountSummaryType> = [
	{
		name: "John Doe",
		meterNumber: "1234567890",
		bills: [
			{
				dailyFees: {
					timePeriod: 90,
					cost: 0.05,
				},
				settlementId: "11",
				settlementStartDateTime: "2020-01-09T15:00:00.0000000Z",
				settlementEndDateTime: "2020-04-08T15:00:00.0000000Z",
				gridQuantityPurchased: [
					{
						description: "Peak;09:00:00 - 17:00:00",
						units: 62.7,
						rate: 0.6524,
						fee: 0.005,
						amount: 40.90548,
					},
					{
						description: "Peak;08:59:59 - 16:59:59",
						units: 62.7,
						rate: 0.6524,
						fee: 0.005,
						amount: 40.90548,
					},
					{
						description: "Peak;09:00:00 - 17:00:00",
						units: 62.7,
						rate: 0.6524,
						fee: 0.505,
						amount: 40.90548,
					},
					{
						description: "Shoulder;07:00:00 - 09:00:00",
						units: 62.7,
						rate: 0.6524,
						fee: 0.505,
						amount: 40.90548,
					},
					{
						description: "Shoulder;17:00:00 - 19:00:00",
						units: 62.7,
						rate: 0.6524,
						fee: 0.505,
						amount: 40.90548,
					},
					{
						description: "Off-Peak;19:00:00 - 07:00:00",
						units: 62.7,
						rate: 0.6524,
						fee: 0.505,
						amount: 40.90548,
					},
				],
				p2pQuantityPurchased: [
					{
						description: "Peak;09:00:00 - 17:00:00",
						units: 62.7,
						rate: 0.6524,
						fee: 0.505,
						amount: 40.90548,
					},
					{
						description: "Shoulder;07:00:00 - 09:00:00",
						units: 62.7,
						rate: 0.6524,
						fee: 0.505,
						amount: 40.90548,
					},
					{
						description: "Shoulder;17:00:00 - 19:00:00",
						units: 62.7,
						rate: 0.6524,
						fee: 0.505,
						amount: 40.90548,
					},
					{
						description: "Off-Peak;19:00:00 - 07:00:00",
						units: 62.7,
						rate: 0.6524,
						fee: 0.505,
						amount: 40.90548,
					},
				],
				gridQuantitySold: [
					{
						description: "Peak;09:00:00 - 17:00:00",
						units: 40.9,
						rate: 0.2855,
						fee: 0.005,
						amount: 11.67695,
					},
					{
						description: "Shoulder;07:00:00 - 09:00:00",
						units: 40.9,
						rate: 0.2855,
						fee: 0.005,
						amount: 11.67695,
					},
					{
						description: "Shoulder;17:00:00 - 19:00:00",
						units: 40.9,
						rate: 0.2855,
						fee: 0.005,
						amount: 11.67695,
					},
					{
						description: "Off-Peak;19:00:00 - 07:00:00",
						units: 40.9,
						rate: 0.2855,
						fee: 0.005,
						amount: 11.67695,
					},
				],
				p2pQuantitySold: [
					{
						description: "Peak;09:00:00 - 17:00:00",
						units: 40.9,
						rate: 0.2855,
						fee: 0.005,
						amount: 11.67695,
					},
					{
						description: "Shoulder;07:00:00 - 09:00:00;17:00:00 - 19:00:00",
						units: 40.9,
						rate: 0.2855,
						fee: 0.005,
						amount: 11.67695,
					},
					{
						description: "Off-Peak;19:00:00 - 07:00:00",
						units: 40.9,
						rate: 0.2855,
						fee: 0.005,
						amount: 11.67695,
					},
				],
			},
			{
				dailyFees: {
					timePeriod: 90,
					cost: 0.05,
				},
				settlementId: "12",
				settlementStartDateTime: "2020-01-09T15:00:00.0000000Z",
				settlementEndDateTime: "2020-04-08T15:00:00.0000000Z",
				gridQuantityPurchased: [
					{
						description: "Peak;09:00:00 - 17:00:00",
						units: 62.7,
						rate: 0.6524,
						fee: 0.005,
						amount: 40.90548,
					},
					{
						description: "Peak;08:59:59 - 16:59:59",
						units: 62.7,
						rate: 0.6524,
						fee: 0.005,
						amount: 40.90548,
					},
					{
						description: "Peak;09:00:00 - 17:00:00",
						units: 62.7,
						rate: 0.6524,
						fee: 0.005,
						amount: 40.90548,
					},
					{
						description: "Shoulder;07:00:00 - 09:00:00",
						units: 62.7,
						rate: 0.6524,
						fee: 0.005,
						amount: 40.90548,
					},
					{
						description: "Shoulder;17:00:00 - 19:00:00",
						units: 62.7,
						rate: 0.6524,
						fee: 0.005,
						amount: 40.90548,
					},
					{
						description: "Off-Peak;19:00:00 - 07:00:00",
						units: 62.7,
						rate: 0.6524,
						fee: 0.005,
						amount: 40.90548,
					},
				],
				p2pQuantityPurchased: [
					{
						description: "Peak;09:00:00 - 17:00:00",
						units: 62.7,
						rate: 0.6524,
						fee: 0.005,
						amount: 40.90548,
					},
					{
						description: "Shoulder;07:00:00 - 09:00:00",
						units: 62.7,
						rate: 0.6524,
						fee: 0.005,
						amount: 40.90548,
					},
					{
						description: "Shoulder;17:00:00 - 19:00:00",
						units: 62.7,
						rate: 0.6524,
						fee: 0.005,
						amount: 40.90548,
					},
					{
						description: "Off-Peak;19:00:00 - 07:00:00",
						units: 62.7,
						rate: 0.6524,
						fee: 0.005,
						amount: 40.90548,
					},
				],
				gridQuantitySold: [
					{
						description: "Peak;09:00:00 - 17:00:00",
						units: 40.9,
						rate: 0.2855,
						fee: 0.005,
						amount: 11.67695,
					},
					{
						description: "Shoulder;07:00:00 - 09:00:00",
						units: 40.9,
						rate: 0.2855,
						fee: 0.005,
						amount: 11.67695,
					},
					{
						description: "Shoulder;17:00:00 - 19:00:00",
						units: 40.9,
						rate: 0.2855,
						fee: 0.005,
						amount: 11.67695,
					},
					{
						description: "Off-Peak;19:00:00 - 07:00:00",
						units: 40.9,
						rate: 0.2855,
						fee: 0.005,
						amount: 11.67695,
					},
				],
				p2pQuantitySold: [
					{
						description: "Peak;09:00:00 - 17:00:00",
						units: 40.9,
						rate: 0.2855,
						fee: 0.005,
						amount: 11.67695,
					},
					{
						description: "Shoulder;07:00:00 - 09:00:00;17:00:00 - 19:00:00",
						units: 40.9,
						rate: 0.2855,
						fee: 0.005,
						amount: 11.67695,
					},
					{
						description: "Off-Peak;19:00:00 - 07:00:00",
						units: 40.9,
						rate: 0.2855,
						fee: 0.005,
						amount: 11.67695,
					},
				],
			},
		],
	},
	{
		name: "Jane Doe",
		meterNumber: "1203456789",
		bills: [
			{
				dailyFees: {
					timePeriod: 90,
					cost: 0.05,
				},
				settlementId: "12",
				settlementStartDateTime: "2020-01-09T15:00:00.0000000Z",
				settlementEndDateTime: "2020-04-08T15:00:00.0000000Z",
				gridQuantityPurchased: [
					{
						description: "Peak;09:00:00 - 17:00:00",
						units: 41.2,
						rate: 0.6524,
						fee: 0.005,
						amount: 26.7888,
					},
					{
						description: "Shoulder;07:00:00 - 09:00:00",
						units: 41.2,
						rate: 0.6524,
						fee: 0.005,
						amount: 26.7888,
					},
					{
						description: "Shoulder;17:00:00 - 19:00:00",
						units: 41.2,
						rate: 0.6524,
						fee: 0.005,
						amount: 26.7888,
					},
					{
						description: "Off-Peak;19:00:00 - 07:00:00",
						units: 41.2,
						rate: 0.6524,
						fee: 0.005,
						amount: 26.7888,
					},
				],
				p2pQuantityPurchased: [
					{
						description: "Peak;09:00:00 - 17:00:00",
						units: 41.2,
						rate: 0.6524,
						fee: 0.005,
						amount: 26.7888,
					},
					{
						description: "Shoulder;07:00:00 - 09:00:00",
						units: 41.2,
						rate: 0.6524,
						fee: 0.005,
						amount: 26.7888,
					},
					{
						description: "Shoulder;17:00:00 - 19:00:00",
						units: 41.2,
						rate: 0.6524,
						fee: 0.005,
						amount: 26.7888,
					},
					{
						description: "Off-Peak;19:00:00 - 07:00:00",
						units: 41.2,
						rate: 0.6524,
						fee: 0.005,
						amount: 26.7888,
					},
				],
				gridQuantitySold: [
					{
						description: "Peak;09:00:00 - 17:00:00",
						units: 23.1,
						rate: 0.2855,
						fee: 0.005,
						amount: 6.59505,
					},
					{
						description: "Shoulder;07:00:00 - 09:00:00",
						units: 23.1,
						rate: 0.2855,
						fee: 0.005,
						amount: 6.59505,
					},
					{
						description: "Shoulder;17:00:00 - 19:00:00",
						units: 23.1,
						rate: 0.2855,
						fee: 0.005,
						amount: 6.59505,
					},
					{
						description: "Off-Peak;19:00:00 - 07:00:00",
						units: 23.1,
						rate: 0.2855,
						fee: 0.005,
						amount: 6.59505,
					},
				],
				p2pQuantitySold: [
					{
						description: "Peak;09:00:00 - 17:00:00",
						units: 23.1,
						rate: 0.2855,
						fee: 0.005,
						amount: 6.59505,
					},
					{
						description: "Shoulder;07:00:00 - 09:00:00",
						units: 23.1,
						rate: 0.2855,
						fee: 0.005,
						amount: 6.59505,
					},
					{
						description: "Shoulder;17:00:00 - 19:00:00",
						units: 23.1,
						rate: 0.2855,
						fee: 0.005,
						amount: 6.59505,
					},
					{
						description: "Off-Peak;19:00:00 - 07:00:00",
						units: 23.1,
						rate: 0.2855,
						fee: 0.005,
						amount: 6.59505,
					},
				],
			},
		],
	},
];
