import { ApolloClient } from "@apollo/client";
import { OktaAuth } from "@okta/okta-auth-js";

type LogoutType = {
	oktaAuth: OktaAuth;
	apolloClient: ApolloClient<unknown>;
	callback?: () => never;
};

export const oktaLogout = ({ oktaAuth, apolloClient, callback }: LogoutType) => {
	async function oktaSignOut() {
		return await oktaAuth.signOut({
			revokeAccessToken: true,
			revokeRefreshToken: true,
		});
	}
	async function clearStore() {
		return await apolloClient.clearStore();
	}
	oktaSignOut()
		.then(() => {
			clearStore().then(() => {
				if (callback) {
					callback();
				}
			});
		})
		.catch((error: any) => {
			// TODO: Sentry
			console.error(error);
		});
};
