// import { userProfiles, defaultUser } from './demoData/user/userProfile';
// import demoUsers from './demoData/user/demoUsers';

export const apolloAppCacheTypePolicies = {
	User: {
		fields: {
			isProsumer: {
				read() {
					return true;
				},
			},
		},
	},
	// Query: {
	//   fields: {
	//     userProfile: {
	//       read(_: any, { args }: { args: any }) {
	//         const { identityProviderRef } = args;
	//         // search through our user profiles and return the one that matches the identityProviderRef
	//         const user = userProfiles.find((user) => user.identityProviderRef === identityProviderRef);
	//         return user || defaultUser;
	//       },
	//     },
	//     users: {
	//       read(_: any, { args }: { args: any }) {
	//         // this query is a connection query, so we need to return a connection object
	//         // with edges and pageInfo
	//         const edges = demoUsers.map((user) => ({
	//           __typename: 'UserEdge',
	//           cursor: user.userId,
	//           node: {
	//             __typename: 'User',
	//             id: user.userId,
	//             username: user.username,
	//             email: user.email,
	//             title: user.title,
	//             firstName: user.firstName,
	//             lastName: user.lastName,
	//             tenantName: user.tenantName,
	//             tradingGroupName: user.tradingGroupName,
	//             roles: user.roles,
	//             status: user.status,
	//           },
	//         }));
	//         return {
	//           edges,
	//           // Pagination is not implemented.
	//           pageInfo: {
	//             startCursor: edges[0].cursor,
	//             endCursor: edges[edges.length - 1].cursor,
	//             hasNextPage: false,
	//             hasPreviousPage: false,
	//           },
	//           totalCount: edges.length,
	//         };
	//       },
	//     },
	//   },
	// },
};
