import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { notifications } from "@/app/container/notifications";
import { useUserProfile } from "@/app/hooks/use-user-profile";
import {
	MeterForecastForNextDayDocument,
	MeterProfilesDocument,
	UnassignedProfilesDocument,
	useDeleteMeterProfileMutation,
	useMeterProfilesQuery,
	useResetToDefaultProfileMutation,
} from "@/app/types/generated/graphql";

import { AssignedMeterProfileConfirmationModal, UseAssignedMeterProfiles } from "./assigned-meter-profiles-table.types";

export const useAssignedMeterProfilesTable: UseAssignedMeterProfiles = ({ selectedMeterId }) => {
	const { t } = useTranslation();
	const [visibleModal, setVisibleModal] = useState<AssignedMeterProfileConfirmationModal>();
	const [selectedMeterProfileId, setSelectedMeterProfileId] = useState<string>();
	const { userProfile, loading: userProfileLoading } = useUserProfile();

	const { data: meterProfiles, loading: meterProfilesLoading } = useMeterProfilesQuery({
		variables: {
			meterProfilesFilter: {
				id: selectedMeterId,
				applicationHostId: userProfile?.applicationHostId ?? "",
				tenantId: userProfile?.tenantId ?? "",
				tradingGroupId: userProfile?.tradingGroupId ?? "",
			},
		},
		skip: userProfileLoading || !selectedMeterId,
	});

	const [mutateResetToDefault, { loading: resetToBaselineLoading }] = useResetToDefaultProfileMutation({
		refetchQueries: [MeterProfilesDocument],
	});
	const [mutateDeleteMeterProfile, { loading: deleteMeterProfileLoading }] = useDeleteMeterProfileMutation({
		refetchQueries: [MeterProfilesDocument, UnassignedProfilesDocument, MeterForecastForNextDayDocument],
	});

	const showDeleteMeterProfileConfirmationModal = useCallback(() => {
		setVisibleModal(AssignedMeterProfileConfirmationModal.DELETE_METER_PROFILE);
	}, []);

	const showResetMeterProfileConfirmationModal = useCallback(() => {
		setVisibleModal(AssignedMeterProfileConfirmationModal.RESET_METER_PROFILE);
	}, []);

	const hideConfirmationModal = useCallback(() => {
		setVisibleModal(undefined);
	}, []);

	const confirmDeleteMeterProfile = useCallback(async () => {
		try {
			await mutateDeleteMeterProfile({
				variables: {
					deleteMeterProfileInput: {
						profileId: selectedMeterProfileId ?? "",
						meterId: selectedMeterId,
						tenantId: userProfile?.tenantId ?? "",
					},
				},
			});

			hideConfirmationModal();
			notifications.success({
				message: t("Meter Profile deleted successfully"),
			});
		} catch (error) {
			if (error instanceof Error) {
				notifications.error({
					message: error.message || "Unfortunately we encountered an error in deleting the meter profile.",
				});
			}
		}
	}, [
		hideConfirmationModal,
		mutateDeleteMeterProfile,
		selectedMeterId,
		selectedMeterProfileId,
		t,
		userProfile?.tenantId,
	]);

	const confirmResetMeterProfile = useCallback(async () => {
		try {
			await mutateResetToDefault({
				variables: {
					toDefaultProfileInput: {
						meterId: selectedMeterId,
						profileId: selectedMeterProfileId ?? "",
						applicationHostId: userProfile?.applicationHostId ?? "",
						tenantId: userProfile?.tenantId ?? "",
						tradingGroupId: userProfile?.tradingGroupId ?? "",
					},
				},
			});

			hideConfirmationModal();
			notifications.success({
				message: t("Meter Profile reset to its baseline value successfully"),
			});
		} catch (error) {
			if (error instanceof Error) {
				notifications.error({
					message: error.message || t("Unfortunately we encountered an error in resetting the meter profile."),
				});
			}
		}
	}, [
		hideConfirmationModal,
		mutateResetToDefault,
		selectedMeterId,
		selectedMeterProfileId,
		t,
		userProfile?.applicationHostId,
		userProfile?.tenantId,
		userProfile?.tradingGroupId,
	]);

	return {
		assignedMeterProfiles: meterProfiles?.meterProfiles?.profiles?.map((profile) => ({
			id: profile?.id,
			name: profile?.name,
		})),
		loading: userProfileLoading || meterProfilesLoading,
		selectedMeterProfileId,
		setSelectedMeterProfileId,
		visibleModal,
		showDeleteMeterProfileConfirmationModal,
		showResetMeterProfileConfirmationModal,
		hideConfirmationModal,
		confirmDeleteMeterProfile,
		deleteMeterProfileLoading,
		confirmResetMeterProfile,
		resetToBaselineLoading,
	};
};
