import { OktaAuth } from "@okta/okta-auth-js";
import axios from "axios";

import { AUTH_BASE_URL } from "../lib/endpoints";

export const obtainStateToken = async (data: any) => {
	return postCall(`${AUTH_BASE_URL}/authn`, data, requestHeader);
};

export const resetPassword = async (body: any) => {
	return postCall(`${AUTH_BASE_URL}/authn/credentials/reset_password`, body, requestHeader);
};

export const changePassword = async (oktaAuth: OktaAuth, recoveryToken: string, newPassword: string) => {
	const transaction = await oktaAuth.verifyRecoveryToken({
		recoveryToken,
	});

	if (transaction.status !== "PASSWORD_RESET" || !transaction.resetPassword) {
		throw new Error(`Failed to verify recovery token from url with status: ${transaction.status}`);
	}

	const response = await transaction.resetPassword({ newPassword });

	if (!response || response.status !== "SUCCESS") {
		throw new Error(`Failed to reset password with status: ${transaction.status}`);
	}

	return response;
};

const postCall = async (url: string, body: any, header: any) => {
	const res = await axios.post(url, body, header);
	return { body: res.data, status: res.status };
};

const requestHeader = {
	"Content-Type": "application/json",
	Accept: "application/json",
};
