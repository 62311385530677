import { Locale, setDefaultOptions } from "date-fns";
import { toLower } from "lodash";
import moment from "moment";
import { registerLocale } from "react-datepicker";

export const init = async (systemLocale: string): Promise<void> => {
	// date-fns default locale is en-US as of version v2.29.2
	if (systemLocale === "en-US") return;

	try {
		const locale = (await import(
			/* @vite-ignore */
			`date-fns/locale/${systemLocale}/index.js`
		)) as Locale | undefined;
		setDefaultOptions({ locale });
		if (locale) registerLocale(systemLocale, locale);
	} catch (e) {
		throw new Error(`Failed to load date-fns locale info for ${systemLocale}`);
	}
};

// export const init = async (locale: string): Promise<void> => {
// 	const baseLocale = toLower(locale);
// 	// const baseLocale = locale.split('-')[0];
// 	if (moment.locale() === baseLocale) return;

// 	try {
// 		if (!moment.locales().includes(baseLocale)) {
// 			await import(`moment/locale/${baseLocale}`);
// 		}
// 		moment.locale(baseLocale);
// 	} catch (e) {
// 		console.error(e);
// 	}
// };

export const format = (date: Date, formatStr?: string) => moment(date).format(formatStr);

export const i18nDate = {
	init,
	format,
};

export default i18nDate;
