import * as React from "react";
import { SVGProps } from "react";

export const Consumer = (props: SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} fill="none" {...props}>
		<path
			fill="#27D9E5"
			d="M15 30C6.73 30 0 23.27 0 15S6.73 0 15 0s15 6.73 15 15-6.73 15-15 15ZM15 .644C7.083.644.644 7.083.644 15S7.084 29.356 15 29.356c7.915 0 14.356-6.44 14.356-14.356C29.356 7.085 22.916.644 15 .644Z"
		/>
		<path
			fill="#27D9E5"
			d="M22.413 24.085h-2.65a.744.744 0 0 1-.744-.744v-7.78l-8.044-.024-.025 7.804a.744.744 0 0 1-.743.744h-2.62a.744.744 0 0 1-.744-.744v-11.12c0-.234.113-.458.3-.598l7.47-5.562a.746.746 0 0 1 .893.005l7.356 5.56a.75.75 0 0 1 .295.592v11.121a.744.744 0 0 1-.744.744v.002Zm-11.438-9.267h8.02c.41 0 .743.333.743.744v7.78l2.675.024.025-11.146-1.306-.999-6.059-4.58-7.502 5.562-.011 11.139 2.644.024.025-7.804c0-.411.333-.744.744-.744h.002Z"
		/>
	</svg>
);
