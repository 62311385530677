import { Dispatch, SetStateAction } from "react";

import { MeterAssetType } from "@/app/types/generated/graphql";

import { SelectedMeterDataType } from "./assign-meter-to-profiles-modal";

export interface MeterProfileType {
	id: string;
	meterNumber: string;
	participantType: MeterAssetType;
	meterProfileStatus?: MeterProfileAssignmentStatus;
}

export const PARTICIPANT_TYPE_LABEL = {
	[MeterAssetType.Consumer]: "Consumer",
	[MeterAssetType.Producer]: "Producer",
	[MeterAssetType.Grid]: "Grid",
	[MeterAssetType.Prosumer]: "Prosumer",
	[MeterAssetType.ProsumerBess]: "Prosumer with BESS",
};

export type UseMeterProfilesFn = () => {
	loading: boolean;
	dataSource: MeterProfileType[];
	selectedMeterData: SelectedMeterDataType | null;
	setSelectedMeterData: Dispatch<SetStateAction<SelectedMeterDataType | null>>;
};

export type MeterProfileViewProps = ReturnType<UseMeterProfilesFn>;

export enum MeterProfileAssignmentStatus {
	UNASSIGNED = "unassigned",
	PARTIALLY_ASSIGNED = "partiallyAssigned",
	FULLY_ASSIGNED = "fullyAssigned",
}

export const MeterProfileAssignmentStatusColorCode = {
	[MeterProfileAssignmentStatus.UNASSIGNED]: "#FF0000",
	[MeterProfileAssignmentStatus.PARTIALLY_ASSIGNED]: "#FFA800",
	[MeterProfileAssignmentStatus.FULLY_ASSIGNED]: "#0BCC1E",
};
