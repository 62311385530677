import { Text, TextProps } from "@powerledger/ui-component-lib";
import { ErrorMessage as ErrorMessageBase, ErrorMessageProps as ErrorMessageBaseProps } from "formik";
import { FC } from "react";

type ErrorMessageProps = ErrorMessageBaseProps & TextProps;

export const ErrorMessage: FC<ErrorMessageProps> = ({ children, sx, ...props }) => (
	<ErrorMessageBase {...props}>
		{(msg) => <Text sx={{ variant: "forms.errorMessage", ...sx }}>{msg}</Text>}
	</ErrorMessageBase>
);

export default ErrorMessage;
