import { OktaAuth, OktaAuthOptions } from "@okta/okta-auth-js";

import { AppConfig } from "./config";

const oktaAuthConfig: OktaAuthOptions = {
	issuer: AppConfig.AUTH_ISSUER || "https://{yourOktaDomain}.com/oauth2/{authServerId}",
	clientId: AppConfig.AUTH_CLIENT_ID,
	redirectUri: `${window.location.origin}/login/callback`,
	pkce: true,
};

export const oktaAuthClient = new OktaAuth(oktaAuthConfig);
