import { CalendarSVG } from "@powerledger/ui-component-lib";
import { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { Input } from "theme-ui";

import { CustomDatePickerInputTypes } from "./custom-date-picker-input.types";

/**
 * A custom date picker input component with a calendar icon.
 *
 * ⚠️ Should only be used as a custom input prop in the DatePicker component.
 *
 * @param onClick The function to call when the input is clicked, passed by DatePicker itself.
 * @param value The string value of the input, passed by DatePicker itself.
 * @param disabled Whether to disable the input, passed by DatePicker itself.
 * @param showIcon Whether to show the calendar icon, defaults to true.
 * @param position The position of the calendar icon, either left or right.
 * @returns A JSX element representing the custom date picker input.
 *
 * Future ideas to make it more generic
 *
 * @todo Allow the icon to be passed as props
 * @todo Allow customization of the position of the icon, like inside the input field or outside
 */
export const CustomDatePickerInput = forwardRef<HTMLInputElement, CustomDatePickerInputTypes>(function CustomInput(
	{ value, onClick, showIcon, position = "right", disabled, placeholder },
	ref,
) {
	const { t } = useTranslation();
	return (
		<div style={{ position: "relative" }}>
			<Input
				disabled={disabled}
				type="text"
				id="date-picker"
				onClick={onClick}
				autoComplete="off"
				placeholder={t(placeholder || "Select a Date")}
				ref={ref}
				value={value}
				style={position === "right" ? { paddingRight: "3rem" } : { paddingLeft: "3rem" }}
				readOnly
			/>
			{showIcon && (
				<div
					style={{
						position: "absolute",
						top: "50%",
						transform: "translateY(-40%)",
						...(position === "right" ? { right: "1rem" } : { left: "1rem" }),
						...(disabled ? { opacity: 0.5, cursor: "not-allowed" } : {}),
					}}
				>
					<CalendarSVG width={16} height={16} />
				</div>
			)}
		</div>
	);
});
