import { Box } from "@powerledger/ui-component-lib";
import { FC } from "react";

import { UserInfoType } from "./user-info.types";

export const UserInfo: FC<UserInfoType> = ({ firstName, lastName, email }) => {
	return (
		<Box>
			<Box sx={{ fontSize: 3 }}>{`${firstName}${lastName && " " + lastName}`}</Box>
			<Box sx={{ fontSize: 1, color: "textDarker" }}>{email || ""}</Box>
		</Box>
	);
};
