import { Layout } from "@/app/components";

import { SettingsView } from "./settings-view";
import { useSettings } from "./use-settings";

export const Settings = () => {
	const logic = useSettings();
	return (
		<Layout pageTitle="Settings">
			<SettingsView {...logic} />
		</Layout>
	);
};
