import { Box } from "@powerledger/ui-component-lib";
import { FC } from "react";

import { Step, StepType } from "./step";

export const SignupStepper: FC<{
	currentStep: number;
	steps: Array<StepType>;
}> = ({ currentStep, steps }) => {
	return (
		<Box sx={{ position: "relative" }}>
			{steps.map((step, index) => (
				<Box key={step.title}>
					<Step
						step={step}
						active={currentStep === index}
						isCompleted={index < currentStep}
						sx={{ pb: 4, alignItems: "center" }}
					/>
				</Box>
			))}
		</Box>
	);
};
