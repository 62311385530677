import { SettlementType } from "../settlements.types";

export const structureData = (bills: SettlementType[]) => {
	let invoiceString = "";
	let boughtCost = 0;
	let soldCost = 0;
	let boughtkWh = 0;
	let soldkWh = 0;

	let additionalCharges = 0;
	bills.forEach((bill, index) => {
		const formattedString = (index > 0 ? ", " : "") + bill.settlementId;
		invoiceString = invoiceString + formattedString;

		let gridBoughtSubTotal = 0;
		let gridBoughtUnitsSubTotal = 0;
		let gridBoughtFeesSubTotal = 0;

		let p2pBoughtSubTotal = 0;
		let p2pBoughtUnitsSubTotal = 0;
		let p2pBoughtFeesSubTotal = 0;

		let gridSoldSubTotal = 0;
		let gridSoldUnitsSubTotal = 0;
		let gridSoldFeesSubTotal = 0;

		let p2pSoldSubTotal = 0;
		let p2pSoldUnitsSubTotal = 0;
		let p2pSoldFeesSubTotal = 0;

		let totalDays = 0;
		let totalDailyCost = 0;

		bill.gridQuantityPurchased.forEach((sale) => {
			gridBoughtSubTotal += sale.amount;
			gridBoughtFeesSubTotal += sale.units * (sale?.fee || 0);
			gridBoughtUnitsSubTotal += sale.units;
		});

		bill.p2pQuantityPurchased.forEach((sale) => {
			p2pBoughtSubTotal += sale.amount;
			p2pBoughtUnitsSubTotal += sale.units;
			p2pBoughtFeesSubTotal += sale.units * (sale?.fee || 0);
		});

		bill.gridQuantitySold.forEach((sale) => {
			gridSoldSubTotal += sale.amount;
			gridSoldUnitsSubTotal += sale.units;
			gridSoldFeesSubTotal += sale.units * (sale?.fee || 0);
		});
		bill.p2pQuantitySold.forEach((sale) => {
			p2pSoldSubTotal += sale.amount;
			p2pSoldUnitsSubTotal += sale.units;
			p2pSoldFeesSubTotal += sale.units * (sale?.fee || 0);
		});

		boughtCost += gridBoughtSubTotal + p2pBoughtSubTotal;
		boughtkWh = boughtkWh + gridBoughtUnitsSubTotal + p2pBoughtUnitsSubTotal;
		soldCost = soldCost + gridSoldSubTotal + p2pSoldSubTotal;
		soldkWh = soldkWh + gridSoldUnitsSubTotal + p2pSoldUnitsSubTotal;

		totalDays += bill.dailyFees?.timePeriod ? bill.dailyFees?.timePeriod : 0;
		totalDailyCost += bill.dailyFees?.cost ? bill.dailyFees?.cost : 0;

		additionalCharges +=
			totalDays * totalDailyCost +
			(gridBoughtFeesSubTotal + p2pBoughtFeesSubTotal - p2pSoldFeesSubTotal - gridSoldFeesSubTotal);
	});

	return {
		invoiceString: invoiceString,
		boughtCost: boughtCost,
		soldCost: soldCost,
		boughtkWh: Number.parseFloat(boughtkWh.toFixed(2)),
		soldkWh: Number.parseFloat(soldkWh.toFixed(2)),
		additionalCharges: additionalCharges,
	};
};
