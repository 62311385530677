import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { notifications } from "@/app/container/notifications";
import { useUserProfile } from "@/app/hooks/use-user-profile";
import { ProfileTemplatesDocument, useDeleteProfileTemplateMutation } from "@/app/types/generated/graphql";

import { UseDeleteBaselineProfileType } from "./delete-baseline-profile-modal.types";

/**
 * This function returns an object with props that allow to delete baseline profile from the given baseline.
 *
 * @param onConfirm function to execute after the profile is deleted successfully.
 * @param baselineProfileId the ID of the baseline profile to delete
 *
 * @return An object with the following properties:
 * - errorMessage: a string that is set when there is an error in deleting the baseline profile.
 * - loading: a boolean that is true when the function is waiting for a response from the API.
 * - onDelete: a function that deletes the baseline profile.
 */
export const useDeleteBaselineProfile: UseDeleteBaselineProfileType = ({ onConfirm, baselineProfileId }) => {
	const { t } = useTranslation();
	const [errorMessage, setErrorMessage] = useState<string | null>(null);
	const [deleteBaselineProfile, { loading }] = useDeleteProfileTemplateMutation();

	const { userProfile } = useUserProfile();

	const onDelete = useCallback(async () => {
		try {
			await deleteBaselineProfile({
				variables: {
					deleteProfileTemplateId: baselineProfileId,
					tenancyInfo: {
						applicationHostId: userProfile?.applicationHostId ?? "",
						tenantId: userProfile?.tenantId ?? "",
						tradingGroupId: userProfile?.tradingGroupId ?? "",
					},
				},
				refetchQueries: [ProfileTemplatesDocument],
			});

			notifications.success({
				message: t("Baseline Profile deleted successfully"),
			});
			onConfirm();
		} catch (e) {
			setErrorMessage(t("Unfortunately we encountered an error in deleting the baseline profile."));
		}
	}, [baselineProfileId, deleteBaselineProfile, onConfirm, t, userProfile]);

	return { errorMessage, loading, onDelete };
};
