import Api from "./api";
import ApolloClient from "./apolloClient";
import notifications from "./notifications";
import { lemTheme } from "./theme-override";

export const container = {
	api: Api(),
	apolloClient: ApolloClient(),
	notifications,
	lemTheme,
};
