import { UserRole, useUserProfileQuery } from "../types/generated/graphql";

export const useUserRoles = (identityProviderRef: string) => {
	const { data: userData } = useUserProfileQuery({
		variables: {
			identityProviderRef,
		},
		skip: !identityProviderRef,
	});

	return {
		roles: userData?.userProfile?.roles || [],
		isParticipant: userData?.userProfile?.roles?.includes(UserRole.ParticipantUser),
	};
};
