import { SelectTranslation, TableTranslation } from "@powerledger/ui-component-lib/dist-types";

import i18n from "../lib/i18n";
import { UserRole } from "../types/generated/graphql";

export const dateFormat = (date?: string | number, dateTimeString?: string) => {
	const dateObj = date ? new Date(date) : new Date();
	return dateObj.toLocaleDateString(dateTimeString || "en-au");
};

/**
 * Appends comma at thousand places and ensures decimal digits are displayed as specified by `decimalOpions` params
 * @param value is the number that needs to include comma at thousand places
 * @param decimalOptions provides access to control how many digits are included in the result after decimal places
 * @returns a string with comma separated large numbers and decimals included after rounding up as specified
 */
type DecimalOptions = { minimumFractionDigits?: number; maximumFractionDigits?: number };
export const commaSeparateNumberToThousandPlaces = (
	num: number | null | undefined,
	decimalOptions?: DecimalOptions,
): string | undefined => {
	if (isNaN(Number(num)) || num === undefined || num === null) {
		return undefined;
	}
	if (
		decimalOptions?.maximumFractionDigits &&
		decimalOptions?.minimumFractionDigits &&
		decimalOptions.maximumFractionDigits < decimalOptions.minimumFractionDigits
	) {
		decimalOptions.maximumFractionDigits = decimalOptions?.minimumFractionDigits;
	}
	return (Number(num) || 0).toLocaleString("en-US", { ...decimalOptions });
};

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const noop = () => {};

export const emailRegex = /[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[a-z]{2,4}$/;

export const userRoleLabels = Object.freeze({
	[UserRole.PlatformAdmin]: "Platform Admin",
	[UserRole.TenantAdmin]: "Tenant Admin",
	[UserRole.AppHostAdmin]: "Host Admin",
	[UserRole.ParticipantUser]: "Participant",
	[UserRole.Operator]: "Operator",
	[UserRole.CorporateUser]: "Corporate User",
	[UserRole.RetailerUser]: "Retailer User",
});
export const userRoleOptions = Object.freeze([
	{
		value: UserRole.PlatformAdmin,
		label: userRoleLabels.PlatformAdmin,
	},
	{
		value: UserRole.TenantAdmin,
		label: userRoleLabels.TenantAdmin,
	},
	{
		value: UserRole.AppHostAdmin,
		label: userRoleLabels.AppHostAdmin,
	},
	{
		value: UserRole.ParticipantUser,
		label: userRoleLabels.ParticipantUser,
	},
	{
		value: UserRole.Operator,
		label: userRoleLabels.Operator,
	},
	{
		value: UserRole.CorporateUser,
		label: userRoleLabels.CorporateUser,
	},
	{
		value: UserRole.RetailerUser,
		label: userRoleLabels.RetailerUser,
	},
]);

const adminRoles = [UserRole.PlatformAdmin, UserRole.TenantAdmin, UserRole.AppHostAdmin];
/**
 * Determines if our user is either:
 *  - a platform admin
 *  - a tenant admin, or
 *  - an app host admin
 * @param role
 */
export function userIsAdmin(role: UserRole) {
	return adminRoles.includes(role);
}

export const getDefaultTableTranslation = (translation?: TableTranslation) => {
	const { t } = i18n;

	return {
		item: translation?.item ?? t("item"),
		items: translation?.items ?? t("items"),
		noDataText: translation?.noDataText ?? t("No data"),
		pagination: translation?.pagination ?? {
			firstPage: t("first page"),
			previousPage: t("previous page"),
			nextPage: t("next page"),
			lastPage: t("last page"),
		},
	};
};

export const getDefaultSelectTranslation = (translation?: SelectTranslation): SelectTranslation => {
	const { t } = i18n;

	return {
		allSelected: t("All Selected"),
		loading: t("Loading..."),
		...translation,
	};
};

/**
 * Returns the input string with each word capitalized, as if it were a title.
 *
 * @param str - The string to be converted into title case.
 * @return The input string in title case.
 */
export const camelCaseToTitleCase = (str: string) => {
	// Add a space before each capital letter
	str = str.replace(/([A-Z])/g, " $1");

	// Capitalize the first letter of the string
	str = str.charAt(0).toUpperCase() + str.slice(1);

	return str;
};
