import { Box, Button, Flex, IconLoading, Input } from "@powerledger/ui-component-lib";
import { FC, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { PasswordStrengthBar, StrengthResult } from "../password-input";
import { InputWithActionProps } from "./input-with-action.types";

export const InputWithAction: FC<InputWithActionProps> = ({
	name = "",
	value,
	onInput,
	placeholder,
	action,
	status,
	showStrength = false,
	loading = false,
	bg = "secondary",
	type = "text",
	noButton = false,
	suffix,
	...props
}) => {
	const handlePerformAction = useCallback(() => {
		if (!loading && action?.onClick) action?.onClick();
	}, [action, loading]);

	const { t } = useTranslation();

	const variantBase = props.variant || "forms.inputWithAction";

	const checkStrength = (password: string) => {
		let standardStrength = 0;
		let extraStrength = 0;
		const missingRequirements = [];

		const specialCharacters = /[!~`@#$%^&*(),.?":{}|<>]/;
		const letters = /[a-z]/;
		const capitalLetters = /[A-Z]/;
		const numbers = /[0-9]/;

		if (!password) {
			return {
				strength: {
					standardStrength: 0,
					extraStrength: 0,
				},
				missingRequirements: [],
				length: 0,
			};
		}

		// Password Requirements
		password.length >= 12 ? standardStrength++ : missingRequirements.push(t("Minimum 12 characters"));
		letters.test(password) ? standardStrength++ : missingRequirements.push(t("Include a lower case letter"));
		numbers.test(password) ? standardStrength++ : missingRequirements.push(t("Include a number"));
		capitalLetters.test(password) ? standardStrength++ : missingRequirements.push(t("Include a capital letter"));
		specialCharacters.test(password) ? standardStrength++ : missingRequirements.push(t("No special characters found"));

		// Great Password condition
		password.length >= 18 && extraStrength++;

		return {
			strength: {
				standardStrength: standardStrength,
				extraStrength: extraStrength,
			},
			missingRequirements: missingRequirements,
			length: password.length,
		};
	};

	const [strengthResults, setStrengthResults] = useState<StrengthResult>({
		strength: {
			standardStrength: 0,
			extraStrength: 0,
		},
		missingRequirements: [""],
		length: 0,
	});

	return (
		<Flex variant={`${variantBase}.container`}>
			<Flex variant={`${variantBase}.inputContainer`}>
				<Box sx={{ position: "relative", width: "100%" }}>
					<Input
						{...props}
						sx={{
							pr: suffix ? 5 : [3, 4],
							borderBottomRightRadius: noButton ? 7 : [7, 0],
							borderTopRightRadius: noButton ? 7 : [7, 0],
						}}
						name={name}
						value={value}
						type={type}
						variant={`${variantBase}.inputField`}
						placeholder={placeholder}
						onInput={onInput}
						onChange={(event) =>
							showStrength &&
							setStrengthResults(() => checkStrength(event?.currentTarget ? event?.currentTarget?.value : ""))
						}
						onKeyUp={({ key }: { key: string }) => {
							if (key === "Enter") handlePerformAction();
						}}
					/>
					<Flex variant={`${variantBase}.suffix`}>{suffix}</Flex>
				</Box>
				{!noButton && (
					<Flex variant={`${variantBase}.inputContainer.buttonContainer`}>
						<Button
							variant={`${variantBase}.actionButton`}
							disabled={loading}
							onClick={() => {
								if (action?.onClick) action?.onClick();
							}}
						>
							{action?.content}
						</Button>
						{loading && (
							<Box variant={`${variantBase}.inputContainer.innerLoadingContainer`}>
								<IconLoading />
							</Box>
						)}
					</Flex>
				)}
				<Box variant={`${variantBase}.borderedContainer`} />
			</Flex>
			{showStrength && <PasswordStrengthBar strengthResult={strengthResults} />}
			{loading && (
				<Box variant={`${variantBase}.outerLoadingContainer`}>
					<IconLoading />
				</Box>
			)}
		</Flex>
	);
};
