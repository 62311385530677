import {
	Box,
	Flex,
	IconHandshake,
	IconLockDotted,
	IconSingleUser,
	ThemeUIStyleObject,
} from "@powerledger/ui-component-lib";
import { FC } from "react";

export type StepType = {
	icon: string;
	title: string;
};

const iconComponents: Record<string, JSX.Element> = {
	Handshake: <IconHandshake width={36} height={32} stroke="text" color="transparent" />,
	LockDotted: <IconLockDotted width={36} height={32} stroke="text" color="transparent" />,
	SingleUser: <IconSingleUser width={36} height={32} stroke="text" color="transparent" />,
};

export const Step: FC<{
	step: StepType;
	active: boolean;
	isCompleted: boolean;
	sx?: ThemeUIStyleObject;
}> = ({ step, active, isCompleted, sx }) => {
	const IconComponent = iconComponents[step.icon];

	return (
		<Flex sx={{ position: "relative", ...sx }}>
			<Flex
				sx={{
					alignItems: "center",
					justifyContent: "center",
					width: 6,
					height: 6,
					borderRadius: 4,
					transition: "base",
					...(active ? { bg: "secondary" } : { bg: "transparent" }),
					flexShrink: 0,
				}}
				className="stepIconContainer"
			>
				{IconComponent}
			</Flex>
			<Box sx={{ ml: 3 }}>
				<Box
					sx={{
						fontSize: 1,
						fontFamily: "MintGroteskV08",
						transition: "base",
						fontWeight: "bold",
						...(active ? { color: "primary" } : isCompleted ? { color: "textDarker" } : {}),
					}}
				>
					{step.title}
				</Box>
			</Box>
		</Flex>
	);
};
