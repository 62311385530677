import { Button, ButtonGroup, Flex, Heading, Text } from "@powerledger/ui-component-lib";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import { ResendInvitationViewPropType } from "./resend-invitation-modal.types";

export const ResendInvitationView: FC<ResendInvitationViewPropType> = ({
	errorMessage,
	handleOnResend,
	disabled,
	loading,
	onCancel,
}) => {
	const { t } = useTranslation();

	return (
		<Flex sx={{ flexDirection: "column", p: 3, height: 150, justifyContent: "space-around" }}>
			<Heading>{t("Are you sure you want to resend the invitation(s)?")}</Heading>
			{<Text sx={{ color: "warning", height: 1 }}>{t(errorMessage || "")}</Text>}
			<ButtonGroup>
				<Button variant="secondary" onClick={onCancel}>
					{t("Cancel")}
				</Button>
				<Button disabled={disabled} onClick={handleOnResend}>
					{loading ? t("Resending activation link...") : t("Confirm")}
				</Button>
			</ButtonGroup>
		</Flex>
	);
};
