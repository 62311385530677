import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { notifications } from "@/app/container/notifications";
import { useUserProfile } from "@/app/hooks/use-user-profile";
import { useProfileTemplateEnergyDataQuery, useProfileTemplatesQuery } from "@/app/types/generated/graphql";
import { convertEnergyDataToEnergyDataGraphDataSet } from "@/app/util/visualization-helper";

import { ProfileOption, UseBaselineProfileVisualization } from "./baseline-profile-visualization.types";

export const useBaselineProfileVisualization: UseBaselineProfileVisualization = () => {
	const { t } = useTranslation();
	const [selectedProfile, setSelectedProfile] = useState<ProfileOption | null>();

	const { userProfile, loading: userProfileLoading } = useUserProfile();
	const { data: baselineProfiles, loading: baselineProfilesLoading } = useProfileTemplatesQuery({
		variables: {
			where: {
				applicationHostId: userProfile?.applicationHostId ?? "",
				tenantId: userProfile?.tenantId ?? "",
				tradingGroupId: userProfile?.tradingGroupId ?? "",
			},
		},
		skip: userProfileLoading,
		onError: () => {
			notifications.error({
				message: t("Failed to fetch the list of baseline profiles"),
			});
		},
		onCompleted: (data) => {
			const firstBaselineProfile = data?.profileTemplates?.[0];
			if (!selectedProfile && firstBaselineProfile) {
				setSelectedProfile({
					label: firstBaselineProfile.name,
					value: firstBaselineProfile,
				});
			}
		},
	});

	const baselineProfileOptions = useMemo(() => {
		return (
			baselineProfiles?.profileTemplates?.map((baselineProfile) => ({
				label: baselineProfile.name,
				value: baselineProfile,
			})) ?? []
		);
	}, [baselineProfiles?.profileTemplates]);

	const { data: selectedProfileEnergyData, loading: selectedProfileEnergyDataLoading } =
		useProfileTemplateEnergyDataQuery({
			variables: {
				where: {
					applicationHostId: userProfile?.applicationHostId ?? "",
					tenantId: userProfile?.tenantId ?? "",
					tradingGroupId: userProfile?.tradingGroupId ?? "",
					id: selectedProfile?.value.id,
				},
			},
			skip: !selectedProfile?.value,
			onError: () => {
				notifications.error({
					message: t("Failed to fetch energy data for the selected baseline profiles"),
				});
			},
		});

	return {
		selectedProfile,
		setSelectedProfile,
		baselineProfileOptions,
		energyDataGraphDataSet: convertEnergyDataToEnergyDataGraphDataSet(
			selectedProfileEnergyData?.profileTemplates?.[0].energyData,
		),
		selectedProfileEnergyDataLoading,
		baselineProfilesLoading,
	};
};
