import { BarLayer, ResponsiveBar } from "@nivo/bar";
import { Box, getColor, PL_DEFAULT_THEME as defaultTheme } from "@powerledger/ui-component-lib";
import { line } from "d3-shape";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { commaSeparateNumberToThousandPlaces } from "@/app/util";

import { formatCurrency } from "../../tables/common-functions";
import { BoughtSoldType } from "./";

export const BoughtSoldGraph: FC<BoughtSoldType> = ({
	boughtCost,
	boughtkWh,
	soldCost,
	soldkWh,
	additionalCharges,
}) => {
	const { t } = useTranslation();
	const canChargesFit = additionalCharges / (boughtCost + soldCost + additionalCharges) > 0.2;

	const mainRed = getColor(defaultTheme, "redOrange");
	const mainBlue = getColor(defaultTheme, "graphBlue");
	const data = useMemo(
		() => [
			{
				key: "a",
				bought: boughtCost,
				sold: soldCost,
				charges: additionalCharges,
			},
		],
		[boughtCost, soldCost, additionalCharges],
	);
	const commonProps = {
		margin: { top: 0, right: canChargesFit ? 0 : 90, bottom: 90, left: 0 },
		data: data,
		indexBy: "key",
		keys: ["bought", "sold", "charges"],
		padding: 0.2,
		labelSkipWidth: 16,
		labelSkipHeight: 16,
		enableGridY: false,
		enableGridX: false,
	};

	const customLines: BarLayer<any> = ({ bars }) => {
		const lineGenerator = line();
		return (
			<>
				{data[0].bought ? (
					<>
						<path
							style={{ transition: "180ms, ease-out" }}
							key={"bought"}
							d={
								lineGenerator([
									[0, 0],
									[0, 180],
								]) || undefined
							}
							fill="none"
							stroke={mainBlue}
						/>
						<text fontSize={"16px"} key={"bought-line-1"} fill={"white"} fontWeight="bold" x={10} y={120}>
							{t("Electricity Bought")}
						</text>
						<text fontSize={"14px"} key={"bought-line-2"} fill={"white"} x={10} y={140}>
							{commaSeparateNumberToThousandPlaces(boughtkWh) + "kWh"}
						</text>
						<text fontSize={"14px"} key={"bought-line-3"} fill={"white"} x={10} y={160}>
							{formatCurrency(boughtCost)}
						</text>
					</>
				) : (
					<></>
				)}
				{data[0].sold ? (
					<>
						<path
							style={{ transition: "180ms" }}
							key={"sold"}
							d={
								lineGenerator([
									[bars[1].x + bars[1].width, 0],
									[bars[1].x + bars[1].width, 180],
								]) || undefined
							}
							fill="none"
							stroke={mainRed}
						/>
						<text
							textAnchor="end"
							key={"sold-line-1"}
							fontSize={"16px"}
							fill={"white"}
							fontWeight="bold"
							x={bars[1].x + bars[1].width - 10}
							y={120}
						>
							{t("Electricity Sold")}
						</text>
						<text
							textAnchor="end"
							key={"sold-line-2"}
							fontSize={"14px"}
							fill={"white"}
							x={bars[1].x + bars[1].width - 10}
							y={140}
						>
							{commaSeparateNumberToThousandPlaces(soldkWh) + "kWh"}
						</text>
						<text
							textAnchor="end"
							key={"sold-line-3"}
							fontSize={"14px"}
							fill={"white"}
							x={bars[1].x + bars[1].width - 10}
							y={160}
						>
							{formatCurrency(soldCost)}
						</text>
					</>
				) : (
					<></>
				)}
				{data[0].charges ? (
					<>
						<path
							style={{ transition: "180ms" }}
							key={"charges"}
							d={
								lineGenerator([
									[bars[2].x + bars[2].width, 0],
									[bars[2].x + bars[2].width, 180],
								]) || undefined
							}
							fill="none"
							stroke={mainBlue}
						/>

						<text
							textAnchor={canChargesFit ? "end" : "start"}
							key={"charges-line-1"}
							fontSize={"16px"}
							fill={"white"}
							fontWeight="bold"
							x={bars[2].x + bars[2].width - (canChargesFit ? 10 : -10)}
							y={120}
						>
							{t("Additional")}
						</text>
						<text
							textAnchor={canChargesFit ? "end" : "start"}
							key={"charges-line-2"}
							fontSize={"16px"}
							fill={"white"}
							fontWeight="bold"
							x={bars[2].x + bars[2].width - (canChargesFit ? 10 : -10)}
							y={140}
						>
							{t("Charges")}
						</text>
						<text
							textAnchor={canChargesFit ? "end" : "start"}
							key={"charges-line-3"}
							fontSize={"14px"}
							fill={"white"}
							x={bars[2].x + bars[2].width - (canChargesFit ? 10 : -10)}
							y={160}
						>
							{formatCurrency(additionalCharges)}
						</text>
					</>
				) : (
					<></>
				)}
			</>
		);
	};

	return (
		<Box sx={{ height: 165, width: "100%" }}>
			<ResponsiveBar
				{...commonProps}
				enableLabel={false}
				isInteractive={false}
				groupMode="stacked"
				layout="horizontal"
				defs={[
					{
						id: "gradientA",
						type: "linearGradient",
						color: mainBlue,
						colors: [
							{ offset: 0, color: `${mainBlue}aa` },
							{ offset: 100, color: "#00000000" },
						],
					},
					{
						id: "gradientB",
						type: "linearGradient",
						color: mainRed,
						colors: [
							{ offset: 0, color: `${mainRed}aa` },
							{ offset: 100, color: "#00000000" },
						],
					},
				]}
				fill={[
					{ match: { id: "bought" }, id: "gradientA" },
					{ match: { id: "charges" }, id: "gradientA" },
					{ match: { id: "sold" }, id: "gradientB" },
				]}
				colors={({ id }) => {
					return id === "sold" ? mainRed : mainBlue;
				}}
				padding={0}
				borderWidth={1}
				borderColor={{
					from: "color",
				}}
				axisTop={null}
				axisRight={null}
				axisBottom={null}
				axisLeft={null}
				layers={["grid", "axes", "bars", customLines]}
			/>
		</Box>
	);
};
