import { useApolloClient } from "@apollo/client";
import {
	Box,
	IconAdminUser,
	IconChartBar,
	IconCogWheel,
	IconDashboard,
	IconDocument,
	IconFDashboard,
	IconLogOut,
	IconMarkets,
	Menu,
	MenuItemProps,
} from "@powerledger/ui-component-lib";
import { memo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";

import { useOkta } from "@/app/hooks/use-okta";
import { usePrivateRoutes } from "@/app/hooks/use-private-routes";

import { NavigationRouteProps } from "../../../../secure-routes";
import { UserRole } from "../../../../types/generated/graphql";
import { oktaLogout } from "../../../../util";

const IconComponentsMap: Record<string, JSX.Element> = {
	Menu: <Menu />,
	IconPortfolio: <IconDashboard height={25} />,
	IconMarkets: <IconMarkets height={25} />,
	IconLogOut: <IconLogOut height={25} />,
	IconDocument: <IconDocument height={25} />,
	IconDashboard: <IconFDashboard height={25} />,
	IconChartBar: <IconChartBar height={25} />,
	IconCogWheel: <IconCogWheel height={25} />,
	IconAdminUser: <IconAdminUser height={25} />,
};

export const SidebarLink = ({ link, menuItemProps }: { link: NavigationRouteProps; menuItemProps?: MenuItemProps }) => {
	const { t } = useTranslation();
	const location = useLocation();
	const history = useHistory();

	const { icon, path, navTitle } = link;
	const { active } = menuItemProps || {};
	// We should allow nested routes, e.g. /dashboard/network to be active when /dashboard is active
	const isActivePath = (path && location.pathname.startsWith(path)) || active;

	return (
		<Menu.Item
			handleOnClick={() => path && history.push(path)}
			icon={icon && IconComponentsMap[icon]}
			active={isActivePath}
			sx={{
				"&:hover": {
					color: "secondary",
				},
				"&:focus": {
					color: "secondary",
					fontWeight: "700",
				},
				...(isActivePath && {
					borderRightStyle: ["none", null, "solid"],
					borderColor: ["none", null, "secondary"],
					color: "secondary",
					fontWeight: "400",
				}),
				svg: {
					transform: "scale(0.9)",
				},
			}}
			{...menuItemProps}
		>
			{t(navTitle)}
		</Menu.Item>
	);
};

export const SidebarLinks = memo(function SidebarLinkMemo({ roles }: { roles: UserRole[] }) {
	const privateRoutes = usePrivateRoutes(roles);

	return (
		<Box
			sx={{
				mt: 4,
			}}
		>
			<Menu>
				<>
					{privateRoutes.map((route) => (
						<SidebarLink key={route.path} link={route} />
					))}
					<LogoutLink />
				</>
			</Menu>
		</Box>
	);
});

const LogoutLink = memo(function LogoutMemo() {
	const { oktaAuth } = useOkta();
	const apolloClient = useApolloClient();
	const onClickOktaLogoutCallback = useCallback(() => oktaLogout({ oktaAuth, apolloClient }), [oktaAuth, apolloClient]);
	return (
		<Box
			sx={{
				mt: 4,
			}}
		>
			<SidebarLink
				link={{
					icon: "IconLogOut",
					navTitle: "Log out",
					permittedRoles: [],
				}}
				menuItemProps={{
					handleOnClick: onClickOktaLogoutCallback,
					active: false,
				}}
			/>
		</Box>
	);
});
