import {
	Button,
	Flex,
	Heading,
	IconLoading,
	IconLongArrow,
	LegacySelect,
	Paragraph,
	Text,
} from "@powerledger/ui-component-lib";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { mixed, object, string } from "yup";

import { getDefaultSelectTranslation } from "@/app/util";

import { Form, FormFieldLabel, FormInput } from "../../../../components";
import { MeterAssets } from "../../../../components/meter-assets";
import { MeterAssetType } from "../../../../types/generated/graphql";
import { StepLayout } from "../step-layout";
import { AccountDetailsViewType, FormValues } from "./account-details.types";

/**
 * Renders the account details view.
 *
 * @param loading - Indicates whether the view is in a loading state.
 * @param userProfile - The user profile data.
 * @param handleSetDetails - The function to handle setting the details.
 * @param onboardUserMeterError - The error that occurred while onboarding the user's meter.
 * @param updateOwnAccountError - The error that occurred while updating the user's account.
 * @param isParticipant - Indicates whether the user is a participant.
 * @return The JSX element representing the account details view.
 */
export const AccountDetailsView = ({
	loading,
	userProfile,
	retailerOptions,
	retailersLoading,
	handleSetDetails,
	onboardUserMeterError,
	updateOwnAccountError,
	isParticipant,
}: AccountDetailsViewType) => {
	const { t } = useTranslation();

	if (loading) return <IconLoading />;

	const initialFormValues: FormValues = {
		firstName: "",
		lastName: "",
		email: userProfile?.username || "",
		retailerId: "",
		nmi: "",
		meterAssetType: MeterAssetType.Consumer,
	};

	return (
		<Formik
			initialValues={initialFormValues}
			validationSchema={object().shape({
				firstName: string().hasValidName().required(t("First name is required")),
				lastName: string().hasValidName().required(t("Last name is required")),
				...(isParticipant
					? {
							retailerId: string().nullable(),
							nmi: string().hasValidMeterNumber().required("Electricity meter number is required"),
							meterAssetType: mixed<MeterAssetType>(),
					  }
					: {}),
			})}
			onSubmit={handleSetDetails}
			validateOnMount
		>
			{({ handleSubmit, isSubmitting, isValid, setFieldValue, values }) => (
				<Form onSubmit={handleSubmit} sx={{ height: "100%" }}>
					<StepLayout
						label={t("")}
						heading={t("My profile")}
						id="account-details"
						actions={
							<Flex sx={{ flexDirection: "column" }}>
								{updateOwnAccountError && (
									<Text sx={{ color: "negative", fontSize: 0, mb: 3 }}>{t("Something went wrong")}</Text>
								)}
								<Flex sx={{ alignItems: "center", justifyContent: "flex-start" }}>
									<Button type="submit" variant="pill" disabled={isSubmitting || !isValid}>
										<Text>{t("Next")}</Text>
										<IconLongArrow size={4} noSvgPositioning sx={{ ml: 2 }} />
									</Button>
									{isSubmitting && <IconLoading sx={{ ml: 2 }} />}
								</Flex>
							</Flex>
						}
					>
						<>
							<Flex sx={{ flexDirection: "column", alignItems: "flex-start" }}>
								<Heading
									sx={{
										mt: [2, 4],
										mb: [2, 3],
									}}
									as="h2"
								>
									{t("Personal Details")}
								</Heading>
								<Flex
									sx={{
										flexWrap: ["wrap"],
									}}
								>
									<Form.Item sx={{ flex: 1, mr: 1 }}>
										<FormFieldLabel name="firstName" label={t("First name*")}>
											<FormInput
												sx={{
													"::placeholder": {
														opacity: 0.5,
													},
												}}
												name="firstName"
												placeholder={t("Enter your first name")}
											/>
										</FormFieldLabel>
									</Form.Item>
									<Form.Item sx={{ flex: 1, ml: 1 }}>
										<FormFieldLabel name="lastName" label={t("Last name*")}>
											<FormInput
												sx={{
													"::placeholder": {
														opacity: 0.5,
													},
												}}
												name="lastName"
												placeholder={t("Enter your last name")}
											/>
										</FormFieldLabel>
									</Form.Item>
									<Form.Item sx={{ flex: 1, ml: 2 }}>
										<FormFieldLabel name="email" label={t("Email")}>
											<FormInput disabled name="email" />
										</FormFieldLabel>
									</Form.Item>
								</Flex>
							</Flex>

							{isParticipant && (
								<Flex sx={{ flexDirection: "column", alignItems: "flex-start" }}>
									<Heading sx={{ mt: [2, 4], mb: [2, 3] }} as="h2">
										{t("Retailer and Tariff")}
									</Heading>
									<Paragraph sx={{ mb: 2, width: "80%", color: "secondaryLight" }}>
										{t(
											"Please choose your retailer and electricity tariff from the dropdown menu. If you are unsure about your retailer or tariff, or if they are not listed, you can select 'None/Not sure/Not in this list' and update it later by contacting your admin.",
										)}
									</Paragraph>
									<Form.Item sx={{ width: "60%" }}>
										<FormFieldLabel name="retailerId">
											<LegacySelect
												name={"retailerId"}
												value={retailerOptions?.find((retailer) => retailer.value === values.retailerId)}
												translation={getDefaultSelectTranslation()}
												options={retailerOptions || []}
												isLoading={retailersLoading}
												onChange={(retailer) => setFieldValue("retailerId", retailer?.value)}
												hideSelectedOptions={false}
											/>
										</FormFieldLabel>
									</Form.Item>

									<Heading sx={{ mt: [2, 4], mb: [2, 3] }} as="h2">
										{t("Electricity Meter Number")}
									</Heading>
									<Paragraph sx={{ mb: 2, width: "80%", color: "secondaryLight" }}>
										{t(
											"To onboard to your LEM, you will need to provide the number found on your electricity bill or meter. If you are uncertain about the number, please feel free to contact your LEM admin or electricity retailer for assistance in obtaining the correct information.",
										)}
									</Paragraph>
									<Form.Item sx={{ width: "60%" }}>
										<FormFieldLabel name="nmi">
											<FormInput
												sx={{
													"::placeholder": {
														opacity: 0.5,
													},
												}}
												name={"nmi"}
												placeholder={t("Enter your Electricity Meter Number")}
											/>
										</FormFieldLabel>
									</Form.Item>

									<Heading sx={{ mt: [2, 4], mb: [2, 3] }} as="h2">
										{t("Assets")}
									</Heading>
									<Paragraph sx={{ mb: 2, width: "80%", color: "secondaryLight" }}>
										{t(
											"Please indicate if you have solar or battery storage devices on your property, as this will determine your role in the LEM. If you are unsure, you can skip this step, and the system will automatically assign you as a participant without solar or battery storage devices. You can always update your selection later by contacting your admin.",
										)}
									</Paragraph>
									<Form.Item sx={{ width: "70%", mt: 3 }}>
										<MeterAssets
											onChange={(value) => setFieldValue("meterAssetType", value)}
											value={values.meterAssetType}
										/>
									</Form.Item>
									<Text sx={{ color: "warning" }}>{t(onboardUserMeterError?.message || "")}</Text>
								</Flex>
							)}
						</>
					</StepLayout>
				</Form>
			)}
		</Formik>
	);
};
