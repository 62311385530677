import { Box, Link } from "@powerledger/ui-component-lib";
import { useTranslation } from "react-i18next";

import { useSupportEmail } from "../../hooks/useSupportEmail";
import { PRIVACY_POLICY_URL, TERMS_OF_ACCESS_URL } from "../../lib/endpoints";

export const Footer = () => {
	const { t } = useTranslation();
	const supportEmail = useSupportEmail();

	return (
		<Box sx={{ width: "100%", p: 2, position: "absolute", bottom: 2, left: 0 }}>
			<Box
				sx={{
					fontSize: 1,
					color: "text",
					mt: 4,
					marginLeft: 1,
					display: "flex",
					flexDirection: "row",
					alignItems: "flex-end",
					flexWrap: "wrap",
					marginTop: "auto",
					p: 3,
				}}
			>
				<Link
					href={TERMS_OF_ACCESS_URL}
					target="_blank"
					sx={{ color: "text", mx: 3, textDecoration: "none", "&:hover": { textDecoration: "underline" } }}
				>
					{t("Terms of Service")}
				</Link>
				<Link
					href={PRIVACY_POLICY_URL}
					target="_blank"
					sx={{
						color: "text",
						textDecoration: "none",
						mx: 3,
						"&:hover": { textDecoration: "underline" },
					}}
				>
					{t("Privacy Policy")}
				</Link>
				<Link
					href={`mailto:${supportEmail}`}
					target="_blank"
					sx={{
						color: "text",
						mx: 3,
						textDecoration: "none",
						"&:hover": { textDecoration: "underline" },
					}}
				>
					{t("Contact Us")}
				</Link>
				<Box sx={{ color: "textDarker", fontSize: 0, mx: 3 }}>
					{t(`©${new Date().getFullYear()} Powerledger. All rights reserved`)}
				</Box>
			</Box>
		</Box>
	);
};
