import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { notifications } from "@/app/container/notifications";
import { useResendUserActivationEmailMutation } from "@/app/types/generated/graphql";

import { UseResendInvitationType } from "./resend-invitation-modal.types";

/**
 * This function returns an object with props that allow to resend an invitation email to single user.
 *
 * @param onConfirm function to execute after the invitation is sent successfully.
 * @param userIds array of user IDs to resend invitation(s), currently only supporting a single user.
 *
 * @return An object with the following properties:
 * - disabled: a boolean that is true when an error has occurred or the resend link function is loading.
 * - errorMessage: a string that is set when there is an error in resending the activation link.
 * - loading: a boolean that is true when the function is waiting for a response from the API.
 * - handleOnResend: a function that sends a new email invitation to the provided users.
 */
export const useResendInvitation: UseResendInvitationType = ({ onConfirm, userIds }) => {
	const { t } = useTranslation();
	const [handleResendActivationLink, { loading }] = useResendUserActivationEmailMutation();
	const [errorMessage, setErrorMessage] = useState<string | null>(null);

	const handleOnResend = useCallback(async () => {
		try {
			await handleResendActivationLink({
				variables: {
					userId: userIds[0], // TODO (LEM-1448): Currently API only supports re-inviting a single user, modify when it supports re-inviting multiple user
				},
			});

			notifications.success({
				message: t("Invitation(s) sent successfully"),
			});
			onConfirm();
		} catch (e) {
			setErrorMessage(t("Unfortunately we encountered an error in resending the activation link."));
		}
	}, [handleResendActivationLink, onConfirm, t, userIds]);

	return { disabled: Boolean(errorMessage) || loading, errorMessage, loading, handleOnResend };
};
