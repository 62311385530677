/** @jsxImportSource theme-ui */
import { Button, Flex, IconRefresh, IconTrash, InfoSVG, Table, Text, Tooltip } from "@powerledger/ui-component-lib";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Column, Row } from "react-table";

import { ConfirmationModal } from "@/app/components/modals";
import { getDefaultTableTranslation } from "@/app/util";

import {
	AssignedMeterProfileConfirmationModal,
	AssignedMeterProfilesViewProps,
	MeterProfileType,
} from "./assigned-meter-profiles-table.types";

export const AssignedMeterProfilesTableView: FC<AssignedMeterProfilesViewProps> = ({
	loading,
	assignedMeterProfiles,
	setSelectedMeterProfileId,
	visibleModal,
	showDeleteMeterProfileConfirmationModal,
	showResetMeterProfileConfirmationModal,
	hideConfirmationModal,

	confirmDeleteMeterProfile,
	deleteMeterProfileLoading,

	confirmResetMeterProfile,
	resetToBaselineLoading,
}) => {
	const { t } = useTranslation();

	const tableColumns = useMemo<Column<MeterProfileType>[]>(
		() => [
			{
				Header: t("Profile Name"),
				accessor: "name",
				width: "70%",
				disableSortBy: true,
			},
			{
				id: "reset-to-baseline",
				Header: (
					<Flex sx={{ justifyContent: "center", width: "100%" }}>
						<Flex>
							<Text sx={{ width: "90%" }}>{t("Reset to baseline")}</Text>
							<Tooltip
								id={"assigned-meter-profiles-table-reset-to-baseline-tooltip"}
								content={
									<Text>
										{t(
											"The baseline profile is the starting profile of the meter forecast. This profile is adapted by historical meter readings over time. Selecting to reset a baseline profile sets the adapted forecast profile back to its initial baseline values. This removes the influence of historical meter readings and sets the forecast back to the baseline profile. Over time it will be adapted again by new meter readings. Select this option if the meter readings have not resulted in an accurate forecast for this meter.",
										)}
									</Text>
								}
								sx={{ width: "10%" }}
							>
								<InfoSVG />
							</Tooltip>
						</Flex>
					</Flex>
				),
				Cell: ({ row }: { row: Row<MeterProfileType> }) => {
					return (
						<Flex sx={{ justifyContent: "center", width: "90%" }}>
							<Button
								variant="secondary"
								onClick={() => {
									setSelectedMeterProfileId(row.original.id);
									showResetMeterProfileConfirmationModal();
								}}
								disabled={!row.original.id}
								sx={{
									borderBottom: 0,
									backgroundColor: "transparent",
									":disabled": {
										backgroundColor: "transparent",
										"div svg path": {
											stroke: "none",
										},
									},
								}}
							>
								<IconRefresh sx={{ width: 40, fill: "white", color: "white" }} />
							</Button>
						</Flex>
					);
				},
			},
			{
				id: "delete-profile",
				Cell: ({ row }: { row: Row<MeterProfileType> }) => {
					return (
						<Flex sx={{ justifyContent: "center" }}>
							<Button
								variant="secondary"
								onClick={() => {
									setSelectedMeterProfileId(row.original.id);
									showDeleteMeterProfileConfirmationModal();
								}}
								disabled={!row.original.id}
								sx={{
									borderBottom: 0,
									backgroundColor: "transparent",
									":disabled": {
										backgroundColor: "transparent",
										"div svg path": {
											// let only the cursor be not-allowed when disabled
											// keep the stroke unchanged when disabled
											stroke: "#FF7283",
										},
									},
								}}
							>
								<IconTrash color={"#FF7283"} stroke={"#FF7283"} />
							</Button>
						</Flex>
					);
				},
			},
		],
		[t, setSelectedMeterProfileId, showResetMeterProfileConfirmationModal, showDeleteMeterProfileConfirmationModal],
	);

	return (
		<Flex sx={{ flexDirection: "column", width: "100%", rowGap: 3, height: "52%" }}>
			<Text>{t("Forecast Profiles")}</Text>

			<Table
				loading={loading}
				columns={tableColumns}
				translation={getDefaultTableTranslation()}
				dataSource={assignedMeterProfiles ?? []}
				pagination={false}
				containerSx={{
					overflow: "hidden",
					"> div > table": {
						overflow: "scroll",
					},
				}}
			/>

			{/* Confirmation modal to reset the meter profile to its baseline value */}
			<ConfirmationModal
				confirmationTitle="Are you sure you want to reset the meter profile to its baseline value?"
				showModal={visibleModal === AssignedMeterProfileConfirmationModal.RESET_METER_PROFILE}
				onCancel={hideConfirmationModal}
				onConfirm={confirmResetMeterProfile}
				disabled={resetToBaselineLoading}
				confirmationButtonText={resetToBaselineLoading ? "Resetting meter profile..." : "Confirm"}
			/>

			{/* Confirmation modal to disassociate the meter profile from the selected meter */}
			<ConfirmationModal
				confirmationTitle="Are you sure you want to delete the associated meter profile?"
				showModal={visibleModal === AssignedMeterProfileConfirmationModal.DELETE_METER_PROFILE}
				onCancel={hideConfirmationModal}
				onConfirm={confirmDeleteMeterProfile}
				disabled={deleteMeterProfileLoading}
				confirmationButtonText={deleteMeterProfileLoading ? "Deleting meter profile..." : "Confirm"}
			/>
		</Flex>
	);
};
