import { Option } from "@powerledger/ui-component-lib";

import { MeterAssetType } from "@/app/types/generated/graphql";

import { ProfileClassLabel } from "../../../baseline-forecast-profile/profile-overview-table";
import { getParticipantSupportedProfileClassList } from "../../meter-profile-helper";

/**
 * Returns the options to select profile class for assigning a meter to profiles for a given participant type.
 *
 * @param participantType The type of participant.
 * @return An array of options containing the label and value for each supported profile class.
 */
export const getAssignMeterProfileClassOptions = (participantType: MeterAssetType): Option[] => {
	return getParticipantSupportedProfileClassList(participantType).map((profileClass) => ({
		label: ProfileClassLabel[profileClass],
		value: profileClass,
	}));
};
