import { Button } from "@powerledger/ui-component-lib";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { PublicLayout } from "../../../components/public-layout";

export const ResetPasswordConfirmation = () => {
	const { t } = useTranslation();
	const history = useHistory();

	return (
		<PublicLayout heading={t("Success. Your password has been reset")} subheading={t("Forgotten Password")}>
			<Button variant="pill" sx={{ mt: 3, px: 4 }} onClick={() => history.push("/login")}>
				{t("Log in to dashboard.")}
			</Button>
		</PublicLayout>
	);
};
