import { Defs } from "@nivo/core";
import { ComputedDatum, CustomLayerProps, Layer, ResponsiveLine } from "@nivo/line";
import { Box, Flex, getColor, PL_DEFAULT_THEME as defaultTheme, Text } from "@powerledger/ui-component-lib";
import { area } from "d3-shape";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import { CustomLegend } from "../../custom-legend";
import { generateYAxisRange, textDarkerColour, theme } from "../helpers";
import { DataPointType, DataType, StylesObject } from "../nivo-graphs.types";
import { defaultData } from "./default-data";
import { ExportImportGraphType } from "./export-import-graph.types";

function percentageDifference(v1: number, v2: number) {
	return Math.abs(((v1 - v2) / ((v1 + v2) / 2)) * 100);
}

const styleById: StylesObject = {
	LEM: {
		strokeDasharray: "4, 4",
		strokeWidth: 2,
		stroke: getColor(defaultTheme, "redOrange"),
	},
	Difference: {
		strokeWidth: 2,
		stroke: getColor(defaultTheme, "lightYellow"),
	},
	default: {
		strokeWidth: 2,
		stroke: getColor(defaultTheme, "lightBlue"),
	},
};
/**
 * Generates our percentage difference lines by comparing two sets of data.
 * Lines are drawn on the global maxima and minima for two sets of data.
 */
function generateDiffs(data: DataType[]) {
	// If there's nothing to compare, don't
	if (data.length < 2) {
		return {
			expandedData: data,
			gMaximaPercentageDifference: 0,
			gMinimaPercentageDifference: 0,
		};
	}

	const BAU = [...data[0].data];
	const LEM = [...data[1].data];

	// Each data set has an array of data points of x and y values
	// For BAU, get the highest and lowest y value
	// remove all missing values for x or y
	const BAUData = BAU.filter((d) => d.y !== null && d.x !== null);
	const LEMData = LEM.filter((d) => d.y !== null && d.x !== null);

	// We should return our highest data point but keep the value of the index in the array
	const BAUHighWithIndex: any = BAUData.reduce((prev, current, currentIndex) => {
		if (prev.y && current.y) {
			return prev.y > current.y ? prev : { ...current, index: currentIndex };
		}
		return prev;
	});

	const BAULowWithIndex: any = BAUData.reduce((prev, current, currentIndex) => {
		if (prev.y && current.y) {
			return prev.y < current.y ? prev : { ...current, index: currentIndex };
		}
		return prev;
	});

	const LEMHighWithIndex: any = LEMData.reduce((prev, current, currentIndex) => {
		if (prev.y && current.y) {
			return prev.y > current.y ? prev : { ...current, index: currentIndex };
		}
		return prev;
	});

	const LEMLowWithIndex: any = LEMData.reduce((prev, current, currentIndex) => {
		if (prev.y && current.y) {
			return prev.y < current.y ? prev : { ...current, index: currentIndex };
		}
		return prev;
	});
	// get our percentage difference for our BAUHigh and LEMHigh
	const gMaximaPercentageDifference = percentageDifference(BAUHighWithIndex.y, LEMHighWithIndex.y);
	const gMinimaPercentageDifference = percentageDifference(BAULowWithIndex.y, LEMLowWithIndex.y);
	// if the difference is zero for both, just return our data.
	if (gMaximaPercentageDifference === 0 && gMinimaPercentageDifference === 0) {
		return {
			expandedData: data,
			gMaximaPercentageDifference: 0,
			gMinimaPercentageDifference: 0,
		};
	}

	// LEMData and BAUData have the same length for their x series, which is a time series.
	// We want to track all x values between the two values, between LEMHigh and BAUHigh.
	// We have the indexes for these values, so we can use that to get the values between them.
	function getValuesBetweenIndexesWithConstantY(data: DataPointType[], start: number, end: number, constantY: number) {
		// If the start is greater than the end, swap them
		if (start > end) {
			[start, end] = [end, start];
		}
		const lineOffset = 6;
		// we want our data to start 3 points before the start, and end 3 points after the end so there is some continuation
		const startIndex = start - lineOffset < 0 ? 0 : start - lineOffset;
		const endIndex = end + lineOffset > data.length ? data.length : end + lineOffset;
		const valuesBetweenIndexes = data.slice(startIndex, endIndex);
		return valuesBetweenIndexes.map((d) => ({ x: d.x, y: constantY }));
	}

	const HighLEMValuesBetween = getValuesBetweenIndexesWithConstantY(
		LEMData,
		LEMHighWithIndex.index,
		BAUHighWithIndex.index,
		LEMHighWithIndex.y,
	);
	const HighBAUValuesBetween = getValuesBetweenIndexesWithConstantY(
		BAUData,
		LEMHighWithIndex.index,
		BAUHighWithIndex.index,
		BAUHighWithIndex.y,
	);
	const LowLEMValuesBetween = getValuesBetweenIndexesWithConstantY(
		LEMData,
		BAULowWithIndex.index,
		LEMLowWithIndex.index,
		LEMLowWithIndex.y,
	);
	const LowBAUValuesBetween = getValuesBetweenIndexesWithConstantY(
		BAUData,
		BAULowWithIndex.index,
		LEMLowWithIndex.index,
		BAULowWithIndex.y,
	);

	const expandedData = [...data];

	expandedData.push(
		{
			id: "BAUDifferenceHigh",
			data: HighBAUValuesBetween,
		},
		{
			id: "LEMDifferenceHigh",
			data: HighLEMValuesBetween,
		},
		{
			id: "BAUDifferenceLow",
			data: LowBAUValuesBetween,
		},
		{
			id: "LEMDifferenceLow",
			data: LowLEMValuesBetween,
		},
	);

	styleById[`BAUDifferenceHigh`] = {
		strokeWidth: 2,
		stroke: getColor(defaultTheme, "lightYellow"),
		strokeDasharray: "4, 4",
	};
	styleById[`LEMDifferenceHigh`] = {
		strokeWidth: 2,
		stroke: getColor(defaultTheme, "lightYellow"),
	};
	styleById[`BAUDifferenceLow`] = {
		strokeWidth: 2,
		stroke: getColor(defaultTheme, "lightYellow"),
		strokeDasharray: "4, 4",
	};
	styleById[`LEMDifferenceLow`] = {
		strokeWidth: 2,
		stroke: getColor(defaultTheme, "lightYellow"),
	};

	return {
		expandedData,
		gMaximaPercentageDifference,
		gMinimaPercentageDifference,
	};
}

const customLines: Layer = ({ series, lineGenerator }) => {
	return series.map(({ id, data, color }) => (
		<path
			key={id}
			d={lineGenerator(
				// @ts-ignore
				data.map((d: ComputedDatum) => {
					return {
						x: d.position.x,
						y: d.position.y,
					};
				}),
			)}
			fill="none"
			stroke={color}
			style={styleById[id] || styleById.default}
		/>
	));
};

type MaximaMinimaProps = {
	gMaximaPercentageDifference: number;
	gMinimaPercentageDifference: number;
};

const diffPercentLabels = (props: CustomLayerProps & MaximaMinimaProps) => {
	const { series, gMaximaPercentageDifference, gMinimaPercentageDifference } = props;

	if (gMaximaPercentageDifference === 0 && gMinimaPercentageDifference === 0) {
		return null;
	}

	const highDiffs = series.filter(
		// return the series which has the term DifferenceHigh in the id
		(value) => {
			return value.id.toString().includes("DifferenceHigh");
		},
	);
	const lowDiffs = series.filter((value) => {
		return value.id.toString().includes("DifferenceLow");
	});

	// get our highDiffYValue by comparing the first data point of each
	const highDiffYValue = Math.min(highDiffs[0].data[0].position.y || 0, highDiffs[1].data[0].position.y || 0);
	// x values are the same, so we just need the first value
	const highDiffsXValue = highDiffs[0].data[0].position.x;
	const lowDiffsYValue = Math.max(lowDiffs[0].data[0].position.y || 0, lowDiffs[1].data[0].position.y || 0);
	const lowDiffsXValue = lowDiffs[0].data[0].position.x;

	return (
		<>
			<text x={highDiffsXValue - 2} y={highDiffYValue - 8} fill={getColor(defaultTheme, "lightYellow")}>
				{/* eslint-disable-next-line i18next/no-literal-string */}
				{gMaximaPercentageDifference.toFixed(2)}%
			</text>
			<text x={lowDiffsXValue - 5} textAnchor={"end"} y={lowDiffsYValue} fill={getColor(defaultTheme, "lightYellow")}>
				{/* eslint-disable-next-line i18next/no-literal-string */}
				{gMinimaPercentageDifference.toFixed(2)}%
			</text>
		</>
	);
};

/**
 * Export Import Graph is for displaying the import and export data for
 * BAU and LEM
 */
export const ExportImportGraph: FC<ExportImportGraphType> = ({ dataSet = defaultData }) => {
	const { t } = useTranslation();

	const {
		expandedData: comparedData,
		gMaximaPercentageDifference,
		gMinimaPercentageDifference,
	} = generateDiffs(dataSet);

	const { range, interval } = generateYAxisRange(dataSet);

	const peakMarkers: Layer = ({ series, innerHeight, innerWidth }) => {
		const noonMarkers = (
			<>
				<text fontSize={"11px"} fill={textDarkerColour} x={-9} y={innerHeight + 35}>
					{t("AM")}
				</text>
				<text fontSize={"11px"} fill={textDarkerColour} x={innerWidth / 2 - 8} y={innerHeight + 35}>
					{t("PM")}
				</text>
			</>
		);
		if (series.length > 1) {
			const peakStart = series[0].data.filter((entry) => new RegExp(/15:00:00/).test(entry.data.x?.toString() || ""));
			const peakEnd = series[0].data.filter((entry) => new RegExp(/22:00:00/).test(entry.data.x?.toString() || ""));
			const onPeakData = [...peakStart, ...peakEnd]; //fixed times for now, will generate this properly when data is provided

			const areaGenerator = area<ComputedDatum>()
				.x((d) => d?.position.x)
				.y0(() => innerHeight)
				.y1(() => interval);
			const averageXPos = (onPeakData[0]?.position.x + onPeakData[1]?.position.x) / 2 - 20;

			return (
				<>
					<Defs
						defs={[
							{
								id: "gradientA",
								type: "linearGradient",
								colors: [
									{ offset: 50, color: `${getColor(defaultTheme, "secondary")}00` },
									{ offset: 100, color: "rgba(100, 143, 255, 0.5)" },
								],
							},
						]}
					/>
					{onPeakData.length > 0 && (
						<>
							<path
								d={areaGenerator(onPeakData) || undefined}
								fill="url(#gradientA)"
								fillOpacity={0.9}
								strokeWidth={2}
							/>
							<text fontSize={"11px"} fill={textDarkerColour} x={averageXPos} y={innerHeight - 7}>
								{t("On Peak")}
							</text>
							<text fontSize={"11px"} fill={textDarkerColour} x={0} y={innerHeight - 7}>
								{t("Off Peak")}
							</text>
						</>
					)}
					{noonMarkers}
				</>
			);
		}
		return noonMarkers;
	};

	return (
		<Box sx={{ height: 300, backgroundColor: "graphBackground", overflow: "hidden", borderRadius: 4, pb: 3 }}>
			{comparedData[0] ? (
				<>
					<CustomLegend
						data={
							comparedData.length > 2 ? [...dataSet, { id: "Difference", name: "Difference %", data: [] }] : dataSet
						}
						colors={styleById}
						sx={{
							left: 60,
							position: "relative",
							top: 20,
							height: "20px",
							overflow: "visible",
							width: "calc(100% - 60px)",
						}}
					/>
					<ResponsiveLine
						theme={theme}
						data={comparedData}
						margin={{ top: 40, right: 110, bottom: 60, left: 60 }}
						yFormat=" >-.2f"
						curve="stepBefore"
						layers={[
							peakMarkers,
							"grid",
							"markers",
							"axes",
							"areas",
							"crosshair",
							"points",
							"slices",
							"mesh",
							customLines,
							(props) => diffPercentLabels({ ...props, gMaximaPercentageDifference, gMinimaPercentageDifference }),
						]}
						xScale={{
							type: "time",
							format: "%Y-%m-%d %H:%M",
							useUTC: false,
							precision: "minute",
						}}
						enableSlices="x"
						sliceTooltip={({ slice }) => {
							const date = new Date(slice.points[0].data.x);
							return (
								<Box
									sx={{
										background: getColor(defaultTheme, "background"),
										padding: "9px 12px",
										border: "1px solid",
										borderColor: getColor(defaultTheme, "secondary"),
									}}
								>
									<Text sx={{ fontWeight: "bold", color: textDarkerColour }}>
										{`${date.toLocaleDateString()} ${date.getHours()}:${
											date.getMinutes() === 0 ? "00" : date.getMinutes()
										}`}
									</Text>
									{slice.points
										.filter((point) => !point.serieId.toString().toLowerCase().includes("difference"))
										.map((point) => (
											<Box
												key={point.id}
												sx={{
													color: point.serieColor,
													padding: "3px 0",
												}}
											>
												<Text sx={{ fontWeight: "bold" }}>{point.serieId}</Text>
												<Text sx={{ fontWeight: "bold" }}>{`: ${point.data.yFormatted}kw`}</Text>
											</Box>
										))}
								</Box>
							);
						}}
						xFormat="time:%Y-%m-%d %H:%M:%S.%L"
						yScale={{
							type: "linear",
							stacked: false,
							max: range.at(-1),
							min: range[0],
						}}
						axisRight={{
							legend: "Power exchange with superior grid (kw)",
							tickValues: range,
							legendOffset: 40,
						}}
						axisLeft={null}
						gridYValues={range}
						axisBottom={{
							format: "%H",
							tickValues: "every 1 hour",

							legendOffset: -12,
						}}
						enablePoints={false}
						enableGridX={false}
						colors={{ scheme: "category10" }}
					/>
				</>
			) : (
				<Flex
					sx={{
						height: "100%",
						justifyContent: "center",
						alignItems: "center",
						color: "textDarker",
					}}
				>
					<Text>{t("No data found")}</Text>
				</Flex>
			)}
		</Box>
	);
};
