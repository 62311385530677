import { PL_DEFAULT_THEME as defaultTheme } from "@powerledger/ui-component-lib";
import { getColor } from "@theme-ui/color";

import { DataType } from "./nivo-graphs.types";

export const textDarkerColour = getColor(defaultTheme, "textDarker");

export const theme = {
	background: "transparent",
	axis: {
		fill: textDarkerColour,
		ticks: {
			line: {
				fill: textDarkerColour,
			},
			text: {
				fill: textDarkerColour,
			},
		},
		legend: {
			text: {
				fill: textDarkerColour,
			},
		},
	},
	grid: {
		line: {
			stroke: textDarkerColour,
		},
	},
	crosshair: {
		line: {
			stroke: textDarkerColour,
		},
	},
};

/**
 *
 * @param data
 * Generates an array of numbers for the graph library to use and display as a Y-axis range
 */
export const generateYAxisRange = (data: DataType[]) => {
	const dataPoints = data.flatMap(({ data }) => data);

	const yPointValues = dataPoints.map(({ y }) => y || 0);

	const maxPoint = Math.max(...yPointValues);
	const minPoint = Math.min(...yPointValues);

	// We select the highest and lowest values in the dataset, divide by 10 and round for future calculations
	const maxInterval = Math.ceil(maxPoint / 10);
	const minInterval = Math.floor(minPoint / 10);

	// The true maximum and minimum range values rounded to the highest/lowest 10 for the max/min values
	let maxRange = maxInterval * 10;
	let minRange = minInterval * 10;
	// We calculate the total range between the highest and lowest value as a positive number, keeping it still at 1/10 so we can multiply it by 10 later and ensure even intervals that are multiples of 10
	const trueRange = Math.sign(minInterval) === -1 ? maxInterval - minInterval : maxInterval + minInterval;

	// Average between the max and min intervals
	const intervalAverage = Math.ceil(trueRange / 2) * 10;

	// This multiplier is used to dynamically increase the intervals into more legible increments as the range between the max and min values increase
	const rangeMultiplier = Math.round(trueRange / 10);

	// uses the % diff between the two Max and Min intervals which have been pre-rounded to multiples of 10 to have a cleaner y-axis
	const interval = Math.ceil((maxRange - minRange) / intervalAverage) * 10 * Math.max(rangeMultiplier, 0.5); // ensure that at small ranges, the interval is 5, then larger ranges go up by increasing intervals of 10 as range increases

	const intervalSteps = [];

	// the calculations for the range start by seeing how many intervals a negative minimum value is from 0, to ensure we always have a 0 if any values are negative
	const maxIntervalMultiplier = maxRange / interval;
	maxRange = interval * Math.ceil(maxIntervalMultiplier);
	const minIntervalMultiplier = minRange / interval;
	minRange = interval * Math.floor(minIntervalMultiplier);

	// if the maxRange or minRange is equal to the respective max/min value, the graph's visuals do not look good, this ensures that graphs where the max/min value is less than half a full interval from the top of the graph another full interval is added
	const maxIntervalStep = maxRange / interval - maxPoint / interval < 0.25 ? maxRange + interval : maxRange;
	const minIntervalStep = minPoint / interval - minRange / interval < 0.25 ? minRange - interval : minRange;

	for (
		let i = 0;
		minIntervalStep + interval * i <= maxIntervalStep; // loop until the iteration of the interval is greater than or equal to the max value we're expecting
		i++
	) {
		intervalSteps[i] = minIntervalStep + interval * i;
	}

	return { range: intervalSteps, interval: isNaN(interval) ? 0 : interval, canYAxisStartFromZero: minPoint >= 0 };
};
