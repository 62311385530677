import { Box, Flex, Heading } from "@powerledger/ui-component-lib";
import { useTranslation } from "react-i18next";

import { UserInfo } from "@/app/pages/settings/user-info";

import { PageHeaderViewProps } from "./page-headert.types";

export const PageHeaderView: React.FC<PageHeaderViewProps> = ({ pageTitle, clientLogo, userProfile }) => {
	const { t } = useTranslation();

	return (
		<Box variant="layouts.headers" as="header">
			<Box>
				{pageTitle && (
					<Heading sx={{ mb: 2 }} as="h1">
						{t(pageTitle)}
					</Heading>
				)}
				{userProfile && (
					<UserInfo
						firstName={userProfile?.firstName || ""}
						lastName={userProfile?.lastName || ""}
						email={userProfile?.username}
					/>
				)}
			</Box>
			<Flex sx={{ height: "100%", alignItems: "flex-start" }}>{clientLogo}</Flex>
		</Box>
	);
};
