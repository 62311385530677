import { Layout } from "@/app/components";

import { DashboardView } from "./dashboard-view";
import { useDashboard } from "./use-dashboard";

export const Dashboard = () => {
	const logic = useDashboard();
	return (
		<Layout pageTitle="Dashboard">
			<DashboardView {...logic} />
		</Layout>
	);
};
