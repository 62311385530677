import { useCallback, useMemo, useState } from "react";
import { SingleValue } from "react-select";

import { demoData } from "./demo-data";
import { AccountOption, AccountSummaryType, UseSettlementsFn } from "./settlements.types";

export const useSettlements: UseSettlementsFn = () => {
	const [selectedAccount, setSelectedAccount] = useState<AccountSummaryType>();
	const [startDate, setStartDate] = useState<Date>(new Date());
	const [displaySoldTable, setDisplaySoldTable] = useState(false);

	const accountOptions = useMemo(() => {
		const data: Array<{ value: AccountSummaryType; label: string }> = demoData
			? demoData.map((val: AccountSummaryType) => ({
					value: val,
					label: val.name,
			  }))
			: [];

		return data;
	}, []);

	const onAccountChange = useCallback(
		(val: SingleValue<AccountOption>) => {
			setSelectedAccount(val?.value);
		},
		[setSelectedAccount],
	);

	return {
		selectedAccount,
		startDate,
		setStartDate,
		displaySoldTable,
		setDisplaySoldTable,
		accountOptions,
		onAccountChange,
	};
};
