import { Box, Flex, Table } from "@powerledger/ui-component-lib";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Column, UseSortByColumnOptions } from "react-table";

import { SourcedEnergyQuantityType, TableType } from "..";
import { formatCurrency, formatNumberValue } from "../common-functions";
import { formatFeesData } from "./fees-table-functions";

export const FeesTable: FC<TableType> = ({ settlements }) => {
	const { t } = useTranslation();

	const formattedData = useMemo(() => {
		return formatFeesData(settlements);
	}, [settlements]);

	const tableColumns: (Column<SourcedEnergyQuantityType> & UseSortByColumnOptions<SourcedEnergyQuantityType>)[] = [
		{
			Header: t("Source") as string,
			accessor: "source",
			Cell: ({ value }: { value: string }) => {
				return <Box sx={{ color: "white" }}>{t(value || "")}</Box>;
			},
			disableSortBy: true,
		},
		{
			Header: t("") as string,
			accessor: "description",
			Cell: ({ value }: { value: string }) => {
				return <Box sx={{ color: "white" }}>{t(value || "")}</Box>;
			},
			disableSortBy: true,
		},
		{
			Header: t("Units") as string,
			accessor: "formattedUnits",
			Cell: ({ value }: { value: string }) => {
				return <Box sx={{ color: "white" }}>{t(value || "")}</Box>;
			},
			disableSortBy: true,
		},
		{
			Header: () => <Flex sx={{ alignItems: "center" }}>{t("Unit Price\n($)")}</Flex>,
			accessor: "rate",
			Cell: ({ value }: { value: string }) => {
				return <Box sx={{ color: "white" }}>{t(formatNumberValue(value, 3) || "")}</Box>;
			},
			disableSortBy: true,
		},
		{
			Header: t("Amount\n($)") as string,
			accessor: "amount",
			Cell: ({ row, value }) => {
				const lastRow = row.index === formattedData.length - 1;
				return (
					<Box sx={{ color: "white", textAlign: "right" }}>
						{t(lastRow ? formatCurrency(value) : formatNumberValue(value) || "0")}
					</Box>
				);
			},
			disableSortBy: true,
		},
	];
	return (
		<Box>
			<Table
				columns={tableColumns}
				dataSource={formattedData}
				translation={{
					item: t("item"),
					items: t("items"),
					noDataText: t("No data"),
					pagination: {
						firstPage: t("first page"),
						previousPage: t("previous page"),
						nextPage: t("next page"),
						lastPage: t("last page"),
					},
				}}
				pagination={false}
				noHover
			/>
		</Box>
	);
};
